import React, { useState, useEffect } from 'react';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
import { Table, Container, Row, Col } from 'react-bootstrap';

const TestSummary = () => {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  console.log(userdata,'USER DATA')

  const [testsSummary, setTestsSummary] = useState([]);
  const fetchTestSummary = async () => {
    await axios({
      method: 'GET',
      url: site_ip + '/getTestsSummary/' + userdata.company_id,
    })
      .then((res) => {
        console.log(res);
        setTestsSummary(res.data.Summary);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchTestSummary();
    //eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i> Test Summary</p>
        </Col>
        <Col md={12}>
          <div class="border bg-white p-3 h-100 mt-4">
            <Table striped responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Attempted</th>
                  <th>Pending</th>
                  <th>Expired</th>
                </tr>
              </thead>
              <tbody>
                {testsSummary.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.attempted}</td>
                    <td>{item.pending}</td>
                    <td>{item.expired}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TestSummary;
