import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { site_ip } from '../../globalSetting';
import axios from 'axios';
import ErrorModal from '../common/ErrorModal';
import SuccessModal from '../common/SuccessModal';
function RequestInterviewFeedback(props) {
  const userdata = JSON.parse(localStorage.getItem('userdata'))?.Content[0];
  const initialValues = {
    job_id: '',
    company_id: userdata.company_id === null ? 1 : userdata.company_id,
    candidate_id: '',
    interviewer_id: ''
  };

  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [interviewer, setInterviewer] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  
  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    fetchJobList();
    //eslint-disable-next-line
  }, []);

  const fetchJobList = async () => {
    const result = await axios.get(`${site_ip}/getJobMaster`);
    setJobs(result.data.Content);
  };
  
  const handleCompany = async (company_id, job_id) => {
    const result = await axios.post(`${site_ip}/getInterviewerByJobs`, {company_id: company_id, job_id: job_id});
    setInterviewer(result.data.Content);
    const result2 = await axios.post(
      `${site_ip}/getCandidatesByJob`,
      {company_id: company_id, job_id: job_id}
    );
    setCandidateList(result2.data.Content);
  };
  
  const proceed = () => {
    if (values.interviewer_id === '') {
      setErrorMessage('Please Select Interviewer');
    } else if (values.job_id === '') {
      setErrorMessage('Please Select Job');
    } else if (values.candidate_id === '') {
      setErrorMessage('Please Select Candidate');
    } else {
      axios({
        method: 'POST',
        url: site_ip + '/requestInterviewFeedback',
        data: {
          company_id: values.company_id,
          job_id: values.job_id,
          candidate_id: values.candidate_id,
          interviewer_id: values.interviewer_id
        },
        dataType: 'json',
      })
        .then(function (response) {
          setSuccessMessage("Feedback request sent successfully");
          setValues(initialValues);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "job_id"){
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
        candidate_id:"",
        interviewer_id:""
      }));
      handleCompany(values.company_id, value)
    }else{  
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Container>
      <Row className="admin-form-row">
        <div className="header">
          <h4>Request Interview Feedback</h4>
        </div>
        <div className="p-3 border bg-white">
          <Row>
            <Col md={2}></Col>
            <Col
              md={8}
            >
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Job</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="job_id"
                      value={values.job_id}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="">Select Job</option>
                      {jobs.map((value) => (
                        <option key={value.job_id} value={value.job_id}>
                          {value.job_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Candidate</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="candidate_id"
                      value={values.candidate_id}
                      onChange={handleChange}
                    >
                      <option value="">Select Candidate</option>
                      {candidateList.map((value) => (
                        <option key={value.candidate_id} value={value.candidate_id}>
                          {value.candidate_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Interviewer </Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="interviewer_id"
                      value={values.interviewer_id}
                      onChange={handleChange}
                    >
                      <option value="">Select Interviewer</option>
                      {interviewer.map((value) => (
                        <option
                          key={value.interviewer_id}
                          value={value.interviewer_id}
                        >
                          {value.interviewer_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <Button variant="primary" onClick={proceed}>
                    {values.updating ? 'Update' : 'Send'}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={2}></Col>
          </Row>
          <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
          <SuccessModal successMessage={successMessage} setSuccessMessage={() => setSuccessMessage(null)} />
        </div>
      </Row>  
    </Container>
  );
}

export default RequestInterviewFeedback;
