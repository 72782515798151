// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-card{
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    height: 120px;
    padding:15px;
}

.box-1{
    background-color: #006666;
}
.box-2{
    background-color: #802000;
}
.box-3{
    background-color: #002b80;
}
.box-4{
    background-color: #732673;
}

.box-heading{
    color:#ffffff;
    font-size: 16px;
}
.box-value{
    color:#ffffff;
    font-size: 30px;
    font-weight: bold;
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/backend/content/common.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,oDAAoD;IACpD,iDAAiD;IACjD,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;AACA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".dashboard-card{\n    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);\n    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);\n    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);\n    height: 120px;\n    padding:15px;\n}\n\n.box-1{\n    background-color: #006666;\n}\n.box-2{\n    background-color: #802000;\n}\n.box-3{\n    background-color: #002b80;\n}\n.box-4{\n    background-color: #732673;\n}\n\n.box-heading{\n    color:#ffffff;\n    font-size: 16px;\n}\n.box-value{\n    color:#ffffff;\n    font-size: 30px;\n    font-weight: bold;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
