import React, { useState } from 'react';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
import { Table, Container, Row, Col, Form, Button } from 'react-bootstrap';

const TestSummaryByDate = () => {
  const userdata = JSON.parse(localStorage.getItem('userdata'));

  const initialSearch = {
    date_from: '',
    date_to: '',
    company_id: userdata.company_id,
  };
  const [search, setSearch] = useState(initialSearch);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [testsSummaryByDate, setTestsSummaryByDate] = useState([]);

  const fetchTestSummaryByDate = async () => {
    await axios({
      method: 'POST',
      url: site_ip + '/getTestsSummaryByDate',
      data: {
        company_id: search.company_id,
        date_from: search.date_from,
        date_to: search.date_to,
      },
    })
      .then((res) => {
        console.log(res);
        setTestsSummaryByDate(res.data.Summary);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>

      <Row>
        <Col md={12}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Test Summary By Date</p>
        </Col>
      </Row>
      <Row className="admin-filter-box mt-3">
        <Col md={3}>
          <Form>
            <Form.Group>
              <Form.Label>Date From</Form.Label>
              <Form.Control
                name="date_from"
                value={search.date_from}
                onChange={handleChange}
                min={search.date_from}
                max={new Date().toISOString().split('T')[0]}
                type="date"
              ></Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col md={3}>
          <Form>
            <Form.Group>
              <Form.Label>Date To</Form.Label>
              <Form.Control
                name="date_to"
                value={search.date_to}
                min={search.date_from === '' ? '' : search.date_from}
                max={new Date().toISOString().split('T')[0]}
                onChange={handleChange}
                type="date"
              ></Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col md={2} className="mt-4" style={{paddingTop:"11px"}}>
          <button
            className="success"
            onClick={fetchTestSummaryByDate}
          >
            Search
          </button>
          <button className="close">
            Cancel
          </button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={12}>
          <div class="border bg-white p-3 h-100 mt-4">
            <Table striped responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Attempted</th>
                  <th>Pending</th>
                  <th>Expired</th>
                </tr>
              </thead>
              <tbody>
                {testsSummaryByDate.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.test_assigned_on}</td>
                    <td>{item.attempted}</td>
                    <td>{item.pending}</td>
                    <td>{item.expired}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TestSummaryByDate;
