import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './homepage.css'
import { site_ip } from './globalSettings';
const Home = () => {
    
    const navigate = useNavigate();

    const blankValues = {
        name: '',
        email: '',
        telephone: '',
        job_title: '',
        industry_id:''
    };
    const [values, setValues] = useState(blankValues)

    const reviews = [
        { _id: 1, label: 'Corporate Clients', numbers: '300+' },
        { _id: 2, label: 'Skills Assessed', numbers: '25+' },
        { _id: 3, label: 'Students Assessed Annually', numbers: '2.5 million' },
        { _id: 4, label: 'Exams Annually', numbers: '400+' },
        { _id: 5, label: 'Assessments Annually', numbers: '80+' },
        { _id: 6, label: 'Countries Reached', numbers: '20+' },
        { _id: 7, label: 'Industries Assessed', numbers: '1000+' },
        { _id: 8, label: 'Academic Clients', numbers: '15+' },
    ];

    const [formOpen, setFormOpen] = useState(false);

    const handleChange = (e) =>{
        let {name, value} = e.target;
        setValues({...values, [name]:value})
        if(name === 'job_title'){
            setValues({...values, [name]:value, industry_id:''})
            fetchIndustryList(value)
        }else{
            setValues({...values, [name]:value})
        }
    }

    useEffect(()=>{
        fetchJobList()
        setTimeout(()=>{
            setFormOpen(true);
        }, 3000)
    }, [])



    const [jobList, setJobList] = useState([])
    const fetchJobList = () => {
        axios.get(
            site_ip + '/getJobMaster'
        ).then((res) => {
            setJobList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const [industryList, setIndustryList] = useState([])
    const fetchIndustryList = (value) => {
        axios.get(
            site_ip + '/getJobListByIndustryId/'+value
        ).then((res) => {
            setIndustryList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const handleSubmit = () =>{
        console.log("Wait")
    }

    return (
        <div>
            <div className="fix-header sticky">
                <Container>
                    <Row style={{ margin: "0px" }}>
                        <Col md={3} style={{ padding: 0 }}>
                            <img src="/home/logo.png" className="header-logo-img" />
                        </Col>
                        <Col md={9}>
                            <div className="navigation">
                                <ul>
                                    <li>
                                        <a aria-current="page" className="active" href="/">Home</a>
                                    </li>
                                    <li>
                                        <a className="" href="/#solutions">Solutions</a>
                                    </li>
                                    <li>
                                        <a className="" href="/contents">Contents</a>
                                    </li>
                                    <li>
                                        <a className="" href="/contact-us">Contact Us</a>
                                    </li>
                                    <li>
                                        <a className="" href="/signup">Register</a>
                                    </li>
                                    <li>
                                        <button onClick={() => navigate('/login')} className="signinbtn">Login</button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Row style={{ margin: "0px", marginBottom: "40px" }} id="solutions">
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue" style={{paddingTop:"30px", paddingBottom:"15px", marginBottom:"20px"}}>
                        <Container>
                            <p className="text-center text-white heading-1">
                                About Us
                            </p>
                        </Container>
                    </div>
                    <Container>
                       
    <div>
      <h1>About Quantum Insight</h1>
      <p>
        Welcome to Quantum Insight, where we are committed to revolutionizing the way companies hire top-notch technical talent. Our platform provides cutting-edge tools and assessments to identify the brightest minds in the field of technology.
      </p>
      <h2>Our Mission</h2>
      <p>
        At Quantum Insight, our mission is to bridge the gap between employers and the most talented technical candidates. We believe that every candidate deserves an opportunity to showcase their skills, and every company deserves to find the perfect fit for their technical requirements. Our platform is designed to facilitate this process, making it efficient, reliable, and transparent.
      </p>
      <h2>What Sets Us Apart</h2>
      <ul>
        <li>
          <strong>Expertise:</strong> Our team comprises industry experts and technologists who understand the nuances of various technical domains. This enables us to create assessments that truly reflect real-world challenges and evaluate candidates with accuracy.
        </li>
        <li>
          <strong>Innovation:</strong> We constantly strive to stay ahead of the curve by leveraging the latest advancements in technology and assessment methodologies. This ensures that our platform remains state-of-the-art and delivers unparalleled results.
        </li>
        <li>
          <strong>Unbiased Evaluation:</strong> We believe in fair and unbiased evaluation. Our platform is designed to focus solely on a candidate's technical knowledge, skills, and problem-solving abilities, eliminating any bias that could influence the hiring decision.
        </li>
        <li>
          <strong>User-Friendly Interface:</strong> Hiring processes can be complex, but we are committed to making it as simple and user-friendly as possible. Our platform provides a seamless experience for both candidates and employers, allowing them to focus on what truly matters – finding the right match.
        </li>
      </ul>
      <h2>Our Commitment</h2>
      <ul>
        <li>
          <strong>Candidate Success:</strong> We want every candidate using our platform to succeed in showcasing their technical prowess. We offer valuable feedback and insights to help candidates identify areas of improvement and grow professionally.
        </li>
        <li>
          <strong>Employer Satisfaction:</strong> Your company's success is our success. We are dedicated to ensuring that the candidates you hire through our platform not only meet your technical requirements but also seamlessly integrate into your team.
        </li>
        <li>
          <strong>Continuous Improvement:</strong> We are constantly evolving our platform based on user feedback and the ever-changing technology landscape. This dedication to improvement ensures that our assessments remain relevant and reliable.
        </li>
      </ul>
      <h2>Join Us Today</h2>
      <p>Whether you are a candidate looking to prove your technical prowess or a company seeking to hire top talent, Quantum Insight is the platform for you. Join us on this transformative journey in shaping the future of technical recruitment.

        </p>                       </div>
                    </Container>
                </Col>
            </Row>
            <Row style={{ margin: "0px" }}>
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue top-bottom-footer-space">
                        <Container>
                            {/* <p className="text-center text-white heading-1">
                                Trusted by More Than 6000 Clients Worldwide
                            </p> */}
                            <Row>
                                <Col>
                                    <p className="footer-header">COMPANY</p>
                                    <a href="/" onClick={(e) => {e.preventDefault(); navigate("/about-us")}} className="footer-link">About Us</a>
                                    <a href="/" onClick={(e) => {e.preventDefault(); navigate("/our-team")}} className="footer-link">Our Team</a>
                                    {/* <a href="#" className="footer-link">News</a>
                                    <a href="#" className="footer-link">Customers</a>
                                    <a href="#" className="footer-link">Careers</a>
                                    <a href="#" className="footer-link">Information Security</a> */}
                                </Col>
                                {/* <Col>
                                    <p className="footer-header">PRODUCTS</p>
                                    <a href="#" className="footer-link">Talent Assessment Solutions</a>
                                    <a href="#" className="footer-link">Coding Assessment Solutions</a>
                                    <a href="#" className="footer-link">Hiring Solutions</a>
                                    <a href="#" className="footer-link">L&D Solutions</a>
                                    <a href="#" className="footer-link">Online Examination Solution</a>
                                    <a href="#" className="footer-link">Remote Proctoring Tools</a>
                                    <a href="#" className="footer-link">360-degree Feedback Tool</a>
                                    <a href="#" className="footer-link">Hackathon Tool</a>
                                    <a href="#" className="footer-link">Test Library</a>
                                </Col> */}
                                {/* <Col>
                                    <p className="footer-header">OTHERS</p>
                                    <a href="#" className="footer-link">Resources</a>
                                    <a href="#" className="footer-link">Blog</a>
                                    <a href="#" className="footer-link">Glossary</a>
                                    <a href="#" className="footer-link">Webinars</a>
                                    <a href="#" className="footer-link">Help & Support</a>
                                    <a href="#" className="footer-link">Accessibility</a>
                                    <a href="#" className="footer-link">COVID-19 Support</a>
                                </Col> */}
                                <Col></Col>
                                <Col>
                                    {/* <p className="footer-header">Partners</p>
                                    <a href="#" className="footer-link">Strategic Partnerships</a>
                                    <a href="#" className="footer-link">Integration Partners</a>
                                    <br /> */}
                                    <p className="footer-header">CALL US</p>
                                    <a href="#" className="footer-link"><i class="fas fa-mobile-alt"></i> +91-9988 77 66 55</a>
                                    <a href="#" className="footer-link"><i class="far fa-envelope"></i> liz@quantauminsight.in</a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </div>

    )
}

export default Home