import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Badge, Form } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../../globalSettings';
import { useNavigate, useLocation } from 'react-router-dom';
// import moment from 'moment';
import '../../../candidate/Exam.css';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
function ExamPage(props) {
  // const [questionList, setQuestionList] = useState([]);
  const location = useLocation();
  let navigate = useNavigate();
  console.log(location.state);
  const testType = location.state.test_type;
  // console.log(testType);
  // const [test_id, setTest_id] = useState(1);
  const [quest_id, setQuest_Id] = useState(0);
  // const [buttonStatus, setButtonStatus] = useState([]);
  // console.log(buttonStatus);
  const [optionStatus, setOptionStatus] = useState([]);
  const [option, setOption] = useState(0);
  // const [correct] = useState(0);
  const [attempted, setAttempted] = useState(0);
  // const [unattempted] = useState(location.state.data.length);
  // const [other, setOther] = useState([]);
  // const [candidateResponse, setCandidateResponse] = useState({});
  const [toggle, setToggle] = useState(false);

  const [submittedAnswers, setSubmittedAnswers] = useState([]);

  // create a blank arrays of option for attempted/unattempted
  const [responsestatus, setResponseStatus] = useState([]);
  const [useranswers, setUserAnswer] = useState([]);
  useEffect(() => {
    let blank_array = [];
    let blank_answers = [];
    for (let i = 0; i < location.state.data.length; i++) {
      blank_array.push(false);
      if (location.state.data[i]?.question_type_id === 1) {
        blank_answers.push([]);
      } else if (location.state.data[i]?.question_type_id === 2) {
        blank_answers.push(0);
      } else if (location.state.data[i]?.question_type_id === 3) {
        blank_answers.push(0);
      } else if (location.state.data[i]?.question_type_id === 4) {
        blank_answers.push('');
      }
    }
    // console.log(blank_answers);
    setUserAnswer(blank_answers);
    setResponseStatus(blank_array);
    //eslint-disable-next-line
  }, []);

  const updateAnswers = (e) => {
    if (!submittedAnswers.includes(quest_id)) {
      setToggle(!toggle);
      if (location.state.data[quest_id]?.question_type_id === 1) {
        let new_ans = useranswers;
        if (new_ans[quest_id].includes(parseInt(e))) {
          new_ans[quest_id] = new_ans[quest_id].filter(
            (item) => item !== parseInt(e)
          );

          if (new_ans[quest_id].length === 0) {
            let new_status = responsestatus;
            new_status[quest_id] = false;
            setResponseStatus(new_status);
          } else {
            let new_status = responsestatus;
            new_status[quest_id] = true;
            setResponseStatus(new_status);
          }
          setUserAnswer(new_ans);
        } else {
          new_ans[quest_id].push(parseInt(e));
          setUserAnswer(new_ans);
        }
      } else if (location.state.data[quest_id].question_type_id === 2) {
        let new_ans = useranswers;
        new_ans[quest_id] = e;
        setUserAnswer(new_ans);
      } else if (location.state.data[quest_id].question_type_id === 3) {
        let new_ans = useranswers;
        new_ans[quest_id] = e;
        setUserAnswer(new_ans);
      } else if (
        location.state.data[quest_id].question_type_id === 4 ||
        location.state.data[quest_id].question_type_id === 5
      ) {
        let new_ans = useranswers;
        new_ans[quest_id] = e.target.value;
        setUserAnswer(new_ans);
        // Change answer status
        if (e.target.value === '') {
          let new_status = responsestatus;
          new_status[quest_id] = false;
          setResponseStatus(new_status);
        } else {
          let new_status = responsestatus;
          new_status[quest_id] = true;
          setResponseStatus(new_status);
        }
      }
    }
  };
  const MailSentExam = () => {
    axios({
      method: 'post',
      url: site_ip + '/saveTestsResult',
      data: {
        candidate_id: localStorage.getItem('quantam_learner_id'),
        site_learner_id: localStorage.getItem('quantam_learner_id'),
        // job_id: null,
        test_type: testType,
        test_series_id: null,
        company_id: null,
        test_questions_count: location.state.data.length,
        test_date: new Date()
          .toISOString()
          .split('T')[0]
          .split('-')
          .reverse()
          .join('-'),
        test_time: null,
        co_test_id: null,
        time_taken: null,
      },
      dataType: 'json',
    }).then((response) => {
      // console.log(response);
      if (response.status === 200) navigate('/learner/thankyou');
    });
    // navigate('/thankyou');
    // const data = { candidate_id: location.state.data.otptoken_list?.data.Candidate };
    // const apiUrl = site_ip + `/sendCandidateMail/${data.candidate_id}`;
    // console.log(apiUrl);

    // fetch(apiUrl)
    //   .then((response) => response.json())
    //   .then((data) => {

    //     console.log('This data', data);
    //   });
  };

  const hChange1 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(location.state.data[quest_id].ques_option1);
    //console.log(location.state.data?[quest_id].ques_option1);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };
  const hChange2 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(location.state.data[quest_id].ques_option2);
    // console.log(location.state.data?[quest_id].ques_option2);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }

    options[quest_id] = true;
    setResponseStatus(options);
  };

  const hChange3 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(location.state.data[quest_id].ques_option3);
    // console.log(location.state.data?[quest_id].option3);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };
  const hChange4 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(location.state.data[quest_id].ques_option4);
    // console.log(location.state.data?[quest_id].ques_option4);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };

  useEffect(() => {
    // console.log(optionStatus);
    setOptionStatus(optionStatus);
    //eslint-disable-next-line
  }, []);
  // let buttonList = buttonStatus.map((item, index) => {

  //   if (item == 0) {
  //     return (
  //       <span>{index + 1}</span>
  //     )
  //   } });

  let buttonList = responsestatus.map((item, index) => {
    return (
      // <span></span>
      <div className={item ? 'attempted' : 'unattempted'}>{index + 1}</div>
    );
  });

  const submitUserAnswer = () => {
    // console.log(useranswers);
    let submitted_answers = submittedAnswers;
    submitted_answers.push(quest_id);
    setSubmittedAnswers(submitted_answers);

    let user_response = null;
    let desc_ans = null;
    if (location.state.data[quest_id].question_type_id === 4) {
      desc_ans = useranswers[quest_id];
    } else if (location.state.data[quest_id].question_type_id === 3) {
      user_response = {
        ans: useranswers[quest_id].toString(),
      };
    } else if (location.state.data[quest_id].question_type_id === 2) {
      user_response = {
        ans: useranswers[quest_id].toString(),
      };
    } else if (location.state.data[quest_id].question_type_id === 1) {
      user_response = {
        ans: useranswers[quest_id].sort().toString(),
      };
    }
    axios({
      method: 'post',
      url: site_ip + '/saveTestsResponses',
      data: {
        candidate_id: localStorage.getItem('quantam_learner_id'),
        site_learner_id: localStorage.getItem('quantam_learner_id'),
        test_attempt_id: null,
        subject_id: location.state.data[quest_id].subject_id,
        topic_id: location.state.data[quest_id].topic_id,
        test_type: testType,
        test_series_id: null,
        ques_type: null,
        question_id: null,
        co_question_id: null,
        attempt_status: 3,
        gain_marks: location.state.data[quest_id].marks,
        time_taken: null,
        cand_responses: user_response,
        cand_resp_desc_ans: desc_ans,
        correct_yn: null,
        // question_id: location.state.data[quest_id].question_id,
        // attempt_status: 3,
        // gain_marks: location.state.data[quest_id].marks,
        // time_taken: location.state.data[quest_id].time_allowed,
        // subject_id: location.state.data[quest_id].subject_id,
        // topic_id: location.state.data[quest_id].topic_id,
        // cand_responses: user_response,
        // can_resp_desc_ans: desc_ans,
        // candidate_id: location.state.data.otptoken_list?.data.Candidate,
        //option_id:option,
        // co_question_id: location.state.data[quest_id].question_id,
        // job_id: location.state.data.instruct_list?.Tests[test_type].job_id,
        // test_attempt_id: location.state.data.attempt_list?.data.Test,
        // exam_type: location.state.data.instruct_list?.Tests[test_type].test_type,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        let new_question = quest_id + 1;
        if (quest_id < location.state.data.length - 1) {
          setQuest_Id(new_question);
          setOption(0);
        } else {
          // alert('Quiz completed.');
          MailSentExam();
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const Next = () => {
    if (!submittedAnswers.includes(quest_id)) submitUserAnswer();
    else MailSentExam();
  };

  const renderQuestion = (text) => {
    return '';
  };

  const renderOptionOne = (text) => {
    return '';
  };
  const renderOptionTwo = (text) => {
    return '';
  };
  const renderOptionThree = (text) => {
    return '';
  };
  const renderOptionFour = (text) => {
    return '';
  };
  const children = ({ remainingTime }) => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    return `${hours}:${minutes}:${seconds}`;
  };
  return (
    <>
      <Container fluid className={'exam-background'}>
        <Row className="align-items-stretch h-100">
          <div className="w-100 mx-auto bg-light headertop exam-header">
            <div class="left-box  d-flex align-items-center pt-2">
              <div class="d-inline-block align-middle mar-t12">
                <h4 class="mar-t0 mar-b8">
                  {/* {location.state.data.instruct_list?.Tests[test_type].test_name} */}
                </h4>
              </div>
              <div class="ms-auto text-end">
                <h6>
                  {parseInt(quest_id) + 1}/{location.state.data?.length}{' '}
                  Question
                </h6>
                {/* <span><Stopwatch></Stopwatch></span> */}
              </div>
            </div>
          </div>
          <Col md={9} className="mx-auto my-3">
            <div className="border p-4 px-3 question-box">
              <div class="row">
                <div class="col-md-12">
                  <div style={{ height: '62vh', overflowY:'auto' }}>
                    <div className="p-3">
                      <p class="question" style={{ fontWeight: 'bold' }}>
                        Question No. {parseInt(quest_id) + 1}
                      </p>
                      <p class="question">
                        <td>
                          {renderQuestion(
                            location.state.data[quest_id]?.question
                          )}
                        </td>
                      </p>
                      {location.state.data[quest_id]?.question_type_id === 1 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="checkbox"
                                id={location.state.data[quest_id].ques_option1}
                                value={option}
                                label={renderOptionOne(
                                  location.state.data[quest_id].ques_option1
                                )}
                                checked={useranswers[quest_id].includes(1)}
                                onChange={(e) => {
                                  hChange1('ques_option1');
                                  updateAnswers(1);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                name={quest_id}
                                type="checkbox"
                                id={option}
                                label={renderOptionTwo(
                                  location.state.data[quest_id].ques_option2
                                )}
                                checked={useranswers[quest_id].includes(2)}
                                onChange={(e) => {
                                  hChange2('ques_option2');
                                  updateAnswers(2);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="checkbox"
                                id={option}
                                label={renderOptionThree(
                                  location.state.data[quest_id].ques_option3
                                )}
                                checked={useranswers[quest_id].includes(3)}
                                onChange={(e) => {
                                  hChange3('ques_option3');
                                  updateAnswers(3);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="checkbox"
                                id={option}
                                label={renderOptionFour(
                                  location.state.data[quest_id].ques_option4
                                )}
                                checked={useranswers[quest_id].includes(4)}
                                onChange={(e) => {
                                  hChange4('ques_option4');
                                  updateAnswers(4);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {location.state.data[quest_id]?.question_type_id === 2 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={location.state.data[quest_id].ques_option1}
                                value={option}
                                label={renderOptionOne(
                                  location.state.data[quest_id].ques_option1
                                )}
                                checked={useranswers[quest_id] === 1}
                                onChange={(e) => {
                                  hChange1('ques_option1');
                                  updateAnswers(1);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                name={quest_id}
                                type="radio"
                                id={option}
                                label={renderOptionTwo(
                                  location.state.data[quest_id].ques_option2
                                )}
                                checked={useranswers[quest_id] === 2}
                                onChange={(e) => {
                                  hChange2('ques_option2');
                                  updateAnswers(2);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="radio"
                                id={option}
                                label={renderOptionThree(
                                  location.state.data[quest_id].ques_option3
                                )}
                                checked={useranswers[quest_id] === 3}
                                onChange={(e) => {
                                  hChange3('ques_option3');
                                  updateAnswers(3);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="radio"
                                id={option}
                                label={renderOptionFour(
                                  location.state.data[quest_id].ques_option4
                                )}
                                checked={useranswers[quest_id] === 4}
                                onChange={(e) => {
                                  hChange4('ques_option4');
                                  updateAnswers(4);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {location.state.data[quest_id]?.question_type_id === 3 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={'True'}
                                value={option}
                                label={'True'}
                                checked={useranswers[quest_id] === 1}
                                onChange={(e) => {
                                  hChange1('True');
                                  updateAnswers(1);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={'False'}
                                value={option}
                                label={'False'}
                                checked={useranswers[quest_id] === 2}
                                onChange={(e) => {
                                  hChange2('False');
                                  updateAnswers(2);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {location.state.data[quest_id]?.question_type_id === 4 ||
                      location.state.data[quest_id]?.question_type_id === 5 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="p-2 w-100 h-100 answers">
                              <Form.Control
                                as="textarea"
                                onChange={(e) => updateAnswers(e)}
                                value={useranswers[quest_id]}
                                placeholder="Answer here..."
                                style={{ height: '40vh' }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div class="btnbox d-flex align-items-center mt-3 top-line">
                <Col md={3}>
                  {quest_id > 0 ? (
                    <Button
                      onClick={() => {
                        setQuest_Id(parseInt(quest_id) - 1);
                      }}
                      variant="outline-danger"
                    >
                      Back
                    </Button>
                  ) : null}{' '}
                </Col>
                <Col md={6} style={{ textAlign: 'center' }}>
                  {quest_id !== parseInt(location.state.data.length) - 1 ? (
                    submittedAnswers.includes(quest_id) ? null : (
                      <Button
                        disabled={
                          responsestatus[quest_id] === true ? false : true
                        }
                        className="btn ms-3 toggle-btn"
                        onClick={Next}
                      >
                        Save & Next
                      </Button>
                    )
                  ) : null}

                  {quest_id === parseInt(location.state.data.length) - 1 ? (
                    <Button
                      disabled={
                        responsestatus[quest_id] === true ? false : true
                      }
                      className="btn ms-3 toggle-btn"
                      onClick={Next}
                    >
                      Finish
                    </Button>
                  ) : null}
                </Col>
                <Col md={3} style={{ textAlign: 'right' }}>
                  {quest_id !== parseInt(location.state.data.length) - 1 ? (
                    <Button
                      onClick={() => {
                        setQuest_Id(parseInt(quest_id) + 1);
                      }}
                      variant="primary"
                    >
                      Next
                    </Button>
                  ) : null}
                </Col>
                <div class="mx-auto">
                  {/* <Link to="/" className="btn btn-danger ms-3">
                    Next
                       </Link>*/}
                </div>
                {/*<Link to="/thankyou" className="btn btn-danger  ms-3">
                  Finish
                      </Link>*/}
              </div>
            </div>
          </Col>
          <Col md={3} className="my-3">
            <div
              class="bg-light border p-3 h-30 fix-right-nav question-box"
              style={{ textAlign: 'center' }}
            >
              <div style={{ width: '120px', display: 'inline-block' }}>
                <CountdownCircleTimer
                  isPlaying
                  size={120}
                  duration={3000}
                  colors={[
                    ['#004777', 0.33],
                    ['#F7B801', 0.33],
                    ['#A30000', 0.33],
                  ]}
                  children={children}
                >
                  {/* {({ remainingTime }) => console.log(remainingTime)} */}
                </CountdownCircleTimer>
              </div>
            </div>
            <div
              class="my-3 bg-light border p-3 fix-right-nav question-box"
              style={{ height: 'calc(100% - (3rem + 120px)' }}
            >
              <div class="d-flex align-items-center border-bottom mb-2 pb-2 px-2">
                <span class="me-4 d-flex align-items-center">
                  <Badge pill className="badge bg-success rounded-0 me-2">
                    {attempted}
                  </Badge>
                  <span style={{ fontSize: '11px' }}>Attempted</span>
                </span>
                <span class="d-flex align-items-center">
                  <Badge
                    pill
                    className="badge bg-white text-dark border rounded-0 me-2"
                  >
                    {parseInt(location.state.data.length) - attempted}
                  </Badge>
                  <span style={{ fontSize: '11px' }}>Unattempted</span>
                </span>
              </div>
              <div
                class="numbering-block d-flex flex-wrap align-items-center"
                style={{ display: 'flex' }}
              >
                {buttonList}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ExamPage;
