import React, {useState} from 'react';

// import './sidebar1.scss';
import './App.css';
import {useNavigate, Link} from 'react-router-dom';

function LeftSideBar() {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);
  const [sidebarActive, setSidebarActive] = useState(0);
  const openMenuMenu = (number) =>{
    setActiveMenu(number)
    if(number === 1){
      // setSidebarActive(1);
      navigate("/exam/dashboard");
    } else if(number === 2){
      setSidebarActive(2);
    } else if(number === 3){
      setSidebarActive(3);
    } 
    // else if(number === 4){
    //   navigate("/learner/test-series");
    // } else if(number === 5){
    //   navigate("/learner/dashboard");
    // } else if(number === 6){
    //   navigate("/learner/referral-list");
    // } else{
      // setSidebarActive(0);
      // navigate("/learner/dashboard");

    // }
  }    

  const logOut = () => {
    localStorage.removeItem('loginid');
    localStorage.removeItem('userdata');
    window.location.href = '/learner';
  };

  return (
    <div className="new-sidebar-panel bg-dark-blue">
      <div className="main-sidebar bg-dark-blue">
        <button className={activeMenu === 1 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(1)}>
          <i class="menu-icon fas fa-tachometer-alt"></i>
          <p>Dashboard</p>
        </button>
        <button className={activeMenu === 2 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(2)}>
          <i class="menu-icon fas fa-user-graduate"></i>
          <p>Common Exam</p>
        </button>
        <button className={activeMenu === 3 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(3)}>
          <i class="menu-icon far fa-list-alt"></i>
          <p>Master Setup</p>
        </button>
        <button className={activeMenu === 9 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => logOut()}>
          <i class="menu-icon fas fa-sign-out-alt"></i>
          <p>Logout</p>
        </button>
      </div>
      <div className={sidebarActive === 0 ? "submenu-sidebar" : "submenu-sidebar active"}>
        <button className="sidebar-close-btn" onClick={() => setSidebarActive(0)}><i class="fas fa-times"></i></button>
        <div style={{height:"20px"}}></div>
        {activeMenu === 2 ?
          <>
            <Link to="/exam/exam-pat-setup">Exam Pattern Setup</Link>
            <Link to="/exam/exam-setup">Exam Setup</Link>
            <Link to="/exam/exam-listing">Exam Listing</Link>
            <Link to="/exam/registeration-by-exam">Exam Reg. List by Exam</Link>
            <Link to="/exam/appeared-students">Student Appeared for Exam</Link>
            <Link to="/exam/payment-listing">Payment Detail Listing</Link>
            <Link to="/exam/student-scores-listing">Student Score</Link>
            <Link to="/exam/ranking-list">Student Ranking List</Link>
            <Link to="/exam/prize-list">Student Prize List</Link>
          </> : null}
        {activeMenu === 3 ?
          <>
            <Link to="/exam/master/subject-list">Subject Listing</Link>
            <Link to="/exam/master/topic-list">Topic Listing</Link>
            <Link to="/exam/master/questions-list">Questions Listing</Link>
          </> : null}
      </div>
    </div>
  );
}

export default LeftSideBar;
