import React, {useState} from 'react';

// import './sidebar1.scss';
import './App.css';
import {useNavigate} from 'react-router-dom';

function LeftSideBar() {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);
  const [sidebarActive, setSidebarActive] = useState(0);
  const openMenuMenu = (number) =>{
    setActiveMenu(number)
    if(number === 1){
      // setSidebarActive(1);
      navigate("/learner/dashboard");
    } else if(number === 2){
      navigate("/learner/custom-test");
    } else if(number === 3){
      navigate("/learner/practice-test");
    } else if(number === 4){
      navigate("/learner/test-series");
    } else if(number === 5){
      navigate("/learner/dashboard");
    } else if(number === 6){
      navigate("/learner/referral-list");
    } else{
      // setSidebarActive(0);
      navigate("/learner/dashboard");

    }
  }    

  const logOut = () => {
    localStorage.removeItem('loginid');
    localStorage.removeItem('userdata');
    window.location.href = '/learner';
  };

  return (
    <div className="new-sidebar-panel bg-dark-blue">
      <div className="main-sidebar bg-dark-blue">
        <button className={activeMenu === 1 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(1)}>
          <i class="menu-icon fas fa-tachometer-alt"></i>
          <p>Dashboard</p>
        </button>
        <button className={activeMenu === 2 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(2)}>
          <i class="menu-icon fas fa-edit"></i>
          <p>Custom Test</p>
        </button>
        <button className={activeMenu === 3 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(3)}>
          <i class="menu-icon fas fa-diagnoses"></i>
          <p>Take Practice Test</p>
        </button>
        <button className={activeMenu === 4 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(4)}>
          <i class="menu-icon fas fa-clipboard-list"></i>
          <p>Test Series</p>
        </button>
        <button className={activeMenu === 5 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(5)}>
          <i class="menu-icon fas fa-chart-line"></i>
          <p>Analytics</p>
        </button>
        <button className={activeMenu === 6 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(6)}>
          <i class="menu-icon fas fa-people-arrows"></i>
          <p>Referral</p>
        </button>
        <button className={activeMenu === 9 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => logOut()}>
          <i class="menu-icon fas fa-sign-out-alt"></i>
          <p>Logout</p>
        </button>
      </div>
    </div>
  );
}

export default LeftSideBar;
