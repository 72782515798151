import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import { FileDrop } from 'react-file-drop';
import { site_ip } from '../../globalSetting';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import ErrorModal from '../common/ErrorModal';
import SuccessModal from '../common/SuccessModal';
import { useParams, useNavigate } from 'react-router-dom';
export default function AddJobDescription() {
  const navigate = useNavigate();
  const params = useParams();
  const [values, setValues] = useState({
    jd_title: "",
    experience_range: "",
    job_description: "",
    jd_file_name: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeWithName = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const [industry, setIndustry] = useState([]);
  const [job, setJob] = useState([]);
  const getIndustry = () => {
    axios
      .get(site_ip + '/getIndustryList')
      .then((res) => {
        // console.log(res)
        setIndustry(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getJobs = () => {
    axios
      .get(site_ip + '/getJobMaster')
      .then((res) => {
        // console.log(res);
        setJob(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getJdById(params.id);
    getIndustry();
    getJobs();
  }, []);

  const getJdById = (jd_id) => {
    axios
      .get(site_ip + '/getJobDescriptionById/' + jd_id)
      .then((res) => {
        setValues(res.data.Content);
    })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    let req = {...values};
    
    if(req.jd_title.trim() === ""){
      setErrorMessage("Please enter job description title.")
      return;
    } else if(req.experience_range === ""){
      setErrorMessage("Please select experience range.")
      return;
    }else if(req.job_description.trim() === ""){
      setErrorMessage("Please add job description.")
      return;
    }
    else {
      axios
        .post(site_ip + '/setJobDescription', req)
        .then((res) => {
          if (res.data.Status === 200) {
            setSuccessMessage("JD updated successfully");
          } else alert('Request Failed');
        })
        .catch((err) => console.log(err));
    }
  };

  const inputRef = useRef();
  
  const attachmentName = (url) =>{
    let url_sections = url.split("/");
    if(url_sections.length > 0){
      return url_sections[url_sections.length - 1]
    }else{
      return ""
    }
  }

  const fileHandler = async (e) => {
    let file = e[0];
    const s3_url =
      'https://kb-manuscripts.s3.us-east-2.amazonaws.com/manuscripts-attachment/' +
      file.name;
    const target = {
      Bucket: 'kb-manuscripts',
      Key: 'manuscripts-attachment/' + file.name,
      Body: file,
    };
    const creds = {
      accessKeyId: 'AKIATT4SF2SQIHAF7MUJ',
      secretAccessKey: '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',
    };

    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'us-east-2', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log('progress : ', progress);
      });

      await parallelUploads3.done();
      handleChangeWithName('jd_file_name', s3_url);
      // console.log(s3_url);
      // saveDocument(s3_url, file)
    } catch (e) {
      console.log(e);
      handleChangeWithName('jd_file_name', s3_url);
    }
  };

  const filePicker = () => {
    inputRef.current.click();
  };

  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>UPDATE Job Description</h4>
          </div>
          <div className="p-3 border bg-white">
            <Row>
            <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Title <span className="required">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="jd_title"
                    value={values.jd_title}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Experience Range <span className="required">*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="experience_range"
                  value={values.experience_range}
                  onChange={handleChange}
                >
                    <option value="">--Select--</option>
                    <option value="0-3">0-3 years</option>
                    <option value="4-6">4-6 years</option>
                    <option value="7-10">7-10 years</option>
                    <option value="10+">10+ years</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Job Description <span className="required">*</span></Form.Label>
                  <CKEditor
                    editor={Editor}
                    data={values.job_description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChangeWithName('job_description', data)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Attach Job Description</Form.Label>
                  <br/>
                  {values.jd_file_name !== "" ? <div>{attachmentName(values.jd_file_name)} (<a href={values.jd_file_name}>Download</a>)</div> : null}
                  <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
                    <p className="placeholder1">
                      DRAG JOB DESCRIPTION HERE <br /> OR <span>BROWSE</span>
                    </p>
                    <input
                      accept=".doc, .docx, .pdf"
                      value=""
                      style={{ visibility: 'hidden', opacity: 0 }}
                      ref={inputRef}
                      multiple="multiple"
                      type="file"
                      onChange={(e) => fileHandler(e.target.files)}
                    />
                  </FileDrop>
                </Form.Group>
              </Col>
              <br />
            <Col md={12} style={{ marginBottom: "15px" }}>
              <button
                className="primary"
                onClick={handleSubmit}
              >
                UPDATE
              </button>
              {/* <button
                className="secondary"
                onClick={handleReset}>
                Reset
              </button> */}
            </Col>
          </Row>
          </div>
      </Col>
     </Row> 
     <ErrorModal errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
     <SuccessModal successMessage={successMessage} setSuccessMessage={() => navigate('/recruiter/job-description/list')} />
    </Container>
  );
}
