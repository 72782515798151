import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link, useNavigate } from 'react-router-dom';
import { site_ip } from '../../../globalSetting';
import axios from 'axios';
// import Swal from 'sweetalert2';
function ManageInterviewerList(props) {
  const navigate = useNavigate();
  const [companyList, setCompanyList] = useState([]);

  const [interviewerList, setInterviewerList] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  useEffect(() => {
    fetchCompanyList();
  }, []);

  const fetchCompanyList = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompanyList(result.data.Content);
  };
  const fetchInterviewerList = async () => {
    if (filterValue !== '') {
      const result = await axios.get(
        `${site_ip}/getCompanyIVPanel/${filterValue}`
      );
      console.log(result);
      setInterviewerList(result.data.Data);
    } else {
      setInterviewerList([]);
    }
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>View Interview Panel</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => navigate(`/admin/add-interviewer-list/`)}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Control
              as="select"
              className="mb-3"
              name="filter"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
            >
              <option value="">Select Company</option>
              {companyList.map((value) => (
                <option key={value.company_id} value={value.company_id}>
                  {value.company_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button variant="primary" onClick={fetchInterviewerList}>
            Search
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Interviewer Name</th>
                  <th>Company Name</th>
                  <th>Job Name</th>
                </tr>
              </thead>
              <tbody>
                {interviewerList?.map((interviewer, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link
                          onClick={() =>
                            navigate(
                              `/admin/edit-interviewer-list/${interviewer.interviewer_id}`
                            )
                          }
                        >
                          <i class="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{interviewer.interviewer_name}</td>
                      <td>{interviewer.company_name}</td>
                      <td>{interviewer.job_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      {/* <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update Interviewer' : 'Add Interviewer'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="company_id"
                  value={values.company_id}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Company</option>
                  {companyList.map((value) => (
                    <option key={value.company_id} value={value.company_id}>
                      {value.company_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Job</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="job_id"
                  value={values.job_id}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Job</option>
                  {jobList.map((value) => (
                    <option key={value.job_id} value={value.job_id}>
                      {value.job_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            {inputList.map((item, i) => {
              return (
                <Col md={12}>
                  <Row>
                    <Col md={8}>
                      <Form.Group>
                        <Form.Label>Interviewer</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-3"
                          name="interviewer"
                          value={values.job_id}
                          onChange={(e) => handleInputChange(e, i)}
                        />{' '}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {inputList.length !== 1 && (
                        <Button
                          variant="secondary"
                          style={{ marginTop: '35px' }}
                          size="sm"
                          onClick={() => handleRemoveClick(i)}
                        >
                          Remove
                        </Button>
                      )}
                      {inputList.length - 1 === i && (
                        <Button
                          style={{ marginTop: '35px' }}
                          size="sm"
                          onClick={handleAddClick}
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}
    </React.Fragment>
  );
}

export default ManageInterviewerList;
