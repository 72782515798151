import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { Link } from 'react-router-dom';

function MainLoginList() {
  // const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [candidateList, setCandidateList] = useState([]);
  const initialSearch = {
    job_id: '',
    date_from: '',
    date_to: '',
    company_id: '',
  };
  const [search, setSearchs] = useState(initialSearch);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [job, getJob] = useState([]);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);

  const [companyList, getCompanyList] = useState([]);
  const fetchCompanyList = () =>
    fetch(`${site_ip}/getCompanyList`).then((res) => res.json());
  useEffect(() => {
    fetchCompanyList().then((data) => {
      getCompanyList(data.Content);
    });
    //eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    axios({
      method: 'post',
      url: site_ip + '/searchCandidates',
      data: {
        job_id: search.job_id,
        date_from: search.date_from,
        date_to: search.date_to,
        company_id: search.company_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        setCandidateList(response.data.Tests);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <div class="align-items-center row">
        <div class="col-md-6">
          <h4>Candidate Results</h4>
        </div>
      </div>
      <Row>
        <Col>
          <div class="border bg-white p-3">
            <Row>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Company </Form.Label>
                    <Form.Control
                      name="company_id"
                      value={search.company_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Company</option>
                      {companyList.map((value) => (
                        <option value={value.company_id}>
                          {value.company_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Job </Form.Label>
                    <Form.Control
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={2} className="mt-4">
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Button variant="primary" onClick={handleSearch}>
                      Search
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
          <div class="border bg-white p-3 h-100 mt-4">
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Action</th>
                  <th>Candidate Name</th>
                </tr>
              </thead>
              <tbody>
                {candidateList.map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `/admin/candidate-result/${value.candidate_id}`,
                        }}
                      >
                        View Result
                      </Link>
                    </td>
                    <td>{value.candidate_name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MainLoginList;
