import React, { useState, useEffect } from 'react';
import { Container, Col, Form, Row, Button } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
// import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
import Swal from 'sweetalert2';
const EditCompanyUsers = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const user_id = params.user_id;
  //   console.log(user_id);
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const initialValues = {
    user_id: '',
    salutation: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    user_email: '',
    user_mobile: '',
    address: '',
    company_id: userdata.company_id,
    role_id: '',
    state_id: '',
    country_id: '',
    zipcode: '',
    gender: '',
    password: '',
  };
  const [values, setValues] = useState(initialValues);
  const fetchUsers = () => {
    axios({
      method: 'get',
      url: site_ip + '/auth/getCompanyUserById/' + user_id,
      dataType: 'json',
    })
      .then(function (response) {
        // console.log(response);
        let tempValues = {
          user_id: user_id,
          salutation: response.data.Students.salutation,
          first_name: response.data.Students.first_name,
          middle_name: response.data.Students.middle_name,
          last_name: response.data.Students.last_name,
          user_email: response.data.Students.user_email,
          user_mobile: response.data.Students.user_mobile,
          address: response.data.Students.address,
          company_id: response.data.Students.company_id,
          role_id: response.data.Students.role_id,
          state_id: response.data.Students.state_id,
          country_id: response.data.Students.country_id,
          zipcode: response.data.Students.zipcode,
          gender: response.data.Students.gender,
        };
        setValues(tempValues);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  useEffect(() => {
    fetchUsers();
    //eslint-disable-next-line
  }, []);

  const [roles, setRoles] = useState([]);
  const [country, setCountry] = useState([]);
  const [company, setCompany] = useState([]);
  console.log(company);
  const [state, setState] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const fetchAdminRoles = async () => {
    const result = await axios.get(`${site_ip}/auth/getAdminRoles`);
    setRoles(result.data.Content);
  };
  const fetchCountryData = async () => {
    const result = await axios.get(`${site_ip}/getCountryList`);
    setCountry(result.data.content);
    // console.log(result);
  };

  const fetchStateData = async (countryid) => {
    const result = await axios.get(`${site_ip}/getState/${countryid}`);
    setState(result.data.Data);
    // console.log(result);
  };

  const fetchCompanyData = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompany(result.data.Content);
    // console.log(result);
  };

  useEffect(() => {
    fetchAdminRoles();
    fetchCountryData();
    fetchCompanyData();
    fetchStateData(1);
  }, []);

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  // const validatePassword = (pass) => {
  //   if (pass.trim().length < 8) {
  //     return false;
  //   }
  //   return true;
  // };

  const proceed = () => {
    if (
      values.salutation === '' ||
      values.first_name === '' ||
      values.middle_name === '' ||
      values.last_name === '' ||
      values.user_email.trim() === '' ||
      values.address === '' ||
      values.company_id === '' ||
      values.country_id === '' ||
      values.state_id === '' ||
      values.zipcode === '' ||
      values.gender === '' ||
      values.role_id === ''
    ) {
      alert('Please fill all fields');
    } else if (!validateEmail(values.user_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.user_mobile)) {
      alert('Please enter valid mobile number');
    } else if (values.user_id !== '') {
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/auth/setCompanyUser',
        data: {
          user_id: user_id,
          salutation: values.salutation,
          first_name: values.first_name,
          middle_name: values.middle_name,
          last_name: values.last_name,
          user_email: values.user_email,
          user_mobile: values.user_mobile,
          address: values.address,
          company_id: values.company_id,
          role_id: values.role_id,
          state_id: values.state_id,
          country_id: values.country_id,
          zipcode: values.zipcode,
          gender: values.gender,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Success === 200) {
            Swal.fire({
              title: 'Success',
              text: 'User updated successfully',
              icon: 'success',
            }).then((result) => {
              //   fetchUsers();
              console.log(result);
              navigate('/manage-company-users');
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  return (
    <React.Fragment>
      <Container>
        <h4>Edit Company User Form</h4>
        <Form className="p-3 border bg-white">
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Salutation</Form.Label>
                <Form.Control
                  as="select"
                  name="salutation"
                  value={values.salutation}
                  onChange={handleChange}
                >
                  <option>Select Salutation</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="middle_name"
                  value={values.middle_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="user_email"
                  value={values.user_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="user_mobile"
                  value={values.user_mobile}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            {/* <Col md={3}>
              <Form.Group>
                <Form.Label>Company Id</Form.Label>
                <Form.Control
                  as="select"
                  name="company_id"
                  value={values.company_id}
                  onChange={handleChange}
                >
                  <option value="">Select Company</option>
                  {company?.map((value) => (
                    <option key={value.company_id} value={value.company_id}>
                      {value.company_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col> */}

            <Col md={3}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="role_id"
                  value={values.role_id}
                  onChange={handleChange}
                >
                  <option value="">Select Role</option>
                  {roles.map((value) => (
                    <option key={value.role_id} value={value.role_id}>
                      {value.role_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="country_id"
                  value={values.country_id}
                  onChange={handleChange}
                >
                  <option value="">Select Country</option>
                  {country?.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.country_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="state_id"
                  value={values.state_id}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {state.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="zipcode"
                  value={values.zipcode}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                >
                  <option>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button onClick={proceed}>Submit</Button>
        </Form>
      </Container>{' '}
    </React.Fragment>
  );
};

export default EditCompanyUsers;
