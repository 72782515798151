import React, { PureComponent } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default class Example extends PureComponent {
  render() {
    // const data = this.props.data;
    const data = [
      {
        name: 'Result',
        data: [
          {
            name: 'Correct',
            y: 40,
            color: '#4caf50',
          },
          {
            name: 'Incorrect',
            y: 20,
            color: '#800000',
          },
          {
            name: 'Skipped',
            y: 40,
            color: '#e6b800',
          },
        ],
      },
    ];

    const options = {
      chart: {
        type: 'pie',
        //   renderTo: 'atmospheric-composition'
      },
      title: {
        //   verticalAlign: 'middle',
        //   floating: true,
        text: 'Overall Result',
        style: {
          fontSize: '15px',
        },
      },
      plotOptions: {
        //   pie: {
        //   	// dataLabels: {
        //   	// 	format: '{point.name}: {point.percentage:.1f} %'
        //   	// },
        //     innerSize: '10%'
        //   }
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          innerSize: '40%',
        },
      },
      series: data,
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
