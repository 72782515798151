import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ThankyouPage() {
  return (
    <Container className="h-100">
      <Row className="h-100 align-items-center justify-content-center">
        <Col md="6 mx-auto text-center">
          <div class="logoBody">
            <img
              src="../images/quantum-logo.png"
              alt="logo quantum"
              width="250"
            />
          </div>

          <div class="border  text-center p-4">
            <Image src="../images/thnakyou.png" className="top-logo" />
            <h3 class="thnkumsg">Thank You</h3>
            <p class="h3"> for taking the test</p>
          </div>
          <br />
          <Button variant="light">
            <Link to="/feedback">Give Test Feedback</Link>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ThankyouPage;
