import React, { useEffect, useState , useContext} from 'react';
import { Row, Col, Container, Form, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { site_ip } from './globalSettings';
import AuthContext from './authContext';

import Header from './components/common/Header';
import Banner1 from './components/common/Banner1';

const Home = () => {
    const navigate = useNavigate();
    const {
        currentUser,
        setCurrentUser
      } = useContext(AuthContext);
    const blankValues = {
        name: '',
        email: '',
        telephone: '',
        job_title: '',
        industry_id:''
    };
    const [values, setValues] = useState(blankValues)

    const reviews = [
        { _id: 1, label: 'Corporate Clients', numbers: '300+' },
        { _id: 2, label: 'Skills Assessed', numbers: '25+' },
        { _id: 3, label: 'Students Assessed Annually', numbers: '2.5 million' },
        { _id: 4, label: 'Exams Annually', numbers: '400+' },
        { _id: 5, label: 'Assessments Annually', numbers: '80+' },
        { _id: 6, label: 'Countries Reached', numbers: '20+' },
        { _id: 7, label: 'Industries Assessed', numbers: '1000+' },
        { _id: 8, label: 'Academic Clients', numbers: '15+' },
    ];

    const [formOpen, setFormOpen] = useState(false);

    const handleChange = (e) =>{
        let {name, value} = e.target;
        setValues({...values, [name]:value})
        if(name === 'job_title'){
            setValues({...values, [name]:value, industry_id:''})
            fetchIndustryList(value)
        }else{
            setValues({...values, [name]:value})
        }
    }

    useEffect(()=>{

        checkLogin();
        fetchJobList()
        setTimeout(()=>{
            setFormOpen(true);
        }, 3000)


    }, [])


    const checkLogin =()=>{

        // let userdata = JSON.parse(localStorage.getItem('userdata'));

        // console.log(userdata);
        console.log(currentUser,'CU')
        if(currentUser){
            //Check Access token authenticity
            navigate("/dashboard");
        }
    }

    const [jobList, setJobList] = useState([])
    const fetchJobList = () => {
        axios.get(
            site_ip + '/getJobMaster'
        ).then((res) => {
            setJobList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const [industryList, setIndustryList] = useState([])
    const fetchIndustryList = (value) => {
        axios.get(
            site_ip + '/getJobListByIndustryId/'+value
        ).then((res) => {
            setIndustryList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const handleSubmit = () =>{
        console.log("Wait")
    }

    return (
        <div>
            {/* <div className="fix-header sticky">
                <Container>
                    <Row style={{ margin: "0px" }}>
                        <Col md={3} style={{ padding: 0 }}>
                            <img src="/home/logo.png" className="header-logo-img" />
                        </Col>
                        <Col md={9}>
                            <div className="navigation">
                                <ul>
                                    <li>
                                        <a aria-current="page" className="active" href="/">Home</a>
                                    </li>
                                    <li>
                                        <a className="" href="#solutions">Solutions</a>
                                    </li>
                                    <li>
                                        <a className="" target='_blank' href="https://learn.quantuminsight.in">Contents</a>
                                    </li>
                                    <li>
                                        <a className="" href="/contact-us">Contact Us</a>
                                    </li>
                                    <li>
                                        <a className="" href="/signup">Register</a>
                                    </li>
                                    <li>
                                        <button onClick={() => navigate('/login')} className="signinbtn">Login</button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}

            <Header/>
            <Row style={{ margin: "0px" }} className="bg-dark-blue">
                <Col style={{ padding: 0 }}>
                    {/* <Carousel controls={false}>
                        <Carousel.Item>
                            <div className='home-slide-1'>

                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='home-slide-2'>

                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='home-slide-3'>

                            </div>
                        </Carousel.Item>
                    </Carousel> */}
                    {/* <div className='mid-banner'>
                        <h1>Mercer | Mettl: The Global Leaders in Talent Assessment</h1>
                        <p>Conduct Customized Online Assessments on our Powerful Cloud-based Platform, Secured with Best-in-class Proctoring</p>
                        <div className='demo-button'>START FREE OR GET A DEMO</div>
                    </div> */}
                    <Banner1/>
                    {/* <div className="banner-div">
                        
                    </div> */}
                </Col>
            </Row>
            <Row style={{ margin: "0px", marginBottom: "40px" }} id="solutions">
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue section-400">
                        <Container>
                            <p className="text-center text-white heading-1">
                                Holistic Online Assessment Tools for <span className="col-blue">Academic Institutes</span> and <span className="col-green">Corporates</span>
                            </p>
                            <p className="text-center text-white heading-3">
                                Conduct Online Exams | Conduct Virtual Assessments | Hire and Develop Talent
                            </p>
                            <p className="text-white heading-2 align-left">
                                FOR CORPORATES
                            </p>
                        </Container>
                    </div>
                    <div className="card-1-minus-margin">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg'>
                                                <i className="fas fa-pen-fancy card-icon"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">Talent Assessment Solutions</p>
                                        <p className="home-card-subheading">A suite of scientific assessment tools</p>
                                        <ul>
                                            <li className="home-card-listing">Psychometric assessments</li>
                                            <li className="home-card-listing">Behavioral assessments</li>
                                            <li className="home-card-listing">Aptitude assessments</li>
                                            <li className="home-card-listing">Technical assessments</li>
                                            <li className="home-card-listing">Communication skills assessments</li>
                                        </ul>
                                        <button className="card-action-btn" onClick={() => navigate("/login")} >Explore</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg'>
                                                <i className="fas fa-code card-icon"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">Coding Assessment Solutions</p>
                                        <p className="home-card-subheading">Build high-performing coding teams using our suite of coding tools</p>
                                        <ul>
                                            <li className="home-card-listing">Coding assessments for all major technologies</li>
                                            <li className="home-card-listing">A suite of coding simulators</li>
                                            <li className="home-card-listing">Coding interview tool</li>
                                        </ul>
                                        <button className="card-action-btn">Explore</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg'>
                                                <i className="fas fa-suitcase card-icon"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">Hiring Solutions</p>
                                        <p className="home-card-subheading">Assess talent holistically and automate your hiring using our digital tools</p>
                                        <ul>
                                            <li className="home-card-listing">Talent screening assessments</li>
                                            <li className="home-card-listing">Online interview tool</li>
                                            <li className="home-card-listing">Online hackathon and ideathon platform</li>
                                        </ul>
                                        <button className="card-action-btn" onClick={() => navigate("/recruiter")}>Explore</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg'>
                                                <i className="fas fa-signal card-icon"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">Self Assessment & Learning Solutions</p>
                                        <p className="home-card-subheading">Automate your L&D processes using our digital tools</p>
                                        <ul>
                                            <li className="home-card-listing">High-potential identification</li>
                                            <li className="home-card-listing">Succession planning</li>
                                            <li className="home-card-listing">Leadership assessments</li>
                                            <li className="home-card-listing">Assessment & development centers</li>
                                            <li className="home-card-listing">360-degree feedback platform</li>
                                        </ul>
                                        <button className="card-action-btn" onClick={() => navigate("/learner")}>Explore</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <div style={{ marginTop: "50px", marginBottom: "25px" }}>
                        <Container>
                            <p className="text-dark heading-2 align-left">
                                FOR ACADEMIC INSTITUTES
                            </p>
                        </Container>
                    </div>
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg bg-lightblue'>
                                                <i className="fas fa-pen-fancy card-icon text-blue"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">Talent Assessment Solutions</p>
                                        <p className="home-card-subheading">A suite of scientific assessment tools</p>
                                        <ul>
                                            <li className="home-card-listing">Psychometric assessments</li>
                                            <li className="home-card-listing">Behavioral assessments</li>
                                            <li className="home-card-listing">Aptitude assessments</li>
                                            <li className="home-card-listing">Technical assessments</li>
                                            <li className="home-card-listing">Communication skills assessments</li>
                                        </ul>
                                        <button className="card-action-btn btn-blue">Explore</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg bg-lightblue'>
                                                <i className="fas fa-code card-icon text-blue"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">Coding Assessment Solutions</p>
                                        <p className="home-card-subheading">Build high-performing coding teams using our suite of coding tools</p>
                                        <ul>
                                            <li className="home-card-listing">Coding assessments for all major technologies</li>
                                            <li className="home-card-listing">A suite of coding simulators</li>
                                            <li className="home-card-listing">Coding interview tool</li>
                                        </ul>
                                        <button className="card-action-btn btn-blue">Explore</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg bg-lightblue'>
                                                <i className="fas fa-suitcase card-icon text-blue"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">Hiring Solutions</p>
                                        <p className="home-card-subheading">Assess talent holistically and automate your hiring using our digital tools</p>
                                        <ul>
                                            <li className="home-card-listing">Talent screening assessments</li>
                                            <li className="home-card-listing">Online interview tool</li>
                                            <li className="home-card-listing">Online hackathon and ideathon platform</li>
                                        </ul>
                                        <button className="card-action-btn btn-blue">Explore</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="home-card">
                                        <div className='card-icon-div'>
                                            <div className='icon-bg bg-lightblue'>
                                                <i className="fas fa-signal card-icon text-blue"></i>
                                            </div>
                                        </div>
                                        <p className="home-card-heading">L&D Solutions</p>
                                        <p className="home-card-subheading">Automate your L&D processes using our digital tools</p>
                                        <ul>
                                            <li className="home-card-listing">High-potential identification</li>
                                            <li className="home-card-listing">Succession planning</li>
                                            <li className="home-card-listing">Leadership assessments</li>
                                            <li className="home-card-listing">Assessment & development centers</li>
                                            <li className="home-card-listing">360-degree feedback platform</li>
                                        </ul>
                                        <button className="card-action-btn btn-blue">Explore</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                </Col>
            </Row>
            {/* <Row style={{ margin: "0px" }}>
                <Col style={{ padding: 0 }}>
                    <div className="pink-gradiant section-300">
                        <Container>
                            <Carousel>
                                <Carousel.Item>
                                    <p className="text-center text-white heading-1">
                                        We empower the biggest brands the world over to assess their workforce
                                    </p>
                                    <div style={{ display: "flex", marginTop: "50px" }}>
                                        <div style={{ width: "100px" }}></div>
                                        <div className="carousel-3-card">
                                            <p className="carousel-card-value">300+</p>
                                            <p className="carousel-card-label">Corporate Clients</p>
                                        </div>
                                        <div className="carousel-3-card">
                                            <p className="carousel-card-value">25+</p>
                                            <p className="carousel-card-label">Skills Assessed</p>
                                        </div>
                                        <div className="carousel-3-card">
                                            <p className="carousel-card-value">2.5 million</p>
                                            <p className="carousel-card-label">Students Assessed Annually</p>
                                        </div>
                                        <div style={{ width: "100px" }}></div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <p className="text-center text-white heading-1">
                                        We empower academic institutes globally to shift their exams online
                                    </p>
                                    <div style={{ display: "flex", marginTop: "50px" }}>
                                        <div style={{ width: "100px" }}></div>
                                        <div className="carousel-3-card">
                                            <p className="carousel-card-value">400+</p>
                                            <p className="carousel-card-label">Exams Annually</p>
                                        </div>
                                        <div className="carousel-3-card">
                                            <p className="carousel-card-value">80+</p>
                                            <p className="carousel-card-label">Assessments Annually</p>
                                        </div>
                                        <div className="carousel-3-card">
                                            <p className="carousel-card-value">2.5 million</p>
                                            <p className="carousel-card-label">Assessments Annually</p>
                                        </div>
                                        <div style={{ width: "100px" }}></div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </Container>
                    </div>
                </Col>
            </Row>
            <Row style={{ margin: "0px", marginTop: "30px" }}>
                <Col>
                    <Container>
                        <p className="text-dark heading-1 text-center">
                            One Click Integration with Major ATSs, LMSs and Other Client Systems
                        </p>
                        <Row>
                            <Col md={3}></Col>
                            <Col md={3}>
                                <div className='integration-card'>
                                    <div className='green-gradiant' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                        <img src="/home/person.png" className="transparent-card-img" />
                                    </div>
                                    <div style={{ padding: "15px" }}>
                                        <p className="home-card-heading text-center">LMS and HRMS Integrations</p>
                                        <p className="home-card-subheading text-center">Our LMS and HRMS Partnerships Empower You to Integrate Assessments & Proctoring Into Your Systems Seamlessly</p>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: "20px" }}>
                                            <a className="green-anchor-tag text-center" href="#">KNOW MORE <i class="fas fa-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='integration-card'>
                                    <div className='pink-gradiant' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                        <img src="/home/women.png" className="transparent-card-img" />
                                    </div>
                                    <div style={{ padding: "15px" }}>
                                        <p className="home-card-heading text-center">LMS and HRMS Integrations</p>
                                        <p className="home-card-subheading text-center">Our LMS and HRMS Partnerships Empower You to Integrate Assessments & Proctoring Into Your Systems Seamlessly</p>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: "20px" }}>
                                            <a className="green-anchor-tag text-center" href="#">KNOW MORE <i class="fas fa-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                    </Container>
                    <div style={{ height: "50px" }}></div>
                </Col>
            </Row> */}
            <Row style={{ margin: "0px" }}>
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue top-bottom-footer-space">
                        <Container>
                            {/* <p className="text-center text-white heading-1">
                                Trusted by More Than 6000 Clients Worldwide
                            </p> */}
                            <Row>
                                <Col>
                                    <p className="footer-header">COMPANY</p>
                                    <a href="/" onClick={(e) => {e.preventDefault(); navigate("/about-us")}} className="footer-link">About Us</a>
                                    <a href="/" onClick={(e) => {e.preventDefault(); navigate("/our-team")}} className="footer-link">Our Team</a>
                                    {/* <a href="#" className="footer-link">News</a>
                                    <a href="#" className="footer-link">Customers</a>
                                    <a href="#" className="footer-link">Careers</a>
                                    <a href="#" className="footer-link">Information Security</a> */}
                                </Col>
                                {/* <Col>
                                    <p className="footer-header">PRODUCTS</p>
                                    <a href="#" className="footer-link">Talent Assessment Solutions</a>
                                    <a href="#" className="footer-link">Coding Assessment Solutions</a>
                                    <a href="#" className="footer-link">Hiring Solutions</a>
                                    <a href="#" className="footer-link">L&D Solutions</a>
                                    <a href="#" className="footer-link">Online Examination Solution</a>
                                    <a href="#" className="footer-link">Remote Proctoring Tools</a>
                                    <a href="#" className="footer-link">360-degree Feedback Tool</a>
                                    <a href="#" className="footer-link">Hackathon Tool</a>
                                    <a href="#" className="footer-link">Test Library</a>
                                </Col> */}
                                {/* <Col>
                                    <p className="footer-header">OTHERS</p>
                                    <a href="#" className="footer-link">Resources</a>
                                    <a href="#" className="footer-link">Blog</a>
                                    <a href="#" className="footer-link">Glossary</a>
                                    <a href="#" className="footer-link">Webinars</a>
                                    <a href="#" className="footer-link">Help & Support</a>
                                    <a href="#" className="footer-link">Accessibility</a>
                                    <a href="#" className="footer-link">COVID-19 Support</a>
                                </Col> */}
                                <Col></Col>
                                <Col>
                                    {/* <p className="footer-header">Partners</p>
                                    <a href="#" className="footer-link">Strategic Partnerships</a>
                                    <a href="#" className="footer-link">Integration Partners</a>
                                    <br /> */}
                                    <p className="footer-header">CALL US</p>
                                    <a href="#" className="footer-link"><i class="fas fa-mobile-alt"></i> +91-9988 77 66 55</a>
                                    <a href="#" className="footer-link"><i class="far fa-envelope"></i> liz@quantauminsight.in</a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>

            {/* Fix Signup Page */}
            {/* <div className={formOpen ? "fixRegisterForm active" : "fixRegisterForm"}>
                <div className="header bg-dark-blue">
                    Register
                </div>
                <div style={{ padding: "15px" }}>
                    <Row style={{ marginBottom: "15px" }}>
                        <Col>
                            <p className='registerLabel'>Name <span className="required">*</span></p>
                            <Form.Control type="name" placeholder="Name" name="name" onChange={handleChange} value={values.name} />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "15px" }}>
                        <Col>
                            <p className='registerLabel'>Email <span className="required">*</span></p>
                            <Form.Control type="email" placeholder="Email" name="email" onChange={handleChange} value={values.email}/>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "15px" }}>
                        <Col>
                            <p className='registerLabel'>Mobile <span className="required">*</span></p>
                            <Form.Control type="text" placeholder="Mobile" name="telephone" onChange={handleChange} value={values.telephone} />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "15px" }}>
                        <Col>
                            <p className='registerLabel'>Job Profile <span className="required">*</span></p>
                            <Form.Control
                                name="job_title"
                                value={values.job_title}
                                onChange={handleChange}
                                as="select"
                            >
                                <option value="">--Select--</option>
                                {jobList?.map((value) => (
                                    <option key={value.job_id} value={value.job_id}>{value.job_name}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "15px" }}>
                        <Col>
                            <p className='registerLabel'>Industry <span className="required">*</span></p>
                            <Form.Control
                                name="industry_id"
                                value={values.industry_id}
                                onChange={handleChange}
                                as="select"
                            >
                                <option value="">--Select--</option>
                                {industryList?.map((value) => (
                                    <option value={value.industry_id}>
                                        {value.industry_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="align-items-center justify-content-center flex">
                                <button className='primary-btn' onClick={()=>handleSubmit()}>REGISTER</button>
                            </div>
                        </Col>
                    </Row>                
                </div>
            </div> */}
            {/* <div className="registerFixButton" onClick={() => setFormOpen(!formOpen)}>
                <img className={formOpen ? 'signup-icon rotate' : 'signup-icon'} src="/home/plus.png" />
                <p>{formOpen ? 'Close' : 'Register'}</p>
            </div> */}
        </div>

    )
}

export default Home