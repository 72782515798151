import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar from './content/common/TopBar';
import AuthHeader from './content/common/AuthHeader';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '../pages/afterlogin.css';
import LeftSideBar from './LeftSideBar';
import Login from './content/login';
import Signup from './content/Signup';
import Dashboard from './content/Dashboard';

import ExamPatternSetup from './content/commonexam/ExamPatternSetup';
import AppearedStudents from './content/commonexam/AppearedStudents';
import ExamListing from './content/commonexam/ExamListing';
import ExamRegListing from './content/commonexam/ExamRegListing';
import ExamSetup from './content/commonexam/ExamSetup';
import PaymentListing from './content/commonexam/PaymentListing';
import PrizeListing from './content/commonexam/PrizeListing';
import RankingListing from './content/commonexam/RankingListing';
import StudentScore from './content/commonexam/StudentScore';  

import SubjectListing from './content/master/SubjectListing';
import QuestionListing from './content/master/Questions/QuestionListing';
import QuestionAdd from './content/master/Questions/QuestionAdd';
import TopicListing from './content/master/TopicListing';



function App() {

  const LayoutOfLoginAndSignup = (children) => (
    <>
      <AuthHeader /> {children}
    </>
  );

  const LayoutOfAfterLogin = (children) => (
    <>
    <TopBar />
    <div style={{display:"flex", overflow:"hidden"}}>
      <LeftSideBar />
      <div style={{padding:"15px", width:"100%"}}>
        <div className="content-wrapper" style={{overflowY:"auto", height:"calc(100vh - 80px)", overflowX:"hidden"}}> {children}</div>
      </div>
    </div>
    </>
  );

  return (
    <>
      <Routes>
          <Route
            exact
            path="/"
            layout={LayoutOfLoginAndSignup}
            element={LayoutOfLoginAndSignup(<Login/>)}
          />{' '}
          <Route
            exact
            path="/signup"
            layout={LayoutOfLoginAndSignup}
            element={LayoutOfLoginAndSignup(<Signup/>)}
          />{' '}
          <Route
            exact
            path="/dashboard"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<Dashboard/>)}
          />{' '}
          <Route
            exact
            path="/exam-pat-setup"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamPatternSetup/>)}
          />
          <Route
            exact
            path="/exam-setup"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamSetup/>)}
          />
          <Route
            exact
            path="/exam-listing"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamListing/>)}
          />
          <Route
            exact
            path="/registeration-by-exam"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamRegListing/>)}
          />
          <Route
            exact
            path="/appeared-students"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamRegListing/>)}
          />
          <Route
            exact
            path="/payment-listing"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamRegListing/>)}
          />
          <Route
            exact
            path="/student-scores-listing"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamRegListing/>)}
          />
          <Route
            exact
            path="/ranking-list"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamRegListing/>)}
          />
          <Route
            exact
            path="/prize-list"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<ExamRegListing/>)}
          />
          <Route
            exact
            path="/master/questions-list"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<QuestionListing/>)}
          />
          <Route
            exact
            path="/master/questions-add"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<QuestionAdd/>)}
          />
          <Route
            exact
            path="/master/subject-list"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<SubjectListing/>)}
          />
          <Route
            exact
            path="/master/topic-list"
            layout={LayoutOfAfterLogin}
            element={LayoutOfAfterLogin(<TopicListing/>)}
          />
        </Routes>
    </>
  );
}

export default App;
