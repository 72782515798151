import React, { useState, useEffect } from 'react';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import { TextField } from '@material-ui/core';
import axios from 'axios';
import './dialog.css';
// import MenuItem from '@material-ui/core/MenuItem';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, MenuItem } from '@mui/material';

import {} from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
export default function FormDialog({
  open,
  handleClose,
  data,
  onChange,
  handleFormSubmit,
}) {
  const { topic_id, topic_name, topic_desc, subject_id } = data;

  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    const loadSubjects = async () => {
      axios({
        url: site_ip + '/getSubjectList',
      }).then(function (response) {
        setSubjectList(response.data.content);
      });
    };
    loadSubjects();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {topic_id ? 'Update Topic' : 'Add Topic'}
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              id="topic_name"
              value={topic_name}
              onChange={(e) => onChange(e)}
              placeholder="Enter Topic Name"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="topic_desc"
              value={topic_desc}
              onChange={(e) => onChange(e)}
              placeholder="Topic Description"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="subject_id"
              select
              // placeholder="Select Subject"
              value={subject_id}
              onChange={(e) => onChange(e, 'subject_id')}
              margin="dense"
              fullWidth
              variant="outlined"
            >
              <MenuItem key={0} value={0}>
                {'Select Subject'}
              </MenuItem>
              {subjectList.map((option) => (
                <MenuItem key={option.subject_id} value={option.subject_id}>
                  {option.subject_name}
                </MenuItem>
              ))}
            </TextField>

            <br />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => handleFormSubmit()}
            variant="contained"
          >
            {topic_id ? 'Update' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
