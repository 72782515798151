import React,{useState, useEffect, useContext} from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import './LoginNew.css';
import { site_ip } from "../globalSettings";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { browserName } from "react-device-detect";
import Swal from "sweetalert2";
import logo from './../assets/Image.jpg';



function LoginNew() {
  const navigate = useNavigate();
  const initialValues = { user_email: '', password: '' };
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   console.log(navigator.platform);
  //   console.log(browserName);
  //   console.log(new Date());
  // }, []);

  const handleSubmit = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: site_ip + '/auth/signin',
      data: {
        user_email: values.user_email,
        password: values.password,
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response.data.accessToken!==null) {
          localStorage.setItem('userdata', JSON.stringify(response.data));
          setLogin(response.data.id, response.data.company_id);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log('error' + error);
        Swal.fire({
          title:"Invalid Email or Password!",
          icon:"error"
        })
      });
  };

  const setLogin = (id, company_id) => {
    axios({
      method: 'post',
      url: site_ip + '/setUserLogin',
      data: {
        user_id: id,
        user_ip: '',
        user_browser: navigator.platform,
        user_device: browserName,
        login_datetime: new Date(),
      },
      dataType: 'json',
    })
      .then(function (response) {
        localStorage.setItem('loginid', JSON.stringify(response.data.loginid));
        navigate('/dashboard');
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <Container fluid className="height-header-minus">
      <Row className="height-header-minus ">
        <Col md={4}>
        < img src={logo} alt="logo" className='img'  />
        </Col>
       
        <Col md={8} className="align-items-center justify-content-center flex pos-relative" style={{flexDirection:"column"}}>
        <div className="align-item-self align-items-center justify-content-center flex">
        Dont have an account yet? <button className='get-started-btn' onClick={()=>handleSubmit()}>Get started</button>

         </div>
          <div className="login-box-div-new">
          <p className=" header heading-1">
              Login to your Quantum Account
          </p>
            {/* <Form> */}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="user_email"
                  value={values.user_email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
              <Row>
                  <Col md={6}>
                    <Form.Label>Password</Form.Label>
                  </Col>
                  <Col md={6}>
                    <div className="forgot">
                      <a href="someone@example.com">Forgot Password</a>
                    </div>
                  </Col>
                </Row>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              </Form.Group>
              <div className="align-items-center justify-content-center flex">
                <button className='login-btn' onClick={()=>handleSubmit()}>LOGIN</button>
              </div>
            {/* </Form> */}
            {/* <div className="password align-items-center  justify-content-center flex margin-top-20">
              <div className="line"></div>
              Or Use Alternative Method
              <div className="line"></div></div>
              <div className="align-items-center justify-content-center flex margin-top-20">
                <button className='sign-on-btn' onClick={()=>handleSubmit()}>Login with single sign on </button>
              </div> */}
          </div>
        </Col>
      </Row>
    </Container> 
    </>
  );
}

export default LoginNew;