import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import { FileDrop } from 'react-file-drop';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';
import Progress from '../Progress';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import ErrorModal from '../../common/ErrorModal';
import { useParams, useNavigate } from 'react-router-dom';
export default function UpdateResumes(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    resume_file_name: "",
    job_id: "",
    experience_range: "",
    candidate_name: "",
    candidate_email: "",
    candidate_mobile: "",
    present_employer: "",
    analytics_files: "",
    resume_status: "",
    industry_id: "",
    skill_id: "",
    resume_fulltext: "",
    graph_files: "",
    company_id:"",
    resume_status:"",
    resume_source:"",
    resume_rating:"1"
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeWithName = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const [industry, setIndustry] = useState([]);
  const [skill, setSkill] = useState([]);
  const [job, setJob] = useState([]);
  const getIndustry = () => {
    axios
      .get(site_ip + '/getIndustryList')
      .then((res) => {
        setIndustry(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getSkills = () => {
    axios
      .get(site_ip + '/getSkills')
      .then((res) => {
        setSkill(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getJobs = () => {
    axios
      .get(site_ip + '/getJobMaster')
      .then((res) => {
        setJob(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getResumeById(params.id);
    getIndustry();
    getSkills();
    getJobs();
  }, []);

  const getResumeById = (resume_id) => {
    axios
      .get(site_ip + '/getResumeById/' + resume_id)
      .then((res) => {
        setValues(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    let req = {...values};
    if(req.candidate_name.trim() === ""){
      setErrorMessage("Candidate Name cannot be blank.")
      return;
    }else if(!validateEmail(req.candidate_email)){
      setErrorMessage("Please enter a valid email address.")
      return;
    }else if(!phonenumber(req.candidate_mobile)){
      setErrorMessage("Please enter a valid mobile number.")
      return;
    }else if(req.experience_range === ""){
      setErrorMessage("Please select experience range.")
      return;
    }else if(req.industry_id === ""){
      setErrorMessage("Please select industry.")
      return;
    }else if(req.skill_id === ""){
      setErrorMessage("Please select skill.")
      return;
    }else if(req.job_id === ""){
      setErrorMessage("Please select job.")
      return;
    }else if(req.resume_file_name.trim() === ""){
      setErrorMessage("Please attach resume copy.")
      return;
    }
    else {
      axios
        .post(site_ip + '/setResume', req)
        .then((res) => {
          // console.log(res)
          if (res.data.Status === 200) {
            Swal.fire('Resume details updated successfully');
            navigate('/recruiter/resumes/list');
          } else alert('Request Failed');
        })
        .catch((err) => console.log(err));
    }
  };

  const inputRef = useRef();
  
  const attachmentName = (url) =>{
    let url_sections = url.split("/");
    if(url_sections.length > 0){
      return url_sections[url_sections.length - 1]
    }else{
      return ""
    }
  }

  const fileHandler = async (e) => {

    let file = e[0];
    const s3_url =
      'https://kb-manuscripts.s3.us-east-2.amazonaws.com/manuscripts-attachment/' +
      file.name;
    const target = {
      Bucket: 'kb-manuscripts',
      Key: 'manuscripts-attachment/' + file.name,
      Body: file,
    };
    const creds = {
      accessKeyId: 'AKIATT4SF2SQIHAF7MUJ',
      secretAccessKey: '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',
    };

    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'us-east-2', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log('progress : ', progress);
      });

      await parallelUploads3.done();
      handleChangeWithName('resume_file_name', s3_url);
      // console.log(s3_url);
      // saveDocument(s3_url, file)
    } catch (e) {
      console.log(e);
      handleChangeWithName('resume_file_name', s3_url);
    }
  };

  const filePicker = () => {
    inputRef.current.click();
  };
  
  const handleReset = () => {

  }

  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Update Resume</h4>
          </div>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Candidate Name <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="candidate_name"
                    value={values.candidate_name}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Candidate Email <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="candidate_email"
                    value={values.candidate_email}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Candidate Mobile <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="candidate_mobile"
                    value={values.candidate_mobile}
                    onChange={handleChange}
                    maxLength={10}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Present Employer</Form.Label>
                  <Form.Control
                    name="present_employer"
                    value={values.present_employer}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Experience Range <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="experience_range"
                    value={values.experience_range}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="0-3">0-3 years</option>
                    <option value="4-6">4-6 years</option>
                    <option value="7-10">7-10 years</option>
                    <option value="10+">10+ years</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Industry <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="industry_id"
                    value={values.industry_id}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {industry?.map((item) => (
                      <option key={item.industry_id} value={item.industry_id}>
                        {item.industry_name}
                      </option>
                    ))}{' '}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Skill <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="skill_id"
                    value={values.skill_id}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {skill?.map((item) => (
                      <option key={item.skill_id} value={item.skill_id}>
                        {item.skill_name}
                      </option>
                    ))}{' '}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Job <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="job_id"
                    value={values.job_id}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {job?.map((item) => (
                      <option key={item.job_id} value={item.job_id}>
                        {item.job_name}
                      </option>
                    ))}{' '}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Resume Source </Form.Label>
                  <Form.Control
                    as="select"
                    name="resume_source"
                    value={values.resume_source}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="1">Direct search</option>
                    <option value="2">Job Portal</option>
                    <option value="3">Vendor</option>
                    <option value="4">Linkedin</option>
                    <option value="5">Referral</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Rating </Form.Label>
                  <Form.Control
                    as="select"
                    name="resume_rating"
                    value={values.resume_rating}
                    onChange={handleChange}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Resume Status <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="resume_status"
                    value={values.resume_status}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="1">New</option>
                    <option value="2">Shortlisted</option>
                    <option value="3">Selected</option>
                    <option value="4">Rejected</option>
                    <option value="5">Not suitable</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Resume Text</Form.Label>
                  <CKEditor
                    editor={Editor}
                    data={values.resume_fulltext}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChangeWithName('resume_fulltext', data)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Attach Resume <span className="required">*</span></Form.Label>
                  <br/>
                  {values.resume_file_name !== "" ? <div>{attachmentName(values.resume_file_name)} (<a href={values.resume_file_name}>Download</a>)</div> : null}
                  <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
                    <p className="placeholder1">
                      DRAG RESUME HERE <br /> OR <span>BROWSE</span>
                    </p>
                    <input
                      accept=".doc, .docx, .pdf"
                      value=""
                      style={{ visibility: 'hidden', opacity: 0 }}
                      ref={inputRef}
                      multiple="multiple"
                      type="file"
                      onChange={(e) => fileHandler(e.target.files)}
                    />
                  </FileDrop>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Col md={12} style={{ marginBottom: "15px" }}>
              <button
                className="primary"
                onClick={handleSubmit}
              >
                UPDATE
              </button>
              {/* <button
                className="secondary"
                onClick={handleReset}>
                Reset
              </button> */}
            </Col>
          </div>
        </Col>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
    </Container>
  );
}
