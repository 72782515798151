import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
import axios from 'axios';
import Swal from 'sweetalert2';

function ScheduleInterview(props) {
  const initialValues = {
    schedule_id: '',
    job_id: '',
    company_id: '',
    candidate_id: '',
    interviewer_id: '',
    iv_status: '',
    iv_channel: '',
    date: '',
    time: '',
    updating: false,
  };

  const [companyList, setCompanyList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [interviewSchedule, setInterviewSchedule] = useState([]);
  const [interviewer, setInterviewer] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [add, setAdd] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    fetchCompanyList();
    fetchJobList();
  }, []);

  const fetchCompanyList = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompanyList(result.data.Content);
  };
  const fetchJobList = async () => {
    const result = await axios.get(`${site_ip}/getJobMaster`);
    setJobs(result.data.Content);
  };

  const fetchInterviewSchedule = async () => {
    if (filterValue !== '') {
      const result = await axios.get(`${site_ip}/getIVSchedule/${filterValue}`);
      // console.log(result);
      setInterviewSchedule(result.data.Content);
    } else {
      setInterviewSchedule([]);
    }
  };
  const handleCompany = async (company_id) => {
    const result = await axios.get(`${site_ip}/getInterviewer/${company_id}`);
    // console.log(result);
    setInterviewer(result.data.Content);
    const result2 = await axios.get(
      `${site_ip}/getJobCandidatesList/${company_id}`
    );
    // console.log(result2);
    setCandidateList(result2.data.Content);
  };

  const editNow = (item) => {
    handleCompany(item.company_id);
    const newValues = {
      schedule_id: item.id,
      job_id: item.job_id,
      company_id: item.company_id,
      candidate_id: item.candidate_id,
      interviewer_id: item.interviewer_id,
      iv_channel: item.iv_channel,
      iv_status: item.iv_status,
      date: item.iv_datetime.substring(0, 10),
      time: item.iv_datetime.substring(11, 19),
      // iv_datetime: item.iv_datetime,
      updating: true,
    };
    setValues(newValues);
    console.log(values);
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const proceed = () => {
    if (values.interviewer_id === '') {
      alert('Please Select Interviewer');
    } else if (values.company_id === '') {
      alert('Please Select Company');
    } else if (values.schedule_id === '') {
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/addIVSchedule',
        data: {
          job_id: values.job_id,
          company_id: values.company_id,
          candidate_id: values.candidate_id,
          interviewer_id: values.interviewer_id,
          iv_channel: values.iv_channel,
          iv_status: values.iv_status,
          iv_datetime: values.date + ' ' + values.time,
        },
        dataType: 'json',
      })
        .then(function (response) {
          // console.log(response);
          if (response.data.Message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Schedule added successfully',
              icon: 'success',
            }).then((result) => {
              fetchInterviewSchedule();
            });
          } else {
            alert('Request failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/setIVSchedule/' + values.schedule_id,

        data: {
          id: values.schedule_id,
          job_id: values.job_id,
          company_id: values.company_id,
          candidate_id: values.candidate_id,
          interviewer_id: values.interviewer_id,
          iv_channel: values.iv_channel,
          iv_status: values.iv_status,
          iv_datetime: values.date + ' ' + values.time,
        },
        dataType: 'json',
      })
        .then(function (response) {
          console.log(response);
          if (response.data.Message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Schedule details successfully updated',
              icon: 'success',
            }).then((result) => {
              fetchInterviewSchedule();
            });
          } else {
            alert('Request failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Schedule Interview</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => handleShow()}
            aria-controls="example-collapse-text"
            aria-expanded={add}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Control
              as="select"
              className="mb-3"
              name="filter"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
            >
              <option value="">Select Company</option>
              {companyList.map((value) => (
                <option key={value.company_id} value={value.company_id}>
                  {value.company_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button variant="primary" onClick={fetchInterviewSchedule}>
            Search
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Candidate Id</th>
                  <th>Interview Channel</th>
                  <th>Date & Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {interviewSchedule?.map((schedule, index) => {
                  const date = new Date(schedule.iv_datetime);
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link onClick={() => editNow(schedule)} to="#">
                          <i class="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{schedule.candidate_id}</td>
                      <td>{schedule.iv_channel}</td>
                      <td>{date.toDateString()}</td>
                      <td>{schedule.iv_status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating
              ? 'Update Interview Schedule'
              : 'Add Interview Schedule'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="company_id"
                  value={values.company_id}
                  onChange={(e) => {
                    handleChange(e);
                    handleCompany(e.target.value);
                  }}
                >
                  <option value="">Select Company</option>
                  {companyList.map((value) => (
                    <option key={value.company_id} value={value.company_id}>
                      {value.company_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Job</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="job_id"
                  value={values.job_id}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Select Job</option>
                  {jobs.map((value) => (
                    <option key={value.job_id} value={value.job_id}>
                      {value.job_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Candidate</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="candidate_id"
                  value={values.candidate_id}
                  onChange={handleChange}
                >
                  <option value="">Select Candidate</option>
                  {candidateList.map((value) => (
                    <option key={value.candidate_id} value={value.candidate_id}>
                      {value.candidate_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group>
                <Form.Label>Interviewer </Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="interviewer_id"
                  value={values.interviewer_id}
                  onChange={handleChange}
                >
                  <option value="">Select Interviewer</option>
                  {interviewer.map((value) => (
                    <option
                      key={value.interviewer_id}
                      value={value.interviewer_id}
                    >
                      {value.interviewer_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  className="mb-3"
                  name="date"
                  value={values.date}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Time</Form.Label>
                <Form.Control
                  type="time"
                  className="mb-3"
                  name="time"
                  value={values.time}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Interview Channel</Form.Label>
                <Form.Control
                  as="select"
                  required
                  className="mb-3"
                  name="iv_channel"
                  value={values.iv_channel}
                  onChange={handleChange}
                >
                  <option value="">Select Channel</option>
                  <option value="GMeet">Google Meet</option>
                  <option value="MSTeam">MS Teams</option>
                  <option value="Zoom">ZOOM</option>
                  <option value="Daily">Daily</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Interview Status</Form.Label>
                <Form.Control
                  as="select"
                  required
                  className="mb-3"
                  name="iv_status"
                  value={values.iv_status}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ScheduleInterview;
