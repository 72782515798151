import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { site_ip } from '../../globalSetting';
import Swal from 'sweetalert2';
import axios from 'axios';
// import parse from "html-react-parser";
import { useLocation, useNavigate } from 'react-router-dom';

import queryString from 'query-string';
import '../../../pages/afterlogin.css';
function ExamsSetupAdd() {
  let navigate = useNavigate();
  const location = useLocation();
  let queIdFromDb = location.state.questions_count;
  // let autoSelect = location.state.auto_select;
  const [checkedItems, setCheckedItems] = useState([]);
  const [added_questions, setAddedQuestions] = useState([]);
  const [test_set, setTestSet] = useState([]);
  const intialValues = {
    subject_id: '',
    topic_id: '',
    question_id: '',
    taxonomy_id: '',
    dificulty_level_id: '',
  };

  const testid = queryString.parse(location.search);
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [job, getJob] = useState(null);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [search, setSearch] = useState(intialValues);
  const [topic, getTopic] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [taxonomies, getTexonomy] = useState(null);
  const fetchTexonomyData = () =>
    fetch(`${site_ip}/getTaxonomy`).then((res) => res.json());
  useEffect(() => {
    fetchTexonomyData().then((data) => {
      getTexonomy(data.Content);
      console.log(taxonomies);
    });
    //eslint-disable-next-line
  }, []);
  const fetchTopicData = () =>
    fetch(`${site_ip}/getSubjectTopics`).then((res) => res.json());
  useEffect(() => {
    fetchTopicData().then((data) => getTopic(data.content));
  }, []);
  const [subject, getSubject] = useState(null);
  const fetchSubjectData = () =>
    fetch(`${site_ip}/getSubjects`).then((res) => res.json());
  useEffect(() => {
    fetchSubjectData().then((data) => getSubject(data.Content));
  }, []);
  const [skill, getSkill] = useState(null);
  const fetchSkillData = () =>
    fetch(`${site_ip}/getSkills`).then((res) => res.json());
  useEffect(() => {
    fetchSkillData().then((data) => getSkill(data.Content));
  }, []);
  const [QuestionCount, setQuestionCount] = useState(null);
  const [questions, getQuestionList] = useState(null);

  const fetchQuestionListData = () =>
    fetch(`${site_ip}/getCompanyQuestionBank`).then((res) => res.json());
  useEffect(() => {
    fetchQuestionListData().then((data) => {
      if (data.content) {
        getQuestionList(data.content);
      }
      if (data.QuestionCount) {
        setQuestionCount(data.QuestionCount);
      }
    });
  }, []);

  const addedQuestions = () =>
    fetch(`${site_ip}/getTestQuestionsSet/${testid.id}`).then((res) =>
      res.json()
    );
  useEffect(() => {
    addedQuestions().then((data) => {
      setTestSet(data.Questions);
      if (data.Questions.length > 0) {
        let selected = [];
        for (let i = 0; i < data.Questions.length; i++) {
          selected.push(parseInt(data.Questions[i].ques_id));
        }
        selected.sort();
        setCheckedItems(selected);
        setAddedQuestions(selected);
      }
    });
    //eslint-disable-next-line
  }, []);

  var { subData, topicData, questionData } = [];

  if (subject) {
    subData = subject.map((res, index) => (
      <option value={res.subject_id}>{res.subject_name}</option>
    ));
  }
  if (topic) {
    topicData = topic.map((res, index) => (
      <option value={res.topic_id}>{res.topic_name}</option>
    ));
  }

  const handleCheckboxClick = (e, i, id) => {
    const newCheckboxes = [...checkedItems];
    let check_val = e.target.value;
    let it_status = newCheckboxes.includes(parseInt(check_val));
    if (!it_status) {
      console.log(1);
      if (newCheckboxes.length < queIdFromDb) {
        newCheckboxes.push(parseInt(e.target.value));
        setCheckedItems(newCheckboxes);
      } else {
        alert(
          'You cannot add more than ' +
            queIdFromDb +
            ' questions. If you want to add more question than increase Question Count.'
        );
        e.preventDefault();
      }
    } else {
      let index = newCheckboxes.indexOf(parseInt(e.target.value));
      newCheckboxes.splice(index, 1);
      setCheckedItems(newCheckboxes);
    }
  };
  const handleAllCheck = (e) => {
    let newCheckboxes = [];
    for (let index = 0; index < queIdFromDb; index++) {
      if (e.target.checked) {
        // $('#check_' + index).prop('checked', true);
        newCheckboxes = questions
          .filter((data, i) => i <= index)
          .map((data) => data.ques_id);
        setCheckedItems(newCheckboxes);
      } else {
        // $('#check_' + index).prop('checked', false);
        setCheckedItems(newCheckboxes);
      }
    }
  };

  const renderQuestion = (text) => {
    return '';
  };

  if (questions) {
    questionData = questions.map((res, index) => (
      <tr>
        <th scope="row">
          <Form.Group>
            <Form.Check
              checked={checkedItems.includes(res.ques_id)}
              key={res.ques_id}
              type="checkbox"
              onClick={(e) => handleCheckboxClick(e, index, res.ques_id)}
              id={'check_' + index}
              value={res.ques_id}
            />
          </Form.Group>
        </th>
        <td>{index + 1}</td>
        <td>{res.ques_id}</td>
        <td>{renderQuestion(res.ques_desc)}</td>
      </tr>
    ));
  }
  const handleSearch = (e) => {
    axios({
      method: 'post',
      url: site_ip + '/searchCompanyQuestions',
      data: {
        subject_id: search.subject_id,
        topic_id: search.topic_id,
        job_id: search.job_id,
        ques_id: search.question_id,
        ques_complexity_id: search.dificulty_level_id,
        skill_id: search.skill_id,
        company_id: userdata.company_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        getQuestionList(response.data.Questions);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const handleSubmit = (e) => {
    if (queIdFromDb > checkedItems.length) {
      alert('Please select ' + queIdFromDb + ' nos. of questions.');
      return;
    }

    let testid = queryString.parse(location.search);
    let test_id = '';
    let co_question_id = '';
    let created_user = userdata.id;
    let question_id = '';
    let data = [];
    checkedItems.forEach((element) => {
      test_id = testid.id;
      co_question_id = element;
      // created_user = created_user;
      // added_questions
      if (!added_questions.includes(element)) {
        let row_flag = 'I';
        return data.push({
          test_id,
          co_question_id,
          created_user,
          question_id,
          row_flag,
        });
      }
    });

    added_questions.forEach((element) => {
      test_id = testid.id;
      co_question_id = element;
      // created_user = created_user;
      // added_questions
      if (!checkedItems.includes(element)) {
        let row_flag = 'D';
        let obj = test_set.find((o) => o.co_question_id === element);
        let id = obj.id;
        return data.push({ id, row_flag });
      }
    });

    console.log(data);
    axios({
      method: 'post',
      url: site_ip + '/addTestQuestionsSet',
      data: data,
      dataType: 'json',
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: 'Success',
            text: 'Test updated successfully.',
            icon: 'success',
            confirmButtonText: 'Continue',
            showCancelButton: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              // Swal.fire('Saved!', '', 'success');
              navigate({ pathname: '/admin/test-listing' });
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info');
            }
          });
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Search Questions</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Subject </Form.Label>
                      <Form.Control
                        as="select"
                        name="subject_id"
                        className="mb-3"
                        onChange={handleChange}
                        value={search.subject_id}
                      >
                        <option>Select Subject</option>
                        {subData}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Topic</Form.Label>
                      <Form.Control
                        as="select"
                        name="topic_id"
                        onChange={handleChange}
                        value={search.topic_id}
                      >
                        <option>Select Topic</option>
                        {topicData}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Skill Id</Form.Label>
                      <Form.Control
                        name="skill_id"
                        value={search.skill_id}
                        onChange={handleChange}
                        as="select"
                      >
                        <option>Select Skill Id</option>
                        {skill?.map((value) => (
                          <option value={value.skill_id}>
                            {value.skill_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Difficulty Level</Form.Label>
                      <Form.Control
                        as="select"
                        name="dificulty_level_id"
                        onChange={handleChange}
                        value={search.dificulty_level_id}
                      >
                        <option value="">Select Difficulty Level</option>
                        <option value="1">Simple</option>
                        <option value="2">Medium</option>
                        <option value="3">Complex</option>
                        <option value="4">Student Rank</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Job</Form.Label>
                      <Form.Control
                        name="co_job_id"
                        value={search.co_job_id}
                        onChange={handleChange}
                        as="select"
                      >
                        <option>Select Job</option>
                        {job?.map((value) => (
                          <option value={value.job_id}>{value.job_name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Question Id</Form.Label>
                      <Form.Control
                        name="ques_id"
                        value={search.ques_id}
                        onChange={handleChange}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}></Col>
                  <Col md={4} className="mt-4">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Button variant="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col md={3}></Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>
                        Total Question Count : {QuestionCount}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>
                        Total Question Required : {queIdFromDb}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Questions Listing</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th scope="row">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          name="checkall"
                          onClick={handleAllCheck}
                        />
                      </Form.Group>
                    </th>
                    <th>Sr. No</th>
                    <th>Question Id</th>
                    <th>Question</th>
                  </tr>
                </thead>
                <tbody>{questionData}</tbody>
              </Table>
              {/* <Button variant="primary" onClick={handleSubmit}>
                Create
              </Button> */}
              <Row>
                <Col md={5}></Col>
                <Col md={3} className={'center-align-text updown-space'}>
                  <Button onClick={handleSubmit} variant="primary">
                    SAVE
                  </Button>
                </Col>
                <Col md={4}></Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ExamsSetupAdd;
