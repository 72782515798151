import React, { useState, useEffect } from 'react';
//import Aux from "../../../hoc/_Aux";
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { site_ip } from '../../../../../globalSettings';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function AddFeedbackQuestions() {
  const initialInputs = {
    company_id: '',
    job_id: '',
    // question_id: '',
    question_type: '',
    question_options: [],
    minimum_value: '',
    maximum_value: '',
  };
  const [input, setInputs] = useState(initialInputs);
  const navigate = useNavigate();
  //   useEffect(() => {
  //     setInputs((prevState) => ({
  //       ...prevState,
  //       question_options: [],
  //     }));
  //   }, [input.question_options]);

  const [job, setJob] = useState([]);
  const [company, setCompany] = useState([]);

  const fetchJobData = () => {
    axios.get(`${site_ip}/getJobMaster`).then((response) => {
      // console.log(response);
      setJob(response.data.Content);
    });
  };
  const fetchCompanyData = () => {
    axios.get(`${site_ip}/getCompanyList`).then((response) => {
      // console.log(response);
      setCompany(response.data.Content);
    });
  };

  useEffect(() => {
    fetchCompanyData();
    fetchJobData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    var convertedQuestionData = "";
    var convertedOptionOneData = "";
    var convertedOptionTwoData = "";
    var convertedOptionThreeData = "";
    var convertedOptionFourData = "";

    // Put some minimum validation
    if (input.company_id === '') {
      alert('Please select Company');
    } else if (input.question_type === '') {
      alert('Please select Question Type.');
    } else if (input.job_id === '') {
      alert('Please select Job ');
    } else {
      let answer;
      if (input.question_type === 'multiple-choice') {
        answer = JSON.stringify([
          convertedOptionOneData,
          convertedOptionTwoData,
          convertedOptionThreeData,
          convertedOptionFourData,
        ]);
      } else if (input.question_type === 'rating') {
        answer = JSON.stringify([input.minimum_value, input.maximum_value]);
      } else {
        answer = JSON.stringify([]);
      }
      //   console.log(answer);
      axios({
        method: 'post',
        url: site_ip + '/addIVFeedbackQuestions',
        data: {
          company_id: input.company_id,
          job_id: input.job_id,
          // question_id: input.question_id,
          question_type: input.question_type,
          question_desc: JSON.stringify(convertedQuestionData),
          question_options: answer,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Feedback question added successfully.',
              icon: 'success',
            });
            navigate('/admin/feedback-questions');
            
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };


  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="mb-0 pb-4">Add Feedback Question</h4>
          <Form className="p-3 border bg-white">
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    name="company_id"
                    value={input.company_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Company</option>
                    {company?.map((value) => (
                      <option value={value.company_id}>
                        {value.company_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group>
                  <Form.Label>Job</Form.Label>
                  <Form.Control
                    name="job_id"
                    value={input.job_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Job</option>
                    {job?.map((value) => (
                      <option value={value.job_id}>{value.job_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group>
                  <Form.Label>Question Type</Form.Label>
                  <Form.Control
                    name="question_type"
                    value={input.question_type}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    as="select"
                  >
                    <option value="">Select Question Type</option>
                    <option value="descriptive">Descriptive</option>
                    <option value="multiple-choice">Multiple Choice</option>
                    <option value="rating">Rating</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Question</Form.Label>
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      padding: '2px',
                      minHeight: '300px',
                    }}
                  >
                    
                  </div>
                </Form.Group>
              </Col>
              {input.question_type === 'rating' ? (
                <Row>
                  <Col md={4} className="mb-4">
                    <Form.Group>
                      <Form.Label>Minimum Value</Form.Label>
                      <Form.Control
                        name="minimum_value"
                        value={input.minimum_value}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-4">
                    <Form.Group>
                      <Form.Label>Maximum Value</Form.Label>
                      <Form.Control
                        name="maximum_value"
                        value={input.maximum_value}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
              {input.question_type === 'multiple-choice' ? (
                <Row>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Option 1</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <Form.Label>Option 3</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Option 2</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <Form.Label>Option 4</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}

              <Col md={6}>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
