import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Collapse, Table } from 'react-bootstrap';
import { site_ip } from '../../../../globalSettings';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../customTableStyle';
import { useNavigate } from 'react-router-dom';
const ExamQuestionSearch = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [questionlist, getQuestionList] = useState([]);

  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const intialSearch = {
    company_id: userdata.company_id,
    skill_id: '',
    subject_id: '',
    topic_id: '',
    job_id: '',
    ques_desc: '',
    ques_complexity_id: '',
    ques_id: ''
  };
  const [search, setSearch] = useState(intialSearch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Check is it subject
    if (name === 'subject_id') {
      fetchTopics(value);
    }
  };
  const [job, getJob] = useState(null);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [subject, getSubject] = useState(null);
  const fetchSubjectData = () =>
    fetch(`${site_ip}/getSubjects`).then((res) => res.json());
  useEffect(() => {
    fetchSubjectData().then((data) => getSubject(data.Content));
  }, []);
  const [topic, setTopic] = useState([]);

  //     const fetchTopicData = () =>
  // fetch(`${site_ip}/getSubjectTopics`)
  //   .then((res) => res.json())
  //     useEffect(() => {
  //       fetchTopicData().then((data) => getTopic(data.content))
  //     }, []);

  const fetchTopics = (subject) => {
    axios({
      method: 'GET',
      url: site_ip + '/getSubjectTopics/' + subject,
      // data: {
      //   subject : subject
      // },
      // dataType: "json",
    })
      .then(function (response) {
        setTopic(response.data.Student);
        setSearch((prevState) => ({
          ...prevState,
          // eslint-disable-next-line
          ['topic_id']: '',
        }));
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [skill, getSkill] = useState(null);
  const fetchSkillData = () =>
    fetch(`${site_ip}/getSkills`).then((res) => res.json());
  useEffect(() => {
    fetchSkillData().then((data) => getSkill(data.Content));
  }, []);
  const [complexity, getComplexity] = useState(null);
  const fetchComplexityData = () =>
    fetch(`${site_ip}/getQuestionComplexity`).then((res) => res.json());
  useEffect(() => {
    fetchComplexityData().then((data) => getComplexity(data.content));
  }, []);

  const handleSearch = () => {
    axios({
      method: 'post',
      url: site_ip + '/searchCompanyQuestions',
      data: {
        ques_id: search.ques_id,
        job_id: search.job_id,
        company_id: search.company_id,
        subject_id: search.subject_id,
        topic_id: search.topic_id,
        skill_id: search.skill_id,
        ques_complexity_id: search.ques_complexity_id,
        ques_desc: search.ques_desc,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        if (response.data) {
          getQuestionList(response.data.Questions);
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const renderQuestion = (text) => {
    
    return(
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    )
  };

  //For rendering questions
  let questions = [];
  if (questionlist.length !== 0) {
    for (let i = 0; i < questionlist.length; i++) {
      const regex = /(<([^>]+)>)/gi;
      const data = questionlist[i].ques_desc;
      const result = data.replace(regex, '');
      questions.push(result);
    }
  }
  // console.log(questions);
  //PDF
  const pdf = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'portrait';

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = 'Questions List';
    const headers = [['#', 'Question', 'Subject', 'Topic']];
    const data = questionlist.map((elt, index) => [
      index + 1,
      questions[index],
      elt.subject_name,
      elt.topic_name,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('Questions_List.pdf');
  };
  //CSV
  let qlistcsv = questionlist.map((elt, index) => [
    index + 1,
    questions[index],
    elt.subject_name,
    elt.topic_name,
  ]);

  //Adding CSV Headings
  qlistcsv.unshift(['S. No.', 'Question', 'Subject Name', 'Topic Name']);
  useEffect(() => {
    downloadAllQuestions();
    //eslint-disable-next-line
  }, []);
  const [allQuestions, setAllQuestions] = useState([]);
  const downloadAllQuestions = async () => {
    axios({
      method: 'post',
      url: site_ip + '/downloadCompanyQuestions',
      data: {
        company_id: userdata.company_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        setAllQuestions(response.data.Questions);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  let all_questions = [];
  let all_option1 = [];
  let all_option2 = [];
  let all_option3 = [];
  let all_option4 = [];
  let all_exp = [];
  let ques_ans = [];
  let ques_ans_desc = [];
  if (allQuestions.length !== 0) {
    const regex = /(<([^>]+)>)/gi;
    for (let i = 0; i < allQuestions.length; i++) {
      const data = allQuestions[i].ques_desc;
      const result = data.replace(regex, '');
      all_questions.push(result);

      if (allQuestions[i].explanation !== null) {
        const exp = allQuestions[i].explanation;
        const result_exp = exp.replace(regex, '');
        all_exp.push(result_exp);
        //For Options
      }
      if (
        parseInt(allQuestions[i].ques_type_id) === 1 ||
        parseInt(allQuestions[i].ques_type_id) === 2
      ) {
        const option1 = allQuestions[i].option1;
        const result_option1 = option1.replace(regex, '');
        all_option1.push(result_option1);
        const option2 = allQuestions[i].option2;
        const result_option2 = option2.replace(regex, '');
        all_option2.push(result_option2);
        const option3 = allQuestions[i].option3;
        const result_option3 = option3.replace(regex, '');
        all_option3.push(result_option3);
        const option4 = allQuestions[i].option4;
        const result_option4 = option4.replace(regex, '');
        all_option4.push(result_option4);
        if (allQuestions[i].ques_ans !== null)
          ques_ans.push(allQuestions[i].ques_ans.ans);
      } else if (allQuestions[i].ques_type_id === 3) {
        if (allQuestions[i].ques_ans !== null)
          ques_ans.push(allQuestions[i].ques_ans.ans);
      } else if (
        allQuestions[i].ques_type_id === 4 ||
        allQuestions[i].ques_type_id === 5
      ) {
        if (allQuestions[i].ques_ans_desc !== null && allQuestions[i].ques_ans_desc !== undefined) {
          const datax = allQuestions[i].ques_ans_desc;
          const resultx = datax.replace(regex, '');
          ques_ans_desc.push(resultx);
        }
      }
    }
  }

  //PDF
  const pdfAll = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'portrait';

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = 'Questions Bank';
    const headers = [['#', 'Question', 'Subject', 'Topic']];
    const data = allQuestions.map((elt, index) => [
      index + 1,
      all_questions[index],
      elt.subject_name,
      elt.topic_name,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('Questions_Bank.pdf');
  };
  let qalllistcsv = allQuestions.map((elt, index) => [
    index + 1,
    elt.subject_name,
    elt.topic_name,
    elt.question_type,
    all_questions[index],
    all_option1[index],
    all_option2[index],
    all_option3[index],
    all_option4[index],
    ques_ans[index],
    ques_ans_desc[index],
    all_exp[index],
  ]);
  //Adding CSV Headings
  qalllistcsv.unshift([
    'S. No.',
    'Subject Name',
    'Topic Name',
    'Question Type',
    'Question',
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
    'Correct Answer',
    'Answer',
    'Explanation',
  ]);

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"60px"
    },
    {
      name: 'Question ID',
      selector: (row) => row?.ques_id,
      width:"100px"
    },
    {
      name: 'Question',
      width:"400px",
      cell: (row) => (
        <>
          {renderQuestion(row.ques_desc)}
        </>
      )
    },
    {
      name: 'Subject',
      selector: (row) => row?.subject_name,
    },
    {
      name: 'Topic',
      selector: (row) => row?.topic_name,
    },
    {
      name: 'Skill',
      selector: (row) => row?.skill_name,
    },
    {
      name: 'Action',
      width: "120px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm "
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => navigate("/edit-question/"+row?.ques_id)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]
  return (
    <>
      <Row className="align-items-center">
        <Col md={2}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Questions</p>
        </Col>
        <Col md={10} className="text-end">
          <Link
            className="admin-action-btns"
            to={{ pathname: '/add-question' }}
          >
            <i class="fas fa-plus-square icon"></i> Add Question
          </Link>
          <Button
            className='admin-action-btns'
            onClick={() => setOpen(!open)}
            style={{ marginRight: '10px' }}
            aria-expanded={open}
          >
            <i class="fas fa-filter icon"></i> Filters
          </Button>
          <CSVLink
            filename={'QuestionsList.csv'}
            style={{ float: 'right', marginRight: '10px' }}
            className="admin-action-btns"
            data={qalllistcsv}
          >
            <i class="far fa-file-excel icon"></i>
            Download All Questions CSV
          </CSVLink>
          <button
            onClick={pdfAll}
            style={{
              float: 'right',
              marginRight: '10px',
              backgroundcolor: 'blue',
            }}
            className="admin-action-btns"
            data={allQuestions}
          >
            <i class="fas fa-file-pdf icon"></i>
            Download All Questions PDF
          </button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <Collapse in={open}>
              <div className="admin-filter-box">
                <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
                  <h4 className="m-0"> Filter by </h4>
                </div>
                <div className="row">
                  <Form.Group
                    className="col-md-4 mb-4"
                    controlId="exampleForm.ControlSelect1"
                  >
                    <Form.Label>Job</Form.Label>
                    <Form.Control
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Job</option>
                      {job?.map((value, index) => (
                        <option key={index} value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-4"
                    controlId="exampleForm.ControlSelect2"
                  >
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      name="subject_id"
                      value={search.subject_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Subject</option>
                      {subject?.map((value, index) => (
                        <option key={index} value={value.subject_id}>
                          {value.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-4"
                    controlId="exampleForm.ControlSelect3"
                  >
                    <Form.Label>Topic</Form.Label>
                    <Form.Control
                      name="topic_id"
                      value={search.topic_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Topic</option>
                      {topic.map((value, index) => (
                        <option key={index} value={value.topic_id}>
                          {value.topic_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-4"
                    controlId="exampleForm.ControlSelect4"
                  >
                    <Form.Label>Skill</Form.Label>
                    <Form.Control
                      name="skill_id"
                      value={search.skill_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Skill</option>
                      {skill?.map((value, index) => (
                        <option key={index} value={value.skill_id}>
                          {value.skill_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-4"
                    controlId="exampleForm.ControlSelect7"
                  >
                    <Form.Label>Question Id</Form.Label>
                    <Form.Control
                      type="number"
                      name="ques_id"
                      value={search.ques_id}
                      onChange={handleChange} />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-4"
                    controlId="exampleForm.ControlSelect5"
                  >
                    <Form.Label>Complexity Level</Form.Label>
                    <Form.Control
                      name="ques_complexity_id"
                      value={search.ques_complexity_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Complexity Level</option>
                      {complexity?.map((value, index) => (
                        <option key={index} value={value.id}>
                          {value.complexity_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="btn-box  border-top mt-3 pt-3">
                  <button
                    className="success"
                    onClick={handleSearch}
                  >
                    Apply
                  </button>
                  <button className="close">
                    Cancel
                  </button>
                </div>
              </div>
            </Collapse>
            <Row>
              <Col md={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <CSVLink
                  filename={'QuestionsList.csv'}
                  className="admin-action-btns"
                  data={qlistcsv}
                >
                  <i class="far fa-file-excel icon"></i>
                  Download CSV
                </CSVLink>
                <button
                  onClick={pdf}
                  className="admin-action-btns"
                  data={questionlist}
                >
                  <i class="fas fa-file-pdf icon"></i>
                  Download PDF
                </button>
              </Col>
            </Row>
            <DataTable columns={tableHeader} data={questionlist} pagination customStyles={CustomTableStyle} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ExamQuestionSearch;
