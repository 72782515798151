import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar from './content/common/TopBar';
import AuthHeader from './content/common/AuthHeader';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '../pages/afterlogin.css';
import LeftSideBar from './LeftSideBar';
import Login from './content/login';
import Signup from './content/Signup';
import Dashboard from './content/Dashboard';

function App() {

  const LayoutOfLoginAndSignup = (children) => (
    <>
      <AuthHeader /> {children}
    </>
  );

  const LayoutOfAfterLogin = (children) => (
    <>
      <TopBar />
      <div style={{ display: "flex", overflow: "hidden" }}>
        <LeftSideBar />
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 80px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={LayoutOfLoginAndSignup(<Login />)}
        />{' '}
        <Route
          exact
          path="/signup"
          layout={LayoutOfLoginAndSignup}
          element={<Signup />}
        />{' '}
        <Route
          exact
          path="/dashboard"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<Dashboard />)}
        />
      </Routes>
    </>
  );
}

export default App;
