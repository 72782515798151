import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../../customTableStyle';
import { useNavigate } from 'react-router-dom';
export default function Referrals(props) {
  
  const navigate = useNavigate();
  const blankSearch = {
    industry_id: '',
    job_id: '',
    job_closing_status: '',
    date_from: '',
    date_to: '',
    company_id:''
  }

  const [search, setSearch] = useState(blankSearch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  useEffect(() => {
    getList();
  }, []);

  const [data, setData] = useState([]);
  const getList = () => {
    axios
      .get(site_ip + '/getReferaCandidate')
      .then((res) => {
        setData(res.data.Contents);
      })
      .catch((err) => console.log(err));
  };

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"100px"
    },
    {
      name: 'Candidate Name',
      selector: (row) => row?.candidate_name,
    },
    {
      name: 'Candidate Email',
      selector: (row) => row?.candidate_email,
    },
    {
      name: 'Candidate Mobile',
      selector: (row) => row?.candidate_mobile,
    },
    // {
    //   name: 'Total Positions',
    //   selector: (row) => row?.open_positions_count,
    // },
    // {
    //   name: 'Status',
    //   selector: (row) => row.job_closing_status === 'O' ? 'Open' : 'Closed',
    // },
    // {
    //   name: 'Action',
    //   width: "150px",
    //   cell: (row) => (
    //     <>
    //       <button
    //         className="btn btn-sm circle"
    //         role="button"
    //         style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
    //         onClick={() => navigate('/recruiter/jobs/edit-job/' + row.co_job_id)}
    //       >
    //         <i class="fas fa-pencil-alt"></i>
    //       </button>
    //     </>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ]

  return (
    <>
    <Row className="align-items-center">
      <Col md={2}>
        <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Referrals</p>
      </Col>
      <Col md={10} className="text-end">
        
      </Col>
    </Row>
    {/* <Row>
      <Col xl={12} className="mt-3">
        <div className="bg-white">
          <div className="admin-filter-box">
            <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
              <h4 className="m-0"> Filter by </h4>
            </div>
            <div className="row">
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Company <span className='required'>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="company_id"
                  value={search.company_id}
                  onChange={handleChange}
                >
                  <option value="">Select Company</option>
                  {companies?.map((company) => (
                    <option key={company.company_id} value={company.company_id}>
                      {company.company_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Industry</Form.Label>
                <Form.Control
                  as="select"
                  name="industry_id"
                  value={search.industry_id}
                  onChange={handleChange}
                >
                  <option value="">Select Industry</option>
                  {industry?.map((item) => (
                    <option key={item.industry_id} value={item.industry_id}>
                      {item.industry_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Job</Form.Label>
                <Form.Control
                  as="select"
                  name="job_id"
                  value={search.job_id}
                  onChange={handleChange}
                >
                  <option value="">Select Job</option>
                  {job?.map((item) => (
                    <option key={item.job_id} value={item.job_id}>
                      {item.job_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Date From</Form.Label>
                <Form.Control
                  type="date"
                  name="date_from"
                  value={search.date_from}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Date To</Form.Label>
                <Form.Control
                  type="date"
                  name="date_to"
                  value={search.date_to}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Job Closing Status</Form.Label>
                <Form.Control
                  as="select"
                  name="job_closing_status"
                  value={search.job_closing_status}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="O">Open</option>
                  <option value="C">Closed</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="btn-box  border-top mt-3 pt-3">
            <button
              className="success"
              onClick={handleSearch}
            >
              Apply
            </button>
            <button className="close" onClick={handleReset}>
              Reset
            </button>
          </div>
          </div>
        </div>
        </div>
      </Col>
    </Row>       */}
    <Row>
      <Col>
          <DataTable columns={tableHeader} data={data} pagination customStyles={CustomTableStyle} />                
      </Col>
    </Row>
    </>
  );
}
