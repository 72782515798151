import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSettings';
import { Link } from 'react-router-dom';
import CandidateResult from './CandidateResult';

function MainLoginList() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [candidateList, setCandidateList] = useState([]);
  const initialSearch = {
    job_id: '',
    date_from: '',
    date_to: '',
    company_id: userdata.company_id,
  };
  const [search, setSearchs] = useState(initialSearch);
  const [userEmail,setUserEmail] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [job, getJob] = useState([]);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);

  useEffect(() => {
    // fetchCompanyList().then((data) => {
    //   getCompanyList(data.Content);
    // });
    // handleSearch();
    //eslint-disable-next-line
    
    fetchCandidatesGivenTests();
  }, []);


  const fetchCandidatesGivenTests =  async ()=>{
     let useremail =JSON.parse(localStorage.getItem('userdata')).user_email
    

    let result = await axios.get(`${site_ip}/candidatesgiventests/${useremail}`);

    console.log(result,'Result');
      setCandidateList(result.data.Tests)
  }

  const handleSearch = () => {
    axios({
      method: 'post',
      url: site_ip + '/searchCandidates',
      data: {
        job_id: search.job_id,
        date_from: search.date_from,
        date_to: search.date_to,
        company_id: userdata.company_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        setCandidateList(response.data.Tests);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };


  const handleClick =(value)=>{
      console.log(value,'Value')

      
  }
  return (
    <>
      <div class="align-items-center row">
        <Col md={12}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Candidate Results</p>
        </Col>
      </div>
      <Row>
        <Col className="mt-3">
          <div className="admin-filter-box">
            <Row>
              {/* <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Company </Form.Label>
                    <Form.Control
                      name="company_id"
                      value={search.company_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Company</option>
                      {companyList.map((value) => (
                        <option value={value.company_id}>
                          {value.company_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col> */}
              <Col md={4}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Job </Form.Label>
                    <Form.Control
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={4}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={4}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={4} className="mt-2">
                <button
                  className="success" 
                  onClick={handleSearch}
                > 
                  Search
                </button>
                <button className="close">
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
          <div class="border bg-white p-3 h-100 mt-4">
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Candidate Name</th>
                  <th>Test Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {candidateList.map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.candidate_name}</td>
                    <td>{value.test_name}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `/candidate-result/${value.candidate_id}-${value.test_id}`,
                          state:{data:{name: value.candidate_name, test:value.test_name}}
                        }}
                      >
                        View Result
                      </Link>

                      {/* <Button onClick={()=>handleClick(value)} >View Result</Button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MainLoginList;
