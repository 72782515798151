import React, { useState, useEffect } from 'react';
import { site_ip } from '../globalSetting';
import { Row, Col, Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './common.css';
function Dashboard() {
  const [comapanydata, getCompanyData] = useState([]);
  const fetchCompanyData = () =>
    fetch(`${site_ip}/companyDashboard`).then((res) => res.json());
  useEffect(() => {
    fetchCompanyData().then((data) => getCompanyData(data));
  }, []);
  return (
    <>
      
      {/* <div class="container-fluid containerLess">
        <div class="row containerWhite mx-2">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-1">
              <p className="box-heading">Question Count</p>
              <p className="box-value">{comapanydata.QuestionCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-2">
              <p className="box-heading">Test Assigned Count</p>
              <p className="box-value">{comapanydata.TestAssignedCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-3">
              <p className="box-heading">Test Count</p>
              <p className="box-value">{comapanydata.TestsCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-4">
              <p className="box-heading">Candidate Count</p>
              <p className="box-value">{comapanydata.candidatesCount}</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default Dashboard;
