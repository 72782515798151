import React from 'react';
import './Banner1.css'

const Banner1 = () => {
  return (
    <>
      <div class="MuiBox-root css-1net8eh">
        <div class="MuiContainer-root MuiContainer-maxWidthLg css-kgd2lw">
          <h1 class="MuiTypography-root MuiTypography-h1 css-17zm5v8">
           Quantum | Talent Finder: Redefining Talent Assessment
          </h1>
          <p class="MuiTypography-root MuiTypography-body1 MuiTypography-paragraph css-epa5hv">
            Conduct Customized Online Assessments on our Powerful Cloud-based
            Platform, Secured with Best-in-class Proctoring
          </p>
          <div class="MuiGrid-root MuiGrid-container MuiGrid-item css-n431dd">
            <a target="_self" href="/signup/">
              <button
                class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeLarge MuiButton-containedSizeLarge MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeLarge MuiButton-containedSizeLarge css-2on639"
                tabindex="0"
                type="button"
              >
                Start Free or Get a Demo
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner1;
