import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form} from 'react-bootstrap';
import { FileDrop } from 'react-file-drop';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import ErrorModal from '../../common/ErrorModal';
import SuccessModal from '../../common/SuccessModal';
import { useNavigate } from 'react-router-dom';
export default function AddResumes() {
  const navigate = useNavigate();
  const userdata = localStorage.getItem("userdata");
  const userdata_obj = JSON.parse(userdata);
  const [values, setValues] = useState({
    candidate_name: "",
    candidate_email: "",
    candidate_mobile: "",
    current_job: "",
    company_id:"",
    co_job_id: "",
    refer_by_user_id: userdata_obj.Content[0].user_id,
    referred_by_name: userdata_obj.Content[0].user_name,
    referred_by_email: userdata_obj.Content[0].user_email,
    relationship: "",
    known_since: "",
    resume_file_name: ""
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeWithName = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    let req = {...values};
    if(req.candidate_name.trim() === ""){
      setErrorMessage("Candidate Name cannot be blank.")
      return;
    }else if(!validateEmail(req.candidate_email)){
      setErrorMessage("Please enter a valid email address.")
      return;
    }else if(!phonenumber(req.candidate_mobile)){
      setErrorMessage("Please enter a valid mobile number.")
      return;
    }else if(req.current_job === ""){
      setErrorMessage("Please enter current job.")
      return;
    }else if(req.resume_file_name.trim() === ""){
      setErrorMessage("Please attach resume copy.")
      return;
    }
    else {
      axios
        .post(site_ip + '/addReferACandidate', req)
        .then((res) => {
          // console.log(res)
          if (res.data.Status === 200) {
            setSuccessMessage("New referral added.")
          } else alert('Request Failed');
        })
        .catch((err) => console.log(err));
    }
  };

  const inputRef = useRef();
  
  const attachmentName = (url) =>{
    let url_sections = url.split("/");
    if(url_sections.length > 0){
      return url_sections[url_sections.length - 1]
    }else{
      return ""
    }
  }

  const fileHandler = async (e) => {

    let file = e[0];
    const s3_url =
      'https://kb-manuscripts.s3.us-east-2.amazonaws.com/manuscripts-attachment/' +
      file.name;
    const target = {
      Bucket: 'kb-manuscripts',
      Key: 'manuscripts-attachment/' + file.name,
      Body: file,
    };
    const creds = {
      accessKeyId: 'AKIATT4SF2SQIHAF7MUJ',
      secretAccessKey: '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',
    };

    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'us-east-2', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log('progress : ', progress);
      });

      await parallelUploads3.done();
      handleChangeWithName('resume_file_name', s3_url);
      // console.log(s3_url);
      // saveDocument(s3_url, file)
    } catch (e) {
      console.log(e);
      handleChangeWithName('resume_file_name', s3_url);
    }
  };

  const filePicker = () => {
    inputRef.current.click();
  };
  
  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Refer a Candidate</h4>
          </div>
          <div className="p-3 border bg-white">
            <p style={{borderBottom:"1px solid #000000", fontSize:"#000000", fontWeight:"bold", fontSize:"15px"}}>Candidate Information</p>
            <Row className="mb-4">
              <Col md={4} style={{display:"flex", alignItems:"center"}}>
                <p style={{marginBottom:"0px"}}>Candidate Name <span className="required">*</span></p>
              </Col>
              <Col md={8}>
                <Form.Control
                    name="candidate_name"
                    value={values.candidate_name}
                    onChange={handleChange}
                    type="text"
                  />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={4} style={{display:"flex", alignItems:"center"}}>
                <p style={{marginBottom:"0px"}}>Candidate Email <span className="required">*</span></p>
              </Col>
              <Col md={8}>
                <Form.Control
                    name="candidate_email"
                    value={values.candidate_email}
                    onChange={handleChange}
                    type="text"
                  />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={4} style={{display:"flex", alignItems:"center"}}>
                <p style={{marginBottom:"0px"}}>Candidate Mobile <span className="required">*</span></p>
              </Col>
              <Col md={8}>
                <Form.Control
                    name="candidate_mobile"
                    value={values.candidate_mobile}
                    maxLength={10}
                    onChange={handleChange}
                    type="text"
                  />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={4} style={{display:"flex", alignItems:"center"}}>
                <p style={{marginBottom:"0px"}}>Current Job</p>
              </Col>
              <Col md={8}>
                <Form.Control
                    name="current_job"
                    value={values.current_job}
                    onChange={handleChange}
                    type="text"
                  />
              </Col>
            </Row>
            <p style={{borderBottom:"1px solid #000000", fontSize:"#000000", fontWeight:"bold", fontSize:"15px"}}>Additional Information</p>
            <Row className="mb-4">
              <Col md={4} style={{display:"flex", alignItems:"center"}}>
                <p style={{marginBottom:"0px"}}>Relationship </p>
              </Col>
              <Col md={8}>
                  <Form.Control
                    name="relationship"
                    value={values.relationship}
                    onChange={handleChange}
                    type="text"
                  />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={4} style={{display:"flex", alignItems:"center"}}>
                <p style={{marginBottom:"0px"}}>Know period (in years)</p>
              </Col>
              <Col md={8}>
                  <Form.Control
                    name="known_since"
                    value={values.known_since}
                    onChange={handleChange}
                    type="text"
                  />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={4} style={{display:"flex"}}>
                <p style={{marginBottom:"0px"}}>Attach Resume</p>
              </Col>
              <Col md={8}>
                <Form.Group>
                  {values.resume_file_name !== "" ? <div>{attachmentName(values.resume_file_name)} (<a href={values.resume_file_name}>Download</a>)</div> : null}
                  <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
                    <p className="placeholder1">
                      DRAG RESUME HERE <br /> OR <span>BROWSE</span>
                    </p>
                    <input
                      accept=".doc, .docx, .pdf"
                      value=""
                      style={{ visibility: 'hidden', opacity: 0 }}
                      ref={inputRef}
                      multiple="multiple"
                      type="file"
                      onChange={(e) => fileHandler(e.target.files)}
                    />
                  </FileDrop>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Col md={12} style={{ marginBottom: "15px" }}>
              <button
                className="primary"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
              {/* <button
                className="secondary"
                onClick={handleReset}>
                Reset
              </button> */}
            </Col>
          </div>
        </Col>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      <SuccessModal successMessage={successMessage} setSuccessMessage={() => navigate('/recruiter/referrals')} />
    </Container>
  );
}
