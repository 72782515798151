import React, { useEffect, useState } from 'react';
import './assigntest.css';
import { Button, Col, Form, Row, Badge } from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link} from 'react-router-dom';
import moment from 'moment';
function AssignTest() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // console.log(show);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const intialInput = { test_date: '', test_time: '' };
  const [input, setInputs] = useState(intialInput);
  const [candidate, getCandidate] = useState(null);
  const [test, getTest] = useState(null);
  const [job, getJob] = useState(null);
  const [reset, setReset] = useState(false);
  // const fetchCandidateData = () =>
  // fetch(`${site_ip}/getJobCandidatesList/${userdata.company_id}`)
  //   .then((res) => res.json())
  //     useEffect(() => {
  //       fetchCandidateData().then((data) => getCandidate(data.Content),setCheckedItems([]))
  //     }, []);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, [reset]);
  const fetchTestData = () =>
    fetch(`${site_ip}/getTestsList`).then((res) => res.json());
  useEffect(() => {
    fetchTestData().then((data) => getTest(data.Tests));
  }, []);
  const intialValues = { job_id: '', test_id: '' };
  const [search, setSearch] = useState(intialValues);
  const [errors, setErrors] = useState({});
  const validate = (search) => {
    let errors = {};
    if (!search.test_id) {
      errors.test_id = 'Test id is required';
    }
    if (!search.job_id) {
      errors.job_id = 'Job id is required';
    }
    return errors;
  };
  const handleSearch = (e) => {
    e.preventDefault();
    const validationErrors = validate(search);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      axios({
        method: 'post',
        url: site_ip + '/searchCompanyJobCandidates',
        data: {
          job_id: search.job_id,
          company_id: userdata.company_id,
          test_id: userdata.test_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          getCandidate(response.data.Content);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      console.log('errors try again', validationErrors);
    }
  };

 

  const [checkedItems, setCheckedItems] = useState([]);
  const handleCheckboxClick = (e, i, id) => {
    const newCheckboxes = [...checkedItems];
    if (e.target.checked) {
      newCheckboxes.push(id);
      setCheckedItems(newCheckboxes);
    } else {
      let index = newCheckboxes.indexOf(e.target.value);
      newCheckboxes.splice(index, 1);
      setCheckedItems(newCheckboxes);
    }
  };
  const handleSubmit = (e) => {
    let data = [];
    checkedItems.forEach((element) => {
      let candidate_id = element;
      let job_id = search.job_id;
      let test_id = search.test_id;
      let test_date = input.test_date;
      let test_time = input.test_time;
      let user_email = JSON.parse(localStorage.getItem('userdata')).user_email;
      let company_id = JSON.parse(localStorage.getItem('userdata')).company_id;
      return data.push({ candidate_id, job_id, test_id, test_date, test_time ,user_email,company_id});
    });
    axios({
      method: 'post',
      url: site_ip + '/setAssignTesttoCandidate',
      data: data,
      dataType: 'json',
    })
      .then(function (response) {
        if (response.data.status === 200) {
          Swal.fire({
            title: 'Success',
            text: 'Test Assigned successfully.',
            icon: 'success',
          });
          setInputs(intialInput);
          setSearch(intialValues);
          getCandidate(null);
          setCheckedItems([]);
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const dateFormat = (selected_date) =>{
    if(selected_date !== ""){
      return moment(selected_date).format("MMM Do YYYY"); 
    }
    return "No date selected";
  }


  const handleResetCandidate = (candidateId) => {
    // You can make the API call here using axios, similar to your handleSubmit function
    // For example:
    axios({
      method: 'post',
      url: site_ip + '/resetCandidate',
      data: { candidate_id: candidateId },
      dataType: 'json',
    })
      .then(function (response) {
        // Handle success if needed
        setInputs(intialInput);
        setSearch(intialValues);
        getCandidate(null);
       
      })
      .catch(function (error) {
        console.log('error', error);
      });

      setReset(true)
  };


  
  // ... (rest of your component code)
  
  return (
    <>
      <div class="align-items-center row">
        <div class="col-md-6">
          <p className="admin-page-title"><i class="fas fa-list-ol"></i> Assign Test by Search</p>
        </div>
        <Col md={6} className="text-end">
          <Link
            className="admin-action-btns"
            to={{ pathname: '/test-listing' }}
          >
            <i class="fas fa-plus-square icon"></i> Add Test
          </Link>
        </Col>
      </div>
      <Row>
        <Col className="mt-3">
          <div className="admin-filter-box">
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Job </Form.Label>
                    <Form.Control
                      as="select"
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                    >
                      <option>Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                    {errors.job_id && (
                      <p style={{ color: 'red' }}>{errors.job_id}</p>
                    )}
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect2">
                    <Form.Label>Test</Form.Label>
                    <Form.Control
                      as="select"
                      name="test_id"
                      value={search.test_id}
                      onChange={handleChange}
                    >
                      <option>Select Test</option>
                      {test?.map((value) => (
                        <option value={value.test_id}>{value.test_name}</option>
                      ))}
                    </Form.Control>
                    {errors.test_id && (
                      <p style={{ color: 'red' }}>{errors.test_id}</p>
                    )}
                  </Form.Group>
                </Form>
              </Col>

              {/* <Col md={2} className="mt-4">
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Button variant="primary" onClick={handleSearch}>
                      Search
                    </Button>
                  </Form.Group>
                </Form>
              </Col> */}
              <Col md={2} className="mt-4">
                <button
                  className="success"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <button className="close">
                  Cancel
                </button>
              </Col>
             </Row>   
            </div>
            <div>
              <Row>          
              <Col md={6} className="mt-4">
                <div class="candidateList">
                  <span class="headinglist">List of Candidates</span>
                  <div style={{height:"140px", overflowY:"auto", paddingLeft:"5px"}}>
                    {candidate?.map((value, index) => (
                      <div className='d-flex justify-content-between'>
                        <Form.Check
                        type="checkbox"
                        key={index}
                        id={'check_' + value.candidate_id}
                        label={value.candidate_name}
                        className="mb-2"
                        value={value.candidate_id}
                        onClick={(e) =>
                          handleCheckboxClick(e, index, value.candidate_id)
                        }
                      />

                   {value.co_test_id!=null &&  <span
      className="reset-icon"
      onClick={() => handleResetCandidate(value.candidate_id)}
    >
      <i className="fas fa-undo"></i>
    </span> }
                      </div> 
                    

                      
                    ))}
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-4">
                <div class="candidateList">
                  <span class="headinglist">Schedule Date and time</span>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Select Date of Test</Form.Label>
                    <Form.Control
                      name="test_date"
                      value={input.test_date}
                      onChange={handleChangeInput}
                      type="date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Select Time of Test</Form.Label>
                    <Form.Control
                      name="test_time"
                      value={input.test_time}
                      onChange={handleChangeInput}
                      type="time"
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6} className="mt-4">
              Schedule Date: <Badge bg="dark">{dateFormat(input.test_date)}</Badge>{' '}
              Time: <Badge bg="dark me-3"> {input.test_time === "" ? "No time selected" : input.test_time}</Badge>
              </Col>
              <Col md={6} className="mt-4 text-end admin-form-row" >
                <button
                  className="primary"
                  onClick={handleSubmit}
                >
                  Assign Test
                </button>
                {/* <Button variant="outline-primary ms-3" onClick={handleCancel}>Cancel</Button> */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default AssignTest;
