import React ,{Component}from 'react';
import {Button} from "react-bootstrap";
export default class Stopwatch extends Component {
    constructor(props) {
    super(props);
    this.state = {
      hours: 0,
      minutes: 30,
      seconds:0,
     
    }
    this.hoursInput = React.createRef();
    this.minutesInput= React.createRef();
    this.secondsInput = React.createRef();
  }

inputHandler = (e) => {
  this.setState({[e.target.name]: e.target.value});


  

}

convertToSeconds = ( hours, minutes,seconds) => {
  return seconds + minutes * 60 + hours * 60 * 60;
}
componentDidMount() {
  this.timer = setInterval(this.countDown, 1000);

}

  countDown = () => {
    const  { hours, minutes, seconds } = this.state;
    let c_seconds = this.convertToSeconds(hours, minutes, seconds);

    if(c_seconds) {

      // seconds change
      seconds ? this.setState({seconds: seconds-1}) : this.setState({seconds: 59});

      // minutes change
      if(c_seconds % 60 === 0 && minutes) {
        this.setState({minutes: minutes -1});
      }
// when only hours entered
if(!minutes && hours) {
  this.setState({minutes: 59});
}

// hours change
if(c_seconds % 3600 === 0 && hours) {
  this.setState({hours: hours-1});
}

} else {
clearInterval(this.timer);
}
}
stopTimer = () => {
  clearInterval(this.timer);
}

/*resetTimer = () => {
  this.setState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  this.hoursInput.current.value = 0;
  this.minutesInput.current.value = 0;
  this.secondsInput.current.value = 0;
  
}*/


    render(){ 
        const { hours, minutes, seconds } = this.state;
       
   return(
     <div>
    
 <Button type="button"  className="time">{hours}: {minutes} : {seconds}</Button>
    
</div>

    
)}};
