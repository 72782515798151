import React from 'react';
// import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar from './content/common/TopBar';
import AuthHeader from './content/common/AuthHeader';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '../pages/afterlogin.css';
// import Home from "./components/pages/Home";
// import LoginPage from './components/pages/LoginPage';
import LeftSideBar from './LeftSideBar';
import Login from './content/login';
import Signup from './content/Signup';
import Dashboard from './content/Dashboard';
import ExamPage from './content/tests/ExamPage';
import CustomTest from './content/tests/CustomTest';
import TestSeries from './content/tests/TestSeries';
import ThankyouPage from './content/tests/ThankyouPage';
import TestSeriesExam from './content/tests/TestSeriesExam';
import InstructionPage from './content/tests/InstructionPage';
import PracticeTest from './content/tests/PracticeTest';

//Referral
import ListReferral from './content/referral/ListReferral';

// import ViewIndustry from './content/industry/View';
import QuestionType from './content/questiontype/View';

import TestListing from './content/manageTest/TestListing';
import TestPatternAdd from './content/manageTest/TestPatternAdd';
import TestPatternEdit from './content/manageTest/TestPatternEdit';
import TestSetupAdd from './content/manageTest/TestSetupAdd';
import TestSetupEdit from './content/manageTest/TestSetupEdit';
import TestSuccess from './content/manageTest/Success';

import CompanyList from './content/company/ListCompany';
import AddCompany from './content/company/Add';
import EditCompany from './content/company/EditCompany';

// Master
// 1. Industries
import ManageIndustries from './content/masters/ManageIndustry';
import ManageJobs from './content/masters/ManageJobs';
import ManageSkills from './content/masters/ManageSkills';
import ViewQuestionTypes from './content/masters/ViewQuestionTypes';
import ViewQuestionComplexities from './content/masters/ViewQuestionComplexities';
import ViewTaxonomy from './content/masters/ViewTaxonomy';
import ManageQuestionTags from './content/masters/ManageQuestionTags';
import ViewCategories from './content/masters/ViewCategories';
import ManageSubjects from './content/masters/ManageSubjects';
import ManageSubjectTopics from './content/masters/ManageSubjectTopics';
import IndustryJobSubjects from './content/masters/IndustryJobSubjects';
// Users
import AdminUsers from './content/users/AdminUsers';
import CompanyUsers from './content/users/CompanyUsers';
import AddCompanyUsers from './content/users/AddCompanyUsers';
import EditCompanyUsers from './content/users/EditCompanyUsers';

//Site Learner
import SiteLearner from './content/users/SiteLearner';
import AddSiteLearner from './content/users/AddSiteLearner';
import EditSiteLearner from './content/users/EditSiteLearner';

// Analytics
import LoginAnalytics from './content/analytics/MainLoginList';
import CandidateResult from './content/analytics/CandidateResult';
import TestAnalytics from './content/analytics/TestAnalytics';
import CandidateList from './content/reports/CandidateList';

// Result screen
import ResultScreen from './content/result/TestResult';

function App() {

  const BlankLayout = (children) => <>{children}</>;

  const LayoutOfLoginAndSignup = (children) => (
    <>
      <AuthHeader /> {children}
    </>
  );

  const LayoutOfAfterLogin = (children) => (
    <>
    <TopBar />
    <div style={{display:"flex", overflow:"hidden"}}>
      <LeftSideBar />
      <div style={{padding:"15px", width:"100%"}}>
        <div className="content-wrapper" style={{overflowY:"auto", height:"calc(100vh - 80px)", overflowX:"hidden"}}> {children}</div>
      </div>
    </div>
    </>
  );

  return (
    <>
      <Routes>
          <Route
            exact
            path="/"
            element={LayoutOfLoginAndSignup(<Login/>)}
          />{' '}
          <Route
            exact
            path="/signup"
            element={LayoutOfLoginAndSignup(<Signup/>)}
          />{' '}
          <Route
            exact
            path="/dashboard"
            element={LayoutOfAfterLogin(<Dashboard/>)}
          />{' '}
          {/* Referral */}
          <Route
            exact
            path="/referral-list"
            element={LayoutOfAfterLogin(<ListReferral/>)}
          />{' '}
          <Route
            exact
            path="/exam-page"
            element={BlankLayout(<ExamPage/>)}
          />{' '}
          <Route
            exact
            path="/custom-test"
            element={LayoutOfAfterLogin(<CustomTest/>)}
          />{' '}
          <Route
            exact
            path="/test-series"
            element={LayoutOfAfterLogin(<TestSeries/>)}
          />{' '}
          <Route
            exact
            path="/practice-test"
            element={LayoutOfAfterLogin(<PracticeTest/>)}
          />{' '}
          <Route
            exact
            path="/thankyou"
            element={BlankLayout(<ThankyouPage/>)}
          />{' '}
          <Route
            exact
            path="/test-series-exam/:test_series_id"
            element={BlankLayout(<TestSeriesExam/>)}
          />{' '}
          <Route
            exact
            path="/instruction-page"
            element={BlankLayout(<InstructionPage/>)}
          />{' '}
          {/* <Route
            exact
            path="/industry/View"
            layout={LayoutOfAfterLogin}
            element={<ViewIndustry/>}
          />{' '} */}
          <Route
            exact
            path="/questiontype/View"
            element={LayoutOfAfterLogin(<QuestionType/>)}
          />{' '}
          <Route
            exact
            path="/test-listing"
            element={LayoutOfAfterLogin(<TestListing/>)}
          />{' '}
          <Route
            exact
            path="/test-add"
            element={LayoutOfAfterLogin(<TestPatternAdd/>)}
          />{' '}
          <Route
            exact
            path="/test-edit/:id"
            element={LayoutOfAfterLogin(<TestPatternEdit/>)}
          />{' '}
          <Route
            exact
            path="/test-setup-add"
            element={LayoutOfAfterLogin(<TestSetupAdd/>)}
          />{' '}
          <Route
            exact
            path="/test-setup-edit"
            element={LayoutOfAfterLogin(<TestSetupEdit/>)}
          />{' '}
          <Route
            exact
            path="/test-success"
            element={LayoutOfAfterLogin(<TestSuccess/>)}
          />{' '}
          <Route
            exact
            path="/company-list"
            element={LayoutOfAfterLogin(<CompanyList/>)}
          />{' '}
          <Route
            exact
            path="/company-add"
            element={LayoutOfAfterLogin(<AddCompany/>)}
          />{' '}
          <Route
            exact
            path="/company-edit/:id"
            element={LayoutOfAfterLogin(<EditCompany/>)}
          />{' '}
          {/* Industry */}
          <Route
            exact
            path="/manage-industries"
            element={LayoutOfAfterLogin(<ManageIndustries/>)}
          />{' '}
          {/* Jobs */}
          <Route
            exact
            path="/manage-jobs"
            element={LayoutOfAfterLogin(<ManageJobs/>)}
          />{' '}
          {/* View Question Types */}
          <Route
            exact
            path="/view-question-types"
            element={LayoutOfAfterLogin(<ViewQuestionTypes/>)}
          />{' '}
          {/* View Question Complexity */}
          <Route
            exact
            path="/view-question-complexities"
            element={LayoutOfAfterLogin(<ViewQuestionComplexities/>)}
          />{' '}
          {/* Taxonomy */}
          <Route
            exact
            path="/view-taxonomy"
            element={LayoutOfAfterLogin(<ViewTaxonomy/>)}
          />{' '}
          {/* Manage Skills */}
          <Route
            exact
            path="/manage-skills"
            element={LayoutOfAfterLogin(<ManageSkills/>)}
          />{' '}
          {/* View Question Tag */}
          <Route
            exact
            path="/manage-questiontags"
            element={LayoutOfAfterLogin(<ManageQuestionTags/>)}
          />{' '}
          {/* View Question Tag */}
          <Route
            exact
            path="/view-categories"
            element={LayoutOfAfterLogin(<ViewCategories/>)}
          />{' '}
          {/* View Question Tag */}
          <Route
            exact
            path="/manage-subjects"
            element={LayoutOfAfterLogin(<ManageSubjects/>)}
          />{' '}
          <Route
            exact
            path="/manage-subjecttopics"
            element={LayoutOfAfterLogin(<ManageSubjectTopics/>)}
          />{' '}
          <Route
            exact
            path="/industry-job-subjects"
            element={LayoutOfAfterLogin(<IndustryJobSubjects/>)}
          />{' '}
          {/* Admin Users */}
          <Route
            exact
            path="/manage-admin-users"
            element={LayoutOfAfterLogin(<AdminUsers/>)}
          />{' '}
          {/* Company Users */}
          <Route
            exact
            path="/manage-company-users"
            element={LayoutOfAfterLogin(<CompanyUsers/>)}
          />{' '}
          <Route
            exact
            path="/add-company-users"
            element={LayoutOfAfterLogin(<AddCompanyUsers/>)}
          />{' '}
          <Route
            exact
            path="/edit-company-users/:id"
            element={LayoutOfAfterLogin(<EditCompanyUsers/>)}
          />{' '}
          {/* Site Learner */}
          <Route
            exact
            path="/manage-site-learner"
            element={LayoutOfAfterLogin(<SiteLearner/>)}
          />{' '}
          <Route
            exact
            path="/add-site-learner"
            element={LayoutOfAfterLogin(<AddSiteLearner/>)}
          />{' '}
          <Route
            exact
            path="/edit-site-learner/:id"
            element={LayoutOfAfterLogin(<EditSiteLearner/>)}
          />{' '}
          {/* Main Login Analytics */}
          <Route
            exact
            path="/main-login-analytics"
            element={LayoutOfAfterLogin(<LoginAnalytics/>)}
          />{' '}
          <Route
            exact
            path="/candidate-result/:id"
            element={LayoutOfAfterLogin(<CandidateResult/>)}
          />{' '}
          <Route
            exact
            path="/candidate-list"
            element={LayoutOfAfterLogin(<CandidateList/>)}
          />{' '}
          <Route
            exact
            path="/test-analytics"
            element={LayoutOfAfterLogin(<TestAnalytics/>)}
          />{' '}
          {/* Result Screen */}
          <Route
            exact
            path="/test-result"
            element={LayoutOfAfterLogin(<ResultScreen/>)}
          />{' '}
        </Routes>
    </>
  );
}

export default App;
