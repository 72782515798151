// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/learner-background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg_learner {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: calc(100vh - 80px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-card {
  padding-left: 40px;
  padding-right: 40px;
}

.heading-black-text {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.image-box {
  padding: 80px;
  background-color: midnightblue;
}

.sidebar .sidebar-links > a:hover {
  background: #000000 !important;
  color: #ffffff !important;
}

.sidebar .sidebar-links > a[aria-expanded='true'] {
  background: #000000 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/learner/content/Learner.css"],"names":[],"mappings":"AAAA;EACE,yDAAyD;EACzD,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".bg_learner {\n  background-image: url('../assets/learner-background.jpg');\n  height: calc(100vh - 80px);\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.login-box {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.login-card {\n  padding-left: 40px;\n  padding-right: 40px;\n}\n\n.heading-black-text {\n  color: #000000;\n  font-size: 20px;\n  font-weight: bold;\n}\n\n.image-box {\n  padding: 80px;\n  background-color: midnightblue;\n}\n\n.sidebar .sidebar-links > a:hover {\n  background: #000000 !important;\n  color: #ffffff !important;\n}\n\n.sidebar .sidebar-links > a[aria-expanded='true'] {\n  background: #000000 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
