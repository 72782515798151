import React, { Component } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import Aux from '../../hoc/_Aux';
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
// import Pagination from '../Pagination';
import axios from 'axios';
export default class ViewQuestionType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: {
        pageNo: 0,
        sizePerPage: 10,
      },
      totalPage: 0,
      totalCount: 0,
      sr_no: '',
    };
  }
  componentDidMount() {
    this.loadAllQuestionType();
  }
  loadAllQuestionType() {
    var thisObject = this;
    let { pageNo } = this.state.filter;
    axios
      .get(
        `${site_ip}/getQuestionType?page=${pageNo}`
        //site_ip + '/getQuestionType' + '?page=' + pageNo
      )
      .then((response) => response.data)
      .then(
        (result) => {
          thisObject.setState({
            data: result.content,
            totalCount: result.CategoryCount,
            totalPage: result.totalpages,
            sr_no: result.tableSequence,
          });
        },
        (error) => {
          this.setState({
            error,
          });
        }
      );
  }
  onPageHandler = async (type, { page }) => {
    let data = page;
    let { filter } = this.state;
    let tempFilter = {
      ...filter,
    };
    tempFilter['pageNo'] = data;
    await this.setState({
      filter: tempFilter,
    });
    this.loadAllQuestionType();
  };
  render() {
    // const columns = [
    //   {
    //     dataField: '',
    //     text: '',
    //     formatter: (cell, row, rowIndex) => {
    //       return (
    //         <Form.Check
    //           custom
    //           type="checkbox"
    //           id={'checkbox_'}
    //           //onClick={this.handleCheckbox}
    //         />
    //       );
    //     },
    //     headerStyle: (colum, colIndex) => {
    //       return { width: '6%' };
    //     },
    //   },
    //   {
    //     dataField: 'question_type',
    //     text: 'Question Type',
    //     headerStyle: (colum, colIndex) => {
    //       return { width: '45%' };
    //     },
    //   },
    //   {
    //     dataField: 'id',
    //     text: 'Action',
    //     headerStyle: (colum, colIndex) => {
    //       return { width: '10%' };
    //     },
    //     formatter: (cell, row) => {
    //       return (
    //         <Link to={{ pathname: '/admin/questiontype/edit/' + cell }}>
    //           <i
    //             className="feather icon-edit"
    //             style={{
    //               fontSize: 30,
    //               color: 'orange',
    //             }}
    //           />
    //         </Link>
    //       );
    //     },
    //   },
    // ];
    // const { filter, data } = this.state;
    return (
      <Aux>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h5">View Question Type</Card.Title>
              </Card.Header>
              <Card.Body>
                <Col>
                  <Row className="row" align="right">
                    <div>Bulk Action</div>
                    &nbsp;&nbsp;&nbsp;
                    <div>
                      <Form.Control
                        as="select"
                        className="mb-1"
                        name={'icon_class'}
                      >
                        <option value="">Select</option>
                      </Form.Control>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                      <Link to={{ pathname: '/admin/questiontype/view' }}>
                        <Button className="btn btn-success">
                          <i className="feather icon-download" />
                          Export to Excel
                        </Button>
                      </Link>
                      <Link to={{ pathname: '/admin/questiontype/add' }}>
                        <Button className="btn btn-success feather icon-book">
                          Create Question Type
                        </Button>
                      </Link>
                    </div>
                  </Row>
                </Col>
                {/* <Pagination
                  data={data}
                  keyField="id"
                  page={filter.pageNo}
                  sizePerPage={filter.sizePerPage || 5}
                  totalSize={this.state.totalCount}
                  onTableChange={this.onPageHandler}
                  columns={columns}
                /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Aux>
    );
  }
}
