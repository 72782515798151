import React, { useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { Image, Collapse } from 'react-bootstrap';
import { site_ip } from '../../globalSettings';
import axios from 'axios';
//import './sidebar1.scss';
import './App.css';
function LeftSideBar() {
  const [open, setOpen] = useState(false);
  const [opensetManageUsers, setManageUsers] = useState(false);
  const [opensetManageCompanies, setManageCompanies] = useState(false);
  const [opensetManageLearners, setManageLearners] = useState(false);
  const [opensetManageQuestions, setManageQuestions] = useState(false);
  const [opensetManageTests, setManageTests] = useState(false);
  const [opensetManageCompanyTests, setManageCompanyTests] = useState(false);
  const [opensetManageSubscriptions, setManageSubscriptions] = useState(false);
  const [opensetManageAnalytics, setManageAnalytics] = useState(false);
  const [openInstructions, setOpenInstructions] = useState(false);
  const [openInterview, setOpenInterview] = useState(false);
  const [openReportsAnalytics, setReportsAnalytics] = useState(false);
  
  const baseUrl = '/backend';

  const logOut = () => {
    axios({
      method: 'post',
      url: site_ip + '/setUserLogout',
      data: {
        login_id: localStorage.getItem('loginid'),
      },
      dataType: 'json',
    })
      .then(function (response) {
        localStorage.removeItem('loginid');
        localStorage.removeItem('userdata');
        window.location.href = '/';
        // <Redirect to="/" />
        // window.location.href = '/admin/dashboard';
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  
  const [openManageQuestions, setOpenManageQuestions] = useState(false);
  const [opensetManageTest, setManageTest] = useState(false);
  const [opensetManageCandidate, setManageCandidate] = useState(false);
  // return (
  //   <div className="sidebar shadow leftsidebar">
  //     <div class="d-flex flex-column h-100">
  //       <div class="logo-block text-center">
  //         <Image src="../images/logo.png" className="top-logo" />
  //       </div>

  //       <div class="sidebar-links d-flex flex-column p-3">
  //           <Link to="/backend/dashboard">Dashboard</Link>

  //           <Link
  //             onClick={() => setManageQuestions(!opensetManageQuestions)}
  //             aria-controls="example-collapse-text"
  //             aria-expanded={opensetManageQuestions}
  //             className="d-flex align-items-center"
  //             to="#"
  //           >
  //             Questions
  //             <i class="fas fa-chevron-right right-arrow ms-auto"></i>
  //           </Link>
  //           <Collapse in={opensetManageQuestions}>
  //             <div>
  //               <div className="subMenu d-flex flex-column">
  //                 <Link to="/backend/search-question">Search Question</Link>
  //                 <Link to="/backend/add-question">Add Question</Link>
  //                 <Link to="/backend/upload-question">Upload Questions</Link>
  //               </div>
  //             </div>
  //           </Collapse>

  //           {/* <Link to="/#">
  //             <i class="menu-icon fas fa-tachometer-alt"></i> Reports
  //           </Link>
  //           <Link to="/#">
  //             <i class="menu-icon fas fa-tachometer-alt"></i> Analytics
  //           </Link> */}
  //         </div>

  //       <div class="mt-auto text-center LogoutBox">
  //         <Link to="/backend">Logout</Link>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="sidebar1 shadow bg-aliceblue">
      <div className="d-flex flex-column ">
        <div className="logo-block text-center" >
          {/* <Image src="../images/logo.png" className="top-logo" /> */}
        </div>

          <div className="sidebar-links d-flex flex-column p-3">
            <Link to="/backend/dashboard">
              <i className="menu-icon fas fa-tachometer-alt"></i> Dashboard
            </Link>

            <Link
              onClick={() => setOpenManageQuestions(!openManageQuestions)}
              aria-controls="example-collapse-text"
              aria-expanded={openManageQuestions}
              className="d-flex align-items-center"
              to="#"
            >
              <i className="menu-icon fas fa-edit"></i> Questions
              <i className="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={openManageQuestions}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/backend/search-question">Search Question</Link>
                  <Link to="/backend/add-question">Add Question</Link>
                  {/* <Link to="/upload-question">Upload Questions</Link> */}
                  <Link to="/backend/question-upload">Upload Questions</Link>
                </div>
              </div>
            </Collapse>
            <Link
              onClick={() => setManageTest(!opensetManageTest)}
              aria-controls="example-collapse-text"
              aria-expanded={opensetManageTest}
              className="d-flex align-items-center"
              to="#"
            >
              <i className="menu-icon fas fa-diagnoses"></i> Test
              <i className="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={opensetManageTest}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/backend/test-listing">Search Test</Link>
                  <Link to="/backend/test-add">Add Test</Link>
                </div>
              </div>
            </Collapse>
            <Link
              onClick={() => setManageCandidate(!opensetManageCandidate)}
              aria-controls="example-collapse-text"
              aria-expanded={opensetManageCandidate}
              className="d-flex align-items-center"
              to="#"
            >
              <i className="menu-icon fas fa-user-check"></i> Candidates
              <i className="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={opensetManageCandidate}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/backend/manage-candidate">Search Candidates</Link>
                  <Link to="/backend/add-candidate">Add Candidate </Link>
                </div>
              </div>
            </Collapse>
            {/* <Link to="/assign-test">
              <i class="menu-icon fas fa-spell-check"></i> Assign Test
            </Link> */}
            <Link
              onClick={() => setReportsAnalytics(!openReportsAnalytics)}
              aria-controls="example-collapse-text"
              aria-expanded={openReportsAnalytics}
              className="d-flex align-items-center"
              to="#"
            >
              <i className="menu-icon fas fa-user-check"></i> Reports & Analytics
              <i className="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={openReportsAnalytics}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/backend/main-login-analytics">Candidate Results</Link>
                  <Link to="/backend/candidate-list">Candidate List</Link>
                  <Link to="/backend/candidates-feedback">Candidates Feedback</Link>
                  <Link to="/backend/test-analytics">Test Analytics</Link>
                  <Link to="/backend/test-summary">Test Summary</Link>
                  <Link to="/backend/test-summary-by-date">Test Summary By Date</Link>
                  <Link to="/backend/test-summary-by-datejob">
                    Test Summary By Date Job
                  </Link>
                  {/* <Link to="/add-candidate">Add Candidate </Link> */}
                </div>
              </div>
            </Collapse>
            <Link
              onClick={() => setManageUsers(!opensetManageUsers)}
              aria-controls="example-collapse-text"
              aria-expanded={opensetManageUsers}
              className="d-flex align-items-center"
              to="#"
            >
              <i className="menu-icon fas fa-user-check"></i>Users
              <i className="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={opensetManageUsers}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/backend/manage-company-users">Company Users</Link>
                  {/* <Link to="/admin/add-company-users"> Add Company Users</Link> */}
                </div>
              </div>
            </Collapse>

            {/* <Link
              onClick={() => setMasters(!opensetMasters)}
              aria-controls="example-collapse-text"
              aria-expanded={opensetMasters}
              className="d-flex align-items-center"
              to="#"
            >
              <i class="menu-icon fas fa-folder-plus"></i> Masters
              <i class="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={opensetMasters}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/company">Designation</Link>
                  <Link to="/Skills">Skills</Link>
                  <Link to="/subjects">Subjects</Link>
                  <Link to="/Topic">Topic</Link>
                  <Link to="/job">Job</Link>
                  <Link to="/Questiontag">Question Tags</Link>
                </div>
              </div>
            </Collapse> */}
            {/* <Link
              aria-controls="example-collapse-text"
              className="d-flex align-items-center"
              to="/user-feedback-questions"
            >
              <i class="menu-icon fas fa-user-check"></i>
              User Feedback Questions
            </Link> */}
            <Link
              onClick={() => setOpenInterview(!openInterview)}
              aria-controls="example-collapse-text"
              aria-expanded={openInterview}
              className="d-flex align-items-center"
              to="#"
            >
              <i className="menu-icon fas fa-user-check"></i>
              Interviews
              <i className="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={openInterview}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/backend/manage-interviewer"> Interviewers</Link>
                  <Link to="/backend/manage-interviewer-list">Interview Panel</Link>
                  <Link to="/backend/feedback-questions">
                    Interview Feedback Questions
                  </Link>
                  <Link to="/backend/schedule-interview">Schedule Interview</Link>
                  <Link to="/backend/request-interview-feedback">
                    Request Interview Feedback
                  </Link>
                </div>
              </div>
            </Collapse>
            <Link
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="d-flex align-items-center"
              to="#"
            >
              <i className="menu-icon fas fa-folder-plus"></i>
              Master
              <i className="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={open}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to={`${baseUrl}/manage-industries`}>Industries</Link>
                  <Link to={`${baseUrl}/manage-jobs`}>Jobs</Link>
                  <Link to="/backend/manage-skills">Skills</Link>
                  <Link to="/backend/manage-questiontags">Question Tags</Link>
                  <Link to="/backend/manage-subjects">Subjects</Link>
                  <Link to="/backend/manage-subjecttopics">Subject Topic</Link>
                  <Link to="/backend/manage-interviewer">Interviewer</Link>
                  <Link to="/backend/view-question-types">View Question Types</Link>
                  <Link to="/backend/view-question-complexities">
                    View Question Complexities
                  </Link>
                  <Link to="/backend/view-taxonomy">View Taxonomy</Link>
                  <Link to="/backend/view-categories">View Categories</Link>
                  <Link
                    aria-controls="example-collapse-text"
                    className="d-flex align-items-center"
                    to="/backend/user-feedback-questions"
                  >
                    User Feedback Questions
                  </Link>
                  {/* <Link to="/industryjobsubjectsmapping/view">
                    Industry-Job-Subjects Mapping
                  </Link> */}
                </div>
              </div>
            </Collapse>

            {/* <Link
              onClick={() => setManageAnalytics(!opensetManageAnalytics)}
              aria-controls="example-collapse-text"
              aria-expanded={opensetManageAnalytics}
              className="d-flex align-items-center"
              to="#"
            >
              <i class="menu-icon fas fa-user-check"></i>
              Analytics
              <i class="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={opensetManageAnalytics}>
              <div>
                <div className="subMenu d-flex flex-column">
                </div>
              </div>
            </Collapse> */}
          </div>

        <div className="mt-auto text-center LogoutBox green-gradiant">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              logOut();
            }}
          >
            <i className="fas fa-sign-out-alt"></i> Logout
          </Link>
        </div>
      </div>
    </div>

  );
}

export default LeftSideBar;
