import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Table,
  Pagination,
} from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
// import Swal from 'sweetalert2';
import axios from 'axios';
// import parse from 'html-react-parser';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import '../../pages/afterlogin.css';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../customTableStyle';
function ExamsSetupAdd() {
  let navigate = useNavigate();
  const location = useLocation();
  let queIdFromDb = location.state.questions_count;
  // let autoSelect = location.state.auto_select;
  const intialValues = {
    subject_id: '',
    topic_id: '',
    ques_id: '',
    taxonomy_id: '',
    job_id: '',
    dificulty_level_id: '',
  };
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [job, getJob] = useState(null);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [search, setSearch] = useState(intialValues);
  const [topic, setTopic] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Check is it subject
    if (name === 'subject_id') {
      fetchTopicData(value);
    }
  };
  const [taxonomies, setTaxonomy] = useState(null);
  const fetchTexonomyData = () =>
    fetch(`${site_ip}/getTaxonomy`).then((res) => res.json());
  useEffect(() => {
    fetchTexonomyData().then((data) => {
      setTaxonomy(data.Content);
      console.log(taxonomies);
    });
    //eslint-disable-next-line
  }, []);
  const fetchTopicData = (subject) => {
    axios({
      method: 'GET',
      url: site_ip + '/getSubjectTopics/' + subject,
    })
      .then(function (response) {
        // console.log(response);
        setTopic(response.data.Student);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const [subject, getSubject] = useState(null);
  const fetchSubjectData = () =>
    fetch(`${site_ip}/getSubjects`).then((res) => res.json());
  useEffect(() => {
    fetchSubjectData().then((data) => getSubject(data.Content));
  }, []);
  const [skill, getSkill] = useState(null);
  const fetchSkillData = () =>
    fetch(`${site_ip}/getSkills`).then((res) => res.json());
  useEffect(() => {
    fetchSkillData().then((data) => getSkill(data.Content));
  }, []);
  const [QuestionCount, setQuestionCount] = useState([]);
  const [questions, setQuestionList] = useState([]);
  const fetchQuestionListData = () =>
    fetch(`${site_ip}/getCompanyQuestions/${userdata.company_id}`).then((res) =>
      res.json()
    );
  useEffect(() => {
    fetchQuestionListData().then((data) => {
      console.log(data);
      if (data.Questions) {
        setQuestionList(data.Questions);
      }
      if (data.Count) {
        setQuestionCount(data.Count);
      }
    });
    //eslint-disable-next-line
  }, []);

  var { subData, topicData, questionData } = [];

  if (subject) {
    subData = subject.map((res, index) => (
      <option value={res.subject_id}>{res.subject_name}</option>
    ));
  }
  if (topic) {
    topicData = topic.map((res, index) => (
      <option value={res.topic_id}>{res.topic_name}</option>
    ));
  }
  const [checkedItems, setCheckedItems] = useState([]);
  // const handleCheckboxClick = (e, i, id) => {
  //   const newCheckboxes = [...checkedItems];
  //   if (e.target.checked) {
  //     if (newCheckboxes.length < queIdFromDb) {
  //       newCheckboxes.push(e.target.value);
  //       setCheckedItems(newCheckboxes);
  //     } else {
  //       alert(
  //         'You cannot add more than ' +
  //           queIdFromDb +
  //           ' questions. If you want to add more question than increase Question Count.'
  //       );
  //       e.preventDefault();
  //     }
  //   } else {
  //     let index = newCheckboxes.indexOf(e.target.value);
  //     newCheckboxes.splice(index, 1);
  //     setCheckedItems(newCheckboxes);
  //   }
  // };

  const handleCheckboxClick = (e, i, id) => {
    const newCheckboxes = [...checkedItems];
    let it_status = newCheckboxes.includes(parseInt(id));
    if (!it_status) {
        if (newCheckboxes.length < queIdFromDb) {
            newCheckboxes.push(parseInt(e.target.value));
            setCheckedItems(newCheckboxes);
        } else {
            alert(
                'You cannot add more than ' +
                queIdFromDb +
                ' questions. If you want to add more question than increase Question Count.'
            );
            e.preventDefault();
        }
    } else {
        let index = newCheckboxes.indexOf(parseInt(id));
        newCheckboxes.splice(index, 1);
        setCheckedItems(newCheckboxes);
    }
};

  const handleAllCheck = (e) => {
    let newCheckboxes = [];
    for (let index = 0; index < queIdFromDb; index++) {
      if (e.target.checked) {
        // $('#check_' + index).prop('checked', true);
        newCheckboxes = questions
          .filter((data, i) => i <= index)
          .map((data) => data.ques_id);
        setCheckedItems(newCheckboxes);
      } else {
        // $('#check_' + index).prop('checked', false);
        setCheckedItems(newCheckboxes);
      }
    }
  };

  const renderQuestion = (text) => {
    return(
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    )
  };

  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsPerPage] = useState(10);

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questions?.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );
  const paginate = (pageNum) => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage - 1);
  const pageNumbers = [];
  // console.log(questions, currentQuestions);
  for (let i = 1; i <= Math.ceil(questions?.length / questionsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (currentQuestions) {
    questionData = currentQuestions.map((res, index) => (
      <tr>
        <th scope="row">
          <Form.Group>
            <Form.Check
              key={res.ques_id}
              type="checkbox"
              onClick={(e) => handleCheckboxClick(e, index, res.ques_id)}
              id={'check_' + index}
              value={res.ques_id}
            />
          </Form.Group>
        </th>
        <td>{(currentPage - 1) * 10 + index + 1}</td>
        <td>{res.ques_id}</td>
        <td>
          {res.ques_type_id === 1
            ? 'Multi Select'
            : res.ques_type_id === 2
            ? 'Single Choice'
            : res.ques_type_id === 3
            ? 'True / False'
            : res.ques_type_id === 4
            ? 'Short Description'
            : res.ques_type_id === 5
            ? 'Descriptive Answer'
            : null}
        </td>
        <td>{res.topic_name}</td>
        <td>{renderQuestion(res.ques_desc)}</td>
      </tr>
    ));
  }

  const handleSearch = (e) => {
    axios({
      method: 'post',
      url: site_ip + '/searchCompanyQuestions',
      data: {
        subject_id: search.subject_id,
        topic_id: search.topic_id,
        job_id: search.job_id,
        ques_id: search.ques_id,
        ques_complexity_id: search.dificulty_level_id,
        skill_id: search.skill_id,
        company_id: userdata.company_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        setQuestionList(response.data.Questions);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const handleSubmit = (e) => {
    if (queIdFromDb > checkedItems.length) {
      alert('Please select ' + queIdFromDb + ' nos. of questions.');
      return;
    }
    let testid = queryString.parse(location.search);
    let test_id = '';
    let co_question_id = '';
    let created_user = userdata.id;
    let question_id = '';
    let data = [];
    checkedItems.forEach((element) => {
      test_id = testid.id;
      co_question_id = element;
      // created_user = created_user;
      return data.push({
        test_id,
        co_question_id,
        created_user,
        question_id,
        row_flag: 'I',
      });
    });
    axios({
      method: 'post',
      url: site_ip + '/addTestQuestionsSet',
      data: data,
      dataType: 'json',
    })
      .then(function (response) {
        // console.log(response);
        // if (response.status === 200) {
        //   Swal.fire({
        //     title: 'Success',
        //     text: 'Test created successfully.',
        //     icon: 'success',
        //     confirmButtonText: 'Add Test',
        //     showCancelButton: false,
        //     allowOutsideClick: false,
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       // Swal.fire('Saved!', '', 'success');
        //       navigate({ pathname: '/test-add' });
        //     } else if (result.isDenied) {
        //       Swal.fire('Changes are not saved', '', 'info');
        //     }
        //   });
        // }
        if (response.status === 200) {
          navigate('/test-success', {state: { detail: testid.id, update: false }});
        } else {
          alert('Request Failed');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  let tableHeader = [
    {
        name: 'Action',
        width: "150px",
        cell: (row, index) => (
          <>
            <Form.Check checked={checkedItems.includes(row.ques_id)}
                key={row.ques_id}
                type="checkbox"
                onChange={
                    (e) => handleCheckboxClick(e, index, row.ques_id)
                }
                id={'check_' + index}
                value={row.ques_id}
            />
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"100px"
    },
    {
      name: 'Question ID',
      selector: (row) => row?.ques_id,
      width: "150px",
    },
    {
      name: 'Question Type',
      cell: (row) => (
        <>
          {
            row.ques_type_id === 1 ?
                'Multi Select' : row.ques_type_id === 2 ?
                    'Single Choice' : row.ques_type_id === 3 ?
                        'True / False' : row.ques_type_id === 4 ?
                            'Short Description' : row.ques_type_id === 5 ?
                                'Descriptive Answer' : null
          }
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Topic Name',
      selector: (row) => row?.topic_name,
      width: "350px",
    },
    {
      name: 'Question',
      cell: (row) => (
        <>
            {renderQuestion(row.ques_desc)}
        </>
      )
    },
    
  ]

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      <b>Questions Count : {QuestionCount}</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      <b>Questions Required : {queIdFromDb}</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      <b>Questions Selected : {checkedItems.length}</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header>
              <Card.Title as="h5">Search Questions</Card.Title>
            </Card.Header>

            <Card.Body>
              <Form>
                <Row>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Subject </Form.Label>
                      <Form.Control
                        as="select"
                        name="subject_id"
                        className="mb-3"
                        onChange={handleChange}
                        value={search.subject_id}
                      >
                        <option>Select Subject</option>
                        {subData}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Topic</Form.Label>
                      <Form.Control
                        as="select"
                        name="topic_id"
                        onChange={handleChange}
                        value={search.topic_id}
                      >
                        <option>Select Topic</option>
                        {topicData}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Skill</Form.Label>
                      <Form.Control
                        name="skill_id"
                        value={search.skill_id}
                        onChange={handleChange}
                        as="select"
                      >
                        <option>Select Skill</option>
                        {skill?.map((value) => (
                          <option value={value.skill_id}>
                            {value.skill_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Difficulty Level</Form.Label>
                      <Form.Control
                        as="select"
                        name="dificulty_level_id"
                        onChange={handleChange}
                        value={search.dificulty_level_id}
                      >
                        <option value="">Select Difficulty Level</option>
                        <option value="1">Simple</option>
                        <option value="2">Medium</option>
                        <option value="3">Complex</option>
                        <option value="4">Student Rank</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Job</Form.Label>
                      <Form.Control
                        name="job_id"
                        value={search.job_id}
                        onChange={handleChange}
                        as="select"
                      >
                        <option>Select Job</option>
                        {job?.map((value) => (
                          <option value={value.job_id}>{value.job_name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Question Id</Form.Label>
                      <Form.Control
                        name="ques_id"
                        value={search.ques_id}
                        onChange={handleChange}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}></Col>
                  <Col md={4} className="mt-4">
                    <Form.Group>
                      <Button variant="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col md={3}></Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header>
              <Card.Title as="h5">Questions Listing</Card.Title>
            </Card.Header>
            <Card.Body>
              <DataTable columns={tableHeader} data={questions} pagination className='w-100' customStyles={CustomTableStyle} />
              <Row>
                <Col md={5}></Col>
                <Col md={3} className={'center-align-text updown-space'}>
                  <Button onClick={handleSubmit} variant="primary">
                    Create
                  </Button>
                </Col>
                <Col md={4}></Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ExamsSetupAdd;
