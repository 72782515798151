import React from 'react';
// import { site_ip } from '../../globalSetting';
import { Row, Col, Container, Table, Nav } from 'react-bootstrap';
import '../common.css';
function TestResult() {
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            {/* Content */}
            <Row>
              <Col md={3}>
                <div className="dashboadcards card1">
                  <p className="value">3</p>
                  <p className="label">Score</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="dashboadcards card2">
                  <p className="value">10%</p>
                  <p className="label">Negative Marks</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="dashboadcards card3">
                  <p className="value">3</p>
                  <p className="label">Avg Time per Question (Sec)</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="dashboadcards card4">
                  <p className="value">10%</p>
                  <p className="label">Accuracy</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <br />

                <Nav variant="pills" defaultActiveKey="/section-level">
                  <Nav.Item>
                    <Nav.Link eventKey="/section-level">Section Level</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="area-level">Area Level</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="topic-level">Topic Level</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="difficulty-level">
                      Difficulty Level
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="solution">Solution</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rank">Rank</Nav.Link>
                  </Nav.Item>
                </Nav>
                <br />
                <h5>
                  <b>Scorecard</b>
                </h5>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="center-align">Questions</th>
                      <th className="center-align">Time Taken</th>
                      <th className="center-align">Attempt</th>
                      <th className="center-align">Correct</th>
                      <th className="center-align">Incorrect</th>
                      <th className="center-align">Skipped</th>
                      <th className="center-align">Score</th>
                      <th className="center-align">Accuracy %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>All Sections</td>
                      <td className="center-align">60</td>
                      <td className="center-align">01:00:00</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">0 (00:00:00)</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">46 (00:57:13)</td>
                      <td className="center-align">-3.50</td>
                      <td className="center-align">0.00</td>
                    </tr>
                    <tr>
                      <td>General English</td>
                      <td className="center-align">15</td>
                      <td className="center-align">01:00:00</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">0 (00:00:00)</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">46 (00:57:13)</td>
                      <td className="center-align">-3.50</td>
                      <td className="center-align">0.00</td>
                    </tr>
                    <tr>
                      <td>Analytical and Logical Reasoning</td>
                      <td className="center-align">25</td>
                      <td className="center-align">01:00:00</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">0 (00:00:00)</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">46 (00:57:13)</td>
                      <td className="center-align">-3.50</td>
                      <td className="center-align">0.00</td>
                    </tr>
                    <tr>
                      <td>Quantitative</td>
                      <td className="center-align">20</td>
                      <td className="center-align">01:00:00</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">0 (00:00:00)</td>
                      <td className="center-align">14 (00:02:47)</td>
                      <td className="center-align">46 (00:57:13)</td>
                      <td className="center-align">-3.50</td>
                      <td className="center-align">0.00</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default TestResult;
