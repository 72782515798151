// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import './Login.css';
// import { site_ip } from '../globalSettings.js';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { browserName } from 'react-device-detect';
// import '../homepage.css'

// function Login() {
//   const navigate = useNavigate();
//   const initialValues = { user_email: '', password: '' };
//   const [values, setValues] = useState(initialValues);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setValues((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   // useEffect(() => {
//   //   console.log(navigator.platform);
//   //   console.log(browserName);
//   //   console.log(new Date());
//   // }, []);

//   const handleSubmit = () => {
//     axios({
//       method: 'post',
//       url: site_ip + '/auth/signin',
//       data: {
//         user_email: values.user_email,
//         password: values.password,
//       },
//       dataType: 'json',
//     })
//       .then(function (response) {
//         if (response.data.accessToken) {
//           localStorage.setItem('userdata', JSON.stringify(response.data));
//           setLogin(response.data.id, response.data.company_id);
//         }
//       })
//       .catch(function (error) {
//         console.log('error' + error);
//       });
//   };

//   const setLogin = (id, company_id) => {
//     axios({
//       method: 'post',
//       url: site_ip + '/setUserLogin',
//       data: {
//         user_id: id,
//         user_ip: '',
//         user_browser: navigator.platform,
//         user_device: browserName,
//         login_datetime: new Date(),
//       },
//       dataType: 'json',
//     })
//       .then(function (response) {
//         localStorage.setItem('loginid', JSON.stringify(response.data.loginid));
//         navigate('/dashboard');
//       })
//       .catch(function (error) {
//         console.log('error' + error);
//       });
//   };
//   return (
//     <>
//       <div class="conatiner">

//       <div class="d-flex align-content-center justify-content-center ">
       
//          <div class="sideBarImg">
//           <img src="/images/sidebar_img.jpg" class="center-fit" alt="SIDE BAR"   />
//           <div class="sideImgText text-center">
//             <h4>A Platform for talent assessment and hiring Insight</h4>
//             <p style={{lineHeight:'1.2'}}>Start today and learn how you can enhance the quality of your recruiting, optimize your hiring process, and have a significant influence on your organization.


// </p>
//           </div>
//          </div>
//           <div class="sidemenu align-self-center " >
           
//             <div class="d-flex flex-column  justify-content-center align-items-center">
//               {/* <Form> */}
//               <div class="w-50">
//                 <Form.Group  controlId="formBasicEmail">
//                   <Form.Label class="h4">Email address</Form.Label>
//                   <Form.Control
                  
//                     type="email"
//                     placeholder="Enter email"
//                     name="user_email"
//                     value={values.user_email}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//                 <Form.Group  controlId="formBasicPassword">
//                   <Form.Label  class="h4 mt-3">Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     name="password"
//                     value={values.password}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//                 <p class="passwordText"><Link to="/forgotpassword"> Forget Password</Link></p>  

//                 </div>
//                 <div class="mt-3">
//                   <button className='primary-btn' onClick={()=>handleSubmit()}>LOGIN</button>
//                 </div>
//               {/* </Form> */}
//             </div>
            
//           </div>
          
//           {/* <div className="fixed-whitebox-bottom"></div> */}
   
//         </div>
//         </div>
//     </>
//   );
// }

// export default Login;

import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './Login.css';
import { site_ip } from '../globalSettings.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { browserName } from 'react-device-detect';
import '../homepage.css'
import AuthContext from '../authContext';
import Swal from 'sweetalert2';
import CircleLoader from './CircleLoader';

function Login() {
  const navigate = useNavigate();
  const initialValues = { user_email: '', password: '' };
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const {
    currentUser,
    setCurrentUser
  } = useContext(AuthContext);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   console.log(navigator.platform);
  //   console.log(browserName);
  //   console.log(new Date());
  // }, []);

  const handleSubmit = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: site_ip + '/auth/signin',
      data: {
        user_email: values.user_email,
        password: values.password,
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response.data.accessToken!==null) {

          localStorage.setItem('userdata', JSON.stringify(response.data));
          setCurrentUser(response.data)
          setLogin(response.data.id, response.data.company_id);
        }

        setLoading(false);

      })
      .catch(function (error) {
        console.log('error' + error);
        Swal.fire({
          title:"Invalid Email or Password!",
          icon:"error"
        })
      });
  };

  const setLogin = (id, company_id) => {
    axios({
      method: 'post',
      url: site_ip + '/setUserLogin',
      data: {
        user_id: id,
        user_ip: '',
        user_browser: navigator.platform,
        user_device: browserName,
        login_datetime: new Date(),
      },
      dataType: 'json',
    })
      .then(function (response) {
        localStorage.setItem('loginid', JSON.stringify(response.data.loginid));
        navigate('/dashboard');
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <Container fluid className="height-header-minus">
      <Row className="height-header-minus bg-dark-blue">
        <Col md={3}></Col>
        <Col md={6} className="align-items-center justify-content-center flex pos-relative" style={{flexDirection:"column"}}>
        
          <p className="text-center text-white heading-1">
              LOGIN
          </p>
          <div className="login-box-div">
            {/* <Form> */}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="user_email"
                  value={values.user_email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              </Form.Group>
              <div className="align-items-center justify-content-center flex">
                <button className='primary-btn' onClick={()=>handleSubmit()}>LOGIN</button>
              </div>
            {/* </Form> */}
          </div>
          
        </Col>
        <Col md={3}>
        </Col>
        <div className="fixed-whitebox-bottom"></div>
      </Row>
    </Container>

    
     
    </>
  );
}

export default Login;