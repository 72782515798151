// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topband {
  background: #591c35 !important;
  color: #ffffff;
  width: 100%;
  padding: 0 !important;
  transition: all 0.3s ease-in-out;
  display: flex;
  /* border-bottom: 1px solid #ddd; */
}
/* .navbar {
  display: none;
}
footer {
  display: none;
} */
.userpic {
  background: #eaeaea;
  border: none;
  color: #252b36;
  border-radius: 30px;
  min-width: 51px;
  min-height: 51px;
  margin-left: 20px;
}

.logo-container {
  background-color: #ffffff;
  width: 250px;
  display: inline-block;
  height: 81px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.logo-img1 {
  width: 230px !important;
}

.header-container {
  display: inline-block;
  width: calc(100vw - 290px);
  padding-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/exam/content/common/topbar.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,cAAc;EACd,WAAW;EACX,qBAAqB;EACrB,gCAAgC;EAChC,aAAa;EACb,mCAAmC;AACrC;AACA;;;;;GAKG;AACH;EACE,mBAAmB;EACnB,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,0BAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":[".topband {\r\n  background: #591c35 !important;\r\n  color: #ffffff;\r\n  width: 100%;\r\n  padding: 0 !important;\r\n  transition: all 0.3s ease-in-out;\r\n  display: flex;\r\n  /* border-bottom: 1px solid #ddd; */\r\n}\r\n/* .navbar {\r\n  display: none;\r\n}\r\nfooter {\r\n  display: none;\r\n} */\r\n.userpic {\r\n  background: #eaeaea;\r\n  border: none;\r\n  color: #252b36;\r\n  border-radius: 30px;\r\n  min-width: 51px;\r\n  min-height: 51px;\r\n  margin-left: 20px;\r\n}\r\n\r\n.logo-container {\r\n  background-color: #ffffff;\r\n  width: 250px;\r\n  display: inline-block;\r\n  height: 81px;\r\n  display: flex;\r\n  align-items: center;\r\n  padding-left: 10px;\r\n}\r\n\r\n.logo-img1 {\r\n  width: 230px !important;\r\n}\r\n\r\n.header-container {\r\n  display: inline-block;\r\n  width: calc(100vw - 290px);\r\n  padding-top: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
