import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../customTableStyle';
import { useNavigate } from 'react-router-dom';
export default function JobList(props) {
  
  const navigate = useNavigate();
  const blankSearch = {
    industry_id: '',
    job_id: '',
    job_closing_status: '',
    date_from: '',
    date_to: '',
    company_id:''
  }

  const [search, setSearch] = useState(blankSearch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [industry, setIndustry] = useState([]);
  const [job, setJob] = useState([]);
  const getIndustry = () => {
    axios
      .get(site_ip + '/getIndustryList')
      .then((res) => {
        // console.log(res)
        setIndustry(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getJobs = () => {
    axios
      .get(site_ip + '/getJobMaster')
      .then((res) => {
        // console.log(res);
        setJob(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const [jobs, setJobs] = useState([]);
  // const getAllJobDescriptions = () => {
  //   axios
  //     .get(site_ip + '/getAllCompanyJobs')
  //     .then((res) => {
  //       setJobs(res.data.Content);
  //     })
  //     .catch((err) => console.log(err));
  // };
  useEffect(() => {
    getIndustry();
    // getAllJobDescriptions();
    getJobs();
    getCompanies();
  }, []);

  const [companies, setCompanies] = useState([]);
  const getCompanies = () => {
    axios
      .get(site_ip + '/getCompanyList')
      .then((res) => {
        // console.log(res);
        setCompanies(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = () => {
    axios
      .post(site_ip + '/searchCompanyJobs', search)
      .then((res) => {
        // console.log(res);
        setJobs(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const handleReset = () => {
    setSearch(blankSearch)
    axios
      .post(site_ip + '/searchCompanyJobs', blankSearch)
      .then((res) => {
        setJobs(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"100px"
    },
    {
      name: 'Client Name',
      selector: (row) => row?.client_name,
    },
    // {
    //   name: 'Company',
    //   selector: (row) => row?.candidate_email,
    // },
    {
      name: 'Job',
      selector: (row) => row?.job_name,
    },
    {
      name: 'Total Positions',
      selector: (row) => row?.open_positions_count,
    },
    {
      name: 'Status',
      selector: (row) => row.job_closing_status === 'O' ? 'Open' : 'Closed',
    },
    {
      name: 'Action',
      width: "150px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => navigate('/recruiter/jobs/edit-job/' + row.co_job_id)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
          {/* <Link to={{ pathname: `/edit-question/${value.ques_id}` }}>
                      <i className="menu-icon fas fa-edit"></i>
                    </Link> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <>
    <Row className="align-items-center">
      <Col md={2}>
        <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Open Positions</p>
      </Col>
      <Col md={10} className="text-end">
      </Col>
    </Row>
    <Row>
      <Col xl={12} className="mt-3">
        <div className="bg-white">
          <div className="admin-filter-box">
            <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
              <h4 className="m-0"> Filter by </h4>
            </div>
            <div className="row">
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Company <span className='required'>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="company_id"
                  value={search.company_id}
                  onChange={handleChange}
                >
                  <option value="">Select Company</option>
                  {companies?.map((company) => (
                    <option key={company.company_id} value={company.company_id}>
                      {company.company_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Industry</Form.Label>
                <Form.Control
                  as="select"
                  name="industry_id"
                  value={search.industry_id}
                  onChange={handleChange}
                >
                  <option value="">Select Industry</option>
                  {industry?.map((item) => (
                    <option key={item.industry_id} value={item.industry_id}>
                      {item.industry_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Job</Form.Label>
                <Form.Control
                  as="select"
                  name="job_id"
                  value={search.job_id}
                  onChange={handleChange}
                >
                  <option value="">Select Job</option>
                  {job?.map((item) => (
                    <option key={item.job_id} value={item.job_id}>
                      {item.job_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Date From</Form.Label>
                <Form.Control
                  type="date"
                  name="date_from"
                  value={search.date_from}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Date To</Form.Label>
                <Form.Control
                  type="date"
                  name="date_to"
                  value={search.date_to}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Job Closing Status</Form.Label>
                <Form.Control
                  as="select"
                  name="job_closing_status"
                  value={search.job_closing_status}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="O">Open</option>
                  <option value="C">Closed</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="btn-box  border-top mt-3 pt-3">
            <button
              className="success"
              onClick={handleSearch}
            >
              Apply
            </button>
            <button className="close" onClick={handleReset}>
              Reset
            </button>
          </div>
          </div>
        </div>
        </div>
      </Col>
    </Row>      
    <Row>
      <Col>
          <DataTable columns={tableHeader} data={jobs} pagination customStyles={CustomTableStyle} />                
      </Col>
    </Row>
    </>
  );
}
