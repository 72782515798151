import {Otp_Token,Question_List,Instruct_List,Attempt_List} from './types';

export const GETOTPTOKEN=(result)=>{
  return (dispatch)=>{
    dispatch({
    type:Otp_Token,
    payload:result
})

}
}

export const GETQUESTIONLIST=(result)=>{
  return (dispatch)=>{
    dispatch({
    type:Question_List,
    payload:result
})

}
}
export const GETINSTRUCTLIST=(result)=>{

  return (dispatch)=>{
    dispatch({
    type:Instruct_List,
    payload:result
})
console.log(result)
}
}

export const  GETATTEMPTLIST=(result)=>{
  return (dispatch)=>{
    dispatch({
    type:Attempt_List,
    payload:result
})
console.log(result)
}

}
// export const Country_List=(result)=>{
//   return (dispatch)=>{
//     dispatch({
//     type:Country_ListT,
//     payload:result
  
// })
// console.log(result)
// }

// }