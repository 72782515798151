import React from "react";
import { Container,Col,Row,Button,Badge,Form } from "react-bootstrap";
import { useNavigate,useLocation } from "react-router-dom";

import "../../../../components/candidate/Exam.css";
import { useState } from "react";
import { useEffect } from "react";
function ExamQuestionPreview (props){
    const location = useLocation();
    let navigate = useNavigate();
    const [question,setQuestion] = useState(location.state.data);

      const [option, setOption] = useState(0);

    console.log(location,'Location')

    

    const renderAnything=(text)=>{
         return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text.replace(/&nbsp;/g, '').replace(/<br>/g, ''),
                }}
            />
        )
    }

    
  const renderOptionOne = (text) => { debugger
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };
  const renderOptionTwo = (text) => {
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };
  const renderOptionThree = (text) => {
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };
  const renderOptionFour = (text) => {
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };

  
  const hChange1 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(question.option1);
    //console.log(stored_question_list?.Questions[quest_id].option1);
  };

  const hChange2 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(question.option2);
    // console.log(question.option2);

  };

  const hChange3 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(question.option3);
    // console.log(question.option3);

   
  };
  
  const hChange4 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(question.option4);
    // console.log(stored_question_list?.Questions[quest_id].option4);
  };

    return (<>
    <Container fluid className={'exam-background'}>
        <Row className="align-items-stretch h-100">
          <div className="w-100 mx-auto bg-light headertop exam-header">
            <div class="left-box  d-flex align-items-center pt-2">
              <div class="d-inline-block align-middle mar-t12">
                <h4 class="mar-t0 mar-b8">
                  {/* {instruction_lists?.Tests[test_type].test_name} */}
                </h4>
              </div>
              <div class="ms-auto text-end">
                <h6>
                  {/* {parseInt(quest_id) + 1}/{stored_question_list?.Count} Question */}
                </h6>
                {/* <span><Stopwatch></Stopwatch></span> */}
              </div>
            </div>/
          </div>
          <Col md={9} className="mx-auto my-3">
        
            <div className="border p-4 px-3 question-box">
              <div class="row">
                <div class="col-md-12">
                  <div style={{ height: '62vh', overflowY:'auto' }}>
                    <div className="p-3">
                      <p class="question" style={{ fontWeight: 'bold' }}>
                        Question No. ##
                      </p>
                      <p class="question">
                        <td>
                           { renderAnything(
                            question?.ques_desc
                          )}
                        </td>
                      </p>
                      
                        <>
                          {question.ques_type_id ==
                      1 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="checkbox"
                                id={
                                  question.option1
                                }
                            
                                label={renderOptionOne(question.option1)}
                                // checked={useranswers[quest_id].includes(1)}
                               
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                type="checkbox"
                                id={option}
                                label={renderOptionTwo(
                                  question
                                    .option2
                                )}
                               
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                
                                type="checkbox"
                                id={option}
                                label={renderOptionThree(
                                  question
                                    .option3
                                )}
                               
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                
                                type="checkbox"
                                id={option}
                                label={renderOptionFour(
                                  question
                                    .option4
                                )}
                               
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {question.ques_type_id ==
                      2 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={
                                 question.option1
                                }
                                value={option}
                                label={renderOptionOne(
                                 question.option1
                                )}

                                onChange={(e)=>{
                                    hChange1('option1');
                                }}
                                
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                
                                type="radio"
                                id={option}
                                label={renderOptionTwo(
                                 question.option2
                                )}
                                
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                
                                type="radio"
                                id={option}
                                label={renderOptionThree(
                                  question
                                    .option3
                                )}
                                
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                
                                type="radio"
                                id={option}
                                label={renderOptionFour(
                                  question
                                    .option4
                                )}
                                
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {question.ques_type_id ==
                      3 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={'True'}
                                value={option}
                                label={'True'}
                                
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={'False'}
                                value={option}
                                label={'False'}
                                
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {question.ques_type_id ==
                        4 ||
                      question.ques_type_id ==
                        5 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="p-2 w-100 h-100 answers">
                              <Form.Control
                                as="textarea"
                                placeholder="Answer here..."
                                style={{ height: '40vh' }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                        </>
                    
                    </div>
                  </div>
                </div>
              </div>

              <div class="btnbox d-flex align-items-center mt-3 top-line">
                <Col md={3}>
                    <Button
                      onClick={()=>{navigate(-1)}}
                      variant="outline-danger"
                    >
                      Back
                    </Button>
                </Col>
                
                <div class="mx-auto">
                  {/* <Link to="/" className="btn btn-danger ms-3">
                    Next
                       </Link>*/}
                </div>
                {/*<Link to="/thankyou" className="btn btn-danger  ms-3">
                  Finish
                      </Link>*/}
              </div>
            </div> 
          </Col>
          <Col md={3} className="my-3">
            
            <div
              class="my-3 bg-light border p-3 fix-right-nav question-box"
              style={{ height: 'calc(100% - (3rem + 120px)' }}
            >
              <div class="d-flex align-items-center border-bottom mb-2 pb-2 px-2">
                <span class="me-4 d-flex align-items-center">
                  <Badge pill className="badge bg-success rounded-0 me-2">
                    {1}
                  </Badge>
                  <span style={{ fontSize: '11px' }}>Attempted</span>
                </span>
                <span class="d-flex align-items-center">
                  <Badge
                    pill
                    className="badge bg-white text-dark border rounded-0 me-2"
                  >
                   1
                  </Badge>
                  <span style={{ fontSize: '11px' }}>Unattempted</span>
                </span>
              </div>
              <div
                class="numbering-block d-flex flex-wrap align-items-center"
                style={{ display: 'flex' }}
              >
                <div className='unattempted'>{1}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>)
}

export default ExamQuestionPreview;