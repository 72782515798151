import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    Form
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../../customTableStyle';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../common/ErrorModal';
import SuccessModal from '../../common/SuccessModal';


export default function JobList(props) {

    const navigate = useNavigate();
    const blankSearch = {
        customer_id: "",
        job_id: "",
        skill_id: "",
        experience_range: "",
        status: "",
        assigned_date_from: "",
        assigned_date_to: ""
    }

    const [statusUpdate, setStatusUpdate] = useState(null);
    const [search, setSearch] = useState(blankSearch);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearch((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDataChange = (e) =>{
        const {name, value} = e.target;
        setStatusUpdate((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const [skill, setSkill] = useState([]);
    const [job, setJob] = useState([]);
    const getSkills = () => {
        axios
            .get(site_ip + '/getSkills')
            .then((res) => {
                setSkill(res.data.Content);
            })
            .catch((err) => console.log(err));
    };
    const getJobs = () => {
        axios
            .get(site_ip + '/getJobMaster')
            .then((res) => {
                setJob(res.data.Content);
            })
            .catch((err) => console.log(err));
    };
    const [customers, setCustomers] = useState([]);
    const getCustomers = () => {
        axios
            .get(site_ip + '/getCustomer')
            .then((res) => {
                setCustomers(res.data.Content);
            })
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        getJobs();
        getCustomers();
        getSkills();
    }, []);

    const [resumes, setResumes] = useState([]);
    const handleSearch = () => {
        if (search.customer_id === "") {
            setErrorMessage("Please select customer");
            return
        }
        axios
            .post(site_ip + '/searchCustomerResumes', search)
            .then((res) => {
                // console.log(res);
                setResumes(res.data.Data);
            })
            .catch((err) => console.log(err));
    };

    const handleReset = () => {
        setSearch(blankSearch)
    };

    const renderStatus = (id) => {
        if (id === "1") {
            return(
                <div style={{padding:"5px 10px", borderRadius:"100px", backgroundColor:"#ccc", color:"#000000"}}>
                    Assigned
                </div>
            )
        } else if (id === "2") {
            return(
                <div style={{padding:"5px 10px", borderRadius:"100px", backgroundColor:"#ff0000", color:"#ffffff"}}>
                    Rejected
                </div>
            )
        } else if (id === "3") {
            return(
                <div style={{padding:"5px 10px", borderRadius:"100px", backgroundColor:"#4caf50", color:"#ffffff"}}>
                    Selected
                </div>
            )
        }
        return "";
    }

    let tableHeader = [
        {
            name: '#',
            selector: (row, index) => (index + 1),
            width: "100px"
        },
        {
            name: 'Candidate Name',
            selector: (row) => row?.candidate_name,
        },
        {
            name: 'Email',
            selector: (row) => row?.candidate_email,
        },
        {
            name: 'Mobile',
            selector: (row) => row?.candidate_mobile,
        },
        {
            name: 'Job',
            selector: (row) => row?.job_name,
        },
        {
            name: 'Skill',
            selector: (row) => row?.skill_name,
        },
        {
            name: 'Status',
            cell: (row) => (
                <>
                    {renderStatus(row.status)}
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Feedback',
            width:"200px",
            cell: (row) => (
                <div style={{padding:"5px"}}>
                    <p style={{whiteSpace:"pre-wrap"}}>{row.feedback}</p>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Action',
            width: "150px",
            cell: (row) => (
                <>
                    <button
                        className="btn btn-sm circle"
                        role="button"
                        style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
                        onClick={() => setStatusUpdate(row)}
                    >
                        <i class="fas fa-pencil-alt"></i>
                    </button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    const proceed = () =>{
        let req = {...statusUpdate}
        axios
            .post(site_ip + '/setCustomerResume', [req])
            .then((res) => {
                setSuccessMessage("Resume status/feedback updated successfully!")
                setStatusUpdate(null);
                handleSearch();
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            <Row className="align-items-center">
                <Col md={2}>
                    <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Assigned Resumes</p>
                </Col>
                <Col md={10} className="text-end">
                </Col>
            </Row>
            <Row>
                <Col xl={12} className="mt-3">
                    <div className="bg-white">
                        <div className="admin-filter-box">
                            <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
                                <h4 className="m-0"> Filter by </h4>
                            </div>
                            <div className="row">
                                <Row>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Customer <span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="customer_id"
                                                value={search.customer_id}
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {customers?.map((item) => (
                                                    <option key={item.customer_id} value={item.customer_id}>
                                                        {item.customer_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Skill</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="skill_id"
                                                value={search.skill_id}
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {skill?.map((item) => (
                                                    <option key={item.skill_id} value={item.skill_id}>
                                                        {item.skill_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Job</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="job_id"
                                                value={search.job_id}
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                {job?.map((item) => (
                                                    <option key={item.job_id} value={item.job_id}>
                                                        {item.job_name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Experience</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="experience_range"
                                                value={search.experience_range}
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                <option value="0-3">0-3 years</option>
                                                <option value="4-6">4-6 years</option>
                                                <option value="7-10">7-10 years</option>
                                                <option value="10+">10+ years</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="status"
                                                value={search.status}
                                                onChange={handleChange}
                                            >
                                                <option value="">--Select--</option>
                                                <option value="1">Assigned</option>
                                                <option value="2">Rejected</option>
                                                <option value="3">Selected</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date From</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="assigned_date_from"
                                                value={search.assigned_date_from}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date To</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="assigned_date_to"
                                                value={search.assigned_date_to}
                                                min={search.assigned_date_from === "" ? "" : search.assigned_date_from}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="btn-box  border-top mt-3 pt-3">
                                    <button
                                        className="success"
                                        onClick={handleSearch}
                                    >
                                        Apply
                                    </button>
                                    <button className="close" onClick={handleReset}>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable columns={tableHeader} data={resumes} pagination customStyles={CustomTableStyle} />
                </Col>
            </Row>
            <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
            <SuccessModal successMessage={successMessage} setSuccessMessage={() => { setSuccessMessage(null) }} />
            <Modal show={statusUpdate === null ? false : true} onHide={() => setStatusUpdate(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={statusUpdate?.status}
                                    onChange={handleDataChange}
                                >
                                    <option value="1">Assigned</option>
                                    <option value="2">Rejected</option>
                                    <option value="3">Selected</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Feedback <span style={{color:"#ff0000"}}>*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="feedback"
                                    value={statusUpdate?.feedback === null ? "" : statusUpdate?.feedback}
                                    onChange={handleDataChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={proceed}>
                        Update
                    </Button>
                    <Button variant="secondary" onClick={() => setStatusUpdate(null)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
