// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/bg-new.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: calc(100vh);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-card{
    padding-left: 40px;
    padding-right: 40px;
}

.heading-black-text{color: #000000; font-size: 20px; font-weight: bold;}

.image-box{padding: 80px; background-color: midnightblue;}

.sidebar .sidebar-links > a:hover{
    background: #000000!important;
    color: #ffffff!important;
}

.sidebar .sidebar-links > a[aria-expanded=true]{
    background: #000000!important;
}`, "",{"version":3,"sources":["webpack://./src/components/backend/content/Admin.css"],"names":[],"mappings":"AAAA;IACI,yDAA6C;IAC7C,mBAAmB;IACnB,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA,oBAAoB,cAAc,EAAE,eAAe,EAAE,iBAAiB,CAAC;;AAEvE,WAAW,aAAa,EAAE,8BAA8B,CAAC;;AAEzD;IACI,6BAA6B;IAC7B,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".bg {\n    background-image: url(\"../assets/bg-new.jpg\");\n    height: calc(100vh);\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.login-box{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.login-card{\n    padding-left: 40px;\n    padding-right: 40px;\n}\n\n.heading-black-text{color: #000000; font-size: 20px; font-weight: bold;}\n\n.image-box{padding: 80px; background-color: midnightblue;}\n\n.sidebar .sidebar-links > a:hover{\n    background: #000000!important;\n    color: #ffffff!important;\n}\n\n.sidebar .sidebar-links > a[aria-expanded=true]{\n    background: #000000!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
