import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';
import ErrorModal from '../../common/ErrorModal';
import SuccessModal from '../../common/SuccessModal';
import { useNavigate } from 'react-router-dom';
export default function AddVendor() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    vendor_name: "",
    vendor_contact_person: "",
    vendor_contact_email: "",
    vendor_phone: "",
    city_id: "",
    state_id: "",
    country_id: "",
    vendor_gst: "",
    vendor_co_pan: ""
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "country_id"){
      setValues((prev) => ({
        ...prev,
        [name]: value,
        state_id:"",
        city_id:""
      }));
      getStates(value)
    }else if(name === "state_id"){
      setValues((prev) => ({
        ...prev,
        [name]: value,
        city_id:""
      }));
      getCities(value)
    }else{
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };


  const [countries, setCountries] = useState([]);
  const getCountries = () => {
    axios
      .get(site_ip + '/getCountryList')
      .then((res) => {
        // console.log(res);
        setCountries(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const [states, setStates] = useState([]);
  const getStates = (id) => {
    axios
      .get(site_ip + '/getState/'+id)
      .then((res) => {
        setStates(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const [cities, setCities] = useState([]);
  const getCities = (id) => {
    axios
      .get(site_ip + '/getCities/'+id)
      .then((res) => {
        setCities(res.data.States);
      })
      .catch((err) => console.log(err));
  };
  
  useEffect(() => {
    getCountries();
  }, []);

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    let req = {...values};
    if(req.vendor_name.trim() === ""){
      setErrorMessage("Candidate Name cannot be blank.")
      return;
    }else if(req.vendor_contact_person.trim() === ""){
      setErrorMessage("Candidate Name cannot be blank.")
      return;
    }else if(!validateEmail(req.vendor_contact_email)){
      setErrorMessage("Please enter a valid email address.")
      return;
    }else if(!phonenumber(req.vendor_phone)){
      setErrorMessage("Please enter a valid mobile number.")
      return;
    }else if(req.vendor_gst === ""){
      setErrorMessage("Please enter experience in years.")
      return;
    }else if(req.vendor_co_pan === ""){
      setErrorMessage("Please select company.")
      return;
    }else {
      axios
        .post(site_ip + '/addVendor', req)
        .then((res) => {
          setSuccessMessage("New Vendor added successfully!")
        })
        .catch((err) => console.log(err));
    }
  };

  const inputRef = useRef();
  
  const attachmentName = (url) =>{
    let url_sections = url.split("/");
    if(url_sections.length > 0){
      return url_sections[url_sections.length - 1]
    }else{
      return ""
    }
  }

  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Add Vendor</h4>
          </div>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Vendor Name <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="vendor_name"
                    value={values.vendor_name}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Vendor Contact Person <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="vendor_contact_person"
                    value={values.vendor_contact_person}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Email <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="vendor_contact_email"
                    value={values.vendor_contact_email}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Mobile Number<span className="required">*</span></Form.Label>
                  <Form.Control
                    name="vendor_phone"
                    value={values.vendor_phone}
                    onChange={handleChange}
                    maxLength={10}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    name="country_id"
                    value={values.country_id}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {countries?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.country_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    name="state_id"
                    value={values.state_id}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {states?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    as="select"
                    name="city_id"
                    value={values.city_id}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {cities?.map((item) => (
                      <option key={item.city_id} value={item.city_id}>
                        {item.city_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Vendor GST<span className="required">*</span></Form.Label>
                  <Form.Control
                    name="vendor_gst"
                    value={values.vendor_gst}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Vendor PAN<span className="required">*</span></Form.Label>
                  <Form.Control
                    name="vendor_co_pan"
                    value={values.vendor_co_pan}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Col md={12} style={{ marginBottom: "15px" }}>
              <button
                className="primary"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </Col>
          </div>
        </Col>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      <SuccessModal successMessage={successMessage} setSuccessMessage={() => navigate("/recruiter/vendors")} />
    </Container>
  );
}
