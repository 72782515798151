import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Container } from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../Exam.css';
const ListUserFeedback = () => {
  let navigate = useNavigate();
  const [tests, setTests] = useState([]);
  const [interviewer, setInterviewer] = useState({});
  const fetchTestList = async (id) => {
    await axios({
      method: 'get',
      url: site_ip + '/getInterviewCandidateList/' + id,
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        setTests(response.data.Content);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  useEffect(() => {
    let interviewerData = localStorage.getItem('interviewer');
    if (interviewerData !== null && interviewerData !== undefined) {
      interviewerData = JSON.parse(interviewerData);
      setInterviewer(interviewerData);
      fetchTestList(interviewerData.interviewer_id);
    } else {
      navigate('/interviewer/login');
    }
    //eslint-disable-next-line
  }, []);

  const logOut = () => {};

  return (
    <>
      <div className="allBody">
        <div className="headerSection">
          <Container>
            <Row className="headerRow">
              <Col md={8} className="titleSection">
                <p>List of Interviews</p>
              </Col>
              <Col md={2} className="userSection">
                <p className="userLink">
                  Hi{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {interviewer?.interviewer_name}
                  </span>
                </p>
              </Col>
              <Col md={2} className="userSection">
                <a
                  className="logout"
                  onClick={(e) => {
                    e.preventDefault();
                    logOut();
                  }}
                  href="/#"
                >
                  Logout
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row className="listSection">
              <Col xl={12} className="mt-3" style={{ marginBottom: '1rem' }}>
                <div className="p-3 border bg-white">
                  <Table striped hover size="sm" responsive className="mt-4">
                    <thead className="lt-head">
                      <tr>
                        <th scope="row">Sr No</th>
                        <th>Candidate Name</th>
                        <th>Job</th>
                        <th>Company</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tests?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th>{index + 1}</th>
                            <td>{item.candidate_name}</td>
                            <td>{item.job_name}</td>
                            <td>{item.company_name}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/interviewer/feedback`,
                                  state: {
                                    company: item.company_id,
                                    job: item.job_id,
                                    request_id: item.id,
                                  },
                                }}
                              >
                                Submit Feedback
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <Container>
        <Row className="align-items-center">
          <Col md={3}></Col>
          <Col md={6}>
            <h6>Please answer these questions</h6>
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container> */}
      </div>
    </>
  );
};

export default ListUserFeedback;
