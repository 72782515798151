import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  // Tab,
  // Tabs,
  Form,
  Row,
  Table,
  // Modal,
} from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
function CandidateList() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const intialValues = {
    job_id: '',
    date_from: '',
    date_to: '',
    company_id: userdata.company_id,
  };
  const [candidatelist, setCandidateList] = useState([]);
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const [JobsList, setJobsList] = useState([]);

  // const handleShow = () => setShow(true);
  const [search, setSearch] = useState(intialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    const loadjob = async () => {
      axios({
        url: site_ip + '/getJobsList',
      }).then(function (response) {
        // console.log(response.data);
        setJobsList(response.data.content);
      });
    };
    //console.log(setindustriesList)
    loadjob();
  }, []);

  const handleSearch = (e) => {
    let data = intialValues;

    if (e !== undefined) {
      data = search;
    }
    console.log(data);
    axios({
      method: 'post',
      url: site_ip + '/getCandidateReport',
      data: data,
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response.data);
        setCandidateList(response.data.Summary);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line
    handleSearch();
    // eslint-disable-next-line
  }, []);
  //   const fetchCandidateListData = () =>
  //     fetch(`${site_ip}/getJobCandidatesList/` + userdata.company_id).then(
  //       (res) => res.json()
  //     );
  //   useEffect(() => {
  //     fetchCandidateListData().then((data) => getCandidateList(data.Content));
  //     //eslint-disable-next-line
  //   }, []);
  return (
    <>
      <div class="align-items-center row">
        <Col md={12}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Candidate List</p>
        </Col>
      </div>
      <Row>
        <Col className="mt-3">
          <div className="admin-filter-box">
            <Row>
              <Col md={4}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Job</Form.Label>
                    <Form.Control
                      as="select"
                      value={search.job_id}
                      name="job_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Job</option>
                      {JobsList.map((item, index) => {
                        return (
                          <option key={item.job_id} value={item.job_id}>
                            {item.job_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formjob">
                  <Form.Label>Date From</Form.Label>
                  <Form.Control
                    type="date"
                    name="date_from"
                    className="mb-3"
                    max={new Date().toISOString().split('T')[0]}
                    onChange={handleChange}
                    value={search.date_from}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formjob">
                  <Form.Label>Date To</Form.Label>
                  <Form.Control
                    type="date"
                    name="date_to"
                    className="mb-3"
                    min={search.date_from === '' ? '' : search.date_from}
                    max={new Date().toISOString().split('T')[0]}
                    onChange={handleChange}
                    value={search.date_to}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={2} className="mt-2">
                <button
                  className="success"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <button className="close">
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
          <div class="border bg-white p-3 h-100 mt-4">
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Test Date</th>
                  <th>Test Time</th>
                  <th>Marks</th>
                  <th>Marks (in %)</th>
                </tr>
              </thead>
              <tbody>
                {candidatelist?.map((value, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{value.candidate_name}</td>
                    <td>{value.candidate_email}</td>
                    <td>{value.candidate_mobile}</td>
                    <td>{value.test_date}</td>
                    <td>{value.test_time}</td>
                    <td>{value.marks}</td>
                    <td>{value.marks_pct}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CandidateList;
