import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Tab,
  Tabs,
  Form,
  Row,
  Table,
  Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../customTableStyle';
import {useNavigate} from 'react-router-dom';
function ManageCandidate() {
  const navigate = useNavigate();
  const intialValues = {
    candidate_email: '',
    candidate_name: '',
    candidate_mobile: '',
    job_id: '',
  };
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [candidatelist, getCandidateList] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const [search, setSearch] = useState(intialValues);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(candidatelist.length); // Set the total number of rows


  const handleSearch = (e) => {
    axios({
      method: 'post',
      url: site_ip + '/searchCompanyJobCandidates',
      data: {
        candidate_mobile: search.candidate_mobile,
        job_id: search.job_id,
        candidate_name: search.candidate_name,
        company_id: userdata.company_id,
        candidate_email: search.candidate_email,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response.data);
        getCandidateList(response.data.Content)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const fetchCandidateListData = () =>
    fetch(`${site_ip}/getJobCandidatesList/` + userdata.company_id).then(
      (res) => res.json()
    );
  useEffect(() => {
    fetchCandidateListData().then((data) => getCandidateList(data.Content));
    //eslint-disable-next-line
  }, []);

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (currentPage - 1) * pageSize + index + 1,
      width:"100px"
    },
    {
      name: 'Name',
      selector: (row) => row?.candidate_name,
    },
    {
      name: 'Email',
      selector: (row) => row?.candidate_email,
    },
    {
      name: 'Mobile',
      selector: (row) => row?.candidate_mobile,
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
    },
    {
      name: 'Action',
      width: "150px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm "
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => navigate('/edit-candidate/' + row.candidate_id)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]


  return (
    <>
      <div class="align-items-center row">
        <div class="col-md-6">
          <p className="admin-page-title"><i class="fas fa-list-ol"></i> Manage Candidate</p>
        </div>
        <Col md={6} className="text-end">
          <Link
            className="admin-action-btns"
            to={{ pathname: '/add-candidate' }}
          >
            <i class="fas fa-plus-square icon"></i> Add Candidate
          </Link>
        </Col>
      </div>
      <Row>
        <Col className="mt-3">
          <div className="admin-filter-box">
            <Row>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Name </Form.Label>
                    <Form.Control
                      type="text"
                      name="candidate_name"
                      value={search.candidate_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="candidate_email"
                      value={search.candidate_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type="number"
                      name="candidate_mobile"
                      value={search.candidate_mobile}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      as="select"
                      value={search.job_id}
                      name="job_id"
                      onChange={handleChange}
                    >
                      <option>Select your Position</option>
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={2} className="mt-4">
                <button
                  className="success"
                  onClick={handleSearch}
                >
                  Apply
                </button>
                <button className="close">
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
          <div class="border bg-white p-3 h-100 mt-4">
            {/* <DataTable columns={tableHeader} data={candidatelist} pagination customStyles={CustomTableStyle} /> */}

            <DataTable
        columns={tableHeader}
        data={candidatelist}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={pageSize}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
          setCurrentPage(1);
          setPageSize(currentRowsPerPage);
        }}
        customStyles={CustomTableStyle}
      />


            {/* <Tabs defaultActiveKey="upcoming" className="mb-3">
              <Tab eventKey="upcoming" title="Active">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Action</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidatelist?.map((value, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/edit-candidate/${value.candidate_id}`,
                            }}
                          >
                            <i className="menu-icon fas fa-edit"></i>
                          </Link>
                        </td>
                        <td>{value.candidate_name}</td>
                        <td>{value.candidate_email}</td>
                        <td>{value.candidate_mobile}</td>
                        <td>{value.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="completed" title="Completed"></Tab>
            </Tabs> */}
            <Modal
              show={show}
              closeButton="false"
              centered
              onHide={handleClose}
              className="rounded-0"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Candidates</Modal.Title>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </Modal.Header>
              <Modal.Body className="rounded-0">
                <Form>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Name
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control type="number" placeholder="Name" />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Email
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue="email@example.com"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="2">
                      Mobile
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control type="number" placeholder="Password" />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="2">
                      Position
                    </Form.Label>
                    <Col sm="10">
                      <Form.Select>
                        <option>Default select</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Save Details
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ManageCandidate;
