import React, { useState, useEffect } from 'react';
import { Button, Col, Tab, Tabs, Form, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../../../globalSettings';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../../customTableStyle';
const QuestionListing = () => {
  const navigate = useNavigate();
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [subjectListing, setSubjectListing] = useState([])

  // const handleSearch = () => {
  //   axios({
  //     method: 'post',
  //     url: site_ip + '/searchTests',
  //     data: {
  //       job_id: search.job_id,
  //       date_from: search.date_from,
  //       date_to: search.date_to,
  //       test_name: search.test_name,
  //       company_id: userdata.company_id,
  //       industry_id: userdata.IndustryId,
  //     },
  //     dataType: 'json',
  //   })
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         getTestList(response.data.Tests);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log('error' + error);
  //     });
  // };

  useEffect(()=>{
    getData();
  }, [])


  const getData = async () => {
    axios({
      method: 'get',
      url: site_ip + '/getQBankList',
    })
      .then(function (response) {
        setSubjectListing(response.data.Questions);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"100px"
    },
    {
      name: 'Subject',
      selector: (row) => row?.subject_name,
    },
    // {
    //   name: 'Subject Details',
    //   selector: (row) => row?.subject_name,
    // },
    {
      name: 'Action',
      width: "150px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => console.log("wip")}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]
  return (
    <>
      <Row className="align-items-center">
        <Col md={6}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Question Listing</p>
        </Col>
        <Col md={6} className="text-end">
          <Button
            className='admin-action-btns'
            onClick={() => navigate('/exam/master/questions-add')}
          >
            <i class="fas fa-plus icon"></i> Add Question
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {/*<div className="admin-filter-box">
            <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
              <h4 className="m-0">Search Tests </h4>
            </div>
             <Row>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Job </Form.Label>
                    <Form.Control
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Test Name</Form.Label>
                    <Form.Control
                      placeholder="Test Name"
                      name="test_name"
                      value={search.test_name}
                      onChange={handleChange}
                      type="text"
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={2} className="mt-4">
                <button
                  className="success"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <button className="close">
                  Cancel
                </button>
              </Col>
            </Row> 
          </div> */}
          <DataTable columns={tableHeader} data={subjectListing} pagination customStyles={CustomTableStyle} />
        </Col>
      </Row>
    </>
  );
}

export default QuestionListing;
