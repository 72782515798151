import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
import axios from 'axios';
import Swal from 'sweetalert2';
import SuccessModal from '../common/SuccessModal';
import ErrorModal from '../common/ErrorModal';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../customTableStyle';
function ManageInterviewer(props) {
  const userdata = JSON.parse(localStorage.getItem('userdata'))?.Content[0];
  const initialValues = {
    interviewer_id: '',
    company_id: userdata.company_id === null ? 1 : userdata.company_id,
    interviewer_name: '',
    interviewer_email: '',
    mobile_number: '',
    internal_external_ind: '',
    expertise_in: '',
    updating: false,
  };

  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);
  const [add, setAdd] = useState(false);
  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    fetchCompanyList();
    fetchInterviewerList(values.company_id);
    //eslint-disable-next-line
  }, []);

  const fetchCompanyList = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompanyList(result.data.Content);
  };

  const fetchInterviewerList = async (company_id) => {
    const result = await axios.get(`${site_ip}/getInterviewer/${company_id}`);
    setInterviewerList(result.data.Content);
  };

  const editNow = (item) => {
    const newValues = {
      interviewer_id: item.interviewer_id,
      company_id: item.company_id,
      interviewer_name: item.interviewer_name,
      interviewer_email: item.interviewer_email,
      mobile_number: item.mobile_number,
      internal_external_ind: item.internal_external_ind,
      expertise_in: item.expertise_in,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const proceed = () => {
    if (values.interviewer_name.trim() === '') {
      setErrorMessage('Please enter Interviewer name');
      return
    }else if(!validateEmail(values.interviewer_email)){
      setErrorMessage("Please enter a valid email address.")
      return;
    }else if(!phonenumber(values.mobile_number)){
      setErrorMessage("Please enter a valid mobile number.")
      return;
    }else if(values.internal_external_ind === '') {
      setErrorMessage('Please select internal/external interviewer.');
      return
    }else if(values.internal_external_ind === '') {
      setErrorMessage("Please add interviewer's expertise.");
      return
    } else if (values.interviewer_id === '') {
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/addInterviewer',
        data: {
          company_id: values.company_id,
          interviewer_name: values.interviewer_name.trim(),
          interviewer_email: values.interviewer_email,
          mobile_number: values.mobile_number,
          internal_external_ind: values.internal_external_ind,
          expertise_in: values.expertise_in,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Status === 200) {
            setSuccessMessage("New Interviewer added successfully");
            fetchInterviewerList(values.company_id);
          } else {
            alert('Request failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/setInterviewer',
        data: {
          interviewer_id: values.interviewer_id,
          company_id: values.company_id,
          interviewer_name: values.interviewer_name.trim(),
          interviewer_email: values.interviewer_email,
          mobile_number: values.mobile_number,
          internal_external_ind: values.internal_external_ind,
          expertise_in: values.expertise_in,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Status === 200) {
            setSuccessMessage("Interviewer's details updated successfully!");
            fetchInterviewerList(values.company_id);
          } else {
            alert('Request failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Interviewer Name',
      selector: (row) => row?.interviewer_name,
    },
    {
      name: 'Interviewer Email',
      selector: (row) => row?.interviewer_email,
    },
    {
      name: 'Interviewer Mobile',
      selector: (row) => row?.mobile_number,
    },
    {
      name: 'Expertise In',
      selector: (row) => row?.expertise_in,
    },
    {
      name: 'Status',
      selector: (row) => row.internal_external_ind === 'I' ? 'Internal' : 'External',
    },
    {
      name: 'Action',
      width: "150px",
      cell: (row, index) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => editNow(row)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
          {/* <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#33adff', color: "#ffffff", marginLeft: "10px" }}
            onClick={() => setOptionPopup(!optionPopup)}
          >
            <i class="fas fa-cog"></i>
          </button> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Manage Interviewer</p>
        </Col>
        <Col md={6} className="text-end">
          <Button
            className='admin-action-btns'
            onClick={() => handleShow()}
          >
            <i class="fas fa-plus icon"></i> Add
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Control
              as="select"
              className="mb-3"
              name="filter"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
            >
              <option value="">Select Company</option>
              {companyList.map((value) => (
                <option key={value.company_id} value={value.company_id}>
                  {value.company_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button variant="primary" onClick={fetchInterviewerList}>
            Search
          </Button>
        </Col>
      </Row> */}

      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <DataTable columns={tableHeader} data={interviewerList} pagination customStyles={CustomTableStyle} />
          </div>
        </Col>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
      <SuccessModal successMessage={successMessage} setSuccessMessage={() => setSuccessMessage(null)} />
      <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update Interviewer' : 'Add Interviewer'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="admin-form-row">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Name <span className="required">*</span></Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="interviewer_name"
                  value={values.interviewer_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Email <span className="required">*</span></Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="interviewer_email"
                  value={values.interviewer_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Mobile Number <span className="required">*</span></Form.Label>
                <Form.Control
                  type="number"
                  required
                  className="mb-3"
                  name="mobile_number"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Internal/External <span className="required">*</span></Form.Label>
                <Form.Control
                  as="select"
                  required
                  className="mb-3"
                  name="internal_external_ind"
                  value={values.internal_external_ind}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="I">Internal</option>
                  <option value="E">External</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Expertise In <span className="required">*</span></Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="expertise_in"
                  value={values.expertise_in}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ManageInterviewer;
