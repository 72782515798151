// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    text-align: start
}

.login-btn {
    background: steelblue;
    padding: 8px 30px !important;
    color: #fff;
    border-width: 0;
    width: 100%;
}

.get-started-btn {
    background: #fff;
    padding: 8px 20px !important;
    margin-left: 10px;
    color: steelblue;
    border-radius: 10px;
    border: 2px solid steelblue
}

.align-item-self {
    align-self: end;
    margin-top: 20px;
}

.password {
    display: flex;
    flex-direction: row;
}

.line {
    height: 1px;
    width: 200px;
    border: 1px solid lightgray;
    margin-right: 30px;
    margin-left: 30px;
}

.margin-top-20 {
    margin-top: 20px;
}

.sign-on-btn {
    background: green;
    padding: 8px 30px !important;
    color: #fff;
    border-width: 0;

}

.img {
    height: 100%;
    width: 100%;
}

.forgot {
    float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginNew.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,qBAAqB;IACrB,4BAA4B;IAC5B,WAAW;IACX,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB;AACJ;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,4BAA4B;IAC5B,WAAW;IACX,eAAe;;AAEnB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header {\r\n    text-align: start\r\n}\r\n\r\n.login-btn {\r\n    background: steelblue;\r\n    padding: 8px 30px !important;\r\n    color: #fff;\r\n    border-width: 0;\r\n    width: 100%;\r\n}\r\n\r\n.get-started-btn {\r\n    background: #fff;\r\n    padding: 8px 20px !important;\r\n    margin-left: 10px;\r\n    color: steelblue;\r\n    border-radius: 10px;\r\n    border: 2px solid steelblue\r\n}\r\n\r\n.align-item-self {\r\n    align-self: end;\r\n    margin-top: 20px;\r\n}\r\n\r\n.password {\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.line {\r\n    height: 1px;\r\n    width: 200px;\r\n    border: 1px solid lightgray;\r\n    margin-right: 30px;\r\n    margin-left: 30px;\r\n}\r\n\r\n.margin-top-20 {\r\n    margin-top: 20px;\r\n}\r\n\r\n.sign-on-btn {\r\n    background: green;\r\n    padding: 8px 30px !important;\r\n    color: #fff;\r\n    border-width: 0;\r\n\r\n}\r\n\r\n.img {\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n.forgot {\r\n    float: right;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
