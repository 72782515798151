import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../Login.css';
import { site_ip } from '../../globalSettings.js';
import { useNavigate } from 'react-router-dom';
const axios = require('axios');
function Password(props) {
  let navigate = useNavigate();
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const initialValues = {
    user_id: userdata.id,
    user_email: userdata.user_email,
    old_password:'',
    password: '',
    confirm_password: '',
  };
  const [values, setValues] = useState(initialValues);
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [errors, setErrors] = useState({});
  const validate = (values) => {
    let errors = {};
    if (!values.user_email) {
      errors.user_email = 'Email address is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    if (values.password !== values.confirm_password) {
      errors.confirm_password = 'Confirm Password is not match with Password';
    }
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      axios({
        method: 'post',
        url: `${site_ip}/auth/updateUserPass`,
        data: {
          user_id: values.user_id,
          user_email: values.user_email,
          password: values.password,
        },
        dataType: 'json',
      })
        .then(function (response) {
          setSubmitted(true);
          console.log(submitted);
          navigate('/login');
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log('errors try again', validationErrors);
    }
  };
  return (
    <>
      <Row style={{ margin: "0px" }}>
        <Col></Col>
        <Col>
          <div className="profile-content-section">
            <p className="heading-1" style={{ color: "#000000", fontWeight: "bold" }}>
              Update Password
            </p>
            <div>
              <Col md={12} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Old Password <span className='required'>*</span></p>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="old_password"
                    value={values.old_password}
                    onChange={handleChange}
                  />
                  {errors.first_name && (
                    <p style={{ color: 'red' }}>{errors.first_name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">New Password <span className='required'>*</span></p>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <p style={{ color: 'red' }}>{errors.password}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Confirm New Password <span className='required'>*</span></p>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                  />
                  {errors.confirm_password && (
                    <p style={{ color: 'red' }}>{errors.confirm_password}</p>
                  )}
                </Form.Group>
              </Col>
            </div>
            <div className="align-items-center justify-content-center flex">
              <button className='primary-btn' onClick={() => handleSubmit()}>SUBMIT</button>
            </div>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
}

export default Password;
