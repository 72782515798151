import React, { useState } from 'react';

const CompanyRegistration = () => {
  const [formData, setFormData] = useState({
    company_id: '',
    company_name: '',
    company_add1: '',
    company_add2: '',
    company_city_id: '',
    state_id: '',
    country_id: '',
    emp_count: '',
    industry_id: '',
    company_url: '',
    company_logo: '',
    company_email: '',
    contact_person: '',
    contact_person_mobile: '',
    contact_person_email: '',
    company_board_no: '',
    company_status: '',
    signed_on: ''
  });

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);


  };

  return (
    <div className="container mt-5">
      <h2>Register Your Company</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="company_id" className="form-label">Company ID</label>
          <input
            type="text"
            className="form-control"
            id="company_id" 
            name="company_id"
            value={formData.company_id}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_name" className="form-label">Company Name</label>
          <input
            type="text"
            className="form-control"
            id="company_name"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_add1" className="form-label">Company Address Line 1</label>
          <input
            type="text"
            className="form-control"
            id="company_add1"
            name="company_add1"
            value={formData.company_add1}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_add2" className="form-label">Company Address Line 2</label>
          <input
            type="text"
            className="form-control"
            id="company_add2"
            name="company_add2"
            value={formData.company_add2}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_city_id" className="form-label">City ID</label>
          <input
            type="text"
            className="form-control"
            id="company_city_id"
            name="company_city_id"
            value={formData.company_city_id}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="state_id" className="form-label">State ID</label>
          <input
            type="text"
            className="form-control"
            id="state_id"
            name="state_id"
            value={formData.state_id}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="country_id" className="form-label">Country ID</label>
          <input
            type="text"
            className="form-control"
            id="country_id"
            name="country_id"
            value={formData.country_id}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="emp_count" className="form-label">Employee Count</label>
          <input
            type="number"
            className="form-control"
            id="emp_count"
            name="emp_count"
            value={formData.emp_count}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="industry_id" className="form-label">Industry ID</label>
          <input
            type="text"
            className="form-control"
            id="industry_id"
            name="industry_id"
            value={formData.industry_id}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_url" className="form-label">Company URL</label>
          <input
            type="url"
            className="form-control"
            id="company_url"
            name="company_url"
            value={formData.company_url}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_logo" className="form-label">Company Logo</label>
          <input
            type="file"
            className="form-control"
            id="company_logo"
            name="company_logo"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_email" className="form-label">Company Email</label>
          <input
            type="email"
            className="form-control"
            id="company_email"
            name="company_email"
            value={formData.company_email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="contact_person" className="form-label">Contact Person</label>
          <input
            type="text"
            className="form-control"
            id="contact_person"
            name="contact_person"
            value={formData.contact_person}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="contact_person_mobile" className="form-label">Contact Person Mobile</label>
          <input
            type="tel"
            className="form-control"
            id="contact_person_mobile"
            name="contact_person_mobile"
            value={formData.contact_person_mobile}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="contact_person_email" className="form-label">Contact Person Email</label>
          <input
            type="email"
            className="form-control"
            id="contact_person_email"
            name="contact_person_email"
            value={formData.contact_person_email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_board_no" className="form-label">Company Board Number</label>
          <input
            type="text"
            className="form-control"
            id="company_board_no"
            name="company_board_no"
            value={formData.company_board_no}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="company_status" className="form-label">Company Status</label>
          <input
            type="text"
            className="form-control"
            id="company_status"
            name="company_status"
            value={formData.company_status}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="signed_on" className="form-label">Signed On</label>
          <input
            type="date"
            className="form-control"
            id="signed_on"
            name="signed_on"
            value={formData.signed_on}
            onChange={handleChange}
          />
        </div>
        
        <button type="submit" className="btn btn-primary">Register</button>
      </form>
    </div>
  );
};

export default CompanyRegistration;
