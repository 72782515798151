import React from 'react';
import App from './App';
const app = () => {
  return (
    <App />
  );
};


export default app;
