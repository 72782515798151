import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { useNavigate } from 'react-router-dom';

const TestForm = (props) => {
  const learner_id = JSON.parse(localStorage.getItem('quantam_learner_id'));
  // console.log(learner_id);
  const initialData = {
    learner_id: learner_id,
    industry_id: '',
    job_id: '',
    subject_id: '',
    topic_id: '',
    question_count: '',
  };
  const [data, setData] = useState(initialData);
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [industries, setIndustryList] = useState([]);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
    if (name === 'subject_id') getTopicsList(value);
    if (name === 'industry_id') getJobsList(value);
  };
  const getIndustryList = async () => {
    const result = await axios.get(`${site_ip}/getIndustryList`);
    setIndustryList(result.data.Content);
  };
  const getJobsList = async (id) => {
    const response = await axios.get(site_ip + '/getJobListByIndustryId/' + id);
    setJobs(response.data.Content);
  };
  const getSubjectsList = async () => {
    const response = await axios.get(site_ip + '/getSubjects');
    setSubjects(response.data.Content);
  };
  const getTopicsList = async (subject_id) => {
    const response = await axios.get(
      site_ip + '/getSubjectTopics/' + subject_id
    );
    setTopics(response.data.Student);
  };
  useEffect(() => {
    getSubjectsList();
    getIndustryList();
  }, []);
  const redirectToTest = (data) => {
    if (data.length > 0)
      navigate({
        pathname: '/learner/exam-page',
        state: { data: data, test_type: 'Custom' },
      });
    else alert('No Data Found ');
  };
  const handleSubmit = (e) => {
    if (data.industry_id === '') {
      alert('Please select atleast industry');
      return;
    } else {
      axios({
        method: 'POST',
        url: site_ip + '/getCustomTest',
        data: data,
      })
        .then((response) => {
          // setTestData(response.data.Admin);
          console.log(response);
          // console.log(testData);
          redirectToTest(response.data.Admin);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <Container>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Add Custom Test</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col sm>
                <Form.Group>
                  <Form.Label>Industry</Form.Label>
                  <Form.Control
                    name="industry_id"
                    value={data.industry_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Industry</option>
                    {industries?.map((value) => (
                      <option value={value.industry_id}>
                        {value.industry_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group>
                  <Form.Label>Job</Form.Label>
                  <Form.Control
                    name="job_id"
                    value={data.job_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Job</option>
                    {jobs?.map((value) => (
                      <option value={value.job_id}>{value.job_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    name="subject_id"
                    value={data.subject_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Subject</option>
                    {subjects?.map((value) => (
                      <option value={value.subject_id}>
                        {value.subject_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm>
                <Form.Group>
                  <Form.Label>Topics</Form.Label>
                  <Form.Control
                    name="topic_id"
                    value={data.topic_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Topic</option>
                    {topics?.map((value) => (
                      <option value={value.topic_id}>{value.topic_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm>
                <Form.Group>
                  <Form.Label>Question Count</Form.Label>
                  <Form.Control
                    name="question_count"
                    value={data.question_count}
                    onChange={handleChange}
                    type="number"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={handleSubmit} style={{ marginTop: '25px' }}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TestForm;
