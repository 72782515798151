import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { site_ip } from '../../globalSetting';
import { Link } from 'react-router-dom';
import axios from 'axios';
const UserFeedbackQuestions = () => {
  // const [open, setOpen] = useState(true);
  const [questionlist, setQuestionList] = useState([]);
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const fetchQuestionList = async () => {
    const response = await axios
      .get(site_ip + '/getUserFeedbackQuestions/' + userdata.company_id)
      .catch((error) => console.log(error));
    console.log(response);
    setQuestionList(response.data?.Data);
  };
  useEffect(() => {
    fetchQuestionList();
    //eslint-disable-next-line
  }, []);
  const renderQuestion = (text) => {
    return '';
  };

  return (
    <>
      <Row className="align-items-center">
        <Col md={4}>
          <h4>User Feedback Questions</h4>
        </Col>
        <Col md={8} className="text-end">
          <Link
            className="btn btn-dark me-3"
            to={{ pathname: '/add-user-feedback-questions' }}
          >
            <i class="fas fa-plus-square"></i> Add User Feedback Questions
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th>#</th>
                  <th>Action</th>
                  <th>Id</th>
                  <th>User Feedback Question</th>
                  <th>Question Type</th>
                  {/* <th>Feedback Range</th> */}
                </tr>
              </thead>
              <tbody className="lt-body">
                {questionlist?.map((value, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <Link
                        to={{
                          pathname: `/edit-user-feedback-questions/${value.question_id}`,
                          state: value,
                        }}
                      >
                        <i className="menu-icon fas fa-edit"></i>
                      </Link>
                    </td>
                    <td>{value.question_id}</td>
                    <td>{renderQuestion(value.question_desc)}</td>
                    <td>{JSON.parse(value.question_options)[0]}</td>
                    {/* <td>
                      {JSON.parse(value.question_options)[0] +
                        ' - ' +
                        JSON.parse(value.question_options)[1]}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserFeedbackQuestions;
