// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.candidateList {
  border: 1px solid #e4e4e4;
  position: relative;
  padding: 20px;
}
.headinglist {
  position: absolute;
  background: #fff;
  top: -12px;
  left: 10px;
  padding: 2px 10px;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/afterLogin/assignTest/assigntest.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,UAAU;EACV,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".candidateList {\r\n  border: 1px solid #e4e4e4;\r\n  position: relative;\r\n  padding: 20px;\r\n}\r\n.headinglist {\r\n  position: absolute;\r\n  background: #fff;\r\n  top: -12px;\r\n  left: 10px;\r\n  padding: 2px 10px;\r\n  font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
