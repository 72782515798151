import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
import Swal from 'sweetalert2';
function ManageSubjectTopics(props) {
  const initialValues = {
    topic_id: '',
    topic_name: '',
    topic_desc: '',
    subject_id: '',
    updating: false,
  };

  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [add, setAdd] = useState(false);

  const [values, setValues] = useState(initialValues);

  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const proceed = () => {
    let topic_name = values.topic_name;
    if (topic_name.trim() === '') {
      alert('Please enter Topic name');
    } else if (values.topic_id === '') {
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/addSubjectTopics',
        data: {
          topic_name: values.topic_name.trim(),
          topic_desc: values.topic_desc,
          subject_id: values.subject_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Topic added successfully',
              icon: 'success',
            }).then((result) => {
              fetchSubjectTopic();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/setSubjectTopics',
        data: {
          topic_id: values.topic_id,
          topic_name: values.topic_name.trim(),
          topic_desc: values.topic_desc,
          subject_id: values.subject_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Topic details successfully updated',
              icon: 'success',
            }).then((result) => {
              fetchSubjectTopic();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchSubjectList();
  }, []);

  const fetchSubjectList = async (id) => {
    const result = await axios.get(`${site_ip}/getSubjectsList`);
    setSubjectList(result.data.Student);
  };

  const fetchSubjectTopic = async () => {
    if (filterValue !== '') {
      const result = await axios.get(
        `${site_ip}/getSubjectTopics/${filterValue}`
      );
      setTopicList(result.data.Student);
    } else {
      setTopicList([]);
    }
  };

  const editNow = (item) => {
    const newValues = {
      topic_id: item.topic_id,
      topic_name: item.topic_name,
      topic_desc: item.topic_desc,
      subject_id: item.subject_id,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Manage Topics</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => handleShow()}
            aria-controls="example-collapse-text"
            aria-expanded={add}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              as="select"
              className="mb-3"
              name="filter"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
            >
              <option value="">Select Subject</option>
              {subjectList.map((value) => (
                <option key={value.subject_id} value={value.subject_id}>
                  {value.subject_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button variant="primary" onClick={fetchSubjectTopic}>
            Search
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Subject</th>
                </tr>
              </thead>
              <tbody>
                {topicList.map((skill, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link onClick={() => editNow(skill)} to="#">
                          <i class="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{skill.topic_name}</td>
                      <td>{skill.topic_desc}</td>
                      <td>{skill.subject_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update Topic' : 'Add Topic'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Topic Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="topic_name"
                  value={values.topic_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  required
                  name="topic_desc"
                  value={values.topic_desc}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} style={{ marginTop: '15px' }}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="subject_id"
                  value={values.subject_id}
                  onChange={(e) => {
                    handleChange(e);
                    fetchSubjectTopic(e.target.value);
                  }}
                >
                  <option value="">Select Subject</option>
                  {subjectList.map((value) => (
                    <option key={value.subject_id} value={value.subject_id}>
                      {value.subject_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ManageSubjectTopics;
