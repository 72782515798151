import React from 'react';
import { Row, Col, Container, Dropdown } from 'react-bootstrap';
import './topbar.css';
const TopBar = () => {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  return (
    <div className="fix-header sticky">
      {/* <Container> */}
        <Row style={{ margin: "0px" }}>
          <Col md={3} style={{ padding: 0 }}>
            <img src="/home/logo.png" className="header-logo-img" />
          </Col>
          <Col md={9}>
            <div className="navigation">
              {/* <ul> */}
                {/* <li> */}
                <div style={{display:"flex", alignItems:'center', justifyContent:'center', height:"80px"}}>
                    <span class="ms-auto">
                      {userdata.user_name}
                    </span>
                    <span userpic>
                      <Dropdown>
                        <Dropdown.Toggle className="userpic" id="dropdown-basic">
                          <i className="fas fa-user" id="dropdown-basic"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="/hrms/profile">User Profile</Dropdown.Item>
                          {/* <Dropdown.Item href="#/action-2">
                            Edit Profile
                          </Dropdown.Item> */}
                          <Dropdown.Item href="/update-password">
                            Change Password
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </div>
                {/* </li>
              </ul> */}
            </div>
          </Col>
        </Row>
      {/* </Container> */}
    </div>
  )
}

export default TopBar;
