import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { site_ip } from '../../../globalSetting';
import axios from 'axios';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ErrorModal from '../../common/ErrorModal';
import SuccessModal from '../../common/SuccessModal';
import { useNavigate, useLocation } from 'react-router-dom';

export default function AddFeedbackQuestions() {
  const navigate = useNavigate();
  const location = useLocation();

  const userdata = JSON.parse(localStorage.getItem('userdata'))?.Content[0];
  const initialInputs = {
    company_id: userdata.company_id === null ? 1 : userdata.company_id,
    job_id: '',
    question_type: '',
    question_options: [],
    minimum_value: '',
    maximum_value: '',
  };

  const [question, setQuestion] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [input, setInputs] = useState(initialInputs);
  
  const [job, setJob] = useState([]);
  
  const fetchJobData = () => {
    axios.get(`${site_ip}/getJobMaster`).then((response) => {
      setJob(response.data.Content);
    });
  };
  
  useEffect(() => {
    fetchJobData();
    if(location.state !== null){
      let current_data = {...location.state};
      setQuestion(location.state.question_desc)
      if (location.state.question_type === 'multiple-choice') {
        let answers = JSON.parse(location.state.question_options)
        setOption1(answers[0])
        setOption2(answers[1])
        setOption3(answers[2])
        setOption4(answers[3])
      } else if (location.state.question_type === 'rating') {
        let answers = JSON.parse(location.state.question_options)
        current_data = {...current_data, minimum_value: answers[0], maximum_value: answers[1]}
      } else {
        // answer = JSON.stringify([]);
      }
      setInputs(current_data)
    }

  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    
    if (input.company_id === '') {
      errorMessage('Please select Company');
    } else if (input.question_type === '') {
      errorMessage('Please select Question Type.');
    } else if (input.job_id === '') {
      errorMessage('Please select Job ');
    } else {
      let answer;
      if (input.question_type === 'multiple-choice') {
        answer = JSON.stringify([
          option1,
          option2,
          option3,
          option4
        ]);
      } else if (input.question_type === 'rating') {
        answer = JSON.stringify([input.minimum_value, input.maximum_value]);
      } else {
        answer = JSON.stringify([]);
      }
      if(input.question_id === undefined){
        axios({
          method: 'post',
          url: site_ip + '/addIVFeedbackQuestions',
          data: {
            company_id: input.company_id,
            job_id: input.job_id,
            question_type: input.question_type,
            question_desc: question,
            question_options: answer,
          },
          dataType: 'json',
        })
          .then(function (response) {
            setSuccessMessage("New Feedback question added successfully.")
          })
          .catch(function (error) {
            console.log('error' + error);
          });
      }else{
        axios({
          method: 'post',
          url: site_ip + '/setIVFeedbackQuestions',
          data: {
            question_id: input.question_id,
            company_id: input.company_id,
            job_id: input.job_id,
            question_type: input.question_type,
            question_desc: question,
            question_options: answer,
          },
          dataType: 'json',
        })
          .then(function (response) {
            setSuccessMessage("Question details updated successfully.")
          })
          .catch(function (error) {
            console.log('error' + error);
          });
      }
      
    }
  };


  return (
    <Container fluid>
      <Row className="admin-form-row">
        <div className="header">
          <h4>Add Feedback Question</h4>
        </div>
        <div className="p-3 border bg-white">
          <Row>
              <Col md={4} className="mb-4">
                <Form.Group>
                  <Form.Label>Job</Form.Label>
                  <Form.Control
                    name="job_id"
                    value={input.job_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Job</option>
                    {job?.map((value, index) => (
                      <option key={index} value={value.job_id}>{value.job_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group>
                  <Form.Label>Question Type</Form.Label>
                  <Form.Control
                    name="question_type"
                    value={input.question_type}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    as="select"
                  >
                    <option value="">Select Question Type</option>
                    <option value="descriptive">Descriptive</option>
                    <option value="multiple-choice">Multiple Choice</option>
                    <option value="rating">Rating</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Question</Form.Label>
                  <CKEditor
                    editor={Editor}
                    data={question}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setQuestion(data)
                    }}
                  />
                </Form.Group>
              </Col>
              {input.question_type === 'rating' ? (
                <Row>
                  <Col md={4} className="mb-4">
                    <Form.Group>
                      <Form.Label>Minimum Value</Form.Label>
                      <Form.Control
                        name="minimum_value"
                        value={input.minimum_value}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-4">
                    <Form.Group>
                      <Form.Label>Maximum Value</Form.Label>
                      <Form.Control
                        name="maximum_value"
                        value={input.maximum_value}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
              {input.question_type === 'multiple-choice' ? (
                <Row>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Option 1</Form.Label>
                      <CKEditor
                        editor={Editor}
                        data={option1}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setOption1(data)
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <Form.Label>Option 3</Form.Label>
                      <CKEditor
                        editor={Editor}
                        data={option3}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setOption3(data)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Option 2</Form.Label>
                      <CKEditor
                        editor={Editor}
                        data={option2}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setOption2(data)
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <Form.Label>Option 4</Form.Label>
                      <CKEditor
                        editor={Editor}
                        data={option4}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setOption4(data)
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
              <Col md={6}>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Col>
          </Row>
        </div>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
      <SuccessModal successMessage={successMessage} setSuccessMessage={() => navigate('/recruiter/feedback-questions')} />
    </Container>
  );
}
