import React from "react";
import InstructionPage from "../InstructionPage";

function Instructions() {
  return (
    <div>
      <InstructionPage />
    </div>
  );
}

export default Instructions;
