// import React, { useState, useEffect } from 'react';
// import { Row, Col, Form, Button, Container } from 'react-bootstrap';
// import { site_ip } from '../../globalSettings';
// // import { Link } from 'react-router-dom';
// import axios from 'axios';
// import ReactStars from 'react-stars';
// import { useNavigate } from 'react-router-dom';

// const ListUserFeedback = () => {
//   const testdata = JSON.parse(localStorage.getItem('TestDetails'));
//   const candidate_id = JSON.parse(localStorage.getItem('candidate_id'));
//   const navigate = useNavigate();
//   const company_id = testdata.company_id;
//   const job_id = testdata.job_id;
//   // const [open, setOpen] = useState(true);
//   const [questionlist, setQuestionList] = useState([]);
//   const [answers, setAnswers] = useState([]);
//   const [toggle, setToggle] = useState(false);
//   const fetchQuestions = async () => {
//     await axios({
//       method: 'get',
//       url: site_ip + '/getUserFeedbackQuestions/' + company_id,

//       dataType: 'json',
//     })
//       .then(function (response) {
//         let initial_answers = [];
//         for (let i = 0; i < response.data.Data.length; i++) {
//           let answer = {
//             question_id: response.data.Data[i].question_id,
//             question_desc: response.data.Data[i].question_desc,
//             question_type: JSON.parse(
//               response.data.Data[i].question_options
//             )[0],
//             ans: '',
//           };
//           initial_answers.push(answer);
//         }
//         setAnswers(initial_answers);
//         setQuestionList(response.data.Data);
//       })
//       .catch(function (error) {
//         console.log('error' + error);
//       });
//   };

//   useEffect(() => {
//     fetchQuestions();
//     //eslint-disable-next-line
//   }, []);

//   const renderQuestion = (text) => {
//     return '';
//   };

//   const handleChangeAnswer = (e, index) => {
//     // console.log(e);
//     // console.log(index);
//     let custans = answers;
//     custans[index]['ans'] = e;
//     setAnswers(custans);
//     setToggle(!toggle);
//     // const { name, value } = e.target;
//     // setAnswer((prevState) => ({
//     //   ...prevState,
//     //   [name]: value,
//     // }));
//   };
//   // console.log(answers);
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await axios({
//       method: 'post',
//       url: site_ip + '/addCandidateFeedback',
//       data: {
//         site_user_id: candidate_id,
//         feedback_date: new Date(),
//         question_n_responses: answers,
//         comments: null,
//         company_id: company_id,
//         job_id: job_id,
//       },
//     })
//       .then((response) => {
//         console.log(response);
//         if (response.data.Status === 200) {
//           navigate('/candidate/login');
//         } else {
//           alert('Request Failed');
//         }
//       })
//       .catch((error) => console.log(error));
//     // console.log(answers);
//   };
//   return (
//     <div className="feedbackPage">
//       <Container>
//         <br />
//         <Row className="align-items-center">
//           <Col md={3}></Col>
//           <Col md={6}>
//             <h6>Please answer these questions</h6>
//           </Col>
//           <Col md={3}></Col>
//         </Row>
//         <Row>
//           <Col md={3}></Col>
//           <Col md={6}>
//             <div>
//               {questionlist?.map((item, index) => {
//                 return (
//                   <div key={index} class="singleQuestion">
//                     <div className="questionNumber">
//                       <p>Question No.{index + 1}</p>
//                     </div>
//                     <div className="questionPart">
//                       {renderQuestion(item.question_desc)}
//                     </div>
//                     <div className="answerSection">
//                       {JSON.parse(item.question_options)[0] ===
//                       'descriptive' ? (
//                         <Form.Group>
//                           <Form.Control
//                             as="textarea"
//                             name="descriptive"
//                             placeholder="Add your feedback here..."
//                             value={answers[index].ans}
//                             onChange={(e) =>
//                               handleChangeAnswer(e.target.value, index)
//                             }
//                             style={{ height: '100px' }}
//                           />
//                         </Form.Group>
//                       ) : null}
//                       {JSON.parse(item.question_options)[0] ===
//                       'multiple-choice' ? (
//                         <Form.Group>
//                           <RenderOptions
//                             options={item.question_options}
//                             onChange={(e) => handleChangeAnswer(e, index)}
//                             value={answers[index].ans}
//                           />
//                         </Form.Group>
//                       ) : null}

//                       {JSON.parse(item.question_options)[0] === 'rating' ? (
//                         <Form.Group>
//                           <ReactStars
//                             count={parseInt(
//                               JSON.parse(item.question_options)[2]
//                             )}
//                             onChange={(e) => handleChangeAnswer(e, index)}
//                             size={30}
//                             value={answers[index].ans}
//                             color2={'#ffd700'}
//                           />
//                         </Form.Group>
//                       ) : null}
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={3}></Col>
//           <Col md={3}>
//             <Button onClick={handleSubmit}>Submit</Button>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default ListUserFeedback;

// const RenderOptions = (props) => {
//   const renderOption = (text) => {
//     return (
//       <div/>
//     );
//   };

//   let options = JSON.parse(props.options);
//   return (
//     <div>
//       <Row>
//         <Col md={12} className="mb-4">
//           <div class="p-2 w-100 h-100 answers">
//             <Form.Check
//               name="radio"
//               type="radio"
//               id={1}
//               value={1}
//               label={renderOption(options[1])}
//               checked={props.value === 1}
//               onChange={(e) => {
//                 props.onChange(1);
//               }}
//             />
//           </div>
//         </Col>
//         <Col md={12} className="mb-4">
//           <div class="p-2 w-100 h-100 answers ">
//             <Form.Check
//               name="radio"
//               type="radio"
//               id={2}
//               value={2}
//               label={renderOption(options[2])}
//               checked={props.value === 2}
//               onChange={(e) => {
//                 props.onChange(2);
//               }}
//             />
//           </div>
//         </Col>
//         <Col md={12} className="mb-4">
//           <div class="p-2 w-100 h-100 answers">
//             <Form.Check
//               name="radio"
//               type="radio"
//               id={3}
//               value={3}
//               label={renderOption(options[3])}
//               checked={props.value === 3}
//               onChange={(e) => {
//                 props.onChange(3);
//               }}
//             />
//           </div>
//         </Col>
//         <Col md={12} className="mb-4">
//           <div class="p-2 w-100 h-100 answers">
//             <Form.Check
//               name="radio"
//               type="radio"
//               id={4}
//               value={4}
//               label={renderOption(options[4])}
//               checked={props.value === 4}
//               onChange={(e) => {
//                 props.onChange(4);
//               }}
//             />
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// };

import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { site_ip } from '../../globalSettings';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactStars from 'react-stars';


const ListUserFeedback = () => {
  const testdata = JSON.parse(localStorage.getItem('TestDetails'));
  const candidate_id = JSON.parse(localStorage.getItem('candidate_id'));
  const navigate = useNavigate();
  const company_id = testdata.company_id;
  const job_id = testdata.job_id;

  const [feedbackComment, setFeedbackComment] = useState('');
  const [toggle, setToggle] = useState(false);
  const [rating, setRating] = useState(0); // New state for rating


  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios({
      method: 'post',
      url: site_ip + '/addCandidateFeedback',
      data: {
        site_user_id: candidate_id,
        feedback_date: new Date(),
        feedback_comment: feedbackComment,
        company_id: company_id,
        job_id: job_id,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.Status === 200) {
          navigate('/');
        } else {
          alert('Request Failed');
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="feedbackPage">
      <Container>
        <br />
        <Row className="align-items-center">
          <Col md={3}></Col>
          <Col md={6}>
            <h6>Please provide test feedback</h6>
          </Col>
          <Col md={3}></Col>
        </Row>
        <Row>
          <Col md={3}></Col>
          <Col md={6}>
            <div>
              <Form.Group>
                <Form.Label>test Feedback</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={feedbackComment}
                  onChange={(e) => setFeedbackComment(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Rating</Form.Label>
                <ReactStars
                  count={5}
                  onChange={(newRating) => setRating(newRating)}
                  size={30}
                  value={rating}
                  color2={'#ffd700'}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row className='p-2'>
          <Col md={3}></Col>
          <Col md={3}>
            <Button onClick={handleSubmit}>Submit</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListUserFeedback;

