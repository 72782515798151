import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../customTableStyle';
import ErrorModal from '../common/ErrorModal';
import SuccessModal from '../common/SuccessModal';
import moment from 'moment';
function ScheduleInterview(props) {
  const userdata = JSON.parse(localStorage.getItem('userdata'))?.Content[0];

  const initialValues = {
    schedule_id: '',
    job_id: '',
    company_id: userdata.company_id === null ? 1 : userdata.company_id,
    candidate_id: '',
    interviewer_id: '',
    iv_status: '',
    iv_channel: '',
    date: '',
    time: '',
    updating: false,
  };

  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [interviewSchedule, setInterviewSchedule] = useState([]);
  const [interviewer, setInterviewer] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [add, setAdd] = useState(false);

  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    fetchInterviewSchedule(values.company_id);
    fetchJobList();
    //eslint-disable-next-line
  }, []);

  const fetchJobList = async () => {
    const result = await axios.get(`${site_ip}/getJobMaster`);
    setJobs(result.data.Content);
  };

  const fetchInterviewSchedule = async (company_id) => {
    const result = await axios.get(`${site_ip}/getIVSchedule/${company_id}`);
    // console.log(result);
    setInterviewSchedule(result.data.Content);
  };
  const handleCompany = async (company_id, job_id) => {
    const result = await axios.post(`${site_ip}/getInterviewerByJobs`, {company_id: company_id, job_id: job_id});
    setInterviewer(result.data.Content);
    const result2 = await axios.post(
      `${site_ip}/getCandidatesByJob`,
      {company_id: company_id, job_id: job_id}
    );
    setCandidateList(result2.data.Content);
  };

  const editNow = (item) => {
    handleCompany(item.company_id, item.job_id)
    const newValues = {
      schedule_id: item.id,
      job_id: item.job_id,
      company_id: item.company_id,
      candidate_id: item.candidate_id,
      interviewer_id: item.interviewer_id,
      iv_channel: item.iv_channel,
      iv_status: item.iv_status,
      date: item.iv_datetime.substring(0, 10),
      time: item.iv_datetime.substring(11, 19),
      // iv_datetime: item.iv_datetime,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const proceed = () => {
    if (values.job_id === '') {
      setErrorMessage('Please select Job');
    } else if (values.candidate_id === '') {
      setErrorMessage('Please select Candidate');
    } else if (values.interviewer_id === '') {
      setErrorMessage('Please select Interviewer');
    } else if (values.date === '' || values.time === "") {
      setErrorMessage('Please enter date & time');
    } else if (values.iv_channel === '') {
      setErrorMessage('Please select interview channel.');
    } else if (values.iv_status === '') {
      setErrorMessage('Please select interview status.');
    } else if (values.schedule_id === '') {
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/addIVSchedule',
        data: {
          job_id: values.job_id,
          company_id: values.company_id,
          candidate_id: values.candidate_id,
          interviewer_id: values.interviewer_id,
          iv_channel: values.iv_channel,
          iv_status: values.iv_status,
          iv_datetime: values.date + ' ' + values.time,
        },
        dataType: 'json',
      })
        .then(function (response) {
          setSuccessMessage('New Schedule added successfully');
          fetchInterviewSchedule(values.company_id);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/setIVSchedule',
        data: {
          id: values.schedule_id,
          job_id: values.job_id,
          company_id: values.company_id,
          candidate_id: values.candidate_id,
          interviewer_id: values.interviewer_id,
          iv_channel: values.iv_channel,
          iv_status: values.iv_status,
          iv_datetime: values.date + ' ' + values.time,
        },
        dataType: 'json',
      })
        .then(function (response) {
          setSuccessMessage('Schedule details successfully updated!');
          fetchInterviewSchedule(values.company_id);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "job_id"){
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
        candidate_id:"",
        interviewer_id:""
      }));
      handleCompany(values.company_id, value)
    }else{  
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    
  };

  const dateAndTimeFormat = (date_string) =>{
    let formatted = "";
    formatted = moment(new Date(date_string.substring(0, 10))).format("Do MMM YYYY");
    formatted += " "+moment(date_string.substring(11, 19), "HH:mm:ss").format("hh:mm A")
    return formatted;
  }

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Candidate Name',
      selector: (row) => row?.candidate_name,
    },
    {
      name: 'Interviewer Name',
      selector: (row) => row?.interviewer_name,
    },
    {
      name: 'For Job',
      selector: (row) => row?.job_name,
    },
    {
      name: 'Interview Mode',
      selector: (row) => row?.iv_channel,
    },
    {
      name: 'Date',
      selector: (row) => dateAndTimeFormat(row?.iv_datetime),
    },
    {
      name: 'Status',
      selector: (row) => row?.iv_status,
    },
    {
      name: 'Action',
      width: "150px",
      cell: (row, index) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => editNow(row)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i> Schedule Interview</p>
        </Col>
        <Col md={6} className="text-end">
          <Button
            className='admin-action-btns'
            onClick={() => handleShow()}
            style={{ marginRight: '10px' }}
          >
            <i class="fas fa-plus icon"></i> Add
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Control
              as="select"
              className="mb-3"
              name="filter"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
            >
              <option value="">Select Company</option>
              {companyList.map((value) => (
                <option key={value.company_id} value={value.company_id}>
                  {value.company_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button variant="primary" onClick={fetchInterviewSchedule}>
            Search
          </Button>
        </Col>
      </Row> */}
      <Row>
        <Col xl={12} className="mt-3">
          <div className="border bg-white">
            <DataTable columns={tableHeader} data={interviewSchedule} pagination customStyles={CustomTableStyle} />
          </div>
        </Col>
        <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
        <SuccessModal successMessage={successMessage} setSuccessMessage={() => setSuccessMessage(null)} />
      </Row>
      <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating
              ? 'Update Interview Schedule'
              : 'Add Interview Schedule'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="admin-form-row">
            {/* <Col md={12}>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="company_id"
                  value={values.company_id}
                  onChange={(e) => {
                    handleChange(e);
                    handleCompany(e.target.value);
                  }}
                >
                  <option value="">Select Company</option>
                  {companyList.map((value) => (
                    <option key={value.company_id} value={value.company_id}>
                      {value.company_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col> */}
            <Col md={12}>
              <Form.Group>
                <Form.Label>Job <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="job_id"
                  value={values.job_id}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Select Job</option>
                  {jobs.map((value) => (
                    <option key={value.job_id} value={value.job_id}>
                      {value.job_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Candidate <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="candidate_id"
                  value={values.candidate_id}
                  onChange={handleChange}
                >
                  <option value="">Select Candidate</option>
                  {candidateList.map((value) => (
                    <option key={value.candidate_id} value={value.candidate_id}>
                      {value.candidate_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Interviewer <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="interviewer_id"
                  value={values.interviewer_id}
                  onChange={handleChange}
                >
                  <option value="">Select Interviewer</option>
                  {interviewer.map((value) => (
                    <option
                      key={value.interviewer_id}
                      value={value.interviewer_id}
                    >
                      {value.interviewer_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Date <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  type="date"
                  className="mb-3"
                  name="date"
                  min={moment(new Date()).format("YYYY-MM-DD")}
                  value={values.date}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Time <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  type="time"
                  className="mb-3"
                  name="time"
                  value={values.time}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Interview Channel <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  required
                  className="mb-3"
                  name="iv_channel"
                  value={values.iv_channel}
                  onChange={handleChange}
                >
                  <option value="">Select Channel</option>
                  <option value="GMeet">Google Meet</option>
                  <option value="MSTeam">MS Teams</option>
                  <option value="Zoom">ZOOM</option>
                  <option value="Daily">Daily</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Interview Status <span style={{color:"#ff0000"}}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  required
                  className="mb-3"
                  name="iv_status"
                  value={values.iv_status}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ScheduleInterview;
