import React, {useState} from 'react';

// import './sidebar1.scss';
import './App.css';
import {useNavigate, Link} from 'react-router-dom';

function LeftSideBar() {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);
  const [sidebarActive, setSidebarActive] = useState(0);
  const openMenuMenu = (number) =>{
    setActiveMenu(number)
    if(number === 1){
      setSidebarActive(0);
      navigate("/recruiter/dashboard");
    } else if(number === 2){
      setSidebarActive(2);
    } else if(number === 3){
      setSidebarActive(3);
    }else if(number === 4){
      setSidebarActive(4);
    }else if(number === 5){
      setSidebarActive(5);
    }else if(number === 6){
      setSidebarActive(6);
    }else if(number === 7){
      setSidebarActive(7);
    }else if(number === 8){
      setSidebarActive(8);
    }else if(number === 10){
      setSidebarActive(10);
    }else if(number === 11){
      setSidebarActive(0);
      navigate("/recruiter/resumes/assigned-resume-list")
    }
  }    

  const logOut = () => {
    localStorage.removeItem('loginid');
    localStorage.removeItem('userdata');
    window.location.href = '/recruiter';
  };

  return (
    <div className="new-sidebar-panel bg-dark-blue">
      <div className="main-sidebar bg-dark-blue">
      <button className={activeMenu === 1 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(1)}>
          <i class="menu-icon fas fa-home"></i>
          <p>Home</p>
        </button>
        <button className={activeMenu === 2 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(2)}>
          <i class="menu-icon fas fa-check-circle"></i>
          <p>Leave Management</p>
        </button>
        <button className={activeMenu === 3 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(3)}>
          <i class="menu-icon fas fa-users"></i>
          <p>Attendence</p>
        </button>
        <button className={activeMenu === 4 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(4)}>
          <i class="menu-icon fas fa-chart-line"></i>
          <p>Education</p>
        </button>
        <button className={activeMenu === 5 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(5)}>
          <i class="menu-icon far fa-list-alt"></i>
          <p>Experience</p>
        </button>
        <button className={activeMenu === 6 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => openMenuMenu(6)}>
          <i class="menu-icon fas fa-clock"></i>
          <p>Compensation</p>
        </button>  
        <button className={activeMenu === 9 ? "main-sidebar-btn active" : "main-sidebar-btn"} onClick={() => logOut()}>
          <i class="menu-icon fas fa-sign-out-alt"></i>
          <p>Logout</p>
        </button>
      </div>
      <div className={sidebarActive === 0 ? "submenu-sidebar" : "submenu-sidebar active"}>
        <button className="sidebar-close-btn" onClick={() => setSidebarActive(0)}><i class="fas fa-times"></i></button>
        <div style={{height:"20px"}}></div>
        {activeMenu === 2 ?
          <>
            <Link to="/recruiter/candidate/add">Add</Link>
            <Link to="/recruiter/candidate/list">List</Link>
          </> : null}
          {activeMenu === 3 ?
          <>
            <Link to="/recruiter/vendors/add-new-vendor">Add</Link>
            <Link to="/recruiter/vendors">List</Link>
          </> : null}
          {activeMenu === 10 ?
          <>
            <Link to="/recruiter/customers/add-new-customer">Add</Link>
            <Link to="/recruiter/customers">List</Link>
            <Link to="/recruiter/customers/assign-resumes">Assign Resumes</Link>
          </> : null}
          {activeMenu === 4 ?
          <>
            <Link to="/recruiter/jobs/add-job">Add</Link>
            <Link to="/recruiter/jobs/list">List</Link>
          </> : null}
          {activeMenu === 5 ?
          <>
            <Link to="/recruiter/job-description/add-jd">Add</Link>
            <Link to="/recruiter/job-description/list">List</Link>
          </> : null}
          {activeMenu === 6 ?
          <>
            <Link to="/recruiter/resumes/add-resume">Add New Resume</Link>
            <Link to="/recruiter/resumes/list">Resume List</Link>
            <Link to="/recruiter/resumes/bulk-resumes">Upload Resumes in Bulk</Link>
          </> : null}
          {activeMenu === 7 ?
          <>
            <Link to="/recruiter/manage-interviewer"> Interviewers</Link>
            <Link to="/recruiter/manage-interviewer-list">Interview Panel</Link>
            <Link to="/recruiter/feedback-questions">
              Interview Feedback Questions
            </Link>
            <Link to="/recruiter/schedule-interview">Schedule Interview</Link>
            <Link to="/recruiter/request-interview-feedback">
              Request Interview Feedback
            </Link>
          </> : null}
          {activeMenu === 8 ?
          <>
            <Link to="/recruiter/referrals/add-new-referral">Refer a Candidate</Link>
            <Link to="/recruiter/referrals">All Referrals</Link>
          </> : null}
      </div>
    </div>
  );
}

export default LeftSideBar;
