// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CircleLoader.css */
.circle-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #007BFF; /* Change the color here or use a variable */
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CircleLoader.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;IACpC,6BAA6B,EAAE,4CAA4C;IAC3E,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC","sourcesContent":["/* CircleLoader.css */\r\n.circle-loader {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100px;\r\n  }\r\n  \r\n  .circle {\r\n    width: 40px;\r\n    height: 40px;\r\n    border-radius: 50%;\r\n    border: 4px solid rgba(0, 0, 0, 0.1);\r\n    border-top: 4px solid #007BFF; /* Change the color here or use a variable */\r\n    animation: spin 1s linear infinite;\r\n  }\r\n  \r\n  @keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
