import React, { useState, useEffect } from 'react';
// import { NavLink, Redirect } from 'react-router-dom';
// import '../../assets/scss/style.scss';
import Aux from '../hoc/_Aux';
// import Breadcrumb from '../layout/AdminLayout/Breadcrumb';
import { site_ip } from '../globalSetting';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import './Learner.css';
import { Col, Row, Image, Button, Form } from 'react-bootstrap';

function Signup() {
  const initialValues = {
    user_id: '',
    // salutation: '',
    first_name: '',
    // middle_name: '',
    last_name: '',
    user_email: '',
    user_mobile: '',
    state_id: '',
    country_id: '',
    zipcode: '',
    address: '',
    gender: '',
    password: '',
    industry_id: '',
    job_id: '',
  };
  const [values, setValues] = useState(initialValues);
  const [job, setJob] = useState([]);
  const [country, setCountry] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [state, setState] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'country_id') {
      fetchStateData(value);
    }
    if (name === 'industry_id') {
      fetchJobData(value);
    }
  };

  const fetchCountryData = async () => {
    const result = await axios.get(`${site_ip}/getCountryList`);
    setCountry(result.data.content);
    // console.log(result);
  };
  const fetchIndustryData = async () => {
    const result = await axios.get(`${site_ip}/getIndustryList`);
    setIndustry(result.data.Content);
    // console.log(result);
  };

  const fetchJobData = async (id) => {
    const result = await axios.get(`${site_ip}/getJobListByIndustryId/${id}`);
    setJob(result.data.Content);
    // console.log(result);
  };

  const fetchStateData = async (countryid) => {
    const result = await axios.get(`${site_ip}/getState/${countryid}`);
    setState(result.data.Data);
    // console.log(result);
  };

  useEffect(() => {
    // fetchJobData();
    fetchCountryData();
    fetchIndustryData();
  }, []);

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (pass) => {
    if (pass.trim().length < 8) {
      return false;
    }
    return true;
  };

  const proceed = () => {
    if (
      // values.salutation === '' ||
      values.first_name === '' ||
      // values.middle_name === '' ||
      values.last_name === '' ||
      values.address === '' ||
      values.gender === '' ||
      values.user_email.trim() === '' ||
      values.country_id === '' ||
      values.state_id === '' ||
      values.zipcode === '' ||
      values.password === '' ||
      values.industry_id === ''
      // values.job_id === ''
    ) {
      alert('Please fill all fields');
    } else if (!validateEmail(values.user_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.user_mobile)) {
      alert('Please enter valid mobile number');
    } else if (values.user_id === '') {
      if (!validatePassword(values.password)) {
        alert('Password must be minimum 8 character long');
        return;
      }
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/auth/learnerSignup',
        data: {
          // salutation: values.salutation,
          first_name: values.first_name,
          // middle_name: values.middle_name,
          last_name: values.last_name,
          user_email: values.user_email,
          user_mobile: values.user_mobile,
          state_id: values.state_id,
          country_id: values.country_id,
          zipcode: values.zipcode,
          industry_id: values.industry_id,
          job_id: values.job_id,
          address: values.address,
          gender: values.gender,
          password: values.password,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'You have successfully signed up. Continue for Login.',
              icon: 'success',
            }).then((result) => {
              redirectUser();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const redirectUser = () => {
    setTimeout(() => {
      window.location.href = '/learner';
    }, 1000);
  };

  return (
    <Aux>
      <div>
        <Row style={{ margin: '0px' }} className="bg_learner">
          <Col md={6}>{/* <div className="bg"></div> */}</Col>
          {/* <Col md={6} >
          
            </Col> */}
          <Col md={6} className="align-items-center justify-content-center flex pos-relative" style={{ flexDirection: "column" }}>
            <p className="text-center text-white heading-1">
              LEARNER'S SIGNUP
            </p>
            <div className="login-box-div" style={{width:"100%"}}>
              {/* <Form> */}
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>First Name <span className="required">*</span></p>
                    <Form.Control
                      type="text"
                      required
                      className="mb-3"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Last Name <span className="required">*</span></p>
                    <Form.Control
                      type="text"
                      required
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Address <span className="required">*</span></p>
                    <Form.Control
                      type="text"
                      required
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Email <span className="required">*</span></p>
                    <Form.Control
                      type="text"
                      required
                      name="user_email"
                      value={values.user_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Mobile <span className="required">*</span></p>
                    <Form.Control
                      type="text"
                      required
                      className="mb-3"
                      name="user_mobile"
                      value={values.user_mobile}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Industry <span className="required">*</span></p>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="industry_id"
                      value={values.industry_id}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      {industry?.map((value) => (
                        <option
                          key={value.industry_id}
                          value={value.industry_id}
                        >
                          {value.industry_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Job <span className="required">*</span></p>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="job_id"
                      value={values.job_id}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      {job?.map((value) => (
                        <option key={value.job_id} value={value.job_id}>
                          {value.job_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Country <span className="required">*</span></p>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="country_id"
                      value={values.country_id}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      {country?.map((value) => (
                        <option key={value.id} value={value.id}>
                          {value.country_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>State <span className="required">*</span></p>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="state_id"
                      value={values.state_id}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      {state.map((value) => (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Gender <span className="required">*</span></p>
                    <Form.Control
                      as="select"
                      required
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                    >
                      <option>--Select--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Zip Code <span className="required">*</span></p>
                    <Form.Control
                      type="text"
                      required
                      name="zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <p className='registerLabel'>Password <span className="required">*</span></p>
                    <Form.Control
                      type="text"
                      required
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="align-items-center justify-content-center flex" style={{marginTop:"15px"}}>
                <button className='primary-btn' onClick={() => proceed()}>SUBMIT</button>
              </div>
              <div className="align-items-center justify-content-center flex" style={{ flexDirection: "column", marginTop: "15px", fontSize: "13px" }}>
                Already have an account?<br />
                <Link to="/learner" style={{ fontSize: "15px", fontWeight: "bold" }}>Login</Link>
              </div>
              {/* </Form> */}
            </div>
          </Col>
          {/* <Col md={6} className=" login-box">
            <div className=" border bg-white card auth-content">
              <div className="card-header ">
                <Image src="../images/logo.png" className="top-logo" />
              </div>

              <Form className=" login-card">
                <br />
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        className="mb-3"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="user_email"
                        value={values.user_email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        className="mb-3"
                        name="user_mobile"
                        value={values.user_mobile}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="industry_id"
                        value={values.industry_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Industry</option>
                        {industry?.map((value) => (
                          <option
                            key={value.industry_id}
                            value={value.industry_id}
                          >
                            {value.industry_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Job</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="job_id"
                        value={values.job_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Job</option>
                        {job?.map((value) => (
                          <option key={value.job_id} value={value.job_id}>
                            {value.job_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="country_id"
                        value={values.country_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        {country?.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.country_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="state_id"
                        value={values.state_id}
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>
                        {state.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                      >
                        <option>Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="zipcode"
                        value={values.zipcode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <div style={{ textAlign: 'center' }}>
                  <Button variant="primary" type="button" onClick={proceed}>
                    Submit
                  </Button>
                  <br />
                  <br />
                  Already have an account? <Link to="/learner">Login</Link>
                </div>
                <br />
              </Form>
            </div>
          </Col> */}
        </Row>
      </div>
    </Aux>
  );
}

export default Signup;
