import React, { useState, useEffect } from 'react';
import { site_ip } from '../../../globalSettings';
// import { Grid, Button } from '@material-ui/core';

import { Grid, Button } from '@mui/material';
import FormDialog from './skillDialog';
const initialValue = {
  skill_id: '',
  skill_name: '',
  industry_id: '0',
  job_id: '0',
};
function SKills() {
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState(initialValue);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initialValue);
  };

  //update data
  const url = site_ip + `/getSkills`;
  const columnDefs = [
    { headerName: 'Skill Id', field: 'skill_id' },
    { headerName: 'Skill Name', field: 'skill_name' },
    { headerName: 'Industry', field: 'industry_name' },
    { headerName: 'Job', field: 'job_name' },
    {
      headerName: 'Actions',
      field: 'skill_id',
      cellRendererFramework: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleUpdate(params.data)}
          >
            Update
          </Button>
          {/* <Button variant="outlined" color="secondary" onClick={() => handleDelete(params.value)}>Delete</Button> */}
        </div>
      ),
    },
  ];
  // calling getUsers function for first time
  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, []);

  //fetching user data from server
  const getUsers = () => {
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => setTableData(resp.Content));
  };
  const onChange = (e, tagid) => {
    if (tagid === undefined) {
      const { value, id } = e.target;
      setFormData({ ...formData, [id]: value });
    } else {
      const { value } = e.target;
      setFormData({ ...formData, [tagid]: value });
    }
  };
  const onGridReady = (params) => {
    setGridApi(params);
    console.log(gridApi);
  };

  // setting update row data to form data and opening pop up window
  const handleUpdate = (oldData) => {
    setFormData(oldData);
    //  console.log(oldData)
    handleClickOpen();
  };
  //deleting a user
  // const handleDelete = (Skill_id) => {
  //   const confirm = window.confirm(
  //     'Are you sure, you want to delete this row',
  //     Skill_id
  //   );
  //   if (confirm) {
  //     fetch(site_ip + `"/getSkills"/${Skill_id}`, { method: 'DELETE' })
  //       .then((resp) => resp.json())
  //       .then((resp) => getUsers());
  //   }
  // };
  const handleFormSubmit = () => {
    if (formData.skill_id !== '') {
      if (
        formData.skill_name === '' ||
        formData.industry_id === '0' ||
        formData.job_id === '0'
      ) {
        alert('Please fill all field');
      } else {
        const updatedValue = {
          skill_id: formData.skill_id,
          skill_name: formData.skill_name,
          industry_id: formData.industry_id,
          job_id: formData.job_id,
        };
        //updating a user
        const confirm = window.confirm(
          'Are you sure, you want to update this row ?'
        );
        if (confirm) {
          fetch(site_ip + '/setSkills', {
            method: 'POST',
            body: JSON.stringify(updatedValue),
            headers: {
              'content-type': 'application/json',
            },
          })
            .then((resp) => resp.json())
            .then((resp) => {
              handleClose();
              getUsers();

              // setFormData(initialValue)
            });
        }
      }
    } else {
      if (
        formData.skill_name === '' ||
        formData.industry_id === '0' ||
        formData.job_id === '0'
      ) {
        alert('Please fill all field');
      } else {
        fetch(site_ip + '/addSkills', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'content-type': 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then((resp) => {
            handleClose();
            getUsers();
          });
      }
    }
  };

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  return (
    <div className="App">
      <h1>Manage Skills</h1>
      <Grid align="right">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add Skills
        </Button>
      </Grid>
      <div className="ag-theme-alpine" style={{ height: '400px' }}>
        
      </div>
      <FormDialog
        open={open}
        handleClose={handleClose}
        data={formData}
        onChange={onChange}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default SKills;
