import React, { useEffect, useState } from 'react';

import MainHeader from './components/header';
// import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar1 from './components/afterLogin/common/TopBar1';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './components/pages/afterlogin.css';
// import Home from "./components/pages/Home";
import LoginPage from './components/pages/LoginPage';
import LeftSideBar from './components/LeftSideBar';
import Dashboard from './components/afterLogin/Dashboard';
// import SignupPage from "./components/pages/SignupPage";
import FreeTrail from './components/pages/FreeTrail';
import Career from './components/pages/Career';
import Contactus from './components/Contactus';
import CompanyDetails from './components/pages/CompanyDetailPage';
import Profile from './components/pages/Profile';
import ChangePassword from './components/pages/ChangePassword';
import TestListing from './components/afterLogin/manageTest/TestListing';
import TestPatternAdd from './components/afterLogin/manageTest/TestPatternAdd';
import TestPatternEdit from './components/afterLogin/manageTest/TestPatternEdit';
import TestSetupAdd from './components/afterLogin/manageTest/TestSetupAdd';
import TestSetupEdit from './components/afterLogin/manageTest/TestSetupEdit';
import TestSuccess from './components/afterLogin/manageTest/Success';
import ManageCandidate from './components/afterLogin/manageCandidate/ManageCandidate';
import CandidateAdd from './components/afterLogin/manageCandidate/CandidateAdd';
import CandidateEdit from './components/afterLogin/manageCandidate/CandidateEdit';
import AssignTest from './components/afterLogin/assignTest/AssignTest';
import Company from './components/afterLogin/company/Company';
import Skills from './components/afterLogin/company/Skills';
import Job from './components/afterLogin/company/Job';
import Questiontag from './components/afterLogin/company/Questiontag';
import Subjects from './components/afterLogin/company/Subjects';
import Topic from './components/afterLogin/company/Topic';
import CandidateLogin from './components/candidate/HomeSection';
import Instructions from './components/candidate/Pages/Instructions';
import Exam from './components/candidate/Pages/Exam';
import Thankyou from './components/candidate/Pages/Thankyou';
import EPage from './components/candidate/EPage';
import ListUserFeedback from './components/candidate/ListUserFeedback';

import ExamQuestionAdd from './components/afterLogin/exam/Questions/ExamQuestionAdd';
import ExamQuestionEdit from './components/afterLogin/exam/Questions/ExamQuestionEdit';
import ExamQuestionSearch from './components/afterLogin/exam/Questions/ExamQuestionSearch';
// import ExamQuestionUpload from './components/afterLogin/exam/Questions/ExamQuestionUpload';

//Analytics Main Login
import MainLoginList from './components/afterLogin/analytics/MainLoginList';
import CandidateResult from './components/afterLogin/analytics/CandidateResult';
import TestAnalytics from './components/afterLogin/analytics/TestAnalytics';
import TestSummary from './components/afterLogin/analytics/TestSummary';
import TestSummaryByDate from './components/afterLogin/analytics/TestSummaryByDate';
import TestSummaryByDateJob from './components/afterLogin/analytics/TestSummaryByDateJob';
import CandidatesFeedback from './components/afterLogin/reports/CandidatesFeedback';
import ViewFeedback from './components/afterLogin/reports/ViewFeedback';

// Master
import ManageIndustries from './components/afterLogin/masters/ManageIndustry';
import ManageJobs from './components/afterLogin/masters/ManageJobs';
import ManageSkills from './components/afterLogin/masters/ManageSkills';
import ViewQuestionTypes from './components/afterLogin/masters/ViewQuestionTypes';
import ViewQuestionComplexities from './components/afterLogin/masters/ViewQuestionComplexities';
import ViewTaxonomy from './components/afterLogin/masters/ViewTaxonomy';
import ManageQuestionTags from './components/afterLogin/masters/ManageQuestionTags';
import ViewCategories from './components/afterLogin/masters/ViewCategories';
import ManageSubjects from './components/afterLogin/masters/ManageSubjects';
import ManageSubjectTopics from './components/afterLogin/masters/ManageSubjectTopics';
import AuthContext from './authContext';

import MyTests from './components/afterLogin/manageTest/MyTests';
// Reports and Analytics
import CandidateList from './components/afterLogin/reports/CandidateList';
// import BlankPage from './components/afterLogin/manageCandidate/BlankPage';
// Admin Pages
// import Admin from './components/admin/index';


//Company Backend 
import AddCompanySkill from './components/backend/content/company/Add';

// Backend Pages
import Backend from './components/backend/index';

//Company Pages
import CompanyDashboard from './components/company/index';

// Learner Pages
import Learner from './components/learner/index';

// Exam Pages
import ExamRoutes from './components/exam/index'

// Recruiter Pages
import RecruiterRoutes from './components/recruiter/index'

// HRMS
// import HRMSRoutes from './components/hrms/index'

// Employee 
import EmployeeRoutes from './components/employees/index';

//Company Users
import CompanyUsers from './components/afterLogin/users/CompanyUsers';
import AddCompanyUsers from './components/afterLogin/users/AddCompanyUsers';
import EditCompanyUsers from './components/afterLogin/users/EditCompanyUsers';

// Interviwer
import InterviewerLogin from './components/interviewer/HomeSection';
import InterviewList from './components/interviewer/Pages/InterviewList';
import InterviewFeedback from './components/interviewer/Pages/InterviewFeedback';
import InterviewThankyou from './components/interviewer/ThankyouPage';

// Home
import Home from './Home';
import AboutUs from './AboutUs';
import OurTeam from './OurTeam';
import Contents from './Contents';


import NewUserRequests from './components/afterLogin/NewUserRequests';

// import WebcamCapture from './components/Webcam';
import WebCamComponent from "./components/candidate/WebCamComponent"
import CompanyRegistration from './components/pages/CompanyRegistration';
import Adminusers from './components/backend/content/users/AdminUsers';

import CreateCompanyAdmin from './components/pages/CreateCompanyAdmin';
import LoginNew from './components/LoginNew';

import CustomTest from "./components/CustomTest";

import PracticeTest from './components/learner/content/tests/PracticeTest';

import PracticeTestExamPage from './components/candidate/PracticeTestExamPage';
import AddCollege from './components/backend/content/campus/Add';
import ExamQuestionPreview from './components/afterLogin/exam/Questions/ExamQuestionPreview';
import CollegeDashboard from './components/backend/content/campus/college/Dashboard';

function App() {

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(()=>{
    let user= JSON.parse(localStorage.getItem('userdata'));
    console.log(user,'USER D')
    if(user && user?.accessToken){
        setCurrentUser(user)
    }
  },[])


  const LayoutOfAfterLogin = (children) => { 
    
    // const currentPath = window.location.pathname;
    //   console.log(currentPath,'Current Path')
    //   if(!currentUser){
    //     let loginPath ='/login'
    //         if(currentPath==='/login'){
    //             return '';
    //         } else if(currentPath==='/'){
    //           return '';
    //         }else if(currentPath==='/about-us'){
    //           return '';
    //         }
    //         else if(currentPath==='/our-team'){
    //           return '';
    //         } else if(currentPath==='/contents'){
    //           return '';
    //         }else if(currentPath==='/signup'){
    //           return '';
    //         }else if(currentPath==='/career'){
    //           return '';
    //         }else if(currentPath==='/contact-us'){
    //           return '';
    //         }else if(currentPath==='/candidate/login'){
    //           return'';
    //         }
    //          else{
    //           window.history.pushState(null, '', loginPath);
    //         }
    //         }
      
  return  <>
      <TopBar1 />
      <div style={{ display: "flex", overflow: "hidden" }}>
        <LeftSideBar />
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 90px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
    </>
};

  const LayoutOfAfterLoginProfile = (children) => (
    <>
      <TopBar1 /> {children}{' '}
    </>
  );

  const LayoutOfCandidateLogin = (children) => <>{children}</>;
  const LayoutOfLoginAndSignup = (children) => (
    <>
      <MainHeader /> {children}
    </>
  );



  // Admin Panel Layout
  const AdminLayout = (children) => <>{children}</>;

  // Backend Panel Layout
  const BackendLayout = (children) => <>{children}</>;

  // Learner Panel Layout
  const LearnerLayout = (children) => <>{children}</>;

  // Interviewer Layout
  const LayoutOfInterviewerLogin = (children) => <>{children}</>;

  // Exam Panel Layout
  const ExamLayout = (children) => <>{children}</>;

  // Recuiter Panel Layout
  const RecruiterLayout = (children) => <>{children}</>;

  // HRMS Panel Layout
  const HRMSLayout = (children) => <>{children}</>;
  return (
    <AuthContext.Provider 
    value={{
      currentUser,
      setCurrentUser
    }}
    >
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<Home />}
        />
        <Route
          exact
          path="/about-us"
          element={<AboutUs />}
        />
        <Route
          exact
          path="/our-team"
          element={<OurTeam />}
        />
        <Route
          exact
          path="/contents"
          element={<Contents />}
        />
        <Route
          exact
          path="/"
          element={LayoutOfLoginAndSignup(<Home />)}
        />{' '}
        <Route
          exact
          path="/"
          element={LayoutOfLoginAndSignup(<LoginPage />)}
        />{' '}
        <Route
          exact
          path="/login"
          // element={LayoutOfLoginAndSignup(<LoginPage />)}
          element={LayoutOfLoginAndSignup(<LoginNew/>)}
        />{' '}
        <Route
          exact
          path="/signup"
          element={LayoutOfLoginAndSignup(<FreeTrail />)}
        />{' '}
        <Route
          exact
          path="/career"
          element={LayoutOfLoginAndSignup(<Career />)}
        />{' '}
        <Route
          exact
          path="/profile"
          element={LayoutOfAfterLoginProfile(<Profile />)}
        />{' '}
        <Route
          exact
          path="/update-password"
          element={LayoutOfAfterLoginProfile(<ChangePassword />)}
        />{' '}
        <Route
          exact
          path="/company-signup"
          element={LayoutOfLoginAndSignup(<CompanyDetails />)}
        />{' '}
        <Route
          exact
          path="/contact-us"
          element={LayoutOfLoginAndSignup(<Contactus />)}
        />{' '}
        <Route
          exact
          path="/dashboard"
          element={LayoutOfAfterLogin(<Dashboard />)}
        />{' '}

        <Route
          exact
          path='/new-user-requests'
          element={LayoutOfAfterLogin(<NewUserRequests/>)}
        />

        <Route
          exact
          path='/:company_name/register/:id'
          // element={<CompanyRegistration/>}
          element={<AddCompanySkill/>}
        />

        <Route
          exact
          path='/:company_name/admin-register/:id'
          // element={<Adminusers/>}
          element={<CreateCompanyAdmin/>}
        />

        <Route
          exact
          path="/add-question"
          element={LayoutOfAfterLogin(<ExamQuestionAdd />)}
        />{' '}
        <Route
          exact
          path="/search-question"
          element={LayoutOfAfterLogin(<ExamQuestionSearch/>)}
        />{' '}
         <Route
          exact
          path="/edit-question/:id"
          element={LayoutOfAfterLogin(<ExamQuestionEdit />)}
        />{' '}
        
        {/*<Route
          exact
          path="/upload-question"
          element={LayoutOfAfterLogin(ExamQuestionUpload)}
        />{' '}
        <Route
          exact
          path="/question-upload"
          element={LayoutOfAfterLogin(QuestionUpload)}
        />{' '} */}

        <Route
          exact
          path='/custom-test'
          element={LayoutOfAfterLogin(<CustomTest/>)}
        />

        <Route
          exact
          path="/test-listing"
          element={LayoutOfAfterLogin(<TestListing />)}
        />{' '}
        <Route
          exact
          path="/my-tests"
          element={LayoutOfAfterLogin(<MyTests />)}
        />{' '}
        <Route
          exact
          path="/test-add"
          element={LayoutOfAfterLogin(<TestPatternAdd />)}
        />{' '}
        <Route
          exact
          path="/test-edit/:id"
          element={LayoutOfAfterLogin(<TestPatternEdit />)}
        />{' '}
        <Route
          exact
          path="/test-setup-add"
          element={LayoutOfAfterLogin(<TestSetupAdd />)}
        />{' '}
        <Route
          exact
          path="/test-setup-edit"
          element={LayoutOfAfterLogin(<TestSetupEdit />)}
        />{' '}
        <Route
          exact
          path="/test-success"
          element={LayoutOfAfterLogin(<TestSuccess />)}
        />{' '}
        <Route
          exact
          path="/manage-candidate"
          element={LayoutOfAfterLogin(<ManageCandidate />)}
        />{' '}
        <Route
          exact
          path="/candidate-list"
          element={LayoutOfAfterLogin(<CandidateList  />)}
        />{' '}
        <Route
          exact
          path="/add-candidate"
          element={LayoutOfAfterLogin(<CandidateAdd />)}
        />{' '}
        <Route
          exact
          path="/edit-candidate/:id"
          element={LayoutOfAfterLogin(<CandidateEdit />)}
        />{' '}
        <Route
          exact
          path="/assign-test"
          element={LayoutOfAfterLogin(<AssignTest />)}
        />{' '}
        <Route
          exact
          path="/company"
          element={LayoutOfAfterLogin(<Company />)}
        />{' '}
        <Route
          exact
          path="/skills"
          element={LayoutOfAfterLogin(<Skills />)}
        />{' '}
        <Route
          exact
          path="/Job"
          element={LayoutOfAfterLogin(<Job />)}
        />{' '}
        <Route
          exact
          path="/Questiontag"
          element={LayoutOfAfterLogin(<Questiontag />)}
        />{' '}
        <Route
          exact
          path="/Subjects"
          element={LayoutOfAfterLogin(<Subjects />)}
        />{' '}
        <Route
          exact
          path="/Topic"
          element={LayoutOfAfterLogin(<Topic />)}
        />{' '}

        
        <Route
          exact
          path="/candidate/login/:test_id"
          element={LayoutOfCandidateLogin(<CandidateLogin />)}
        />{' '}

        
        <Route
          exact
          path="/candidate/capture"
          element={LayoutOfCandidateLogin(<WebCamComponent />)}
        />{' '}


        {/* Industry */}
        <Route
          exact
          path="/manage-industries"
          element={LayoutOfAfterLogin(<ManageIndustries />)}
        />{' '}
        {/* Jobs */}
        <Route
          exact
          path="/manage-jobs"
          element={LayoutOfAfterLogin(<ManageJobs />)}
        />{' '}
        {/* View Question Types */}
        <Route
          exact
          path="/view-question-types"
          element={LayoutOfAfterLogin(<ViewQuestionTypes />)}
        />{' '}
        {/* View Question Complexity */}
        <Route
          exact
          path="/view-question-complexities"
          element={LayoutOfAfterLogin(<ViewQuestionComplexities />)}
        />{' '}
        {/* Taxonomy */}
        <Route
          exact
          path="/view-taxonomy"
          element={LayoutOfAfterLogin(<ViewTaxonomy />)}
        />{' '}
        {/* Manage Skills */}
        <Route
          exact
          path="/manage-skills"
          element={LayoutOfAfterLogin(<ManageSkills />)}
        />{' '}
        {/* View Question Tag */}
        <Route
          exact
          path="/manage-questiontags"
          element={LayoutOfAfterLogin(<ManageQuestionTags />)}
        />{' '}
        {/* View Question Tag */}
        <Route
          exact
          path="/view-categories"
          element={LayoutOfAfterLogin(<ViewCategories />)}
        />{' '}
        {/* View Question Tag */}
        <Route
          exact
          path="/manage-subjects"
          element={LayoutOfAfterLogin(<ManageSubjects />)}
        />{' '}
        <Route
          exact
          path="/manage-subjecttopics"
          element={LayoutOfAfterLogin(<ManageSubjectTopics />)}
        />{' '}
        
        <Route
          exact
          path="/main-login-analytics"
          element={LayoutOfAfterLogin(<MainLoginList />)}
        />{' '}

        
        <Route
          exact
          path="/candidate-result/:id"
          element={LayoutOfAfterLogin(<CandidateResult />)}
        />{' '}
        <Route
          exact
          path="/test-analytics"
          element={LayoutOfAfterLogin(<TestAnalytics />)}
        />{' '}
        <Route
          exact
          path="/instructions"
          element={LayoutOfCandidateLogin(<Instructions />)}
        />
        <Route
          exact
          path="/start-test"
          element={LayoutOfCandidateLogin(<Exam />)}
        />

        <Route 
          exact
          path='/practice-exam-page'
          element={LayoutOfCandidateLogin(<PracticeTestExamPage />)}
        />

        <Route
          exact
          path='/exam-question-preview'
          element={LayoutOfCandidateLogin(<ExamQuestionPreview/>)}
        />

        <Route
          exact
          path="/thankyou"
          element={LayoutOfCandidateLogin(<Thankyou />)}
        />
        <Route
          exact
          path="/feedback"
          element={LayoutOfCandidateLogin(<ListUserFeedback />)}
        />
        <Route
          exact
          path="/EPage"
          element={LayoutOfCandidateLogin(<EPage />)}
        />
        {/* Admin routes */}
        {/* <Route
            exact
            path="/admin"
            layout={AdminLayout}
            element={<Admin/>}
          /> */}
        {/* Backend Type routes */}
        <Route
          // exact
          path="/admin/*"
          layout={BackendLayout}
          element={<Backend />}
        />

        <Route
          path="/:company/*"
          layou={BackendLayout}
          element={<CompanyDashboard/>}      
        />

        <Route
          path='/campus/:college/dashboard'
          element={LayoutOfAfterLogin(<CollegeDashboard/>)}
        />

        {/* Learner routes */}
        <Route
          path="/learner/*"
          element={<Learner />}
        />
        {/* Recruiter routes */}
        <Route
          path="/recruiter/*"
          element={<RecruiterRoutes />}
        />
        {/* Recruiter routes */}
        {/* <Route
          // exact
          path="/hrms/*"
          element={<HRMSRoutes />}
        /> */}
        
        <Route
          // exact
          path="/employees/*"
          element={<EmployeeRoutes />}
        />
        {/* Exam Routes */}
        {/* Learner routes */}
        <Route
          // exact
          path="/exam/*"
          element={<ExamRoutes />}
        />
        {/* Company Users */}
        <Route
          exact
          path="/manage-company-users"
          element={LayoutOfAfterLogin(<CompanyUsers />)}
        />{' '}
        <Route
          exact
          path="/add-company-users"
          element={LayoutOfAfterLogin(<AddCompanyUsers />)}
        />{' '}
        <Route
          exact
          path="/add-company-users/:id"
          element={LayoutOfAfterLogin(<EditCompanyUsers />)}
        />{' '}
        <Route
          exact
          path="/test-summary"
          element={LayoutOfAfterLogin(<TestSummary />)}
        />{' '}
        <Route
          exact
          path="/test-summary-by-date"
          element={LayoutOfAfterLogin(<TestSummaryByDate />)}
        />{' '}
        <Route
          exact
          path="/test-summary-by-datejob"
          element={LayoutOfAfterLogin(<TestSummaryByDateJob />)}
        />{' '}
        <Route
          exact
          path="/candidates-feedback"
          element={LayoutOfAfterLogin(<CandidatesFeedback />)}
        />{' '}
        <Route
          exact
          path="/view-feedback"
          element={LayoutOfAfterLogin(<ViewFeedback />)}
        />{' '}
        {/* Interviewer Section */}
        <Route
          exact
          path="/interviewer/login"
          layout={LayoutOfInterviewerLogin}
          element={<InterviewerLogin />}
        />{' '}
        <Route
          exact
          path="/interviewer/list"
          layout={LayoutOfInterviewerLogin}
          element={<InterviewList />}
        />{' '}
        <Route
          exact
          path="/interviewer/feedback"
          layout={LayoutOfInterviewerLogin}
          element={<InterviewFeedback />}
        />{' '}
        <Route
          exact
          path="/interviewer/thankyou"
          layout={LayoutOfInterviewerLogin}
          element={<InterviewThankyou />}
        />{' '}


        <Route
          exact
          path='/campus/add-college' 
          element={LayoutOfAfterLogin(<AddCollege/>)}
          />


        <Route 
          exact
          path='/campus/'
        />

        
      </Routes>
    </Router>
    </AuthContext.Provider>
  );
}

export default App;
