import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Collapse } from 'react-bootstrap';
import { site_ip } from '../../../globalSetting';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactStars from 'react-stars';

const ListFeedbackQuestions = () => {
  const [open, setOpen] = useState(true);
  const [questionlist, setQuestionList] = useState([]);
  const intialSearch = {
    company_id: '',
    job_id: '',
  };
  const [search, setSearch] = useState(intialSearch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [job, setJob] = useState([]);
  const [company, setCompany] = useState([]);

  const fetchJobData = () => {
    axios.get(`${site_ip}/getJobMaster`).then((response) => {
      // console.log(response);
      setJob(response.data.Content);
    });
  };
  const fetchCompanyData = () => {
    axios.get(`${site_ip}/getCompanyList`).then((response) => {
      // console.log(response);
      setCompany(response.data.Content);
    });
  };

  useEffect(() => {
    fetchCompanyData();
    fetchJobData();
  }, []);

  const handleSearch = () => {
    if (search.company_id === '') {
      alert('Please select Company');
    } else if (search.job_id === '') {
      alert('Please select Job ');
    } else {
      axios({
        method: 'post',
        url: site_ip + '/getIVFeedbackQuestionsByJob',
        data: {
          job_id: search.job_id,
          company_id: search.company_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          console.log(response);
          setQuestionList(response.data.Content);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const renderQuestion = (text) => {
    return '';
  };

  const [answer, setAnswer] = useState({
    descriptive: '',
    multiple: '',
    rating: '',
  });
  const handleChangeAnswer = (e) => {
    const { name, value } = e.target;
    setAnswer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Feedback Questions</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <i class="fas fa-filter"></i> Filters
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <Collapse in={open}>
            <div className="side-nav-filter-content bg-light p-4 border shadow-sm">
              <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
                <h4 className="m-0"> Filter by </h4>
              </div>
              <div className="row">
                <Form.Group className="col-md-4 mb-4">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    name="company_id"
                    value={search.company_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Company</option>
                    {company?.map((value) => (
                      <option value={value.company_id}>
                        {value.company_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="col-md-4 mb-4">
                  <Form.Label>Job</Form.Label>
                  <Form.Control
                    name="job_id"
                    value={search.job_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Job</option>
                    {job?.map((value) => (
                      <option value={value.job_id}>{value.job_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="btn-box  border-top mt-3 pt-3">
                <Button
                  onClick={() => setOpen(!open)}
                  variant="outline-primary"
                  size="sm"
                >
                  Cancel
                </Button>
                <Button variant="primary ms-4" size="sm" onClick={handleSearch}>
                  Apply
                </Button>
              </div>
            </div>
          </Collapse>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={12}>
          <div className="p-3 border bg-white">
            {questionlist?.map((item, index) => {
              return (
                <div key={index} class="p-3 border mt-4">
                  <div style={{ fontWeight: 'bold' }}>
                    <p>Question No.{index + 1}</p>
                  </div>
                  <div>{renderQuestion(item.question_desc)}</div>
                  {item.question_type === 'descriptive' ? (
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        name="descriptive"
                        placeholder="Enter your feedback here"
                        value={answer.descriptive}
                        onChange={handleChangeAnswer}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  ) : null}
                  {item.question_type === 'multiple-choice' ? (
                    <Form.Group>
                      <RenderOptions options={item.question_options} />
                    </Form.Group>
                  ) : null}
                  {item.question_type === 'rating' ? (
                    <Form.Group>
                      <Form.Label>Please give a rating</Form.Label>
                      <ReactStars
                        count={JSON.parse(item.question_options)[1]}
                        onChange={(val) => alert(val)}
                        size={30}
                        color2={'#ffd700'}
                      />
                    </Form.Group>
                  ) : null}
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ListFeedbackQuestions;

const RenderOptions = (props) => {
  const renderOption = (text) => {
    return (
      <div/>
    );
  };

  let options = JSON.parse(props.options);
  return (
    <div>
      <Row>
        <Col md={12} className="mb-4">
          <div class="border-bottom p-2 w-100 h-100 answers">
            <Form.Check
              name="radio"
              type="radio"
              id={1}
              // value={option}
              label={renderOption(options[0])}
              // checked={useranswers[quest_id] === 1}
              // onChange={(e) => {
              //   hChange1('option1');
              //   updateAnswers(1);
              // }}
            />
          </div>
        </Col>
        <Col md={12} className="mb-4">
          <div class="border-bottom p-2 w-100 h-100 answers ">
            <Form.Check
              name="radio"
              type="radio"
              id={2}
              // value={option}
              label={renderOption(options[1])}
            />
          </div>
        </Col>
        <Col md={12} className="mb-4">
          <div class="border-bottom p-2 w-100 h-100 answers">
            <Form.Check
              name="radio"
              type="radio"
              id={3}
              // value={option}
              label={renderOption(options[2])}
            />
          </div>
        </Col>
        <Col md={12} className="mb-4">
          <div class="border-bottom p-2 w-100 h-100 answers">
            <Form.Check
              name="radio"
              type="radio"
              id={4}
              // value={option}
              label={renderOption(options[3])}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
