import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Collapse } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
import axios from 'axios';

function ListCompanySkill(props) {
  const initialValues = {
    company_name: '',
    industry_id: '',
    state_id: '',
    country_id: '',
  };

  const [show, setShow] = useState(false);
  const [companylist, setCompanyList] = useState([]);
  // const [filtercompanyData, setFilterCompanyData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(initialValues);
  // const [value1, setValue1] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'country_id') {
      fetchStateData(value);
    }
  };

  const fetchStateData = (countryid) => {
    fetch(`${site_ip}/getState/${countryid}`)
      .then((res) => res.json())
      .then((result) => {
        setStateList(result.States);
        setSearch((prevState) => ({
          ...prevState,
          state_id: '',
        }));
      });
  };

  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [show]);

  const handleSearch = (e) => {
    e.preventDefault();
    setShow(true);
    axios({
      method: 'post',
      url: site_ip + '/searchCompany',
      data: {
        // candidate_mobile: search.candidate_mobile,
        company_name: search.company_name,
        industry_id: search.industry_id,
        state_id: search.state_id,
        country_id: search.country_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        setCompanyList(response.data.Questions);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  useEffect(() => {
    CompanyListData();
  }, []);

  const CompanyListData = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompanyList(result.data.Content);
  };

  const fetchCountryData = (e) =>
    fetch(`${site_ip}/getCountryList`)
      .then((res) => res.json())
      .then((result) => {
        setCountryList(result.content);
      });
  useEffect(() => {
    fetchCountryData();
    fetchIndustryData();
  }, []);
  const fetchIndustryData = (e) =>
    fetch(`${site_ip}/getIndustryList`)
      .then((res) => res.json())
      .then((result) => {
        setIndustryList(result.Content);
      });
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Manage Company</h4>
        </Col>
        <Col md={6} className="text-end">
          <Link
            className="btn btn-dark me-3"
            to={{ pathname: '/admin/company-add' }}
          >
            <i class="fas fa-plus-square"></i> Add Company
          </Link>
          <Button
            variant="dark"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <i class="fas fa-filter"></i> Filters
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Collapse in={open}>
              <div className="side-nav-filter-content bg-light p-4 border shadow-sm">
                <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
                  <h4 className="m-0"> Filter by </h4>
                </div>
                <Row>
                  <Col md={3}>
                    <Form>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Company Name </Form.Label>
                        <Form.Control
                          type="text"
                          name="company_name"
                          value={search.company_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="industry_id"
                        value={search.industry_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Industry</option>
                        {industryList.map((value) => (
                          <option
                            key={value.industry_id}
                            value={value.industry_id}
                          >
                            {value.industry_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} style={{ marginBottom: '15px' }}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Country"
                        name="country_id"
                        value={search.country_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        {countryList.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.country_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="State"
                        name="state_id"
                        value={search.state_id}
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>
                        {stateList.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="btn-box  border-top mt-3 pt-3">
                  <Row>
                    <Col md={5}></Col>
                    <Col md={4}>
                      <Button variant="primary" onClick={handleSearch}>
                        Search
                      </Button>
                      <Button
                        variant="warning"
                        onClick={() => {
                          CompanyListData();
                          setSearch(initialValues);
                        }}
                        style={{ marginLeft: '15px' }}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
            {/* <BootstrapTable
              bootstrap4
              keyField="id"
              data={products}
              columns={columns}
              pagination={paginationFactory({ sizePerPage: 5 })}
            /> */}
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Company Name</th>
                  <th>Address1</th>
                  <th>Address2</th>
                  <th>City</th>
                  <th>Employee Count</th>
                  <th>Industry</th>
                  <th>Company Url</th>
                  <th>Company Board</th>
                  {/* <th>SignedOn</th> */}
                  {/* <th>Country</th> */}
                  <th>State</th>
                  <th>Company Email</th>
                  <th>Contact Person</th>
                </tr>
              </thead>
              <tbody>
                {companylist?.length >= 0 &&
                  companylist?.map((company, index) => (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link
                          to={{
                            pathname: `/admin/company-edit/${company.company_id}`,
                          }}
                        >
                          <i className="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{company.company_name}</td>
                      <td>{company.company_add1}</td>
                      <td>{company.company_add2}</td>
                      <td>{company.company_city}</td>
                      <td>{company.emp_count}</td>
                      <td>{company.industry_name}</td>
                      <td>{company.company_url}</td>
                      <td>{company.company_board_no}</td>
                      {/* <td>{company.signed_on}</td> */}
                      <td>{company.name}</td>
                      {/* <td>{company.state_id}</td> */}
                      <td>{company.company_email}</td>
                      <td>{company.contact_person}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div>{/* <BootstrapTable /> */}</div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ListCompanySkill;
