import React, { useState, useEffect } from 'react';
import { site_ip } from '../globalSetting';
import { Row, Col, Container, Table } from 'react-bootstrap';
import DashboardPie from './charts/dashboard_pie';
import { Link } from 'react-router-dom';
import './common.css';
function Dashboard() {
  const [comapanydata, getCompanyData] = useState([]);
  const fetchCompanyData = () =>
    fetch(`${site_ip}/companyDashboard`).then((res) => res.json());
  useEffect(() => {
    fetchCompanyData().then((data) => getCompanyData(data));
  }, []);
  console.log(comapanydata);
  return (
    <>
      <Container>
        <Row>
          <Col md={8}>
            {/* Content */}
            <Row>
              <Col md={4}>
                <div className="dashboadcards card1">
                  <p className="value">3</p>
                  <p className="label">Tests Attempted</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="dashboadcards card2">
                  <p className="value">10%</p>
                  <p className="label">Accuracy</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <br />
                <h5>
                  <b>Recent Tests</b>
                </h5>
                <br />
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Test Title</th>
                      <th className="center-align">Score</th>
                      <th className="center-align">Accuracy</th>
                      <th className="center-align">Attempt</th>
                      <th className="center-align">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>React JS Functional Components</td>
                      <td className="center-align">10.25</td>
                      <td className="center-align">55%</td>
                      <td className="center-align">2</td>
                      <td className="center-align">
                        <Link
                          to="/learner/test-result"
                          className="view-result-btn"
                        >
                          View Result
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            {/* Graph */}
            <DashboardPie />
          </Col>
        </Row>
      </Container>

      {/* <div class="container-fluid containerLess">
        <div class="row containerWhite mx-2">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-1">
              <p className="box-heading">Question Count</p>
              <p className="box-value">{comapanydata.QuestionCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-2">
              <p className="box-heading">Test Assigned Count</p>
              <p className="box-value">{comapanydata.TestAssignedCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-3">
              <p className="box-heading">Test Count</p>
              <p className="box-value">{comapanydata.TestsCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-4">
              <p className="box-heading">Candidate Count</p>
              <p className="box-value">{comapanydata.candidatesCount}</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default Dashboard;
