import React, { useState } from 'react';
// import { NavLink, Redirect } from 'react-router-dom';
// import '../../assets/scss/style.scss';
import Aux from '../hoc/_Aux';
// import Breadcrumb from '../layout/AdminLayout/Breadcrumb';
import { site_ip } from '../globalSetting';
import axios from 'axios';
import './Learner.css';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/learnerSignin',
      data: {
        user_email: email,
        password: password,
      },
      dataType: 'json',
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.accessToken) {
          localStorage.setItem('userdata', JSON.stringify(response.data));
          localStorage.setItem('quantam_learner_id', response.data.id);
          redirectUser();
        } else {
          alert('Invalid credentials');
        }
      })
      .catch(function (error) {
        console.log('error1' + error);
      });
  };

  const redirectUser = () => {
    setTimeout(() => {
      window.location.href = '/exam/dashboard';
    }, 1000);
  };

  return (
    <Aux>
      <div>
        <Row style={{ margin: '0px' }}>
          <Col md={6} className="bg-dark-blue" style={{height:"calc(100vh - 80px)"}}>
          
          </Col>
          <Col md={6} className="align-items-center justify-content-center flex pos-relative" style={{flexDirection:"column"}}>
            <p className="text-center heading-1" style={{fontWeight:"bold"}}>
                EXAM LOGIN
            </p>
            <div className="login-box-div">
              {/* <Form> */}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <p className='registerLabel'>Email</p>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="user_email"
                    value={email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className='registerLabel'>Password</p>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handleChangePassword}
                  />
                </Form.Group>
                <div className="align-items-center justify-content-center flex">
                  <button className='primary-btn' onClick={()=>handleLogin()}>LOGIN</button>
                </div>
                <div className="align-items-center justify-content-center flex" style={{flexDirection:"column", marginTop:"15px", fontSize:"13px"}}>
                    Don't have an account?<br/>
                    <Link to="/exam/signup" style={{fontSize:"15px", fontWeight:"bold"}}>Sign Up</Link>
                  </div>
              {/* </Form> */}
            </div>
          </Col>
        </Row>
      </div>
    </Aux>
  );
}

export default Login;
