import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
// import '../../../../homepage.css'

const Header = () => {
  const navigate  = useNavigate();
  const location = useLocation();
  return (
    <div className="fix-header sticky">
      <Container>
        <Row style={{ margin: "0px" }}>
          <Col md={3} style={{ padding: 0 }}>
            <img src="/home/logo.png" className="header-logo-img" style={{cursor:"pointer"}} onClick={() => navigate('/')} />
          </Col>
          <Col md={9}>
            <div className="navigation">
              <ul>
                <li>
                  {location.pathname === "/learner/signup" ?
                  <button onClick={()=> navigate('/learner')} className="signinbtn" >Login</button> : null }
                  {location.pathname === "/learner" ?
                  <button onClick={()=> navigate('/learner/signup')} className="signinbtn" >Sign Up</button> : null }
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Header;