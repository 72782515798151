import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
// import { site_ip } from '../../globalSetting';
import { site_ip } from '../../../../globalSettings';
import axios from 'axios';
import Swal from 'sweetalert2';
// import ReactPaginate from 'react-paginate';

import { useParams } from 'react-router-dom';
function Adminusers(props) {
  const initialValues = {
    user_id: '',
    user_name: '',
    role_id: '',
    user_email: '',
    mobile_number: '',
    password: '',
    updating: false,
  };

  const initialSearch = {
    user_name: '',
    user_email: '',
    role_id: '',
  };

  //   const [currentPage, setCurrentPage] = useState(0);
  //   const [data, setData] = useState([]);

  const [roles, setRoles] = useState([]);
  const [users, setUsersList] = useState([]);

  const [add, setAdd] = useState(false);

  const [values, setValues] = useState(initialValues);
  const [search, setSearch] = useState(initialSearch);
  const { id } = useParams();
  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (pass) => {
    if (pass.trim().length < 8) {
      return false;
    }
    return true;
  };

  const proceed = () => {
    if (
      values.user_name.trim() === '' ||
      values.user_email.trim() === '' ||
      values.role_id === ''
    ) {
      alert('Please fill all fields');
    } else if (!validateEmail(values.user_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.mobile_number)) {
      alert('Please enter valid mobile number');
    } else if (values.user_id === '') {
      if (!validatePassword(values.password)) {
        alert('Password must be minimum 8 character long');
        return;
      }
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/auth/createAdminUser',
        data: {
          role_id: values.role_id,
          user_name: values.user_name,
          user_email: values.user_email,
          password: values.password,
          mobile_number: values.mobile_number,
          company_id:id
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.Message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New User created successfully',
              icon: 'success',
            }).then((result) => {
              fetchUsers();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/auth/setAdminUser',
        data: {
          user_id: values.user_id,
          role_id: values.role_id,
          user_name: values.user_name,
          user_email: values.user_email,
          password: values.password,
          mobile_number: values.mobile_number,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Job successfully updated',
              icon: 'success',
            }).then((result) => {
              fetchUsers();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchUsers();
    fetchAdminRoles();
    // eslint-disable-next-line
  }, []);

  const fetchAdminRoles = async () => {
    const result = await axios.get(`${site_ip}/auth/getAdminRoles`);
    setRoles(result.data.Content);
  };

  const fetchUsers = () => {
    axios({
      method: 'POST',
      url: site_ip + '/auth/searchAdminUsers',
      data: {
        user_name: search.user_name,
        user_email: search.user_email,
        role_id: search.role_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        setUsersList(response.data.Admin);
        // console.log(response);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const resetFilters = () => {
    setSearch(initialSearch);
    axios({
      method: 'POST',
      url: site_ip + '/auth/searchAdminUsers',
      data: {
        user_name: '',
        user_email: '',
        role_id: '',
      },
      dataType: 'json',
    })
      .then(function (response) {
        setUsersList(response.data.Admin);
        //   console.log(response)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const editNow = (item) => {
    const newValues = {
      user_id: item.user_id,
      user_name: item.user_name,
      role_id: item.role_id,
      user_email: item.user_email,
      mobile_number: item.mobile_number,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);
  };

  //   function handlePageClick({ selected: selectedPage }) {
  //         setCurrentPage(selectedPage);
  //   }

  return (
    <React.Fragment>
      <div style={{ margin:'30px' }}>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Search Users</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => handleShow()}
            aria-controls="example-collapse-text"
            aria-expanded={add}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ marginTop: '15px' }}>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={4}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    className="mb-3"
                    name="role_id"
                    value={search.role_id}
                    onChange={handleChange1}
                  >
                    <option value="">Select Role</option>
                    {roles.map((value) => (
                      <option key={value.role_id} value={value.role_id}>
                        {value.role_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="user_name"
                    value={search.user_name}
                    onChange={handleChange1}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="user_email"
                    value={search.user_email}
                    onChange={handleChange1}
                  />
                </Form.Group>
              </Col>
              <Col md={5}></Col>
              <Col md={7}>
                <Button variant="primary" onClick={fetchUsers}>
                  Search
                </Button>
                <Button
                  variant="secondary"
                  onClick={resetFilters}
                  style={{ marginLeft: '15px' }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link onClick={() => editNow(user)} to="#">
                          <i className="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{user.user_name}</td>
                      <td>{user.user_email}</td>
                      <td>{user.mobile}</td>
                      <td>{user.role_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {/* <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={Math.ceil(jobsList.length / 10)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            /> */}
          </div>
        </Col>
      </Row>
      <Modal size="lg" show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update User Details' : 'Add User'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="user_name"
                  value={values.user_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="role_id"
                  value={values.role_id}
                  onChange={handleChange}
                >
                  <option value="">Select Role</option>
                  {roles.map((value) => (
                    <option key={value.role_id} value={value.role_id}>
                      {value.role_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="user_email"
                  value={values.user_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="mobile_number"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          {!values.updating ? (
            <Row style={{ marginTop: '15px' }}>
              <Col md={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </React.Fragment>
  );
}

export default Adminusers;
