import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import LeftSideBar from "../LeftSideBar";

function App(){

    const BlankLayout = (children) => <>
    <h1>Welcome Company</h1>
    {children}</>;

const LayoutAfterLogin=(children)=>(
        <>
            {/* <TopBar1 /> */}
      <div style={{ display: "flex", overflow: "hidden" }}>
        <LeftSideBar />
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 90px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
        </>
    )

    return (
        <>
            <Routes>
                {/* <Route
                    exact
                    path="/"
                    element={BlankLayout}
                />{' '} */}

                <Route
                    exact
                    path="/dashboard"
                    element={LayoutAfterLogin()}
                />{' '}


            </Routes>
        </>
    )
}


export default App;