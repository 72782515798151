import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { site_ip } from '../../globalSettings';
const axios = require('axios');
function Career() {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    skill_id: '',
    experience: '',
    qualification: '',
    position: '',
    resume: '',
  };
  const [values, setValues] = useState(initialValues);
  // const [country, getCountry] = useState(null);
  // const [industry, getIndustry] = useState(null);
  // const [states, getState] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [job, getJob] = useState(null);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [skill, getSkill] = useState(null);
  const fetchSkillData = () =>
    fetch(`${site_ip}/getSkills`).then((res) => res.json());
  useEffect(() => {
    fetchSkillData().then((data) => getSkill(data.Content));
  }, []);
  const handleSubmit = (e) => {
    axios({
      method: 'post',
      url: `${site_ip}/setFreeTrial`,
      data: {
        name: values.name,
        position: values.position,
        experience: values.experience,
        skill_id: values.skill_id,
        qualification: values.qualification,
        phone: values.phone,
        email: values.email,
        resume: values.resume,
      },
      dataType: 'json',
    })
      .then(function (response) {
        setSubmitted(true);
        console.log(submitted);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Container fluid className="pt-5">
      <Row className="align-items-center h-100 justify-content-center bg-blue  pt-5">
        <Col md={8} className="mx-auto">
          <div className="border shadow w-75 mx-auto p-5 bg-white rounded-3">
            <Row>
              <Col md={6}>
                <h2>Career</h2>
              </Col>
            </Row>
            <Form>
              <div class="p-3">
                <Row>
                  <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 col-6"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-6"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Your Phone</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="User Mobile"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-6"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Your Qualification</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Qualification"
                      name="qualification"
                      value={values.qualification}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-6"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Your Experience</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Experience"
                      name="experience"
                      value={values.experience}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-6"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Your Skill</Form.Label>
                    <Form.Control
                      name="skill_id"
                      value={values.skill_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Skill Id</option>
                      {skill?.map((value) => (
                        <option value={value.skill_id}>
                          {value.skill_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-6"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Your Position</Form.Label>
                    <Form.Control
                      name="position"
                      value={values.position}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="mb-6 col-6"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Attched Resume</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Attched Resume"
                      name="resume"
                      value={values.resume}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Row>
              </div>
              <Button variant="primary" type="button" onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Career;
