import React, { useState, useEffect } from 'react';
//import Aux from "../../../hoc/_Aux";
import { Button, Col, Form, Row, Container } from 'react-bootstrap';

import { site_ip } from '../../globalSetting';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
export default function EditFeedbackQuestions() {
  const navigate = useNavigate();
  const location = useLocation();
  const userdata = JSON.parse(localStorage.getItem('userdata'));

  //   console.log(location.state);

  const initialInputs = {
    question_id: '',
    question_desc: '',
    question_type: '',
    question_options: [],
    minimum_value: '',
    maximum_value: '',
  };
  const [input, setInputs] = useState(initialInputs);

  const [question] = useState(location.state);
  // console.log(location.state);

  useEffect(() => {
    setInputs({
      question_id: question.question_id,
      question_type: JSON.parse(question.question_options)[0],
    });
    if (JSON.parse(question.question_options)[0] === 'multiple-choice') {
      
    } else if (JSON.parse(question.question_options)[0] === 'rating') {
      setInputs({
        question_id: question.question_id,
        question_type: JSON.parse(question.question_options)[0],
        minimum_value: JSON.parse(question.question_options)[1],
        maximum_value: JSON.parse(question.question_options)[2],
      });
    }
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    var convertedQuestionData = "";
    var convertedOptionOneData = "";
    var convertedOptionTwoData = "";
    var convertedOptionThreeData = "";
    var convertedOptionFourData = "";

    let answer;
    if (input.question_type === 'multiple-choice') {
      answer = JSON.stringify([
        input.question_type,
        convertedOptionOneData,
        convertedOptionTwoData,
        convertedOptionThreeData,
        convertedOptionFourData,
      ]);
    } else if (input.question_type === 'rating') {
      answer = JSON.stringify([
        input.question_type,
        input.minimum_value,
        input.maximum_value,
      ]);
    } else {
      answer = JSON.stringify([input.question_type]);
    }
    axios({
      method: 'post',
      url: site_ip + '/setUserFeedbackQuestions',
      data: {
        question_id: input.question_id,
        question_desc: JSON.stringify(convertedQuestionData),
        question_options: answer,
        company_id: userdata.company_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        if (response.data.Status === 200) {
          Swal.fire({
            title: 'Success',
            text: 'New User Feedback Question updated successfully.',
            icon: 'success',
          });
          navigate('/user-feedback-questions');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="mb-0 pb-4">Edit User Feedback Question</h4>
          <Form className="p-3 border bg-white">
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group>
                  <Form.Label>Question Type</Form.Label>
                  <Form.Control
                    name="question_type"
                    value={input.question_type}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    as="select"
                  >
                    <option value="">Select Question Type</option>
                    <option value="descriptive">Descriptive</option>
                    <option value="multiple-choice">Multiple Choice</option>
                    <option value="rating">Rating</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Question</Form.Label>
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      padding: '2px',
                      minHeight: '300px',
                    }}
                  >
                  </div>
                </Form.Group>
              </Col>
              {input.question_type === 'rating' ? (
                <Row>
                  <Col md={4} className="mb-4">
                    <Form.Group>
                      <Form.Label>Minimum Value</Form.Label>
                      <Form.Control
                        name="minimum_value"
                        value={input.minimum_value}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-4">
                    <Form.Group>
                      <Form.Label>Maximum Value</Form.Label>
                      <Form.Control
                        name="maximum_value"
                        value={input.maximum_value}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="number"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
              {input.question_type === 'multiple-choice' ? (
                <Row>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Option 1</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <Form.Label>Option 3</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Option 2</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <Form.Label>Option 4</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
              <Col md={6}>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
