// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-block {
  padding-left: 250px;
  margin: 12px;
}

.select-bottom-space{
  margin-bottom: 16px;
}

.center-align-text{
  text-align: center;
}

.updown-space{
  margin: 15px 0px 15px 0px
}`, "",{"version":3,"sources":["webpack://./src/components/pages/afterlogin.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;AACF","sourcesContent":[".right-block {\r\n  padding-left: 250px;\r\n  margin: 12px;\r\n}\r\n\r\n.select-bottom-space{\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.center-align-text{\r\n  text-align: center;\r\n}\r\n\r\n.updown-space{\r\n  margin: 15px 0px 15px 0px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
