import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { site_ip } from '../../../globalSetting';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const AddInterviewerList = () => {
  const navigate = useNavigate();
  const initialValues = {
    company_id: '',
    job_id: '',
    updating: false,
  };
  const [values, setValues] = useState(initialValues);

  const [inputList, setInputList] = useState([{ interviewer_id: '' }]);
  const [companyList, setCompanyList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);

  useEffect(() => {
    fetchCompanyList();
    fetchJobList();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'company_id') fetchInterviewerList(value);
  };

  const fetchCompanyList = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompanyList(result.data.Content);
  };
  const fetchJobList = async () => {
    const result = await axios.get(`${site_ip}/getJobMaster`);
    setJobList(result.data.Content);
  };

  const fetchInterviewerList = async (value) => {
    const result = await axios.get(`${site_ip}/getInterviewer/${value}`);
    // console.log(result);
    setInterviewerList(result.data.Content);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    if (inputList.length === interviewerList.length) {
    } else {
      setInputList([...inputList, { interviewer_id: '' }]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.job_id === '') {
      alert('Please select Job');
      return;
    }
    const add = { company_id: values.company_id, job_id: values.job_id };
    let panel = [];
    for (let i = 0; i < inputList.length; i++) {
      if (inputList[i].interviewer_id !== '') {
        panel.push(inputList[i]);
        Object.entries(add).forEach(([key, value]) => {
          panel[i][key] = value;
        });
      }
    }
    if (panel.length === 0) {
      alert('Please select atleast one Interviewer');
      return;
    }
    // console.log(inputList);
    axios({
      method: 'POST',
      url: site_ip + '/addCompanyIVPanel',
      data: panel,
    })
      .then((response) => {
        if (response.data.Message === 200) {
          Swal.fire({
            title: 'Success',
            text: 'New Interview Panel added successfully',
            icon: 'success',
          }).then((result) => {
            navigate('/admin/manage-interviewer-list');
          });
        } else {
          alert('Request failed');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <Container>
        <h5>Add Interview Panel</h5>
        <br />

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}></Col>
            <Col
              md={6}
              style={{
                border: '1px solid #ccc',
                padding: '15px',
                backgroundColor: '#f5f5f5',
              }}
            >
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="company_id"
                      value={values.company_id}
                      onChange={handleChange}
                    >
                      <option value="">Select Company</option>
                      {companyList.map((value) => (
                        <option key={value.company_id} value={value.company_id}>
                          {value.company_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Job</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="job_id"
                      value={values.job_id}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select Job</option>
                      {jobList.map((value) => (
                        <option key={value.job_id} value={value.job_id}>
                          {value.job_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {inputList.map((item, i) => {
                  return (
                    <Col md={12}>
                      <Row>
                        <Col md={8}>
                          <Form.Group>
                            <Form.Label>Interviewer List</Form.Label>
                            <Form.Control
                              as="select"
                              className="mb-3"
                              name="interviewer_id"
                              value={item.interviewer_id}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value="">Select Interviewer</option>
                              {interviewerList.map((value) =>
                                item.interviewer_id === value.interviewer_id ? (
                                  <option
                                    key={value.interviewer_id}
                                    value={value.interviewer_id}
                                  >
                                    {value.interviewer_name}
                                  </option>
                                ) : null
                              )}
                              {interviewerList.map((value) =>
                                inputList.findIndex(
                                  (x) =>
                                    x.interviewer_id === value.interviewer_id
                                ) !== -1 ? null : (
                                  <option
                                    key={value.interviewer_id}
                                    value={value.interviewer_id}
                                  >
                                    {value.interviewer_name}
                                  </option>
                                )
                              )}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          {inputList.length !== 1 && (
                            <Button
                              variant="secondary"
                              style={{ marginTop: '35px', marginRight: '10px' }}
                              size="sm"
                              onClick={() => handleRemoveClick(i)}
                            >
                              Remove
                            </Button>
                          )}
                          {inputList.length - 1 === i && (
                            <Button
                              style={{ marginTop: '35px' }}
                              size="sm"
                              onClick={handleAddClick}
                            >
                              Add
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: '30px' }}>
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default AddInterviewerList;
