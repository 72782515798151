import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { site_ip } from '../../../globalSetting';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../common/ErrorModal';
import SuccessModal from '../../common/SuccessModal';
const AddInterviewerList = () => {
  const userdata = JSON.parse(localStorage.getItem('userdata'))?.Content[0];
  const navigate = useNavigate();
  const initialValues = {
    company_id: userdata.company_id === null ? 1 : userdata.company_id,
    job_id: '',
    updating: false,
  };
  const [values, setValues] = useState(initialValues);

  const [inputList, setInputList] = useState([{ interviewer_id: '' }]);
  const [jobList, setJobList] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  useEffect(() => {
    fetchInterviewerList(values.company_id);
    fetchJobList();
    //eslint-disable-next-line
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchJobList = async () => {
    const result = await axios.get(`${site_ip}/getJobMaster`);
    setJobList(result.data.Content);
  };

  const fetchInterviewerList = async (value) => {
    const result = await axios.get(`${site_ip}/getInterviewer/${value}`);
    // console.log(result);
    setInterviewerList(result.data.Content);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    if (inputList.length === interviewerList.length) {
    } else {
      setInputList([...inputList, { interviewer_id: '' }]);
    }
  };

  const handleSubmit = () => {
    if (values.job_id === '') {
      setErrorMessage('Please select Job');
      return;
    }
    const add = { company_id: values.company_id, job_id: values.job_id };
    let panel = [];
    for (let i = 0; i < inputList.length; i++) {
      if (inputList[i].interviewer_id !== '') {
        panel.push(inputList[i]);
        Object.entries(add).forEach(([key, value]) => {
          panel[i][key] = value;
        });
      }
    }
    if (panel.length === 0) {
      setErrorMessage('Please select atleast one Interviewer');
      return;
    }
    axios({
      method: 'POST',
      url: site_ip + '/addCompanyIVPanel',
      data: panel,
    })
      .then((response) => {
        if (response.data.Status === 200) {
          setSuccessMessage('New Interview Panel added successfully')
        } else {
          setErrorMessage('Request failed');
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <Container>
        <Row className="admin-form-row">
          <div className="header">
            <h4>Add Interview Panel</h4>
          </div>
          <div className="p-3 border bg-white">
              <Row>
                <Col md={3}></Col>
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Job</Form.Label>
                        <Form.Control
                          as="select"
                          className="mb-3"
                          name="job_id"
                          value={values.job_id}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">Select Job</option>
                          {jobList.map((value) => (
                            <option key={value.job_id} value={value.job_id}>
                              {value.job_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {inputList.map((item, i) => {
                      return (
                        <Col md={12}>
                          <Row>
                            <Col md={8}>
                              <Form.Group>
                                <Form.Label>Interviewer List</Form.Label>
                                <Form.Control
                                  as="select"
                                  className="mb-3"
                                  name="interviewer_id"
                                  value={item.interviewer_id}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Interviewer</option>
                                  {interviewerList.map((value) =>
                                    item.interviewer_id === value.interviewer_id ? (
                                      <option
                                        key={value.interviewer_id}
                                        value={value.interviewer_id}
                                      >
                                        {value.interviewer_name}
                                      </option>
                                    ) : null
                                  )}
                                  {interviewerList.map((value) =>
                                    inputList.findIndex(
                                      (x) =>
                                        x.interviewer_id === value.interviewer_id
                                    ) !== -1 ? null : (
                                      <option
                                        key={value.interviewer_id}
                                        value={value.interviewer_id}
                                      >
                                        {value.interviewer_name}
                                      </option>
                                    )
                                  )}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              {inputList.length !== 1 && (
                                <Button
                                  variant="secondary"
                                  style={{ marginTop: '35px', marginRight: '10px' }}
                                  size="sm"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remove
                                </Button>
                              )}
                              {inputList.length - 1 === i && (
                                <Button
                                  style={{ marginTop: '35px' }}
                                  size="sm"
                                  onClick={handleAddClick}
                                >
                                  Add
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col md={12} style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Button onClick={() => handleSubmit()}>Submit</Button>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}></Col>
              </Row>
          </div>
        </Row>
        <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
        <SuccessModal successMessage={successMessage} setSuccessMessage={() => navigate('/recruiter/manage-interviewer-list')} />
      </Container>
    </>
  );
};

export default AddInterviewerList;
