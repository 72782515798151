import React, { useState } from 'react'
import Webcam from 'react-webcam'
import axios from 'axios'
import { site_ip } from '../../globalSettings'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
const WebcamComponent = () => <Webcam />
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}
const Profile = () => {
  const [picture, setPicture] = useState('')
//   const [pictures, setPictures] = useState([]);
  const [tag, setTag] = useState('');

  //states to know if profilePicture and PhotoId is saved in DB
  const [ profilePicture, setProfilePicture] = useState(false);
  const [ photoId, setPhotoId] = useState(false);

  let navigate = useNavigate();

  const webcamRef = React.useRef(null)
//   const capture = React.useCallback(() => {
//     const pictureSrc = webcamRef.current.getScreenshot()
//     setPicture(pictureSrc)
//   })

const capture = React.useCallback(() => {
  const pictureSrc = webcamRef.current.getScreenshot();
//   setPictures([...pictures, pictureSrc]);
setPicture(pictureSrc);
});


const savePicturesToAPI = async (savingtag) => {
    try {
         

        const candidate_id = localStorage.getItem('candidate_id');

        
      const response = await axios.post(`${site_ip}/saveCandidatePictures`, { picture, savingtag,candidate_id });
      console.log('Pictures saved to API:', response.data);
      console.log(savingtag,'CUrrent TAG')
      if(savingtag==="Profile"){
        if(response.data.success){
            setProfilePicture(true);
            setPicture('');
          }
      }

      if(savingtag==='idCard'){

        if(response.data.success){
            setPhotoId(true);
            navigate('/start-test')
        }
      }

    } catch (error) {
      console.error('Error saving pictures:', error);
    }
  };

//   return (
//     <div>
//       <h2 className="mb-5 text-center">
//         {!profilePicture ? 'Capture User Image':'Capture User Image with ID'}
//       </h2>
//       {/* <div>
//         {picture == '' ? (
//           <Webcam
//             audio={false}
//             height={400}
//             ref={webcamRef}
//             width={400}
//             screenshotFormat="image/jpeg"
//             videoConstraints={videoConstraints}
//           />
//         ) : (
//           <img src={picture} />
//         )}
//       </div> */}

// <div>
//   {!picture? (
//     <Webcam
//       audio={false}
//       height={400}
//       ref={webcamRef}
//       width={400}
//       screenshotFormat="image/jpeg"
//       videoConstraints={videoConstraints}
//     />
//   ) : (
//     // pictures.map((picture, index) => (
//     //   <img key={index} src={picture} alt={`Captured ${index + 1}`} />
//     // ))

// <img src={picture} alt='Captured' />
//   )}
// </div>


//       {/* <div>
//         {picture != '' ? (
//           <button
//             onClick={(e) => {
//               e.preventDefault()
//               setPicture()
//             }}
//             className="btn btn-primary"
//           >
//             Retake
//           </button>
//         ) : (
//           <button
//             onClick={(e) => {
//               e.preventDefault()
//               capture()
//             }}
//             className="btn btn-danger"
//           >
//             Capture
//           </button>
//         )}
//       </div> */}

// {/* <div>
//   <button
//     onClick={(e) => {
//       e.preventDefault();
//       setPictures([]);
//     }}
//     className="btn btn-primary"
//   >
//     Clear Pictures
//   </button>
//   <button
//     onClick={(e) => {
//       e.preventDefault();
//       // Implement the logic to save pictures to a server or storage here
//       console.log("Pictures saved:", pictures);
//     }}
//     className="btn btn-success"
//   >
//     Save Pictures
//   </button>
// </div> */}

// <div>
//         {picture!=='' ? (
//           <button
//             onClick={() => {
//             //   setPictures([]);
//             setPicture('')
//             }}
//             className="btn btn-primary"
//           >
//             Clear Pictures
//           </button>
//         ) : (
//           <button
//             onClick={() => {
//               capture();
//             }}
//             className="btn btn-danger"
//           >
//             Capture
//           </button>
//         )}
//         {picture!=='' && (
//           <button
//             onClick={() => {

//                 if(profilePicture){
//                     console.log('idCard')
//                     setTag('idCard');
//                     savePicturesToAPI('idCard');
//                 }

//                 if(!profilePicture){
//                     console.log('Profile')
//                     setTag('Profile');
//                     savePicturesToAPI('Profile');
//                 }
                
//             }}
//             className="btn btn-success"
//           >
//             Save Picture
//           </button>
//         )}

//         {/* {profilePicture && (
//               <span className='col'>Picture saved successfuly!</span>
//         )} */}
//       </div>
//     </div>
//   )

return (
  <div className='p-2 m-auto'>
    <h2 className="mb-5 text-center">
      {!profilePicture ? 'Capture User Image' : 'Capture User Image with ID'}
    </h2>

    <div>
      {!picture ? (
        <Webcam
          audio={false}
          height={400}
          ref={webcamRef}
          width={400}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
      ) : (
        <img src={picture} alt="Captured" />
      )}
    </div>

    <div>
      {picture !== '' ? (
        <button
          onClick={() => {
            setPicture('');
          }}
          className="btn btn-primary"
        >
          Clear Pictures
        </button>
      ) : (
        <button
          onClick={() => {
            capture();
          }}
          className="btn btn-danger"
        >
          Capture
        </button>
      )}

      {picture !== '' && (
        <button
          onClick={() => {
            if (profilePicture) {
              setTag('idCard');
              savePicturesToAPI('idCard');
            } else {
              setTag('Profile');
              savePicturesToAPI('Profile');
            }
          }}
          className="btn btn-success m-2"
        >
          Save Picture
        </button>
      )}

      {/* Display instructions based on the current state */}
      <div className="mt-3">
        {profilePicture ? (
          <p>Now capture your image with your ID card visible in the frame.</p>
        ) : (
          <p>Position yourself in the frame and capture your user image.</p>
        )}
      </div>
    </div>
  </div>
);
}
export default Profile