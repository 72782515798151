import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Collapse, Table } from 'react-bootstrap';
import { site_ip } from '../../../globalSetting';
import { Link } from 'react-router-dom';
import axios from 'axios';
const FeedbackQuestions = () => {
  const [open, setOpen] = useState(true);
  const [questionlist, setQuestionList] = useState([]);
  const intialSearch = {
    company_id: '',
    job_id: '',
  };
  const [search, setSearch] = useState(intialSearch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [job, setJob] = useState([]);
  const [company, setCompany] = useState([]);

  const fetchJobData = () => {
    axios.get(`${site_ip}/getJobMaster`).then((response) => {
      // console.log(response);
      setJob(response.data.Content);
    });
  };
  const fetchCompanyData = () => {
    axios.get(`${site_ip}/getCompanyList`).then((response) => {
      // console.log(response);
      setCompany(response.data.Content);
    });
  };

  useEffect(() => {
    fetchCompanyData();
    fetchJobData();
  }, []);

  const handleSearch = () => {
    if (search.company_id === '') {
      alert('Please select Company');
    } else if (search.job_id === '') {
      alert('Please select Job ');
    } else {
      axios({
        method: 'post',
        url: site_ip + '/getIVFeedbackQuestionsByJob',
        data: {
          job_id: search.job_id,
          company_id: search.company_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          console.log(response);
          setQuestionList(response.data.Content);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const renderQuestion = (text) => {
    return '';
  };

  return (
    <>
      <Row className="align-items-center">
        <Col md={4}>
          <h4>Feedback Questions</h4>
        </Col>
        <Col md={8} className="text-end">
          {/* <Link
            className="btn btn-dark me-3"
            to={{ pathname: '/admin/list-feedback-questions' }}
          >
            <i class="fas fa-plus-square"></i> Open Feedback Questions
          </Link> */}
          <Link
            className="btn btn-dark me-3"
            to={{ pathname: '/admin/add-feedback-questions' }}
          >
            <i class="fas fa-plus-square"></i> Add Feedback Questions
          </Link>
          <Button
            variant="dark"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <i class="fas fa-filter"></i> Filters
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Collapse in={open}>
              <div className="side-nav-filter-content bg-light p-4 border shadow-sm">
                <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
                  <h4 className="m-0"> Filter by </h4>
                </div>
                <div className="row">
                  <Form.Group className="col-md-4 mb-4">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                      name="company_id"
                      value={search.company_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Company</option>
                      {company?.map((value) => (
                        <option value={value.company_id}>
                          {value.company_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-4">
                    <Form.Label>Job</Form.Label>
                    <Form.Control
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="btn-box  border-top mt-3 pt-3">
                  <Button
                    onClick={() => setOpen(!open)}
                    variant="outline-primary"
                    size="sm"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary ms-4"
                    size="sm"
                    onClick={handleSearch}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Collapse>

            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th>#</th>
                  <th>Action</th>
                  <th>Id</th>
                  <th>Feedback Question</th>
                  <th>Question Type</th>
                </tr>
              </thead>
              <tbody className="lt-body">
                {questionlist?.map((value, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <Link
                        to={{
                          pathname: `/admin/edit-question/${value.ques_id}`,
                        }}
                      >
                        <i className="menu-icon fas fa-edit"></i>
                      </Link>
                    </td>
                    <td>{value.question_id}</td>
                    <td>{renderQuestion(value.question_desc)}</td>
                    <td>{value.question_type}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FeedbackQuestions;
