import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Collapse, Table } from 'react-bootstrap';
import { site_ip } from '../../../globalSetting';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../../customTableStyle';
import { useNavigate } from 'react-router-dom';
const FeedbackQuestions = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [questionlist, setQuestionList] = useState([]);
  const userdata = JSON.parse(localStorage.getItem('userdata'))?.Content[0];

  const intialSearch = {
    company_id: userdata.company_id === null ? 1 : userdata.company_id,
    job_id: '',
  };
  const [search, setSearch] = useState(intialSearch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [job, setJob] = useState([]);
  // const [company, setCompany] = useState([]);

  const fetchJobData = () => {
    axios.get(`${site_ip}/getJobMaster`).then((response) => {
      // console.log(response);
      setJob(response.data.Content);
    });
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  const handleSearch = () => {
    if (search.company_id === '') {
      alert('Please select Company');
    } else if (search.job_id === '') {
      alert('Please select Job ');
    } else {
      axios({
        method: 'post',
        url: site_ip + '/getIVFeedbackQuestionsByJob',
        data: {
          job_id: search.job_id,
          company_id: search.company_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          console.log(response);
          setQuestionList(response.data.Content);
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const renderQuestion = (text) => {
    return(
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    )
  };

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Question ID',
      selector: (row) => row?.question_id,
      width: "100px"
    },
    {
      name: 'Question',
      cell: (row) => (
        <>
            {renderQuestion(row.question_desc)}
        </>
      )
    },
    {
      name: 'Question Type',
      selector: (row) => row?.question_type,
      width: "150px"
    },
    {
      name: 'Action',
      width: "150px",
      cell: (row, index) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => navigate("/recruiter/edit-feedback-questions/"+row.question_id, {state: row})}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <>
      <Row className="align-items-center">
        <Col md={4}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Feedback Questions</p>
        </Col>
        <Col md={8} className="text-end">
          <Link
            className="admin-action-btns"
            to={{ pathname: '/recruiter/add-feedback-questions' }}
          >
            <i class="fas fa-plus-square icon"></i> Add Feedback Questions
          </Link>
          <Button
            className='admin-action-btns'
            onClick={() => setOpen(!open)}
            style={{ marginRight: '10px' }}
            aria-expanded={open}
          >
            <i class="fas fa-filter icon"></i> Filters
          </Button>

        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <Collapse in={open}>
              <div className="admin-filter-box">
                <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
                  <h4 className="m-0"> Filter by </h4>
                </div>
                <div className="row">
                  <Form.Group className="col-md-4 mb-4">
                    <Form.Label>Job</Form.Label>
                    <Form.Control
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="btn-box  border-top mt-3 pt-3">
                  <button
                    className="success"
                    onClick={handleSearch}
                  >
                    Apply
                  </button>
                  <button className="close" onClick={() => setOpen(!open)}>
                    Cancel
                  </button>
                </div>
              </div>
            </Collapse>
            <DataTable columns={tableHeader} data={questionlist} pagination customStyles={CustomTableStyle} />            
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FeedbackQuestions;
