import React from "react";
import ThankyouPage from "../ThankyouPage";

function Instructions() {
  return (
    <div>
      <ThankyouPage />
    </div>
  );
}

export default Instructions;
