import React, { useState, useEffect } from 'react';
import { site_ip } from '../../../globalSettings';
// import { Grid, Button } from '@material-ui/core';
import FormDialog from './topicDialog';
// import { TextField } from '@material-ui/core';
// import MenuItem from '@material-ui/core/MenuItem';

import { MenuItem, TextField, Grid, Button } from '@mui/material';
import axios from 'axios';
const initialValue = {
  topic_id: '',
  topic_name: '',
  topic_desc: '',
  subject_id: '0',
};
function Topic() {
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState(initialValue);

  const [filterSubjectList, setFilterSubjectList] = useState([]);
  const [filter_id, setFilterID] = useState('0');

  useEffect(() => {
    const loadSubjects = async () => {
      axios({
        url: site_ip + '/getSubjectList',
      }).then(function (response) {
        setFilterSubjectList(response.data.content);
      });
    };
    loadSubjects();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initialValue);
  };

  const columnDefs = [
    { headerName: 'Topic Id', field: 'topic_id' },
    { headerName: 'Topic Name', field: 'topic_name' },
    { headerName: 'Topic Description', field: 'topic_desc' },
    { headerName: 'Subject', field: 'subject_name' },
    {
      headerName: 'Actions',
      field: 'topic_id',
      cellRendererFramework: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleUpdate(params.data)}
          >
            Update
          </Button>
          {/* <Button variant="outlined" color="secondary" onClick={() => handleDelete(params.value)}>Delete</Button> */}
        </div>
      ),
    },
  ];

  const onFilterChange = (e) => {
    setFilterID(e.target.value);
    getTopics(e.target.value);
  };

  //fetching data from server
  const getTopics = (id) => {
    const url = site_ip + `/getSubjectTopics/` + id;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => setTableData(resp.Student));
  };

  const onChange = (e, tagid) => {
    if (tagid === undefined) {
      const { value, id } = e.target;
      setFormData({ ...formData, [id]: value });
    } else {
      const { value } = e.target;
      setFormData({ ...formData, [tagid]: value });
    }
  };
  const onGridReady = (params) => {
    setGridApi(params);
    console.log(gridApi);
  };

  // setting update row data to form data and opening pop up window
  const handleUpdate = (oldData) => {
    setFormData(oldData);
    handleClickOpen();
  };
  //deleting a user
  // const handleDelete = (id) => {
  //   const confirm = window.confirm("Are you sure, you want to delete this row", id)
  //   if (confirm) {
  //     fetch(site_ip + "/getSubjectTopics/"+ id, { method: "DELETE" }).then(resp => resp.json()).then(resp => getUsers())

  //   }
  // }
  const handleFormSubmit = () => {
    if (formData.topic_id !== '') {
      if (
        formData.topic_name === '' ||
        formData.topic_desc === '' ||
        formData.subject_id === '0'
      ) {
        alert('Please fill all field');
      } else {
        const confirm = window.confirm(
          'Are you sure, you want to update this row ?'
        );
        if (confirm) {
          fetch(site_ip + '/setSubjectTopics', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
              'content-type': 'application/json',
            },
          })
            .then((resp) => resp.json())
            .then((resp) => {
              handleClose();
              getTopics(filter_id);
            });
        }
      }
    } else {
      if (
        formData.topic_name === '' ||
        formData.topic_desc === '' ||
        formData.subject_id === '0'
      ) {
        alert('Please fill all field');
      } else {
        let addData = {
          // topic_id: formData.topic_id,
          topic_name: formData.topic_name,
          topic_desc: formData.topic_desc,
          subject_id: formData.subject_id,
        };
        // adding new user
        fetch(site_ip + '/addSubjectTopics', {
          method: 'POST',
          body: JSON.stringify(addData),
          headers: {
            'content-type': 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then((resp) => {
            handleClose();
            getTopics(filter_id);
          });
      }
    }
  };

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  return (
    <div className="App">
      <Grid align="left">
        <TextField
          select
          value={filter_id}
          onChange={(e) => onFilterChange(e)}
          margin="dense"
          // fullWidth
          variant="outlined"
        >
          <MenuItem key={0} value={0}>
            {'Select Subject'}
          </MenuItem>
          {filterSubjectList.map((option) => (
            <MenuItem key={option.subject_id} value={option.subject_id}>
              {option.subject_name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid align="right">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add Topic
        </Button>
      </Grid>
      <div className="ag-theme-alpine" style={{ height: '400px' }}>
        
      </div>
      <FormDialog
        open={open}
        handleClose={handleClose}
        data={formData}
        onChange={onChange}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
}
export default Topic;
