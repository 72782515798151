import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link, useNavigate } from 'react-router-dom';
import { site_ip } from '../../../globalSetting';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../../customTableStyle';

function ManageInterviewerList(props) {
  const navigate = useNavigate();
  const userdata = JSON.parse(localStorage.getItem('userdata'))?.Content[0];
  const [interviewerList, setInterviewerList] = useState([]);
  useEffect(() => {
    fetchInterviewerList(userdata.company_id);
    //eslint-disable-next-line
  }, []);

  const fetchInterviewerList = async (company_id) => {
    const result = await axios.get(
      `${site_ip}/getCompanyIVPanel/${company_id === null ? 1 : company_id}`
    );
    setInterviewerList(result.data.Data);
  };

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Interviewer Name',
      selector: (row) => row?.interviewer_name,
    },
    // {
    //   name: 'Company Name',
    //   selector: (row) => row?.company_name,
    // },
    {
      name: 'Job Name',
      selector: (row) => row?.job_name,
    },
    // {
    //   name: 'Action',
    //   width: "150px",
    //   cell: (row, index) => (
    //     <>
    //       <button
    //         className="btn btn-sm circle"
    //         role="button"
    //         style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
    //         onClick={() => navigate("/recruiter/edit-interviewer-list/"+row.interviewer_id)}
    //       >
    //         <i class="fas fa-pencil-alt"></i>
    //       </button>
    //     </>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ]

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  View Interview Panel</p>
        </Col>
        <Col md={6} className="text-end">
          <Button
            className='admin-action-btns'
            onClick={() => navigate(`/recruiter/add-interviewer-list`)}
          >
            <i class="fas fa-plus icon"></i> Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <DataTable columns={tableHeader} data={interviewerList} pagination customStyles={CustomTableStyle} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ManageInterviewerList;
