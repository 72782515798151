import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../customTableStyle';

export default function JDList(props) {
  const navigate = useNavigate();
  const blankSearch = {
    jd_title:"",
    date_from: '',
    date_to: '',
  }
  const [search, setSearch] = useState(blankSearch);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const [jds, setJds] = useState([]);
  const getAllJobDescriptions = () => {
    axios
      .get(site_ip + '/getAllJobDescriptions')
      .then((res) => {
        setJds(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllJobDescriptions();
  }, []);

  const handleSearch = () => {
    axios
      .post(site_ip + '/searchJobDescription', search)
      .then((res) => {
        setJds(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const handleReset = () => {
    setSearch(blankSearch)
    axios
      .post(site_ip + '/searchJobDescription', blankSearch)
      .then((res) => {
        setJds(res.data.Data);
      })
      .catch((err) => console.log(err));
  };


  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"100px"
    },
    {
      name: 'Title',
      selector: (row) => row?.jd_title,
    },
    {
      name: 'Experience (in years)',
      selector: (row) => row?.experience_range,
    },
    // {
    //   name: 'File Name',
    //   selector: (row) => attachmentName(row?.resume_file_name),
    // },
    // {
    //   name: 'Status',
    //   selector: (row) => row.resume_status === '1' ? 'Active' : 'Inactive',
    // },
    {
      name: 'Action',
      width: "150px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => navigate('/recruiter/job-description/edit-jd/' + row.jd_id)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
          {/* <Link to={{ pathname: `/edit-question/${value.ques_id}` }}>
                      <i className="menu-icon fas fa-edit"></i>
                    </Link> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <>
      <Row className="align-items-center">
        <Col md={2}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Job Description</p>
        </Col>
        <Col md={10} className="text-end">
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="jd_title"
                      value={search.jd_title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              <div className="btn-box  border-top mt-3 pt-3">
                  <button
                    className="success"
                    onClick={handleSearch}
                  >
                    Apply
                  </button>
                  <button className="close" onClick={handleReset}>
                    Reset
                  </button>
                </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable columns={tableHeader} data={jds} pagination customStyles={CustomTableStyle} />                
        </Col>
      </Row>
    </>
    // <Container>
    //   <Card>
    //     <Card.Header as="h4">Search Job Descriptions</Card.Header>
    //     <Card.Body>
    //       <Row>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Industry</Form.Label>
    //             <Form.Control
    //               as="select"
    //               name="industry_id"
    //               value={search.industry_id}
    //               onChange={handleChange}
    //             >
    //               <option value="">Select Industry</option>
    //               {industry?.map((item) => (
    //                 <option key={item.industry_id} value={item.industry_id}>
    //                   {item.industry_name}
    //                 </option>
    //               ))}
    //             </Form.Control>
    //           </Form.Group>
    //         </Col>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Job</Form.Label>
    //             <Form.Control
    //               as="select"
    //               name="job_id"
    //               value={search.job_id}
    //               onChange={handleChange}
    //             >
    //               <option value="">Select Job</option>
    //               {job?.map((item) => (
    //                 <option key={item.job_id} value={item.job_id}>
    //                   {item.job_name}
    //                 </option>
    //               ))}
    //             </Form.Control>
    //           </Form.Group>
    //         </Col>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Date From</Form.Label>
    //             <Form.Control
    //               type="date"
    //               name="date_from"
    //               value={search.date_from}
    //               onChange={handleChange}
    //             />
    //           </Form.Group>
    //         </Col>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Date To</Form.Label>
    //             <Form.Control
    //               type="date"
    //               name="date_to"
    //               value={search.date_to}
    //               onChange={handleChange}
    //             />
    //           </Form.Group>
    //         </Col>
    //         <Col md={4}></Col>
    //         <Col md={4}>
    //           <Button className="mt-2 mb-0 m-3" onClick={handleSearch}>
    //             Search
    //           </Button>
    //           <Button
    //             variant="warning"
    //             className="mt-2"
    //             onClick={() =>
    //               setSearch({
    //                 industry_id: '',
    //                 job_id: '',
    //                 date_from: '',
    //                 date_to: '',
    //               })
    //             }
    //           >
    //             Reset
    //           </Button>
    //         </Col>
    //         <Col md={4}></Col>
    //       </Row>
    //     </Card.Body>
    //   </Card>
    //   <br />
    //   <Card>
    //     <Card.Header as="h4">Job Description List</Card.Header>
    //     <Card.Body>
    //       <Table striped hover responsive>
    //         <thead>
    //           <tr>
    //             <th>ID</th>
    //             <th>JD File Name</th>
    //             <th>Industry</th> <th>Job</th>
    //             <th>Edit JD</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {jds.map((jd) => (
    //             <tr key={jd.jd_id}>
    //               <td>{jd.jd_id}</td>
    //               <td>{jd.jd_file_name}</td>
    //               <td>{jd.industry_id}</td> <td>{jd.job_id}</td>
    //               <td>
    //                 <Link to={'edit-jd/' + jd.jd_id}>Edit </Link>
    //               </td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </Table>
    //     </Card.Body>
    //   </Card>
    // </Container>
  );
}
