import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Login.css";
import { site_ip } from "../globalSettings.js";
import { Link } from "react-router-dom";
import axios from "axios";
function Signup() {
  const initialValues = {
    company_name: "",
    industry_id: "",
    company_add1: "",
    company_add2: "",
    company_city: "",
    company_url: "",
  };
  const [values, handleChange] = useState(initialValues);
  const [industry, getIndustry] = useState(null);
  const fetchIndustryData = () =>
    fetch(`${site_ip}/getIndustryList`).then((res) => res.json());
  useEffect(() => {
    fetchIndustryData().then((data) => getIndustry(data.content));
  }, []);
  const handleSubmit = () => {
    axios
      .post(site_ip + "/createCompanyUsers", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          values,
        }),
      })
      .then((res) => console.log(res.json()))
      .catch((err) => console.log("error"));
  };
  return (
    <>
      <Container fluid className="h-100 pt-5">
        <Row className="align-items-center h-100 justify-content-center bg-blue  pt-5">
          <Col md={8} className="mx-auto">
            <div className="border shadow w-75 mx-auto p-5 bg-white rounded-3">
              <Row>
                <Col md={6}>
                  <h2>Create Company</h2>
                </Col>
                <Col md={6} className="text-end">
                  <Link
                    to="/Signup"
                    className="otplogin btn btn-outline-dark rounded-0 mb-3 ml-5"
                  >
                    Signup Now
                  </Link>
                </Col>
              </Row>
              <Form className="row">
                <Form.Group className="mb-3 col-12" controlId="formBasicEmail">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    name="company_name"
                    value={values.company_name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
                  <Form.Label>Industry Name</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Industry Name"
                    name="industry_id"
                    value={values.industry_id}
                    onChange={handleChange}
                  >
                    <option>Select Industry</option>
                    {industry?.map((value) => (
                      <option value={value.industry_id}>
                        {value.industry_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
                  <Form.Label>Address 1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address 1"
                    name="company_add1"
                    value={values.company_add1}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address 2"
                    name="company_add2"
                    value={values.company_add2}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
                  <Form.Label>City Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City Name"
                    name="company_city"
                    value={values.company_city}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
                  <Form.Label>Comapany Url</Form.Label>
                  <Form.Control
                    text="text"
                    placeholder="Comapany Url"
                    name="company_url"
                    value={values.company_url}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="button" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Signup;
