import React,{useState, useEffect} from 'react';
import { Row, Col, Container, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './topbar.css';
// function TopBar() {
//   const userdata = JSON.parse(localStorage.getItem('userdata'));
//   return (
//     <div className="topband1 sticky-top">
//       <div className="logo-container" >
//         <img className="logo-img" style={{width:"230px"}} alt="top logo" src="../images/logo.png" class="top-logo" /> 
//       </div>
//       <div className="header-container">
//       <Container fluid>
//         <Row className="align-items-center">
//           <Col className="ms-auto text-end" md={4}>
//             <div class="userNameblock d-flex align-items-center">
//               <span class="ms-auto">
//                 {userdata.first_name + ' ' + userdata.last_name}
//               </span>
//               <span userpic>
//                 <Dropdown>
//                   <Dropdown.Toggle className="userpic" id="dropdown-basic">
//                     <i className="fas fa-user" id="dropdown-basic"></i>
//                   </Dropdown.Toggle>

//                   <Dropdown.Menu>
//                     <Dropdown.Item href="/profile">User Profile</Dropdown.Item>
//                     <Dropdown.Item href="#/action-2">
//                       Edit Profile
//                     </Dropdown.Item>
//                     <Dropdown.Item href="/update-password">
//                       Change Password
//                     </Dropdown.Item>
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </span>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       </div>
//     </div>
//   );
// }

const TopBar = () => {
  const userdata = JSON.parse(localStorage.getItem('userdata'));

  const navigate = useNavigate();

  const [ firstName, setFirstName]= useState('');
  const [ lastName, setLastName] = useState('');
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('quantam_admin_id')));


  useEffect(()=>{
    setUsers();
  })

  const setUsers =()=>{ 
    if(userdata){
      if(localStorage.getItem('quantam_admin_id')){
          setFirstName(userdata.user_name);
      }else{
        setFirstName(userdata.first_name);
        setLastName(userdata.last_name)
      }
    }
  }

  return (
    <div className="fix-header sticky" style={{position:'relative'}}>
      {/* <Container> */}
        <Row style={{ margin: "0px" }}>
          <Col md={3} style={{ padding: 0 }}>
            <img src="/home/logo.png" className="header-logo-img" style={{cursor:"pointer"}} onClick={() => navigate("/dashboard")} />
          </Col>
          <Col md={9}>
            <div className="navigation">
              {/* <ul> */}
                {/* <li> */}
                <div style={{display:"flex", alignItems:'center', justifyContent:'center', height:"80px"}}>
                {firstName &&   <span class="ms-auto">
                      {firstName + ' ' + lastName}
                    </span> }
                    <span userpic>
                      <Dropdown>
                        <Dropdown.Toggle className="userpic" id="dropdown-basic">
                          <i className="fas fa-user" id="dropdown-basic"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="/profile">User Profile</Dropdown.Item>
                          {/* <Dropdown.Item href="#/action-2">
                            Edit Profile
                          </Dropdown.Item> */}
                          <Dropdown.Item href="/update-password">
                            Change Password
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </div>
                {/* </li>
              </ul> */}
            </div>
          </Col>
        </Row>
      {/* </Container> */}
    </div>
  )
}

export default TopBar;
