import React, { useState, useEffect } from 'react';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import { TextField } from '@material-ui/core';
import axios from 'axios';
import './dialog.css';
// import MenuItem from '@material-ui/core/MenuItem';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText,DialogTitle, TextField, MenuItem } from '@mui/material';


import {} from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
export default function FormDialog({
  open,
  handleClose,
  data,
  onChange,
  handleFormSubmit,
}) {
  const {
    job_id,
    job_name,
    job_objectives,
    job_description,
    qualifications,
    industry_id,
    exp_range_from,
    exp_range_to,
  } = data;

  //const [selected_industry,setSelectedIndustry]=useState(0);

  const [industrieslist, setindustriesList] = useState([]);
  //  const [JobsList,setJobsList ]=useState([]);

  useEffect(() => {
    const loadIndustries = async () => {
      axios({
        url: site_ip + '/getIndustries',
      }).then(function (response) {
        console.log(response.data);

        setindustriesList(response.data.content);
      });
    };
    //console.log(setindustriesList)
    loadIndustries();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {job_id !== '' ? 'Update Job' : 'Create new job'}
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              id="job_name"
              value={job_name}
              onChange={(e) => onChange(e)}
              placeholder="Enter Job Name"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="job_objectives"
              value={job_objectives}
              onChange={(e) => onChange(e)}
              placeholder="Enter Job Objectives"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="job_description"
              value={job_description}
              onChange={(e) => onChange(e)}
              placeholder="Enter Job Description"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="qualifications"
              value={qualifications}
              onChange={(e) => onChange(e)}
              placeholder="Enter Qualifications"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="exp_range_from"
              value={exp_range_from}
              onChange={(e) => onChange(e)}
              placeholder="Enter Experience Range from"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="exp_range_to"
              value={exp_range_to}
              onChange={(e) => onChange(e)}
              placeholder="Enter Experience Range from"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />

            <TextField
              id="industry_id"
              select
              placeholder="Industry List"
              value={industry_id}
              onChange={(e) => onChange(e, 'industry_id')}
              margin="dense"
              fullWidth
              variant="outlined"
            >
              <MenuItem key={0} value={0}>
                {'Select Industry'}
              </MenuItem>
              {industrieslist.map((option) => (
                <MenuItem key={option.industry_id} value={option.industry_id}>
                  {option.industry_name}
                </MenuItem>
              ))}
            </TextField>
            <br />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => handleFormSubmit()}
            variant="contained"
          >
            {job_id !== '' ? 'Update' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
