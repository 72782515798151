import React from "react";
import ExamPage from "../ExamPage";

function Exam() {
  return (
    <div>
      <ExamPage />
    </div>
  );
}

export default Exam;
