import React, { useState, useEffect } from 'react';
import { Col, Row, Table} from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { site_ip } from '../../globalSetting';
import axios from 'axios';
function ViewQuestionType(props) {
  
  const [data, setData] = useState([]);

  useEffect(() =>{
    fetchData();
  }, [])
  
  const fetchData = async () => {
    const result = await axios.get(`${site_ip}/getQuestionType`);
    setData(result.data.content);
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Question Types</h4>
        </Col>
        <Col md={6} className="text-end">
          
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Question Type</th>
                </tr>
              </thead>
                <tbody>
                  {data.map((item, index) =>{
                      return(
                          <tr key={index}>
                                <th>{index + 1}</th>
                                <td>{item.question_type}</td>
                          </tr>
                      )
                  })}
                </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ViewQuestionType

