import React, { useState, useEffect, useRef } from 'react';
//import Aux from "../../../hoc/_Aux";
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { site_ip } from '../../../../globalSettings';
import Swal from 'sweetalert2';
import axios from 'axios';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useNavigate,useLocation } from 'react-router-dom';

export default function ExamQuestionAdd() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const navigate = useNavigate();
  const initialInputs = {
    company_id: userdata.company_id,
    taxonomy_id: '',
    skill_id: '',
    subject_id: '',
    tag_id: '',
    topic_id: '',
    co_job_id: '',
    marks: '',
    ques_type_id: '',
    ques_ans: [],
    ques_complexity_id: '',
    ques_desc: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    ques_ans_desc: "",
    explanation: "",
  };

 const savedFormData = JSON.parse(localStorage.getItem('examQuestionFormData')) || initialInputs;

  const [input, setInputs] = useState(savedFormData);


    useEffect(() => {
    localStorage.setItem('examQuestionFormData', JSON.stringify(input));
  }, [input]);

  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      ques_ans: [],
    }));
  }, [input.ques_type_id]);

  const handleChangeWithName = (name, value) =>{
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Check is it subject
    if (name === 'subject_id') {
      fetchTopics(value);
    }
  };

  const handleSubmit = () => {
    // Put some minimum validation
    if (input.subject_id === '') {
      alert('Please select subject');
    } else if (input.marks.trim() === '') {
      alert('Please enter marks for this question.');
    } else if (input.ques_type_id === '') {
      alert('Please select question type');
    } else if (
      (input.ques_type_id === '1' ||
        input.ques_type_id === '2' ||
        input.ques_type_id === '3') &&
      input.ques_ans.length === 0
    ) {
      alert('Please select correct answer(s)');
    } else {
      axios({
        method: 'post',
        url: site_ip + '/addQuestionBankCompany',
        data: {
          company_id: input.company_id,
          subject_id: input.subject_id,
          co_job_id: input.co_job_id,
          time_allowed: input.time_allowed,
          topic_id: input.topic_id,
          category_id: input.category,
          marks: input.marks,
          ques_desc: input.ques_desc,
          option1: input.option1,
          option2: input.option2,
          option3: input.option3,
          option4: input.option4,
          ques_ans_desc: input.ques_ans_desc,
          explanation: input.explanation,
          ques_passage: input.ques_passage,
          ques_type_id: input.ques_type_id,
          ques_ans:
            input.ques_ans.length === 0
              ? null
              : { ans: input.ques_ans.sort().toString() },
          ques_complexity_id: input.ques_complexity_id,
          taxonomy_id: input.taxonomy_id,
          skill_id: input.skill_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New question added successfully.',
              icon: 'success',
            });
            setInputs(initialInputs);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };
  const [subject, getSubject] = useState(null);
  const fetchSubjectData = () =>
    fetch(`${site_ip}/getSubjects`).then((res) => res.json());
  useEffect(() => {
    fetchSubjectData().then((data) => getSubject(data.Content));
  }, []);
  const [topic, setTopic] = useState([]);

  const fetchTopics = (subject) => {
    axios({
      method: 'GET',
      url: site_ip + '/getSubjectTopics/' + subject,
    })
      .then(function (response) {
        setTopic(response.data.Student);
        setInputs((prevState) => ({
          ...prevState,
          ['topic_id']: '',
        }));
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [job, getJob] = useState(null);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [skill, getSkill] = useState(null);
  const fetchSkillData = () =>
    fetch(`${site_ip}/getSkills`).then((res) => res.json());
  useEffect(() => {
    fetchSkillData().then((data) => getSkill(data.Content));
  }, []);
  const [complexity, getComplexity] = useState(null);
  const fetchComplexityData = () =>
    fetch(`${site_ip}/getQuestionComplexity`).then((res) => res.json());
  useEffect(() => {
    fetchComplexityData().then((data) => getComplexity(data.content));
  }, []);
  const [texonomy, getTexonomy] = useState(null);
  const fetchTexonomyData = () =>
    fetch(`${site_ip}/getTaxonomy`).then((res) => res.json());
  useEffect(() => {
    fetchTexonomyData().then((data) => getTexonomy(data.Content));
  }, []);
  const [questiontype, getQuestionType] = useState(null);
  const fetchQuestionTypeData = () =>
    fetch(`${site_ip}/getQuestionType`).then((res) => res.json());
  useEffect(() => {
    fetchQuestionTypeData().then((data) => getQuestionType(data.content));
  }, []);
  const [questiontag, getQuestionTag] = useState(null);
  const fetchQuestionTagData = () =>
    fetch(`${site_ip}/getQuestionTags`).then((res) => res.json());
  useEffect(() => {
    fetchQuestionTagData().then((data) => getQuestionTag(data.content));
  }, []);
  
  const handleCorrectAnswer = (e) => {
    if (input.ques_type_id === 1) {
      let options = [];
      for (let i = 0; i < e.target.selectedOptions.length; i++) {
        options.push(e.target.selectedOptions[i].attributes[0].value);
        console.log(options);
      }
      input.ques_ans = options;
    } else {
      input.ques_ans = [e.target.value];
    }
    setInputs(input);
  };

  const handleReset = () => {
        localStorage.removeItem('examQuestionFormData');
    setInputs(initialInputs);
  };

  const handleAddMore = () => {
    // Put some minimum validation
    if (input.subject_id === '') {
      alert('Please select subject');
    } else if (input.marks.trim() === '') {
      alert('Please enter marks for this question.');
    } else if (input.ques_type_id === '') {
      alert('Please select question type');
    } else if (
      (input.ques_type_id === '1' ||
        input.ques_type_id === '2' ||
        input.ques_type_id === '3') &&
      input.ques_ans.length === 0
    ) {
      alert('Please select correct answer(s)');
    } else {
      axios({
        method: 'post',
        url: site_ip + '/addQuestionBankCompany',
        data: {
          company_id: input.company_id,
          subject_id: input.subject_id,
          co_job_id: input.co_job_id,
          time_allowed: input.time_allowed,
          // company_id: input.company_id,
          topic_id: input.topic_id,
          category_id: input.category,
          marks: input.marks,
          ques_desc: input.ques_desc,
          option1: input.option1,
          option2: input.option2,
          option3: input.option3,
          option4: input.option4,
          ques_ans_desc: input.ques_ans_desc,
          explanation: input.explanation,
          ques_passage: input.ques_passage,
          ques_type_id: input.ques_type_id,
          ques_ans:
            input.ques_ans.length === 0
              ? null
              : { ans: input.ques_ans.sort().toString() },
          ques_complexity_id: input.ques_complexity_id,
          taxonomy_id: input.taxonomy_id,
          skill_id: input.skill_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New question added successfully.',
              icon: 'success',
            });
          }
        })
        .catch(function (error) {
            if (error.response && error.response.status === 400) {
                // Handle 400 Bad Request due to duplicate question
                Swal.fire({
                    title: 'Error',
                    text: 'Duplicate question found. This question already exists.',
                    icon: 'error',
                });
            } else {
                // Handle other errors
                console.error('Error: ' + error);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred while adding the question.',
                    icon: 'error',
                });
            }
        });
    }
  };

  const handlePreview =()=>{
    const data= {
          company_id: input.company_id,
          subject_id: input.subject_id,
          co_job_id: input.co_job_id,
          time_allowed: input.time_allowed,
          // company_id: input.company_id,
          topic_id: input.topic_id,
          category_id: input.category,
          marks: input.marks,
          ques_desc: input.ques_desc,
          option1: input.option1,
          option2: input.option2,
          option3: input.option3,
          option4: input.option4,
          ques_ans_desc: input.ques_ans_desc,
          explanation: input.explanation,
          ques_passage: input.ques_passage,
          ques_type_id: input.ques_type_id,
          ques_ans:
            input.ques_ans.length === 0
              ? null
              : { ans: input.ques_ans.sort().toString() },
          ques_complexity_id: input.ques_complexity_id,
          taxonomy_id: input.taxonomy_id,
          skill_id: input.skill_id,
        }

        navigate('/exam-question-preview',{state:{data}})
      
  }

  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Add Question</h4>
          </div>
          {/* <Form className="p-3 border bg-white"> */}
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Job</Form.Label>
                  <Form.Control
                    name="co_job_id"
                    value={input.co_job_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Job</option>
                    {job?.map((value, index) => (
                      <option key={index} value={value.job_id}>{value.job_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    name="subject_id"
                    value={input.subject_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Subject</option>
                    {subject?.map((value, index) => (
                      <option key={index} value={value.subject_id}>
                        {value.subject_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Topic</Form.Label>
                  <Form.Control
                    name="topic_id"
                    value={input.topic_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Topic</option>
                    {topic.map((value, index) => (
                      <option key={index} value={value.topic_id}>{value.topic_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Skill</Form.Label>
                  <Form.Control
                    name="skill_id"
                    value={input.skill_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Skill</option>
                    {skill?.map((value, index) => (
                      <option key={index} value={value.skill_id}>{value.skill_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Complexity Level</Form.Label>
                  <Form.Control
                    name="ques_complexity_id"
                    value={input.ques_complexity_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Complexity Level</option>
                    {complexity?.map((value, index) => (
                      <option key={index} value={value.id}>{value.complexity_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Question Taxonomy</Form.Label>
                  <Form.Control
                    name="taxonomy_id"
                    value={input.taxonomy_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Question Taxonomy</option>
                    {texonomy?.map((value, index) => (
                      <option key={index} value={value.taxonomy_id}>
                        {value.taxonomy}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Question Type</Form.Label>
                  <Form.Control
                    name="ques_type_id"
                    value={input.ques_type_id}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    as="select"
                  >
                    <option value="">Select Question Type</option>
                    {questiontype?.map((value, index) => (
                      <option key={index} value={value.id}>{value.question_type}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Add Tags</Form.Label>
                  <Form.Control
                    name="tag_id"
                    value={input.tag_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">Select Add Tags</option>
                    {questiontag?.map((value, index) => (
                      <option key={index} value={value.id}>{value.tag_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Marks</Form.Label>
                  <Form.Control
                    name="marks"
                    value={input.marks}
                    onChange={handleChange}
                    type="number"
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Question</Form.Label>
                  <CKEditor
                    editor={Editor}
                    data={input.ques_desc}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChangeWithName('ques_desc', data)
                    }}
                  />
                </Form.Group>
              </Col>
              {input.ques_type_id === '1' || input.ques_type_id === '2' ? (
                <Col md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>Option 1</Form.Label>
                    <CKEditor
                      editor={Editor}
                      data={input.option1}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChangeWithName('option1', data)
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mt-4">
                    <Form.Label>Option 3</Form.Label>
                    <CKEditor
                      editor={Editor}
                      data={input.option3}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChangeWithName('option3', data)
                      }}
                    />
                  </Form.Group>
                </Col>
              ) : null}
              {input.ques_type_id === '1' || input.ques_type_id === '2' ? (
                <Col md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>Option 2</Form.Label>
                    <CKEditor
                      editor={Editor}
                      data={input.option2}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChangeWithName('option2', data)
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mt-4">
                    <Form.Label>Option 4</Form.Label>
                    <CKEditor
                      editor={Editor}
                      data={input.option4}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChangeWithName('option4', data)
                      }}
                    />
                  </Form.Group>
                </Col>
              ) : null}
              {input.ques_type_id === '1' ||
                input.ques_type_id === '2' ||
                input.ques_type_id === '3' ? (
                <Col md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>Correct Answer</Form.Label>
                    {input.ques_type_id === '1' ? (
                      <Form.Control
                        name="ques_ans"
                        // value={input.ques_ans.ans}
                        onChange={(e) => handleCorrectAnswer(e)}
                        as="select"
                        multiple
                      >
                        {/* <option value="">Select Correct Answer</option> */}
                        <option value="1">Option 1</option>
                        <option value="2">Option 2</option>
                        <option value="3">Option 3</option>
                        <option value="4">Option 4</option>
                      </Form.Control>
                    ) : null}
                    {input.ques_type_id === '2' ? (
                      <Form.Control
                        name="ques_ans"
                        value={input.ques_ans.ans}
                        onChange={(e) => handleCorrectAnswer(e)}
                        as="select"
                      >
                        <option value="">Select Correct Answer</option>
                        <option value="1">Option 1</option>
                        <option value="2">Option 2</option>
                        <option value="3">Option 3</option>
                        <option value="4">Option 4</option>
                      </Form.Control>
                    ) : null}
                    {input.ques_type_id === '3' ? (
                      <Form.Control
                        name="ques_ans"
                        value={input.ques_ans.ans}
                        onChange={(e) => handleCorrectAnswer(e)}
                        as="select"
                      >
                        <option value="">Select Correct Answer</option>
                        <option value="1">True</option>
                        <option value="2">False</option>
                      </Form.Control>
                    ) : null}
                  </Form.Group>
                </Col>
              ) : null}
              <Col md={6} className="mb-4"></Col>
              <Col md={12}>
                {input.ques_type_id === '4' || input.ques_type_id === '5' ? (
                  <Form.Group>
                    <Form.Label>Answer</Form.Label>
                    <CKEditor
                      editor={Editor}
                      data={input.ques_ans_desc}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChangeWithName('ques_ans_desc', data)
                      }}
                    />
                  </Form.Group>
                ) : null}
              </Col>

              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Explanation</Form.Label>
                  <CKEditor
                    editor={Editor}
                    data={input.explanation}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChangeWithName('explanation', data)
                    }}
                  />
                </Form.Group>
              </Col>
              <br />
              <Col md={12} style={{ marginBottom: "15px" }}>
                <button
                  className="primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  className="ternary"
                  onClick={handleAddMore}
                >
                  Save and Add More
                </button>
                <button
                  className="secondary"
                  onClick={handleReset}>
                  Reset
                </button>
                <button className='secondary' onClick={handlePreview} >
                  Preview
                </button>
              </Col>
            </Row>
          {/* </Form> */}
        </Col>
      </Row>
    </Container>
  );
}
