import React, { useState, useEffect } from 'react';
import { Button, Col, Tab, Tabs, Form, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSettings';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../customTableStyle';
import {useNavigate} from 'react-router-dom';


function MyTests (){
      const navigate = useNavigate();

    const [myTests, setMyTests] = useState([]);
    useEffect(()=>{
        fetchTestByUser();
    },[])

    const fetchTestByUser = (value) => {

        const data = localStorage.getItem('userdata');
        const userData = JSON.parse(data);
        console.log(userData,'UD')
        const company_id=userData?.company_id;
        console.log(company_id,'CID')
        if(company_id){
            axios.get(
                site_ip + '/getTestsByCompanyId/'+company_id
            ).then((res) => {
    
                console.log(res,'RES')
                setMyTests(res.data.Tests)
            }).catch((err) =>
                console.log(err)
            )
        } 
    }


    let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"100px"
    },
    {
      name: 'Test Name',
      selector: (row) => row?.test_name,
    },
    {
      name: 'Job Name',
      selector: (row) => row?.job_name,
    },
    {
      name: 'Question Count',
      selector: (row) => row?.questions_count,
    },
    {
      name: 'Complexity Level',
      selector: (row) => row?.complexity_level,
    },
    {
      name: 'Action',
      width: "150px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm "
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
             onClick={() => navigate('/test-edit/' + row.test_id)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]


   return (
    <>
      <div class="align-items-center row">
        <div class="col-md-6">
          {/* <h4>Search Test</h4> */}
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Tests</p>
        </div>
      </div>
      <Row>
        <Col>
          <div class="border bg-white p-3 h-100 mt-4">
            <DataTable columns={tableHeader} data={myTests} pagination customStyles={CustomTableStyle} />
            {/* <Tabs defaultActiveKey="upcoming" className="mb-3">
              <Tab eventKey="upcoming" title="UPCOMING">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Action</th>
                      <th>Test</th>
                      <th>Job Name</th>
                      <th>Questions Count</th>
                      <th>Complexity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testlist?.map((value, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={{ pathname: `/test-edit/${value.test_id}` }}
                          >
                            <i className="menu-icon fas fa-edit"></i>
                          </Link>
                        </td>
                        <td>{value.test_name}</td>
                        <td>{value.job_name}</td>
                        <td>{value.questions_count}</td>
                        <td>{value.complexity_level}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="completed" title="COMPLETED"></Tab>
            </Tabs> */}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MyTests;