import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
// import crypto from 'crypto';
// import Aux from "../../../../hoc/_Aux";
import { site_ip } from '../../globalSetting';

function ListReferral(props) {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const initialValues = {
    referral_id: '',
    student_id: userdata.id,
    exam_id: '',
    referral_name: '',
    referral_email: '',
    referral_phone: '',
    referral_code: '', //crypto.randomBytes(8).toString('hex'),
    updating: false,
  };

  const [add, setAdd] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [values, setValues] = useState(initialValues);
  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    fetchReferrals();
    // eslint-disable-next-line
  }, []);

  const fetchReferrals = () => {
    axios({
      method: 'get',
      url: site_ip + '/getReferrals',
      dataType: 'json',
    })
      .then(function (response) {
        setReferrals(response.data.Summary);
        // console.log(response);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const proceed = () => {
    if (values.referral_name.trim() === '') {
      alert('Please enter Name');
    } else if (!validateEmail(values.referral_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.referral_phone)) {
      alert('Please enter valid mobile number');
    } else if (values.referral_id === '') {
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/addReferral',
        data: {
          student_id: values.student_id,
          exam_id: values.exam_id,
          referral_name: values.referral_name.trim(),
          referral_email: values.referral_email,
          referral_phone: values.referral_phone,
          referral_code: values.referral_code,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Success === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Referral added successfully',
              icon: 'success',
            }).then((result) => {
              fetchReferrals();
            });
          } else {
            alert('Request Failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/setReferral',
        data: {
          id: values.referral_id,
          student_id: values.student_id,
          exam_id: values.exam_id,
          referral_name: values.referral_name.trim(),
          referral_email: values.referral_email,
          referral_phone: values.referral_phone,
          referral_code: values.referral_code,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Success === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Referral details successfully updated',
              icon: 'success',
            }).then((result) => {
              fetchReferrals();
            });
          } else {
            alert('Request Failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const editNow = (item) => {
    const newValues = {
      referral_id: item.id,
      student_id: item.student_id,
      exam_id: item.exam_id,
      referral_name: item.referral_name,
      referral_email: item.referral_email,
      referral_phone: item.referral_phone,
      referral_code: item.referral_code,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Referral List</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => handleShow()}
            aria-controls="example-collapse-text"
            aria-expanded={add}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                {referrals.map((referral, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link onClick={() => editNow(referral)} to="#">
                          <i class="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{referral.referral_name}</td>
                      <td>{referral.referral_email}</td>
                      <td>{referral.referral_phone}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update Referral' : 'Add Referral'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Exam </Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="exam_id"
                  value={values.exam_id}
                  onChange={handleChange}
                >
                  <option value="">Select Exam</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="referral_name"
                  value={values.referral_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  className="mb-3"
                  name="referral_email"
                  value={values.referral_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="number"
                  required
                  className="mb-3"
                  name="referral_phone"
                  value={values.referral_phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ListReferral;
