import React from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import Webcam from "react-webcam";

const videoConstraints = {
    width: 640,
    height: 360,
    facingMode: "user"
};

export async function dataUrlToFile(dataUrl, fileName) {

    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}


const WebcamCapture = (props) => {
    const webcamRef = React.useRef(null);
    const imageUploader = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);
    const capture = React.useCallback(
        async () => {
            const imageSrc = webcamRef.current.getScreenshot();
            let file = await dataUrlToFile(imageSrc, 'screenshot.png')
            props.selectedFile(file)
        },
        [webcamRef]
);

const handleFileChange = async (e) => {
    let file = e.target.files[0];
    props.selectedFile(file)
};

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "5px" }}>
                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => props.handleClose()}>
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <Webcam
                audio={false}
                height={180}
                ref={webcamRef}
                screenshotFormat="image/png"
                width={320}
                videoConstraints={videoConstraints}
            />
            <div style={{ display: "flex", marginTop: "5px" }}>
                <button className='primary-btn' style={{width:"100%"}} onClick={capture}>Capture</button>
            </div>
            <p style={{ textAlign: 'center', marginBottom:"5px", marginTop:"5px" }}>-- OR --</p>
            <div style={{ display: "flex", marginTop: "5px" }}>
                <button className='primary-btn' style={{width:"100%"}} onClick={() => imageUploader.current.click()}>Upload a picture</button>
                <input
                    className="edit_btn_photo"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={imageUploader}
                    style={{
                    display: 'none',
                    }}
                />
            </div>
        </div>
    );
};

export default WebcamCapture;