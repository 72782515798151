import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../Login.css';
import { site_ip } from '../../globalSettings';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { browserName } from 'react-device-detect';
import '../../homepage.css'
import Swal from 'sweetalert2';


function Login() {
  const navigate = useNavigate();
  const blankValues = {
    name: '',
    email: '',
    telephone: '',
    job_title: '',
    industry_id:''
};
const [values, setValues] = useState(blankValues)

const reviews = [
    { _id: 1, label: 'Corporate Clients', numbers: '300+' },
    { _id: 2, label: 'Skills Assessed', numbers: '25+' },
    { _id: 3, label: 'Students Assessed Annually', numbers: '2.5 million' },
    { _id: 4, label: 'Exams Annually', numbers: '400+' },
    { _id: 5, label: 'Assessments Annually', numbers: '80+' },
    { _id: 6, label: 'Countries Reached', numbers: '20+' },
    { _id: 7, label: 'Industries Assessed', numbers: '1000+' },
    { _id: 8, label: 'Academic Clients', numbers: '15+' },
];

const [formOpen, setFormOpen] = useState(false);

const handleChange = (e) =>{
    let {name, value} = e.target;
    setValues({...values, [name]:value})
    if(name === 'job_title'){
        setValues({...values, [name]:value, industry_id:''})
        fetchIndustryList(value)
    }else{
        setValues({...values, [name]:value})
    }
}

useEffect(()=>{
    fetchJobList()
    setTimeout(()=>{
        setFormOpen(true);
    }, 3000)
}, [])



const [jobList, setJobList] = useState([])
const fetchJobList = () => {
    axios.get(
        site_ip + '/getJobMaster'
    ).then((res) => {
        setJobList(res.data.Content)
    }).catch((err) =>
        console.log(err)
    )
}

const [industryList, setIndustryList] = useState([])
const fetchIndustryList = (value) => {
    axios.get(
        site_ip + '/getJobListByIndustryId/'+value
    ).then((res) => {
        setIndustryList(res.data.Content)
    }).catch((err) =>
        console.log(err)
    )
}
const handleSubmit = () => {

  axios({
    method: 'post',
    url: site_ip + '/auth/request',
    data: {
      company_name: values.company,
      company_user: values.name,
      company_email: values.email,
      company_mobile: values.mobile,
      company:values.company
    },
  })
    .then(function (response) {
      console.log(response, 'RES');
      Swal.fire({
        title: "Request sent. You will receive a response soon.",
        icon: "success",
      }).then(()=>{
        setValues(blankValues);
      })
    })
    .catch(function (error) {
      console.log(error);

      if (error.response) {
        if (error.response.status === 400) {
          Swal.fire({
            title: "Duplicate entry or unique constraint violation.",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "An error occurred while processing your request.",
            icon: "error",
          });
        }
      } else {
        Swal.fire({
          title: "Failed to connect to the server.",
          icon: "error",
        });
      }
    });
};

  return (
    <>
       <Container fluid className="height-header-minus">
        <Row className="height-header-minus bg-dark-blue">
          <Col md={3}></Col>
          <Col md={6} className="align-items-center justify-content-center flex pos-relative" style={{ flexDirection: "column" }}>
            <p className="text-center text-white heading-1">
              REGISTER
            </p>
            <div className="login-box-div">
              <Row style={{ marginBottom: "15px" }}>
                  <Col>
                    <p className='registerLabel'>Name <span className="required">*</span></p>
                    <Form.Control type="name" placeholder="Name" name="name" onChange={handleChange} value={values.name} />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "15px" }}>
                  <Col>
                    <p className='registerLabel'>Email <span className="required">*</span></p>
                    <Form.Control type="email" placeholder="Email" name="email" onChange={handleChange} value={values.email} />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "15px" }}>
                  <Col>
                    <p className='registerLabel'>Mobile <span className="required">*</span></p>
                    <Form.Control type="text" placeholder="Mobile" name="telephone" onChange={handleChange} value={values.telephone} />
                  </Col>
                </Row>
               
                <Row class="mt-2 ">
                  <Col>
                    <p className='registerLabel'>Company<span className="required">*</span></p>
                    <Form.Control  type="text" placeholder="Institution" name="company" value={values.company}  onChange={handleChange}/>
                  </Col>
                </Row>
                {/* <Row style={{ marginBottom: "15px" }}>
                  <Col>
                    <p className='registerLabel'>Job Profile <span className="required">*</span></p>
                    <Form.Control
                      name="job_title"
                      value={values.job_title}
                      onChange={handleChange}
                      as="select"
                    >
                      <option value="">--Select--</option>
                      {jobList?.map((value) => (
                        <option key={value.job_id} value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <div className="align-items-center justify-content-center flex mt-3" >
                      <button className='demo-button' onClick={() => handleSubmit()}>REGISTER</button>
                    </div>
                  </Col>
                </Row>
              </div>
          </Col>
          <Col md={3}></Col>
          <div className="fixed-whitebox-bottom"></div>
        </Row>
      </Container>
    </>
  );
}

export default Login;
