import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { site_ip } from '../../globalSettings';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

const CreateCompanyAdmin = () => {

    const {company_name,id} = useParams();
   const [formData, setFormData] = useState({
        user_id: '',
        user_name: '',
        role_id: 'Admin',
        user_email: '',
        mobile_number: '',
        password: '',
        updating: false,
        company_id: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formData,'Form Data',id,company_name)
        
            axios({
                method:'POST',
                url:site_ip+'/auth/createAdminUser',
                data:{
                    role_id,
                    user_name:formData.user_name,
                    user_email:formData.user_email,
                    password:formData.password,
                    mobile_number:formData.mobile_number,
                    company_id:Number(id)
                },
                dataType:'json'
            })
        .then(function (response) {
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Admin successfully created.',
              icon: 'success',
            }).then((result) => {
                console.log(result)

                navigate('/');
            });
          } else {
            alert('Admin Created!');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });

    };

    const {
        user_name,
        role_id,
        user_email,
        mobile_number,
        password,
        company_id,
    } = formData;

  const navigate=useNavigate();


  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  
  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (pass) => {
    if (pass.trim().length < 8) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="container mt-5">
                <h2>Create {company_name} Admin User</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="user_name">User Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="user_name"
                            name="user_name"
                            required
                            value={user_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="role_id">Role ID</label>
                        <select
                            className="form-control"
                            id="role_id"
                            name="role_id"
                            value={role_id}
                            onChange={handleInputChange}
                        >
                            <option value="Admin">Admin</option>
                            <option value="User">User</option>
                            <option value="Guest">Guest</option>
                        </select>
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="user_email">User Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="user_email"
                            required
                            name="user_email"
                            value={user_email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile_number">Mobile Number</label>
                        <input
                            type="tel"
                            className="form-control"
                            id="mobile_number"
                            required
                            name="mobile_number"
                            value={mobile_number}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            required
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                        />
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="company_id">Company ID</label>
                        <input
                            type="text"
                            className="form-control"
                            id="company_id"
                            name="company_id"
                            value={company_id}
                            onChange={handleInputChange}
                        />
                    </div> */}
                    <button type="submit" className="btn btn-primary btn-block" style={{}}>
                        Submit
                    </button>
                </form>
            </div>
    </>
  );
};

export default CreateCompanyAdmin;
