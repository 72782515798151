import React, { useState, useEffect,useRef } from 'react';
import { Col, Row, Container, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { site_ip,IMG_URL } from '../../../globalSettings';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


function CandidateResult(props) {
  const [result, setResult] = useState([]);
  const [questionsInfo, setQuestionsInfo] = useState([]);
  const [picture, setPicture] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const [candidateName, setCandidateName] = useState('');
  const [testName, setTestName] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  console.log(location,'LOCAtion')

  const { id } = useParams();
  // const apiEndPoint ='api.quantuminsight.in/uploads/saved-pictures/';

  useEffect(() => {
    candidateTestDetailById();
    fetchResult();
    fetchPictures();

    //eslint-disable-next-line
  }, []);

  const pdfContentRef = useRef(null);
  
  function removeHtmlTags(input) {
    const div = document.createElement('div');
    div.innerHTML = input;
    return div.textContent || div.innerText || '';
  }



  const generatePDF =async  () => {
    // const content = document.getElementById('pdf-content');

    // console.log(content,'PDF COntent')

    // if (content) {
    //   generatePdfFromHtml(content, `${id}.pdf`);
    // }

    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(11);

    const title = `Result of ${testName} for ${candidateName}`; //QA Daily Report / Current date
    const headers = [
      [
        ' #',
        'Description',
        'Options',
        'Correct Ans',
        'Candidate Response'
        
      ],
    ];
    // const data = countReport.map((elt, index) => [
    //   index + 1,
    //   elt.doctor,
    //   elt.logincount,
    //   elt?.loginmonth,
    //   elt.loginyear,
    // ]);

    const data = result.map((item,index)=>[
      index+1,
      removeHtmlTags(item.ques_desc),
      `${removeHtmlTags(item.option1)} , ${removeHtmlTags(item.option2)}, ${removeHtmlTags(item.option3)}, ${removeHtmlTags(item.option4)}`, 
      item.ques_ans.ans,
      item.cand_responses.ans,

    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);


    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
      });
    };


    const candidatePhoto = await loadImage(picture);
    const idCardPhoto = await loadImage(candidateId);

    const imgWidth = 150; // Adjust the image width as needed
    const imgHeight = (candidatePhoto.height / candidatePhoto.width) * imgWidth;
    doc.addImage(candidatePhoto, 'JPEG', marginLeft, 180, imgWidth, imgHeight);
    doc.addImage(idCardPhoto, 'JPEG', marginLeft + imgWidth + 20, 180, imgWidth, imgHeight);


    doc.save(`${id}_candidates.pdf`);
  };


  const fetchResult = async () => {
    await axios
      .get(site_ip + '/viewCandidateResult/' +id)
      .then((response) => {
        console.log(response);
        setResult(response.data.Summary);
        setQuestionsInfo(response.data.SummaryInfo);
        // setValues(response.data.Content);
      });
  };

  const candidateTestDetailById= async ()=>{
    await axios({
      method:'post',
      data:{candidate_id:id},
      url:`${site_ip}/candidateTestDetailById`
    }).then((res)=>{
      console.log(res,'RESP')
      setCandidateName(res.data.detail[0].candidate_name); // Set candidate name
      setTestName(res.data.detail[0].test_name);
    })
  }

  const fetchPictures = async()=>{
    await axios.get(site_ip+'/getCandidatePictures/'+id)
    .then((response)=>{
      console.log(response);

      setPicture(response.data.picture);
      setCandidateId(response.data.id)

    })
  }
  

  const renderText = (text) => {
    return ;
  };

  const checkResult = (item) => {
    // console.log(item.cand_responses.ans);
    if (item.ques_type_id !== 4 || item.ques_type_id !== 5) {
      if (item.cand_responses !== null && item.ques_ans !== null) {
        if (item.cand_responses.ans === item.ques_ans.ans) {
          return '#a6d8a8';
        } else {
          return '#ff9999';
        }
      }
    } else {
      return '#ffffff';
    }
  };

 const  htmlDecode=(input)=>{
    var e = document.createElement('div');
    e.innerHTML = input;
    console.log(e,'<<')
    return e;
  }
  
  const renderAnything = (text) => {
    let correct_obj = '';
    try {
      correct_obj = JSON.parse(text);
      console.log(correct_obj,'Correct Obj',text)

    } catch {
      correct_obj = '';
    }
    if (text) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    }
    return '';
  };

  return (
    <div id='pdf-content' ref={pdfContentRef}>
     <div className="row align-items-center">
      <div className="col">
        <Button
          variant="secondary"
          onClick={() => navigate('/main-login-analytics')}
        >
          Back
        </Button>
        <Button variant="primary" onClick={generatePDF} style={{float:'right',backgroundColor:'tomato',borderColor:'tomato'}}>
        Download PDF
      </Button>
      </div>
    </div>
    <br />
      <Card   >
     
        <Container>
        <Row>
      <Col style={{ backgroundColor: 'green', color: 'white' }}>{testName}</Col>
    </Row>

    <Row>
      <Col>Candidate Name </Col>
      <Col style={{ fontWeight:'900' }}>{candidateName}</Col>
    </Row>
        <div class="col-md-6">
         
           {/* Candidate Name */}
   
          {/* <h4>Result</h4> */}
        </div>
          <Row >
            <Col>Total Questions</Col>
            <Col>
              {(Number(questionsInfo[0]?.total_correct_answers) + Number(questionsInfo[0]?.total_incorrect_answers))}
            </Col>
          </Row>
          <Row >
            <Col>Correct</Col>
            <Col>{questionsInfo[0]?.total_correct_answers}</Col>
          </Row>
          <Row >
            <Col>Incorrect</Col>
            <Col>{questionsInfo[0]?.total_incorrect_answers}</Col>
          </Row>

          {/* <Row md={6}>
            <Col>Not Attempted</Col>
            <Col>{questionsInfo[0]?.total_skipped_questions}</Col>
          </Row> */}
          {/* <Row >
            <Col>Total Marks</Col>
            <Col>{questionsInfo[0]?.total_marks_gained}</Col>
          </Row> */}
          {/* <Row >
            <Col>Total Percentage</Col>
            <Col>{Number(questionsInfo[0]?.percentage_correct_answers)}%</Col>
          </Row> */}

  { questionsInfo[0] &&  <Row style={{fontWeight:'900'}}>
      <Col>Grade</Col>
      <Col>
      { 
            Number(questionsInfo[0].percentage_marks_gained)>80?'A':
        <>
          { Number(questionsInfo[0].percentage_marks_gained)<81 && Number(questionsInfo[0].percentage_marks_gained)>60 ? 'B':<>
            {Number(questionsInfo[0].percentage_marks_gained)<=60 && Number(questionsInfo[0].percentage_marks_gained)>=40? 'C':'F'}
          </>}
        </>
      }
      </Col>
    </Row> }
            <Row >
            <Col>Marks (%)</Col>
            <Col>{Number(questionsInfo[0]?.percentage_marks_gained)}%</Col>
          </Row>
          <Row style={{fontWeight:'900'}}>
            <Col>Exam Result</Col>
            <Col>{questionsInfo[0]?.percentage_marks_gained>=40?'PASS':'FAILED'}</Col>
          </Row>
          {/* <Row md={6}>
            <Col>Descriptive</Col>
            <Col>{0}</Col>
          </Row> */}

           <div className="col-md-5 d-flex  " >
        <div className='d-flex flex-lg-column p-2'>
          {picture ? <>
            <img
          src={picture}
          alt="Candidate"
          className="mr-2"
          width="150"
          height="150"
        />
        <label>Candidate Photo</label>
          </>:'No Photo'}
       
        </div>
       <div className='d-flex flex-lg-column p-2'>
        { candidateId ?
        <>
           <img 
          src={candidateId}
          alt="ID Card"
          width="150"
          height="150"
          // style={{ marginLeft: '10px' }}
        />
        <label>Photo with ID</label>
        </>
      :'No Photo with ID' }
       </div>
        
      </div>
        </Container>
      </Card>

     
      
      <Row>
        <Col>
          {result?.map((item, index) => {
            return (
              <div
                key={index}
                class="border p-3 mt-4"
                style={{ backgroundColor: checkResult(item) }}
              >
                <div>
                  <b>Question #{index + 1}</b>
                  <div
          dangerouslySetInnerHTML={{
            __html: item.ques_desc
          }}
        />
                </div>
                <div
                  style={{ borderTop: '1px solid #ccc', paddingTop: '10px' }}
                >
                  {item.ques_type_id === 4 || item.ques_type_id === 5 ? (
                    <div>
                      <p style={{ fontWeight: 'bold' }}>Answer</p>
                      {item.ques_ans_desc !== null
                        ? item.ques_ans_desc
                        : 'NO ANSWER'}
                    </div>
                  ) : null}
                  {item.ques_type_id === 1 || item.ques_type_id === 2 ? (
                    <div>
                      <Row>
                        <Col md={1}>1</Col>
                        <Col> <div
          dangerouslySetInnerHTML={{
            __html: item.option1  }}
        /></Col>
                      </Row>
                      <Row>
                        <Col md={1}>2</Col>
                        {/* <Col md={11}>{renderText(item.option2)}</Col> */}
                        <div className='col'
                          dangerouslySetInnerHTML={{
                            __html:item.option2
                          }}
                        />
                      </Row>
                      <Row>
                        <Col md={1}>3</Col>
                        {/* <Col md={11}>{renderText(item.option3)}</Col>
                         */}

                <div className='col'
                          dangerouslySetInnerHTML={{
                            __html:item.option3
                          }}
                        />
                      </Row>
                      <Row>
                        <Col md={1}>4</Col>
                        {/* <Col md={11}>{renderText(item.option4)}</Col> */}
                        <div className='col'
                          dangerouslySetInnerHTML={{
                            __html:item.option4
                          }}
                        />
                      </Row>
                    </div>
                  ) : null}
                  {item.ques_type_id === 3 ? (
                    <div>
                      <Row>
                        <Col md={1}>1</Col>
                        <Col md={11}>True</Col>
                      </Row>
                      <Row>
                        <Col md={1}>2</Col>
                        <Col md={11}>False</Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
                {item.ques_type_id === 4 || item.ques_type_id === 5 ? (
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                      marginTop: '10px',
                      paddingTop: '5px',
                    }}
                  >
                    <Row>
                      <Col md={8}>
                        <p style={{ fontWeight: 'bold' }}>
                          Candidate Response(s)
                        </p>
                        <p>{item.can_resp_desc_ans}</p>
                      </Col>
                      <Col md={4}>{/* Check question status */}</Col>
                    </Row>
                  </div>
                ) : null}
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
}

export default CandidateResult;
