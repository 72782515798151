import React, { useState } from 'react';
import FloatingLabel from 'react-bootstrap-floating-label';
import OtpInput from 'react-otp-input';
// import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { site_ip } from '../../globalSettings.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Exam.css';

// function simulateNetworkRequest() {
//   return new Promise((resolve) => setTimeout(resolve, 2000));
// }

function HomeSection() {
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [showResults, setShowResults] = React.useState(false);
  const [email, setEmail] = useState('');
  const [errroMsg, setErrorMsg] = useState('');
  // useEffect(() => {
  //   if (isLoading) {
  //     simulateNetworkRequest().then(() => {
  //       setLoading(false);
  //       setShowResults(true);
  //     });
  //   }
  // }, [isLoading]);

  const handleClick = (e) => {
    setLoading(true);
    handleChangeEmail();
  };

  //state = { otp: "" };
  var display = 'none';
  const handleChange = (otp) => {
    setOtp(otp);
    display = 'block';
  };
  const handleSubmit = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/verifyIVEmailOTP ',
      data: {
        interviewer_email: email,
        email_otp: otp,
      },
      dataType: 'json',
    })
      .then(function (response) {
        localStorage.setItem('interviewer', JSON.stringify(response.data.Data));
        navigate('/interviewer/list');
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  function hai(e) {
    setEmail(e.target.value);
    setErrorMsg('');
  }

  const handleChangeEmail = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/sendIVEmailOTP',

      data: {
        interviewer_email: email,
      },
      dataType: 'json',
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.Message === 200) {
          setLoading(false);
          setShowResults(true);
        } else if (response.data.Message === 400) {
          setErrorMsg(response.data.Content);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  return (
    <>
      <div>
        <div className="bg-candidate">
          {/* <div class="divL"> */}
          {/* <div class="loginLZone">
            <div class="logoBody">
              <img
                src="../images/quantum-logo.png"
                alt="quantum logo"
                width="250"
              />
            </div>
            <Image
              src="../images/assessment.jpg"
              className="bannerImg img-fluid"
            />
            <h6 className="login-screen-description-text">
              {' '}
              Talent Management - Enable you to make informed decisions.
            </h6>
            <h6 className="login-screen-description-text">
              {' '}
              We empower and enable you to achieve new heights and wish you all
              the very best.
            </h6>
          </div> */}
          {/* </div> */}
          <div>
            <div style={{ margin: '0px', maxWidth: '500px' }}>
              <div className="white-box">
                <h2 class="h2">Sign In</h2>
                <div class="align-items-center emailBox">
                  <div className="inputTextBox">
                    <FloatingLabel
                      label="Enter Your Email "
                      size="sm"
                      value={email}
                      onChange={hai}
                    />
                  </div>
                  <p style={{ color: '#ff0000', fontSize: '14px' }}>
                    {errroMsg}
                  </p>
                  <div
                    style={{ display: showResults ? 'block' : 'none' }}
                    className="otpBlock otpBox"
                  >
                    <label>Please Enter the OTP</label>
                    <OtpInput
                      value={otp}
                      className="otpT m-1"
                      inputStyle={{ width: '3.8em' }}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="row">
                    <div className="btnBox col-md-6">
                      {!showResults ? (
                        <Button
                          variant="primary"
                          className="submitBtnBox"
                          disabled={isLoading}
                          onClick={!isLoading ? handleClick : null}
                          size="lg"
                        >
                          {isLoading ? 'Sending…' : 'Send OTP'}{' '}
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          disabled={isLoading}
                          onClick={!isLoading ? handleClick : null}
                          size="lg"
                        >
                          {isLoading ? 'Sending…' : 'ReSend OTP'}
                        </Button>
                      )}
                    </div>
                    <div
                      className="btnBox col-md-6"
                      style={{ display: showResults ? 'block' : 'none' }}
                    >
                      {' '}
                      <Button
                        className="btn btn-primary mt-3 ps-4 otpSubtBtn"
                        onClick={handleSubmit}
                      >
                        {' '}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="logo-div">
                  <img
                    style={{ marginTop: '80px' }}
                    alt="corepeelers"
                    src="../images/corepeelers.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeSection;
