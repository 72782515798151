import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
import axios from 'axios';
import Swal from 'sweetalert2';
function ManageInterviewer(props) {
  const initialValues = {
    interviewer_id: '',
    company_id: '',
    interviewer_name: '',
    interviewer_email: '',
    mobile_number: '',
    internal_external_ind: '',
    expertise_in: '',
    updating: false,
  };

  const [companyList, setCompanyList] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);
  const [add, setAdd] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    fetchCompanyList();
    console.log('HIT')
    // fetchInterviewerList();
  }, []);

  const fetchCompanyList = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompanyList(result.data.Content);
  };

  const fetchInterviewerList = async () => {
    if (filterValue !== '') {
      const result = await axios.get(
        `${site_ip}/getInterviewer/${filterValue}`
      );
      console.log(result);
      setInterviewerList(result.data.Content);
    } else {
      setInterviewerList([]);
    }
  };

  const editNow = (item) => {
    const newValues = {
      interviewer_id: item.interviewer_id,
      company_id: item.company_id,
      interviewer_name: item.interviewer_name,
      interviewer_email: item.interviewer_email,
      mobile_number: item.mobile_number,
      internal_external_ind: item.internal_external_ind,
      expertise_in: item.expertise_in,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const proceed = () => {
    let interviewer_name = values.interviewer_name;
    if (interviewer_name.trim() === '') {
      alert('Please enter Interviewer name');
    } else if (values.interviewer_id === '') {
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/addInterviewer',
        data: {
          company_id: values.company_id,
          interviewer_name: values.interviewer_name.trim(),
          interviewer_email: values.interviewer_email,
          mobile_number: values.mobile_number,
          internal_external_ind: values.internal_external_ind,
          expertise_in: values.expertise_in,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Interviewer added successfully',
              icon: 'success',
            }).then((result) => {
              fetchInterviewerList();
            });
          } else {
            alert('Request failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/setInterviewer',
        data: {
          interviewer_id: values.interviewer_id,
          company_id: values.company_id,
          interviewer_name: values.interviewer_name.trim(),
          interviewer_email: values.interviewer_email,
          mobile_number: values.mobile_number,
          internal_external_ind: values.internal_external_ind,
          expertise_in: values.expertise_in,
        },
        dataType: 'json',
      })
        .then(function (response) {
          //   console.log(response);
          if (response.data.Message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Interviewer details successfully updated',
              icon: 'success',
            }).then((result) => {
              fetchInterviewerList();
            });
          } else {
            alert('Request failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Manage Interviewer</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => handleShow()}
            aria-controls="example-collapse-text"
            aria-expanded={add}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Control
              as="select"
              className="mb-3"
              name="filter"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
            >
              <option value="">Select Company</option>
              {companyList.map((value) => (
                <option key={value.company_id} value={value.company_id}>
                  {value.company_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Button variant="primary" onClick={fetchInterviewerList}>
            Search
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Interviewer Name</th>
                  <th> Email</th>
                  <th>Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                {interviewerList?.map((interviewer, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link onClick={() => editNow(interviewer)} to="#">
                          <i class="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{interviewer.interviewer_name}</td>
                      <td>{interviewer.interviewer_email}</td>
                      <td>{interviewer.mobile_number}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update Interviewer' : 'Add Interviewer'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Interviewer Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="interviewer_name"
                  value={values.interviewer_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} style={{ marginTop: '15px' }}>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="company_id"
                  value={values.company_id}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Company</option>
                  {companyList.map((value) => (
                    <option key={value.company_id} value={value.company_id}>
                      {value.company_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Interviewer Email</Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="interviewer_email"
                  value={values.interviewer_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  required
                  className="mb-3"
                  name="mobile_number"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Internal/External</Form.Label>
                <Form.Control
                  as="select"
                  required
                  className="mb-3"
                  name="internal_external_ind"
                  value={values.internal_external_ind}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="I">Internal</option>
                  <option value="E">External</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Expertise In</Form.Label>
                <Form.Control
                  type="text"
                  required
                  className="mb-3"
                  name="expertise_in"
                  value={values.expertise_in}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ManageInterviewer;
