import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../customTableStyle';
import { useNavigate } from 'react-router-dom';
export default function ResumeList(props) {
  const navigate = useNavigate();
  const blankSearch = {
    industry_id: '',
    skill_id: '',
    job_id: '',
    resume_status: '',
    date_from: '',
    date_to: '',
  }
  const [search, setSearch] = useState(blankSearch);
  const [optionPopup, setOptionPopup] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [industry, setIndustry] = useState([]);
  const [skill, setSkill] = useState([]);
  const [job, setJob] = useState([]);
  const getIndustry = () => {
    axios
      .get(site_ip + '/getIndustryList')
      .then((res) => {
        // console.log(res)
        setIndustry(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getSkills = () => {
    axios
      .get(site_ip + '/getSkills')
      .then((res) => {
        // console.log(res)
        setSkill(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getJobs = () => {
    axios
      .get(site_ip + '/getJobMaster')
      .then((res) => {
        // console.log(res);
        setJob(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const [resumes, setResumes] = useState([]);
  const getAllResumes = () => {
    axios
      .get(site_ip + '/getAllResumes')
      .then((res) => {
        // console.log(res);
        setResumes(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getIndustry();
    getSkills();
    getAllResumes();
    getJobs();
  }, []);

  const handleSearch = () => {
    axios
      .post(site_ip + '/searchResumes', search)
      .then((res) => {
        // console.log(res);
        setResumes(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const handleReset = () => {
    setSearch(blankSearch)
    axios
      .post(site_ip + '/searchResumes', blankSearch)
      .then((res) => {
        // console.log(res);
        setResumes(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const attachmentName = (url) => {
    let url_sections = url.split("/");
    if (url_sections.length > 0) {
      return url_sections[url_sections.length - 1]
    } else {
      return ""
    }
  }

  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Candidate Name',
      selector: (row) => row?.candidate_name,
    },
    {
      name: 'Candidate Email',
      selector: (row) => row?.candidate_email,
    },
    {
      name: 'Candidate Mobile',
      selector: (row) => row?.candidate_mobile,
    },
    {
      name: 'File Name',
      selector: (row) => attachmentName(row?.resume_file_name),
    },
    {
      name: 'Status',
      selector: (row) => row.resume_status === '1' ? 'Active' : 'Inactive',
    },
    {
      name: 'Action',
      width: "150px",
      cell: (row, index) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => navigate('/recruiter/resumes/edit-resume/' + row.resume_id)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#33adff', color: "#ffffff", marginLeft: "10px" }}
            onClick={() => setOptionPopup(!optionPopup)}
          >
            <i class="fas fa-cog"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]



  return (
    <>
      <Row className="align-items-center">
        <Col md={2}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Resumes</p>
        </Col>
        <Col md={10} className="text-end">
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <div className="widget-title d-flex align-items-center mb-3 pl-5 ">
                <h4 className="m-0"> Filter by </h4>
              </div>
              <div className="row">
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        as="select"
                        name="industry_id"
                        value={search.industry_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Industry</option>
                        {industry?.map((item) => (
                          <option key={item.industry_id} value={item.industry_id}>
                            {item.industry_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Skill</Form.Label>
                      <Form.Control
                        as="select"
                        name="skill_id"
                        value={search.skill_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Skill</option>
                        {skill?.map((item) => (
                          <option key={item.skill_id} value={item.skill_id}>
                            {item.skill_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Job</Form.Label>
                      <Form.Control
                        as="select"
                        name="job_id"
                        value={search.job_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Job</option>
                        {job?.map((item) => (
                          <option key={item.job_id} value={item.job_id}>
                            {item.job_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Resume Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="resume_status"
                        value={search.resume_status}
                        onChange={handleChange}
                      >
                        <option value="">Select Status</option>
                        <option value="1">New </option>
                        <option value="2">Shortlisted</option>
                        <option value="3">Selected</option>
                        <option value="4">Rejected</option>
                        <option value="5">Not Suitable</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Date From</Form.Label>
                      <Form.Control
                        type="date"
                        name="date_from"
                        value={search.date_from}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Date To</Form.Label>
                      <Form.Control
                        type="date"
                        name="date_to"
                        value={search.date_to}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="btn-box  border-top mt-3 pt-3">
                  <button
                    className="success"
                    onClick={handleSearch}
                  >
                    Apply
                  </button>
                  <button className="close" onClick={handleReset}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable columns={tableHeader} data={resumes} pagination customStyles={CustomTableStyle} />
        </Col>
      </Row>
      <Modal size={"sm"} show={optionPopup} onHide={() => setOptionPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{ color: "#000000", fontSize:"18px" }}>Tasks</span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:"0", overflow:"hidden", borderRadius:"10px"}}>
          <button className="modal-option-button">
            <i class="fas fa-user-plus" style={{marginRight:"10px"}}></i>
            Make a Candidate
          </button>
          <button className="modal-option-button" >
           <i class="fas fa-download" style={{marginRight:"15px"}}></i>
            View/Download
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
