import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
// import Swal from 'sweetalert2';
// import ReactPaginate from 'react-paginate';
function CompanyUsers(props) {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  console.log('USERS')

  // const initialValues = {
  //   user_id: '',
  //   user_name: '',
  //   role_id: '',
  //   user_email: '',
  //   mobile_number: '',
  //   password: '',
  //   updating: false,
  // };

  // const initialSearch = {
  //   user_name: '',
  //   user_email: '',
  //   role_id: '',
  //   company_id: userdata.company_id,
  // };

  //   const [currentPage, setCurrentPage] = useState(0);
  //   const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [users, setUsersList] = useState([]);

  // const [values, setValues] = useState(initialValues);
  // const [search, setSearch] = useState(initialSearch);
  const [company, setCompany] = useState([]);
  console.log(roles, company);
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setValues((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleChange1 = (e) => {
  //   const { name, value } = e.target;
  //   setSearch((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  useEffect(() => {
    fetchUsers();
    fetchAdminRoles();
    fetchCompanyData();
    // eslint-disable-next-line
  }, []);
  const fetchCompanyData = async () => {
    const result = await axios.get(`${site_ip}/getCompanyList`);
    setCompany(result.data.Content);
    // console.log(result);
  };

  const fetchAdminRoles = async () => {
    const result = await axios.get(`${site_ip}/auth/getAdminRoles`);
    setRoles(result.data.Content);
  };

  const fetchUsers = () => {
    axios({
      method: 'get',
      url: site_ip + '/auth/getCompanyUsers/' + userdata.company_id,
      dataType: 'json',
    })
      .then(function (response) {
        setUsersList(response.data.Students);
        console.log(response);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  // const resetFilters = () => {
  //   setSearch(initialSearch);
  //   axios({
  //     method: 'POST',
  //     url: site_ip + '/auth/getCompanyUsers',
  //     dataType: 'json',
  //   })
  //     .then(function (response) {
  //       setUsersList(response.data.Students);
  //       //   console.log(response)
  //     })
  //     .catch(function (error) {
  //       console.log('error' + error);
  //     });
  // };

  const editNow = (user) => {
    navigate(`/add-company-users/${user.user_id}`);
  };

  // function handlePageClick({ selected: selectedPage }) {
  //       setCurrentPage(selectedPage);
  // }

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Company Users</p>
        </Col>
        <Col md={6} className="text-end">
          <Button
            className='admin-action-btns'
            onClick={() => navigate('/add-company-users')}
          >
            <i class="fas fa-plus icon"></i> Add Company User
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <Col md={12} style={{ marginTop: '15px' }}>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={3}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    className="mb-3"
                    name="role_id"
                    value={search.role_id}
                    onChange={handleChange1}
                  >
                    <option value="">Select Role</option>
                    {roles.map((value) => (
                      <option key={value.role_id} value={value.role_id}>
                        {value.role_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="user_name"
                    value={search.user_name}
                    onChange={handleChange1}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="user_email"
                    value={search.user_email}
                    onChange={handleChange1}
                  />
                </Form.Group>
              </Col>
              <Col md={5}></Col>
              <Col md={7}>
                <Button variant="primary" onClick={fetchUsers}>
                  Search
                </Button>
                <Button
                  variant="secondary"
                  onClick={resetFilters}
                  style={{ marginLeft: '15px' }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link onClick={() => editNow(user)} to="#">
                          <i className="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.user_email}</td>
                      <td>{user.user_mobile}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {/* <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={Math.ceil(jobsList.length / 10)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            /> */}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default CompanyUsers;
