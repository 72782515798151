import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form} from 'react-bootstrap';
import { site_ip } from '../../../globalSetting';
import moment from 'moment';
import ErrorModal from '../../common/ErrorModal';
import SuccessModal from '../../common/SuccessModal';
import { useNavigate } from 'react-router-dom';
export default function AddResumes() {
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState("");
  const [selectedResumes, setSelectedResumes] = useState([]);

  const blankFilter = {
    job_id:"",
    skill_id:"",
    resume_status:"",
    experience_range:""
  }

  const [filter, setFilter] = useState(blankFilter);
  const handleFilterChange = (e) =>{
    let {name, value} = e.target;
    setFilter({...filter, [name]:value})
    getAllResumes({...filter, [name]:value})
  }

  const handleCheckBox = (id) =>{
    let selected_resumes = [...selectedResumes];
    // Find index
    let index = selected_resumes.indexOf(id);
    if(index === -1){
      selected_resumes.push(id)
    }else{
      selected_resumes.splice(index, 1)
    }
    setSelectedResumes(selected_resumes)
  }

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  const [skill, setSkill] = useState([]);
  const [job, setJob] = useState([]);
  const getSkills = () => {
    axios
      .get(site_ip + '/getSkills')
      .then((res) => {
        setSkill(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getJobs = () => {
    axios
      .get(site_ip + '/getJobMaster')
      .then((res) => {
        setJob(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getSkills();
    getJobs();
    getCustomers();
    getAllResumes(filter);
  }, []);

  const [resumes, setResumes] = useState([]);
  const getAllResumes = (req) => {
    axios
      .post(site_ip + '/searchResumes', req)
      .then((res) => {
        // console.log(res);
        setResumes(res.data.Data);
        setSelectedResumes([]);
      })
      .catch((err) => console.log(err));
  };

  const [customers, setCustomers] = useState([]);
  const getCustomers = () => {
    axios
      .get(site_ip + '/getCustomer')
      .then((res) => {
        setCustomers(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    if(customerId === ""){
      setErrorMessage("Please select customer.")
      return;
    }else if(selectedResumes.length === 0){
      setErrorMessage("Please select atleast one resume.")
      return;
    }
    else {
      let items = [];
      let resumes = [...selectedResumes];
      resumes.map((item)=>{
        let ele = {
          customer_id: customerId,
          resume_id: item,
          job_id: filter.job_id,
          skill_id: filter.skill_id,
          experience_range: filter.experience_range,
          assigned_date: moment(new Date()).format("YYYY-MM-DD"),
          status: 1
        }
        items.push(ele)
      })
      
      axios
        .post(site_ip + '/addCustomerResume', items)
        .then((res) => {
          setSuccessMessage("Resume(s) successfully added to customer.")
          setFilter(blankFilter);
          setSelectedResumes([]);
          setCustomerId("")
          getAllResumes(blankFilter)
        })
        .catch((err) => console.log(err));
    }
  };
  
  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Assign Resumes to Customer</h4>
          </div>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Customer <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="customer_id"
                    value={customerId}
                    onChange={(e) => setCustomerId(e.target.value)}
                  >
                    <option value="">--Select--</option>
                    {customers?.map((item) => (
                      <option key={item.customer_id} value={item.customer_id}>
                        {item.customer_name}
                      </option>
                    ))}{' '}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12}>
                <p>FILTER FOR RESUMES</p>
                <Row style={{border:"1px solid #ccc", margin:0}}>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Skill</Form.Label>
                      <Form.Control
                        as="select"
                        name="skill_id"
                        value={filter.skill_id}
                        onChange={handleFilterChange}
                      >
                        <option value="">--Select--</option>
                        {skill?.map((item) => (
                          <option key={item.skill_id} value={item.skill_id}>
                            {item.skill_name}
                          </option>
                        ))}{' '}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Job</Form.Label>
                      <Form.Control
                        as="select"
                        name="job_id"
                        value={filter.job_id}
                        onChange={handleFilterChange}
                      >
                        <option value="">--Select--</option>
                        {job?.map((item) => (
                          <option key={item.job_id} value={item.job_id}>
                            {item.job_name}
                          </option>
                        ))}{' '}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Experience</Form.Label>
                      <Form.Control
                        as="select"
                        name="experience_range"
                        value={filter.experience_range}
                        onChange={handleFilterChange}
                      >
                        <option value="">--Select--</option>
                        <option value="0-3">0-3 years</option>
                        <option value="4-6">4-6 years</option>
                        <option value="7-10">7-10 years</option>
                        <option value="10+">10+ years</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Resume Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="resume_status"
                        value={filter.resume_status}
                        onChange={handleFilterChange}
                      >
                        <option value="">--Select--</option>
                        <option value="1">Assigned</option>
                        <option value="2">Rejected</option>
                        <option value="3">Selected</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={12}>

                  </Col>
                </Row>
                <p style={{marginTop:"15px"}}>TOTAL RESUMES ({resumes.length})</p>
                <Row style={{border:"1px solid #ccc", margin:0, paddingTop:"10px", backgroundColor:"#ccc"}}>
                  <Col md={1}>
                    
                  </Col>
                  <Col md={4}>
                    <p style={{marginBottom:"10px", fontWeight:"bold", color:"#00000"}}>Candidate Name</p>
                  </Col>
                  <Col md={4}>
                    <p style={{marginBottom:"10px", fontWeight:"bold", color:"#00000"}}>Candidate Email</p>
                  </Col>
                  <Col md={3}>
                    <p style={{marginBottom:"10px", fontWeight:"bold", color:"#00000"}}>Candidate Mobile</p>
                  </Col>
                </Row>
                {resumes.map((item, index)=>{
                  return(
                    <Row key={index} style={{border:"1px solid #ccc", margin:0, paddingTop:"10px"}}>
                      <Col md={1}>
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="mb-2"
                          onChange={() => handleCheckBox(parseInt(item.resume_id))}
                          checked={selectedResumes.includes(parseInt(item.resume_id))}
                        />
                      </Col>
                      <Col md={4}>
                        <p style={{marginBottom:"0px"}}>{item.candidate_name}</p>
                      </Col>
                      <Col md={4}>
                        <p style={{marginBottom:"0px"}}>{item.candidate_email}</p>
                      </Col>
                      <Col md={3}>
                        <p style={{marginBottom:"0px"}}>{item.candidate_mobile}</p>
                      </Col>
                    </Row>
                  )
                })}
              </Col>
            </Row>
            <Col md={12} style={{ marginBottom: "15px", marginTop:"15px" }}>
              <button
                className="primary"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </Col>
          </div>
        </Col>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
      <SuccessModal successMessage={successMessage} setSuccessMessage={() => {setSuccessMessage(null)}} />
    </Container>
  );
}
