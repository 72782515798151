import React, { useEffect } from 'react';
import { Col, Row, Button,Container, Card } from 'react-bootstrap';
// import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';

function ViewFeedback(props) {
  let { state } = useLocation();
  

  const navigate = useNavigate();
  const renderText = (text) => {
    return '';
  };

  useEffect(()=>{

    console.log(state?.feedback,'State')
    
  })
  return (
    <>
      <div class="align-items-center row">
        <div class="col-md-1">
          <Button
            variant="secondary"
            onClick={() => navigate('/candidates-feedback')}
          >
            Back
          </Button>
        </div>
        <div class="col-md-6" style={{margin:'10px'}}>
          <h4>Feedback Result</h4>
        </div>
      </div>
      <Card>
        <Container>
          <Row md={6}>
            <Col>Job Name</Col>
            <Col>
              {state?.feedback?.job_name}
            </Col>
          </Row>
          <Row md={6}>
            <Col>Candidate Name</Col>
            <Col>{state?.feedback?.candidate_name ? state?.feedback?.candidate_name:'N/A' }</Col>
          </Row>
          <Row md={6}>
            <Col>Comment</Col>
            <Col>{state?.feedback?.comments}</Col>
          </Row>
          <Row md={6}>
            <Col>Comment Date</Col>
  <Col>{state?.feedback?.createdAt ? new Date(state.feedback.createdAt).toLocaleString() : 'N/A'}</Col>
          </Row>
        
        </Container>
      </Card>
      <Row>
        {/* <Col>
          {location?.state?.feedback && location.state.feedback?.map((item, index) => {
            return (
              <div key={index} class="border p-3 mt-4">
                <div>
                  <p style={{ fontSize: '11px' }}>
                    Question Type:{' '}
                    <span
                      style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                    >
                      {item.question_type}
                    </span>
                  </p>
                </div>
                <div>
                  {renderText(
                    item.question_desc === undefined ? '' : item.question_desc
                  )}
                </div>
                <div>
                  <p style={{ fontSize: '11px', fontWeight: 'bold' }}>
                    Candidate's Response
                  </p>
                  <p>{item.ans}</p>
                </div>
              </div>
            );
          })}
        </Col> */}
       
      </Row>
    </>
  );
}

export default ViewFeedback;
