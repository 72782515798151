import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { site_ip } from "../globalSettings";
import axios from "axios";
import Swal from "sweetalert2";
function Contactus() {
  const intialValues = {
    contact_email: "",
    contact_name: "",
    contact_nbr: "",
    message: "",
    contact_company: "",
    contactus_type: "",
  };
  const [input, setInputs] = useState(intialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (token) => {
    axios({
      method: "post",
      url: site_ip + "/setContactUs",
      data: {
        contact_email: input.contact_email,
        contact_name: input.contact_name,
        contact_nbr: input.contact_nbr,
        message: input.message,
        contact_company: input.contact_company,
        contactus_type: input.contactus_type,
      },
      dataType: "json",
    })
      .then(function (response) {
        Swal.fire({
          title: "Thank You For Contact Us",
          icon: "success",
          confirmButtonText: "Confirmed",
          confirmButtonColor: "#45b161",
        }).then((result) => {
          if (result["isConfirmed"]) {
            window.location.href = "/";
          }
        });
      })
      .catch(function (error) {
        console.log("error" + error);
      });
  };
  return (
    // <Container fluid className="cust-height bg-blue">
    //   <Row className="align-items-center h-100 mt-5">
    //     <Col md={6} className="mx-auto">
    //       <div className="p-3 my-4 border shadow rounded-xl bg-white">
    //         <Form className="">
    //           <h3 style={{ textAlign: "center" }}>
    //             {" "}
    //             We Would Love to Hear from You!
    //           </h3>
    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //             <Form.Label>Name</Form.Label>
    //             <Form.Control
    //               type="text"
    //               name="contact_name"
    //               value={input.contact_name}
    //               onChange={handleChange}
    //               placeholder="Enter Name"
    //             />
    //           </Form.Group>
    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //             <Form.Label>Email Address</Form.Label>
    //             <Form.Control
    //               type="email"
    //               name="contact_email"
    //               value={input.contact_email}
    //               onChange={handleChange}
    //               placeholder="Enter Email"
    //             />
    //           </Form.Group>
    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //             <Form.Label>Phone Number</Form.Label>
    //             <Form.Control
    //               type="number"
    //               name="contact_nbr"
    //               value={input.contact_nbr}
    //               onChange={handleChange}
    //               placeholder="Enter Phone"
    //             />
    //           </Form.Group>
    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //             <Form.Label>Company Name</Form.Label>
    //             <Form.Control
    //               type="text"
    //               name="contact_company"
    //               value={input.contact_company}
    //               onChange={handleChange}
    //               placeholder="Enter Company"
    //             />
    //           </Form.Group>
    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //             <Form.Label>Contact Type</Form.Label>
    //             <Form.Control
    //               as="select"
    //               name="contactus_type"
    //               value={input.contactus_type}
    //               onChange={handleChange}
    //             >
    //               <option value="">Select Contact Type</option>
    //               <option value="Enquiry">Enquiry</option>
    //               <option value="Support">Support</option>
    //             </Form.Control>
    //           </Form.Group>
    //           <Form.Group
    //             className="mb-3"
    //             controlId="exampleForm.ControlTextarea1"
    //           >
    //             <Form.Label>Message</Form.Label>
    //             <Form.Control
    //               as="textarea"
    //               name="message"
    //               value={input.message}
    //               onChange={handleChange}
    //               rows={3}
    //             />
    //           </Form.Group>
    //           <Button
    //             variant="primary"
    //             class="g-recaptcha"
    //             data-sitekey="6LcfhzAcAAAAAImAVS4Z-wvINM8_gYwHK7fRShEt"
    //             data-callback="onSubmit"
    //             onClick={handleSubmit}
    //           >
    //             Submit
    //           </Button>
    //         </Form>
    //       </div>
    //     </Col>
    //   </Row>{" "}
    // </Container>
    <>
      <Container fluid className="height-header-minus">
        <Row className="height-header-minus bg-dark-blue">
          <Col md={3}></Col>
          <Col md={6} className="align-items-center justify-content-center flex pos-relative" style={{ flexDirection: "column" }}>
            <p className="text-center text-white heading-1">
              We Would Love to Hear from You!
            </p>
            <div className="login-box-div" style={{width:"100%"}}>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <p className='registerLabel'>Name</p>
                  <Form.Control
                    type="text"
                    name="contact_name"
                    value={input.contact_name}
                    onChange={handleChange}
                    placeholder="Enter Name"
                  />
                </Col>
                <Col>
                  <p className='registerLabel'>Email Address</p>
                  <Form.Control
                    type="email"
                    name="contact_email"
                    value={input.contact_email}
                    onChange={handleChange}
                    placeholder="Enter Email"
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <p className='registerLabel'>Phone Number</p>
                  <Form.Control
                    type="number"
                    name="contact_nbr"
                    value={input.contact_nbr}
                    onChange={handleChange}
                    placeholder="Enter Phone"
                  />
                </Col>
                <Col>
                  <p className='registerLabel'>Company Name</p>
                  <Form.Control
                    type="text"
                    name="contact_company"
                    value={input.contact_company}
                    onChange={handleChange}
                    placeholder="Enter Company"
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <p className='registerLabel'>Contact Type</p>
                  <Form.Control
                    as="select"
                    name="contactus_type"
                    value={input.contactus_type}
                    onChange={handleChange}
                  >
                    <option value="">Select Contact Type</option>
                    <option value="Enquiry">Enquiry</option>
                    <option value="Support">Support</option>
                  </Form.Control>
                </Col>
                <Col></Col>
              </Row>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <p className='registerLabel'>Message</p>
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={input.message}
                    onChange={handleChange}
                    rows={3}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="align-items-center justify-content-center flex">
                    <button className='primary-btn' onClick={() => handleSubmit()}>Submit</button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={3}></Col>
          <div className="fixed-whitebox-bottom"></div>
        </Row>
      </Container>
    </>
  );
}

export default Contactus;
