import Doc from './Doc';
import Pdf from './Pdf.js';
import Unknown from './Unknown';

import React from 'react';

export default function Icon({ name, ...rest }) {
  switch (name) {
    case 'DOC':
      return <Doc {...rest} />;

    case 'DOCX':
      return <Doc {...rest} />;

    case 'PDF':
      return <Pdf {...rest} />;

    default:
      return <Unknown {...rest} />;
  }
}
