import React from "react";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { site_ip } from "../globalSettings";
import { useEffect } from "react";


const Customtest =(props)=>{

     let user_data = JSON.parse(localStorage.getItem("userdata"));

    const navigate =useNavigate();

    const initialData ={
    exam_id: "",
    subject_id: "",
    topic_id: "",
    skill_id: "",
    difficulty_level: "",
    question_count: "",
    question_type_id: "",
    }

const [data, setData] = useState(initialData);
const [subjects, setSubjects] = useState([]);

const [ tests, setTests] = useState([])

useEffect(()=>{
   getTests();

},[])


const getTests = async () => {
    try {
        const response = await axios.get(`${site_ip}/getTestsList`)
      
        console.log(response,'RES')
        setTests(response.data.Tests);
    } catch (error) {
      console.log(error);
    }
  };


 const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
    // if (name === 'industry_id') getJobsList(value);
  };


  const fetchQuestionData = (testId) => {
    const apiUrl = site_ip + `/getTestQuestionsSet/${testId}`;
    console.log(apiUrl);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((res) => {
        localStorage.setItem(
          'question_list',
          JSON.stringify(res)
        );
        console.log('This is your data', data);
            navigate('/practice-exam-page', { state:{id:data.exam_id,question_count:data.question_count}});
      });
  };

  const handleSubmit = ()=>{
    console.log(data,'handle')
        fetchQuestionData(data.exam_id);
  }

  
return (<>
    <Container>
        <Row>
            <Col xs={12}>
              <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h5>Preview Test</h5>
        </Col>  

         <Form>
          <Row>
            <Col sm>
              <Form.Group>
                <Form.Label className="label">Test</Form.Label>
                <Form.Control
                  name="exam_id"
                  value={data.exam_id}
                  onChange={handleChange}
                  className="mb-3"
                  as="select"
                  style={{ backgroundColor: '#ffffff', color: '#202124' }}
                >
                  <option value="">Select Test</option>
                  {tests?.map((item) => (
                    <option value={item.test_id} key={item.test_id}>
                      {item.test_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

          </Row>
          {/* <Row>
            <Col sm>
              <Form.Group>
                <Form.Label className="label">TEST</Form.Label>
                <Form.Control
                  name="subject_id"
                  value={data.subject_id}
                  onChange={handleChange}
                  as="select"
                  style={{ backgroundColor: '#ffffff', color: '#202124' }}
                >
                  <option value="">Select Subject</option>
                  {subjects?.map((value) => (
                    <option value={value.subject_id} key={value.subject_id}>
                      {value.subject_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row> */}
         
          <Row>
            <Col sm style={{ marginTop: '15px' }}>
              <Form.Group>
                <Form.Label>Question Count</Form.Label>
                <Form.Control
                  name="question_count"
                  value={data.question_count}
                  onChange={handleChange}
                  type="number"
                  // style={{ backgroundColor: '#ffffff', color: '#202124' }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
    <Col className="d-flex justify-content-center">
    <Button onClick={handleSubmit}  style={{ marginTop: '25px', backgroundColor: '#4285F4', color: '#ffffff' }}>
      Start Test
    </Button>
    </Col>
</Row>

                </Form>
            </Col>
        </Row>
    </Container>
</>)

}

export default Customtest