import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table, Modal } from 'react-bootstrap';
// import Aux from "../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
import { site_ip } from '../../../globalSettings';
import axios from 'axios';
import Swal from 'sweetalert2';
function ManageSkills(props) {
  const initialValues = {
    skill_id: '',
    skill_name: '',
    industry_id: '',
    job_id: '',
    updating: false,
  };

  const [skillList, setSkillsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [industryList, setIndustryList] = useState([]);

  const [add, setAdd] = useState(false);

  const [values, setValues] = useState(initialValues);

  const handleClose = () => {
    setAdd(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const proceed = () => {
    let skill_name = values.skill_name;
    if (skill_name.trim() === '') {
      alert('Please enter Job name');
    } else if (values.skill_id === '') {
      handleClose();
      // Add industry
      axios({
        method: 'POST',
        url: site_ip + '/addSkills',
        data: {
          skill_name: values.skill_name.trim(),
          industry_id: values.industry_id,
          job_id: values.job_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Skill added successfully',
              icon: 'success',
            }).then((result) => {
              fetchSkillsList();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      axios({
        method: 'POST',
        url: site_ip + '/setSkills',
        data: {
          skill_id: values.skill_id,
          skill_name: values.skill_name.trim(),
          industry_id: values.industry_id,
          job_id: values.job_id,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Skill details successfully updated',
              icon: 'success',
            }).then((result) => {
              fetchSkillsList();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchSkillsList();
    fetchIndustryList();
  }, []);

  const fetchSkillsList = async () => {
    const result = await axios.get(`${site_ip}/getSkills`);
    setSkillsList(result.data.Content);
  };

  const fetchJobsList = async (id) => {
    const result = await axios.get(`${site_ip}/getJobListByIndustryId/${id}`);
    setJobsList(result.data.Content);
  };

  const fetchIndustryList = async () => {
    const result = await axios.get(`${site_ip}/getIndustryList`);
    setIndustryList(result.data.Content);
  };

  const editNow = (item) => {
    const newValues = {
      skill_id: item.skill_id,
      skill_name: item.skill_name,
      industry_id: item.industry_id,
      job_id: item.job_id,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);

    // Fetch Joblists
    if (item.industry_id !== null && item.industry_id !== '') {
      fetchJobsList(item.industry_id);
    }
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={6}>
          <h4>Manage Skills</h4>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => handleShow()}
            aria-controls="example-collapse-text"
            aria-expanded={add}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div className="p-3 border bg-white">
            <Table striped hover size="sm" responsive className="mt-4">
              <thead className="lt-head">
                <tr>
                  <th scope="row">Sr No</th>
                  <th>Action</th>
                  <th>Skill</th>
                  <th>Industry</th>
                  <th>Job Role</th>
                </tr>
              </thead>
              <tbody>
                {skillList.map((skill, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <Link onClick={() => editNow(skill)} to="#">
                          <i class="menu-icon fas fa-edit"></i>
                        </Link>
                      </td>
                      <td>{skill.skill_name}</td>
                      <td>{skill.industry_name}</td>
                      <td>{skill.job_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Modal show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update Skill' : 'Add Skill'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Skill Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="skill_name"
                  value={values.skill_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} style={{ marginTop: '15px' }}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Industry</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="industry_id"
                  value={values.industry_id}
                  onChange={(e) => {
                    handleChange(e);
                    fetchJobsList(e.target.value);
                  }}
                >
                  <option value="">Select Industry</option>
                  {industryList.map((value) => (
                    <option key={value.industry_id} value={value.industry_id}>
                      {value.industry_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Job</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="job_id"
                  value={values.job_id}
                  onChange={handleChange}
                >
                  <option value="">Select JOb</option>
                  {jobsList.map((value) => (
                    <option key={value.job_id} value={value.job_id}>
                      {value.job_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ManageSkills;
