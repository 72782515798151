import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
// import Swal from 'sweetalert2';

const Success = () => {
  const location = useLocation();
  let testid = location.state.detail;

  let update = location.state.update;
  const [questionsCount, setQuestionsCount] = useState(0);
  //   const [questions, getQuestionList] = useState(null);
  const fetchQuestionListData = () =>
    fetch(`${site_ip}/getTestQuestionsSet/${testid}`).then((res) => res.json());
  useEffect(() => {
    fetchQuestionListData().then((data) => {
      // console.log(data);
      if (data.Count) {
        setQuestionsCount(data.Count);
      }
    });
    //eslint-disable-next-line
  }, []);

  const [testPattern, setTestPattern] = useState([]);
  const fetchTestByIdData = () =>
    fetch(`${site_ip}/getTestMasterById/${testid}`).then((res) => res.json());
  useEffect(() => {
    fetchTestByIdData().then((data) => {
      // console.log(data);

      //console.log(data.Content.test_name);
      setTestPattern(data.Content);
    });
    //eslint-disable-next-line
  }, []);

  // Swal.fire({
  //   title: 'Success',
  //   text: 'Question created successfully.',
  //   icon: 'success',
  //   confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
  // });

  return (
    <Container>
      <br></br>
      <br></br>
      <br></br>

      <Card>
        <Card.Body>
          <Row className="justify-content-md-center">
            <Col md="auto" style={{ textAlign: 'center' }}>
              {update ? (
                <h3>You have successfully updated a test </h3>
              ) : (
                <h3>You have successfully created a test </h3>
              )}
              <h5>Test Name: {testPattern.test_name}</h5>
              <h5>Number of Questions: {questionsCount}</h5>
              <br />
              <Link to="/test-add">
                <Button size="lg" variant="primary">
                  Add Test
                </Button>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Success;
