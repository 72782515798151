import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form} from 'react-bootstrap';
import { FileDrop } from 'react-file-drop';
import Swal from 'sweetalert2';
import { site_ip } from '../../../globalSetting';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import ErrorModal from '../../common/ErrorModal';
import { useParams } from 'react-router-dom';
export default function EditCandidate(props) {
  const params = useParams();
  const [values, setValues] = useState({
    candidate_name: "",
    candidate_email: "",
    candidate_mobile: "",
    job_id: "",
    company_id: "",
    age: "",
    exp_in_years: "",
    highest_qualification: "",
    profile_summary: "",
    current_designation: "",
    current_company: "",
    current_location: "",
    resume: ""
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "age" || name === "exp_in_years"){
      if(validPositiveNumber(value)){
        setValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }else{
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChangeWithName = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const [companies, setCompanies] = useState([]);
  const [job, setJob] = useState([]);
  const getCompanies = () => {
    axios
      .get(site_ip + '/getCompanyList')
      .then((res) => {
        // console.log(res);
        setCompanies(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getJobs = () => {
    axios
      .get(site_ip + '/getJobMaster')
      .then((res) => {
        setJob(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchCandidateDetail(params.id)
    getCompanies();
    getJobs();
  }, []);

  const fetchCandidateDetail = (id) => {
    axios
      .get(site_ip + '/getCandidateById/'+id)
      .then((res) => {
        setValues(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const validPositiveNumber = (number) => {
    if (number === "") {
        return true;
    } else {
        var positiveNumber = /^(0|\+?[1-9]\d*)$/;
        if (String(number).match(positiveNumber)) {
            return true;
        } else {
            return false;
        }
    }
};

  const handleSubmit = () => {
    let req = {...values};
    if(req.candidate_name.trim() === ""){
      setErrorMessage("Candidate Name cannot be blank.")
      return;
    }else if(!validateEmail(req.candidate_email)){
      setErrorMessage("Please enter a valid email address.")
      return;
    }else if(!phonenumber(req.candidate_mobile)){
      setErrorMessage("Please enter a valid mobile number.")
      return;
    }else if(req.age === "" || parseInt(req.age) < 18){
      setErrorMessage("Age cannot be less than 18 years.")
      return;
    }else if(req.exp_in_years === ""){
      setErrorMessage("Please enter experience in years.")
      return;
    }else if(req.company_id === ""){
      setErrorMessage("Please select company.")
      return;
    }else if(req.job_id === ""){
      setErrorMessage("Please select job.")
      return;
    }else if(req.resume === "" || req.resume === null){
      setErrorMessage("Please attach resume copy.")
      return;
    }
    else {
      axios
        .post(site_ip + '/setCandidate', req)
        .then((res) => {
          // console.log(res)
          if (res.data.Status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'New Candidate added successfully',
              showConfirmButton: false,
              timer: 1500
            })
            props.navigate('/recruiter/candidate/list');
          } else alert('Request Failed');
        })
        .catch((err) => console.log(err));
    }
  };

  const inputRef = useRef();
  
  const attachmentName = (url) =>{
    if(url === null){
      return ""
    }else{
      let url_sections = url.split("/");
      if(url_sections.length > 0){
        return url_sections[url_sections.length - 1]
      }else{
        return ""
      }
    }
    
  }

  const fileHandler = async (e) => {

    let file = e[0];
    const s3_url =
      'https://kb-manuscripts.s3.us-east-2.amazonaws.com/manuscripts-attachment/' +
      file.name;
    const target = {
      Bucket: 'kb-manuscripts',
      Key: 'manuscripts-attachment/' + file.name,
      Body: file,
    };
    const creds = {
      accessKeyId: 'AKIATT4SF2SQIHAF7MUJ',
      secretAccessKey: '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',
    };

    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'us-east-2', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log('progress : ', progress);
      });

      await parallelUploads3.done();
      handleChangeWithName('resume', s3_url);
      // console.log(s3_url);
      // saveDocument(s3_url, file)
    } catch (e) {
      console.log(e);
      handleChangeWithName('resume', s3_url);
    }
  };

  const filePicker = () => {
    inputRef.current.click();
  };
  
  const handleReset = () => {

  }

  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Add Candidate</h4>
          </div>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Candidate Name <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="candidate_name"
                    value={values.candidate_name}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Candidate Email <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="candidate_email"
                    value={values.candidate_email}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Candidate Mobile <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="candidate_mobile"
                    value={values.candidate_mobile}
                    onChange={handleChange}
                    maxLength={10}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Company <span className='required'>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="company_id"
                    value={values.company_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Company</option>
                    {companies?.map((company) => (
                      <option key={company.company_id} value={company.company_id}>
                        {company.company_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Job <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="job_id"
                    value={values.job_id}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {job?.map((item) => (
                      <option key={item.job_id} value={item.job_id}>
                        {item.job_name}
                      </option>
                    ))}{' '}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Age <span className="required">*</span></Form.Label>
                  <Form.Control
                    name="age"
                    value={values.age}
                    onChange={handleChange}
                    type="number"
                    min={18}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Experience (in years)<span className="required">*</span></Form.Label>
                  <Form.Control
                    name="exp_in_years"
                    value={values.exp_in_years}
                    onChange={handleChange}
                    type="number"
                    min={0}
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Highest Qualification</Form.Label>
                  <Form.Control
                    name="highest_qualification"
                    value={values.highest_qualification}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Profile Summary</Form.Label>
                  <Form.Control
                    name="profile_summary"
                    value={values.profile_summary}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Current Company</Form.Label>
                  <Form.Control
                    name="current_company"
                    value={values.current_company}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Current Designation</Form.Label>
                  <Form.Control
                    name="current_designation"
                    value={values.current_designation}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Current Location</Form.Label>
                  <Form.Control
                    name="current_location"
                    value={values.current_location}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Attach Resume <span className="required">*</span></Form.Label>
                  <br/>
                  {values.resume !== "" && values.resume !== null ? <div>{attachmentName(values.resume)} (<a href={values.resume}>Download</a>)</div> : null}
                  <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
                    <p className="placeholder1">
                      DRAG RESUME HERE <br /> OR <br/> <span>BROWSE</span>
                    </p>
                    <input
                      accept=".doc, .docx, .pdf"
                      value=""
                      style={{ visibility: 'hidden', opacity: 0 }}
                      ref={inputRef}
                      multiple="multiple"
                      type="file"
                      onChange={(e) => fileHandler(e.target.files)}
                    />
                  </FileDrop>
                </Form.Group>
              </Col>
            </Row>
            {/* <div className="progressContainer">
            {filenames &&
              filenames.map((file, i) => (
                <Progress
                  key={i}
                  name={file.resume_file_name}
                  icon={file.icon}
                />
              ))}
          </div> */}
            <br />
            <Col md={12} style={{ marginBottom: "15px" }}>
              <button
                className="primary"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
              {/* <button
                className="secondary"
                onClick={handleReset}>
                Reset
              </button> */}
            </Col>
          </div>
        </Col>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
    </Container>
  );
}
