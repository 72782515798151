import React from 'react';
import './CircleLoader.css'; // Import the CSS file for styling

const CircleLoader = () => {
  return (
    <div className="circle-loader">
      <div className="circle"></div>
    </div>
  );
};

export default CircleLoader;
