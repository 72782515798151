import React, { useState, useEffect } from 'react';
import { Button, Col, Tab, Tabs, Form, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { Link } from 'react-router-dom';

function TestListing() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const initialSearch = {
    job_id: '',
    date_from: '',
    date_to: '',
    test_name: '',
  };
  const [search, setSearchs] = useState(initialSearch);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [job, getJob] = useState(null);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [subject, getSubject] = useState(null);
  const fetchSubjectData = () =>
    fetch(`${site_ip}/getSubjects`).then((res) => res.json());
  useEffect(() => {
    fetchSubjectData().then((data) => {
      getSubject(data.Content);
      console.log(subject);
    });
    //eslint-disable-next-line
  }, []);
  const [complexity, getComplexity] = useState(null);
  const fetchComplexityData = () =>
    fetch(`${site_ip}/getQuestionComplexity`).then((res) => res.json());
  useEffect(() => {
    fetchComplexityData().then((data) => {
      getComplexity(data.content);
      console.log(complexity);
    });
    //eslint-disable-next-line
  }, []);
  const [companyList, getCompanyList] = useState(null);
  const fetchCompanyList = () =>
    fetch(`${site_ip}/getCompanyList`).then((res) => res.json());
  useEffect(() => {
    fetchCompanyList().then((data) => {
      getCompanyList(data.Content);
      console.log(companyList);
    });
    //eslint-disable-next-line
  }, []);
  const [testlist, getTestList] = useState([]);
  const fetchTestListData = () =>
    fetch(`${site_ip}/getTestsList`).then((res) => res.json());
  useEffect(() => {
    fetchTestListData().then((data) => getTestList(data.Tests));
  }, []);
  const handleSearch = () => {
    axios({
      method: 'post',
      url: site_ip + '/searchTests',
      data: {
        job_id: search.job_id,
        date_from: search.date_from,
        date_to: search.date_to,
        test_name: search.test_name,
        company_id: userdata.company_id,
        industry_id: userdata.IndustryId,
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response.status === 200) {
          getTestList(response.data.Tests);
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  return (
    <>
      <div class="align-items-center row">
        <div class="col-md-6">
          <h4>Search Test</h4>
        </div>
      </div>
      <Row>
        <Col>
          <div class="border bg-white p-3">
            <Row>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Job </Form.Label>
                    <Form.Control
                      name="job_id"
                      value={search.job_id}
                      onChange={handleChange}
                      as="select"
                    >
                      <option>Select Job</option>
                      {job?.map((value) => (
                        <option value={value.job_id}>{value.job_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Test Name</Form.Label>
                    <Form.Control
                      placeholder="Test Name"
                      name="test_name"
                      value={search.test_name}
                      onChange={handleChange}
                      type="text"
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>

              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                      type="date"
                    ></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={2} className="mt-4">
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Button variant="primary" onClick={handleSearch}>
                      Search
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
          <div class="border bg-white p-3 h-100 mt-4">
            <Tabs defaultActiveKey="upcoming" className="mb-3">
              <Tab eventKey="upcoming" title="UPCOMING">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Action</th>
                      <th>Test</th>
                      <th>Skill</th>
                      <th>Test Date</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Result Date</th>
                      <th>Test Url</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testlist?.map((value, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/admin/test-edit/${value.test_id}`,
                            }}
                          >
                            <i className="menu-icon fas fa-edit"></i>
                          </Link>
                        </td>
                        <td>{value.test_name}</td>
                        <td>{value.test_type}</td>
                        <td>{value.test_date}</td>
                        <td>{value.start_time}</td>
                        <td>{value.end_time}</td>
                        <td>{value.result_date}</td>
                        <td>
                          <a
                            href={window.location.origin + '/candidate/login'}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {window.location.origin + '/candidate/login'}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="completed" title="COMPLETED"></Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default TestListing;
