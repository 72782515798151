import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './homepage.css'
import { site_ip } from './globalSettings';
const Home = () => {
    const navigate = useNavigate();

    const blankValues = {
        name: '',
        email: '',
        telephone: '',
        job_title: '',
        industry_id:''
    };
    const [values, setValues] = useState(blankValues)

    const reviews = [
        { _id: 1, label: 'Corporate Clients', numbers: '300+' },
        { _id: 2, label: 'Skills Assessed', numbers: '25+' },
        { _id: 3, label: 'Students Assessed Annually', numbers: '2.5 million' },
        { _id: 4, label: 'Exams Annually', numbers: '400+' },
        { _id: 5, label: 'Assessments Annually', numbers: '80+' },
        { _id: 6, label: 'Countries Reached', numbers: '20+' },
        { _id: 7, label: 'Industries Assessed', numbers: '1000+' },
        { _id: 8, label: 'Academic Clients', numbers: '15+' },
    ];

    const [formOpen, setFormOpen] = useState(false);

    const handleChange = (e) =>{
        let {name, value} = e.target;
        setValues({...values, [name]:value})
        if(name === 'job_title'){
            setValues({...values, [name]:value, industry_id:''})
            fetchIndustryList(value)
        }else{
            setValues({...values, [name]:value})
        }
    }

    useEffect(()=>{
        fetchJobList()
        setTimeout(()=>{
            setFormOpen(true);
        }, 3000)
    }, [])



    const [jobList, setJobList] = useState([])
    const fetchJobList = () => {
        axios.get(
            site_ip + '/getJobMaster'
        ).then((res) => {
            setJobList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const [industryList, setIndustryList] = useState([])
    const fetchIndustryList = (value) => {
        axios.get(
            site_ip + '/getJobListByIndustryId/'+value
        ).then((res) => {
            setIndustryList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const handleSubmit = () =>{
        console.log("Wait")
    }

    return (
        <div>
            <div className="fix-header sticky">
                <Container>
                    <Row style={{ margin: "0px" }}>
                        <Col md={3} style={{ padding: 0 }}>
                            <img src="/home/logo.png" className="header-logo-img" />
                        </Col>
                        <Col md={9}>
                            <div className="navigation">
                                <ul>
                                    <li>
                                        <a aria-current="page" className="active" href="/">Home</a>
                                    </li>
                                    <li>
                                        <a className="" href="/#solutions">Solutions</a>
                                    </li>
                                    <li>
                                        <a className="" href="/contents">Contents</a>
                                    </li>
                                    <li>
                                        <a className="" href="/contact-us">Contact Us</a>
                                    </li>
                                    <li>
                                        <a className="" href="/signup">Register</a>
                                    </li>
                                    <li>
                                        <button onClick={() => navigate('/login')} className="signinbtn">Login</button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Row style={{ margin: "0px", marginBottom: "40px" }} id="solutions">
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue" style={{paddingTop:"30px", paddingBottom:"15px", marginBottom:"20px"}}>
                        <Container>
                            <p className="text-center text-white heading-1">
                                Our Team
                            </p>
                        </Container>
                    </div>
                    <Container>
                    <div>
      <h1>Meet the Quantum Insight Team</h1>

      <h2>Founders</h2>
      <div>
        <h3>John Smith</h3>
        <p>
          Co-Founder & CEO
          <br />
          John is a seasoned entrepreneur and technology enthusiast with over 15 years of experience in the software industry. He is driven by the belief that every talented individual deserves an equal opportunity to shine, and he co-founded Quantum Insight to bridge the gap between candidates and employers.
        </p>

        <h3>Sarah Johnson</h3>
        <p>
          Co-Founder & CTO
          <br />
          Sarah is a brilliant software engineer with a passion for creating efficient and scalable solutions. Her technical acumen and leadership skills have been instrumental in shaping the core of Quantum Insight's assessment platform. She is dedicated to building a reliable and innovative system that empowers candidates and businesses alike.
        </p>
      </div>

      <h2>Technology Team</h2>
      <div>
        <h3>Michael Adams</h3>
        <p>
          Lead Software Engineer
          <br />
          Michael is an experienced software engineer with a deep understanding of various programming languages and frameworks. His expertise in building robust and user-friendly platforms ensures that Quantum Insight remains at the forefront of technical candidate evaluation.
        </p>

        <h3>Emily Chen</h3>
        <p>
          Machine Learning Specialist
          <br />
          Emily is a data scientist with a focus on machine learning and artificial intelligence. Her work in developing intelligent assessment algorithms allows Quantum Insight to offer adaptive and personalized evaluations for candidates.
        </p>
      </div>

      <h2>Assessment Experts</h2>
      <div>
        <h3>David Lee</h3>
        <p>
          Technical Assessment Advisor
          <br />
          David brings over a decade of experience in designing and conducting technical assessments for renowned tech companies. His insights and expertise ensure that Quantum Insight's assessments are aligned with real-world challenges and industry standards.
        </p>

        <h3>Rachel Garcia</h3>
        <p>
          Assessment Content Developer
          <br />
          Rachel is a talented content developer with a passion for crafting engaging and rigorous assessment challenges. Her commitment to maintaining a bias-free evaluation process plays a pivotal role in ensuring fair opportunities for all candidates.
        </p>
      </div>

      <h2>Operations and Support</h2>
      <div>
        <h3>Mark Johnson</h3>
        <p>
          Operations Manager
          <br />
          Mark oversees the smooth functioning of Quantum Insight's day-to-day operations. His organizational skills and attention to detail keep the platform running seamlessly and efficiently.
        </p>

        <h3>Laura Brown</h3>
        <p>
          Customer Support Specialist
          <br />
          Laura is dedicated to providing exceptional support to both candidates and employers. Her friendly demeanor and problem-solving skills make the Quantum Insight experience delightful for our users.
        </p>
      </div>

      <h2>Join Our Team</h2>
      <p>
        At Quantum Insight, we believe that a strong team is the foundation of our success. We are always on the lookout for talented individuals who share our vision and want to make a positive impact on the world of technical recruitment. If you are passionate about technology, fairness, and innovation, we invite you to explore career opportunities with us.
      </p>
    </div>
                    </Container>
                </Col>
            </Row>
            <Row style={{ margin: "0px" }}>
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue top-bottom-footer-space">
                        <Container>
                            {/* <p className="text-center text-white heading-1">
                                Trusted by More Than 6000 Clients Worldwide
                            </p> */}
                            <Row>
                                <Col>
                                    <p className="footer-header">COMPANY</p>
                                    <a href="/" onClick={(e) => {e.preventDefault(); navigate("/about-us")}} className="footer-link">About Us</a>
                                    <a href="#" className="footer-link">Our Team</a>
                                    {/* <a href="#" className="footer-link">News</a>
                                    <a href="#" className="footer-link">Customers</a>
                                    <a href="#" className="footer-link">Careers</a>
                                    <a href="#" className="footer-link">Information Security</a> */}
                                </Col>
                                {/* <Col>
                                    <p className="footer-header">PRODUCTS</p>
                                    <a href="#" className="footer-link">Talent Assessment Solutions</a>
                                    <a href="#" className="footer-link">Coding Assessment Solutions</a>
                                    <a href="#" className="footer-link">Hiring Solutions</a>
                                    <a href="#" className="footer-link">L&D Solutions</a>
                                    <a href="#" className="footer-link">Online Examination Solution</a>
                                    <a href="#" className="footer-link">Remote Proctoring Tools</a>
                                    <a href="#" className="footer-link">360-degree Feedback Tool</a>
                                    <a href="#" className="footer-link">Hackathon Tool</a>
                                    <a href="#" className="footer-link">Test Library</a>
                                </Col> */}
                                {/* <Col>
                                    <p className="footer-header">OTHERS</p>
                                    <a href="#" className="footer-link">Resources</a>
                                    <a href="#" className="footer-link">Blog</a>
                                    <a href="#" className="footer-link">Glossary</a>
                                    <a href="#" className="footer-link">Webinars</a>
                                    <a href="#" className="footer-link">Help & Support</a>
                                    <a href="#" className="footer-link">Accessibility</a>
                                    <a href="#" className="footer-link">COVID-19 Support</a>
                                </Col> */}
                                <Col></Col>
                                <Col>
                                    {/* <p className="footer-header">Partners</p>
                                    <a href="#" className="footer-link">Strategic Partnerships</a>
                                    <a href="#" className="footer-link">Integration Partners</a>
                                    <br /> */}
                                    <p className="footer-header">CALL US</p>
                                    <a href="#" className="footer-link"><i class="fas fa-mobile-alt"></i> +91-9988 77 66 55</a>
                                    <a href="#" className="footer-link"><i class="far fa-envelope"></i> liz@quantauminsight.in</a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </div>

    )
}

export default Home