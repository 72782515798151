import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { site_ip } from '../../../../globalSettings';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';

import Papa from 'papaparse';


const AddCollege = () => {
  const initialValue = {
    college_name: '',
    city_name: '',
    state_id: '',
    country_id: '',
  };

  const [tableRows, setRows] = useState(initialValue);
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [allColleges, setAllColleges] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let list = { ...tableRows };
    list[name] = value;
    setRows(list);

    if(name==='country_id') fetchStateData(value);
    if(name ==='state_id') fetchCityData(value);
  };


  useEffect(()=>{
        fetchCountryData();
        fetchCollegeList();
  },[])

  // const handleSubmit = async () => {
  //   console.log(tableRows,'ROWS')
  //   if (
  //     tableRows.college_name === '' ||
  //     tableRows.city_name === '' ||
  //     tableRows.state_id === '' ||
  //     tableRows.country_id === ''
  //   ) {
  //     alert('Please fill all the fields!');
  //     return;
  //   }

  //   await axios({
  //     method: 'post',
  //     url: site_ip + '/campus/college/add',
  //     data: {
  //       college_name: tableRows.college_name,
  //       city_name: tableRows.city_name,
  //       state_id: Number(tableRows.state_id),
  //       country_id: Number(tableRows.country_id),
  //       company_id:JSON.parse(localStorage.getItem('userdata')).company_id
  //     },
  //     dataType: 'json',
  //   })
  //     .then(function (response) {
  //       console.log(response,'Response')
        
  //       if ((response.data.Status = 200)) {
  //         Swal.fire({
  //           title: 'Success',
  //           text: 'New College Added!',
  //           icon: 'success',
  //         }).then((result) => {
  //           setRows(initialValue);
  //         });
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log('error' + error);
  //     });
  // };


  const handleSubmit = async () => {
  console.log(tableRows, 'ROWS');

  if (
    tableRows.college_name === '' ||
    tableRows.city_name === '' ||
    tableRows.state_id === '' ||
    tableRows.country_id === ''
  ) {
    alert('Please fill all the fields!');
    return;
  }

  // Check for duplicate college names in the client-side data
  const isDuplicate = allColleges.some((college) => {
    return (
      college.college_name.toLowerCase() ===
      tableRows.college_name.toLowerCase()
    );
  });

  if (isDuplicate) {
    alert('College name already exists!');
    return;
  }

  await axios({
    method: 'post',
    url: site_ip + '/campus/college/add',
    data: {
      college_name: tableRows.college_name,
      city_name: tableRows.city_name,
      state_id: Number(tableRows.state_id),
      country_id: Number(tableRows.country_id),
      company_id: JSON.parse(localStorage.getItem('userdata')).company_id,
    },
    dataType: 'json',
  })
    .then(function (response) {
      console.log(response, 'Response');

      if (response.data.Status === 200) {
        Swal.fire({
          title: 'Success',
          text: 'New College Added!',
          icon: 'success',
        }).then((result) => {
          setRows(initialValue);
                  fetchCollegeList();

        });
      }
    })
    .catch(function (error) {
      console.log('error' + error);
    });
};
  

    const fetchCountryData = async () => { 
      const result = await axios.get(`${site_ip}/getCountryList`);
            console.log(result,'RESULT')
            setCountry(result.data.Content);
      // console.log(result);
    };

    const fetchStateData = async (countryid) => {
      const result = await axios.get(`${site_ip}/getState/${countryid}`);
      setState(result.data.Data);
      // console.log(result);
    };

    const fetchCityData = async (stateId) => {
      const result = await axios.get(`${site_ip}/getCity/${stateId}`);
      console.log(result,'RESL')
      setCity(result.data.Cities);
      // console.log(result);
    };

    let tableHeader =[
      {
        name:"#",
        selector:(row,index)=>(index+1),
        width:"100px"
      },
      {
        name:"College Name",
        selector:(row)=>row?.college_name
      },
      {
        name:"City",
        selector:(row)=>row?.city
      },
      {
        name:"State",
        selector:(row)=>row?.name
      },
      {
        name:"Country",
        selector:(row)=>row?.country_name
      },
      {
        name:"Action",
        width:'150px',
        cell:(row) => (
          <>
            <button 
              className='btn btn-sm'
              role='button'
              style={{backgroundColor:'#4caf50',color:'#ffffff'}}
              onClick={()=>navigate(`/campus/${row.college_name}/dashboard`)}
            >
              <i className='fas fa=pencil-alt'></i>
            </button>
          </>
        )
      }
    ]

    const fetchCollegeList= async ()=>{
       let result =  await axios.get(site_ip+`/campus/college/list/${JSON.parse(localStorage.getItem('userdata')).company_id}`)
      console.log(result.data.Data)
      setAllColleges(result.data.Data);
    }

    const  sendDataToServer= async ()=>{
      console.log(csvData,'Server')

        csvData.map( async (item)=>{
            
          console.log(item,'ITEM')

    await  axios({
        method:'post',
        url:site_ip+'/campus/college/add',
        data:{
          college_name:item.college_name,
          city_name:item.city,
          state_id:item.state_id,
          country_id:item.country_id,
          company_id:JSON.parse(localStorage.getItem('userdata')).company_id
        },
        dataType:'json'
      }).then(function (response){
        console.log(response,'Response')
      })
        })
        fetchCollegeList();
    }


    const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const data = result.data;

          // Filter out empty or whitespace-only rows
  const nonEmptyRows = data.filter(row => {
    for (const key in row) {
      if (row[key].trim() !== '') {
        return true;
      }
    }
    return false;
  });
  // Assuming the first row contains headers
  const headers = nonEmptyRows[0];
  const rows = nonEmptyRows.slice(1);
          // Assuming you have a function to send the data to the server to insert into the SQL table
          // sendDataToServer(rows);

          console.log(data,'DATA', nonEmptyRows,'ROWS')
          setCsvData(nonEmptyRows);
        },
        header: true, // Treat the first row as headers
      });
    }
  };


  return (
    <>
      <Row className="admin-form-row" style={{ margin: '0px' }}>
        <div className="header">
          <h4>Add College</h4>
        </div>
        <div className='p-3 border bg-white'>
          <Col md={4}>
            <input type="file" accept=".csv" onChange={handleFileUpload} />
            <button onClick={sendDataToServer} className='mt-2 btn btn-primary' style={{backgroundColor:'#4CAF50', borderColor:'#4CAF50'}}>Upload Colleges</button>
          </Col>
              
        </div>

        <div className="p-3 border bg-white">
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  College Name<span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="College Name"
                  className="mb-3"
                  name="college_name"
                  value={tableRows.college_name}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Country<span className="required">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Country"
                  className="mb-3"
                  name="country_id"
                  value={tableRows.country_id}
                  onChange={handleChange}
                >
                   <option value="">Select Country</option>
                  {country &&  country?.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.country_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  State<span className="required">*</span>
                </Form.Label>
               <Form.Control
                  as="select"
                  className="mb-3"
                  name="state_id"
                  value={tableRows.state_id}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {state && state.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  City<span className="required">*</span>
                </Form.Label>
               {/* <Form.Control
                  as="select"
                  className="mb-3"
                  name="city_name"
                  value={tableRows.city_name}
                  onChange={handleChange}
                >
                  <option value="">Select City</option>
                  {city && city.map((value) => (
                    <option key={value.city_name} value={value.city_name}>
                      {value.city_name}
                    </option>
                  ))}
                </Form.Control> */}
                <Form.Control
                  type='text'
                  placeholder='Enter City Name'
                  name='city_name'
                  value={tableRows.city_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={5}>
              <button className="primary" onClick={handleSubmit}>
                Save
              </button>
            </Col>
          </Row>
        </div>
        <div className='border bg-white p-3 h-100 mt-4'>
          <DataTable 
            columns={tableHeader}
            data={allColleges}
            pagination
          />
        </div>
      </Row>

       {/* <table>
        <thead>
          <tr>
            <th>college_id</th>
            <th>college_name</th>
            <th>city</th>
            <th>state_id</th>
            <th>country_id</th>
          </tr>
        </thead>
        <tbody>
          {csvData.map((row, index) => (
            <tr key={index}>
              <td>{row.college_id}</td>
              <td>{row.college_name}</td>
              <td>{row.city}</td>
              <td>{row.state_id}</td>
              <td>{row.country_id}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </>
  );
};

export default AddCollege;
