import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
// import Aux from '../../../../hoc/_Aux';
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
function EditCompany (props) {
  let navigate = useNavigate();
  const params = useParams();  
  const initialValues = {
    company_id: params.id,  
    company_name: '',
    company_add1: '',
    company_add2: '',
    company_city: '',
    emp_count: '',
    industry_id: '',
    company_url: '',
    company_board_no: '',
    signed_on: '',
    state_id: '',
    country_id: '',
    company_email: '',
    contact_person: ''
  };

  const [values, setValues] = useState(initialValues);
  // const [value, setValue] = useState(0);

  const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  // Fetch initial company details
  const companyData = async() =>{
    await axios
    .get(site_ip + '/getCompanyById/' + params.id)
    .then((response) => {
      setValues(response.data.Content);
      // eslint-disable-next-line
      if(response.data.Content.country_id !== '' || response.data.Content.country_id != '0'){
        fetch(`${site_ip}/getState/${response.data.Content.country_id}`)
        .then((res) => res.json())
        .then((result) => {
            setStateList(result.States);
        });
      }      
    });
  }

  
  useEffect(()=>{
    companyData();
    // eslint-disable-next-line
  }, [])



  const fetchCountryData = (e) =>
    fetch(`${site_ip}/getCountryList`)
      .then((res) => res.json())
      .then((result) => {
        setCountryList(result.content);
      });

  const fetchIndustryData = (e) =>
    fetch(`${site_ip}/getIndustryList`)
      .then((res) => res.json())
      .then((result) => {
        setIndustryList(result.Content);
      });

  useEffect(() => {
    fetchCountryData();
    fetchIndustryData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'country_id') {
      fetchStateData(value);
    }
  };

  const fetchStateData = (countryid) => {
    fetch(`${site_ip}/getState/${countryid}`)
      .then((res) => res.json())
      .then((result) => {
        setStateList(result.States);
        setValues((prevState) => ({
          ...prevState,
          state_id: '',
        }));
      });
  };

  const handleSubmit = () => {
    if (
      values.company_name === '' ||
      values.company_add1 === '' ||
      values.company_add2 === '' ||
      values.company_city === ''
    ) {
      alert('Please fill required fields');
    } else {
      axios({
        method: 'POST',
        url: site_ip + '/setCompany',
        data: {
          company_id: values.company_id,
          company_name: values.company_name,
          company_add1: values.company_add1,
          company_add2: values.company_add2,
          company_city: values.company_city,
          emp_count: values.emp_count,
          industry_id: values.industry_id,
          company_url: values.company_url,
          company_board_no: values.company_board_no,
          signed_on: values.signed_on,
          state_id: values.state_id,
          country_id: values.country_id,
          company_email: values.company_email,
          contact_person: values.contact_person,
          // company_board_no: values.company_board_no,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.Status === 200) {
            Swal.fire({
                title: 'Success',
                text: 'Company data updated successfully.',
                icon: 'success',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            navigate('/admin/company-list');
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div align="right" style={{marginBottom:"15px"}}>
            <Link to={{ pathname: '/admin/company-list' }}>
              <Button className="btn btn-success">Company List</Button>
            </Link>
          </div>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Edit Company</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>
                        Company Name:<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name="company_name"
                        type="text"
                        className="mb-3"
                        placeholder="Enter company name"
                        value={values.company_name}
                        onChange={handleChange}
                      />
                      <span style={{ color: 'red' }}></span>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>
                        Company Add1:<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name="company_add1"
                        type="text"
                        className="mb-3"
                        placeholder="Enter company address 1"
                        value={values.company_add1}
                        onChange={handleChange}
                      />
                      <span style={{ color: 'red' }}></span>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>
                        Company Add2:<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        name="company_add2"
                        type="text"
                        className="mb-3"
                        placeholder="Enter company address 2"
                        value={values.company_add2}
                        onChange={handleChange}
                      />
                      <span style={{ color: 'red' }}></span>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>
                        Company City:<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        text="text"
                        placeholder="City"
                        name="company_city"
                        value={values.company_city}
                        onChange={handleChange}
                      />

                      <span style={{ color: 'red' }}></span>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Employee Count</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Nos. of Employee"
                        name="emp_count"
                        value={values.emp_count}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} style={{marginBottom:"15px"}}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Company Url</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company URL"
                        name="company_url"
                        value={values.company_url}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Company Url</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Company Url"
                        name="company_url"
                        value={values.company_url}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col md={4}>
                    {' '}
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="industry_id"
                        value={values.industry_id}
                        onChange={handleChange}
                      >
                        <option value="0">Select Industry</option>
                        {industryList.map((value) => (
                          <option
                            key={value.industry_id}
                            value={value.industry_id}
                          >
                            {value.industry_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Company Board</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Company Board"
                        name="company_board_no"
                        value={values.company_board_no}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Signed On</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Signed on"
                        name="signed_on"
                        value={values.signed_on}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4} style={{marginBottom:"15px"}}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Country"
                        name="country_id"
                        value={values.country_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        {countryList.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.country_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="State"
                        name="state_id"
                        value={values.state_id}
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>
                        {stateList.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Company email</Form.Label>
                      <Form.Control
                        text="email"
                        placeholder="Company Email"
                        name="company_email"
                        value={values.company_email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Contact Person"
                        name="contact_person"
                        value={values.contact_person}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                    <Col md={4}></Col>      
                    <Col md={4} style={{textAlign:"center", marginTop:"15px", marginBottom:"15px"}}>
                     <Button onClick={handleSubmit}>Update</Button>
                    </Col>  
                    <Col md={4}></Col>    
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default EditCompany 

