import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSettings';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import "../../pages/afterlogin.css";
function TestPatternEdit() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const intialValue = {
    ct_exam_pattern_id: '',
    job_id: '',
    company_id: '',
    test_type_id: '',
    test_name: '',
    test_instructions: '',
    test_objectives: '',
    test_image: '',
    questions_count: '',
    ct_test_pattern_id: '',
    test_date: '',
    start_time: '',
    end_time: '',
    result_date: '',
    auto_select_yn: '',
    complexity_level: '',
    test_url: '',
    status: '',
    allow_review_yn: '',
    negative_marking_yn: '',
    time_for_each_question: '',
    test_price: '',
    test_free_paid_ind: '',
    shuffle_ques_yn: '',
    capture_candidate_photo: '',
    remote_proctoring_yn: '',
  };
  let navigate = useNavigate();
  const params = useParams();
  const [testPattern, setTestPattern] = useState(intialValue);
  const fetchTestByIdData = () =>
    fetch(`${site_ip}/getTestMasterById/${params.id}`).then((res) =>
      res.json()
    );
  useEffect(() => {
    fetchTestByIdData().then((data) => setTestPattern(data.Content));
    //eslint-disable-next-line
  }, []);

  // const [isLoading, setLoadning] = useState(false);
  // const [isBasic, setBasic] = useState(false);
  const [testtype, getTestType] = useState(null);
  const fetchTestTypeData = () =>
    fetch(`${site_ip}/getAssessmentType`).then((res) => res.json());
  useEffect(() => {
    fetchTestTypeData().then((data) => getTestType(data.Content));
  }, []);
  const [job, getJob] = useState(null);
  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [complexity, getComplexity] = useState(null);
  const fetchComplexityData = () =>
    fetch(`${site_ip}/getQuestionComplexity`).then((res) => res.json());
  useEffect(() => {
    fetchComplexityData().then((data) => getComplexity(data.content));
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestPattern((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [errors, setErrors] = useState({});
  const validate = (testPattern) => {
    let errors = {};
    if (!testPattern.job_id) {
      errors.job_id = 'Job id is required';
    }
    if (!testPattern.test_type_id) {
      errors.test_type_id = 'Test Type is required';
    }
    if (!testPattern.test_name) {
      errors.test_name = 'Test Name is reqired';
    }
    if (!testPattern.questions_count) {
      errors.questions_count = 'Question Count is required';
    }
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(testPattern);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      axios({
        method: 'post',
        url: site_ip + '/setTestMaster',
        data: {
          test_id: testPattern.test_id,
          job_id: testPattern.job_id,
          company_id: userdata.company_id,
          test_type_id: testPattern.test_type_id,
          test_name: testPattern.test_name,
          test_price: testPattern.test_price,
          test_instructions: testPattern.test_instructions,
          test_objectives: testPattern.test_objectives,
          test_image: testPattern.test_image,
          questions_count: testPattern.questions_count,
          test_date: testPattern.test_date,
          start_time: testPattern.start_time,
          end_time: testPattern.end_time,
          result_date: testPattern.result_date,
          //auto_select_yn: testPattern.auto_select_yn,
          complexity_level: testPattern.complexity_level,
          test_url: testPattern.test_url,
          status: testPattern.status,
          // allow_review_yn: testPattern.allow_review_yn,
          negative_marking_yn: testPattern.negative_marking_yn,
          time_for_each_question: testPattern.time_for_each_question,
          // shuffle_ques_yn: testPattern.shuffle_ques_yn,
          test_free_paid_ind: testPattern.test_free_paid_ind,
          //capture_candidate_photo: testPattern.capture_candidate_photo,
          //remote_proctoring_yn: testPattern.remote_proctoring_yn,
        },
        dataType: 'json',
      })
        .then(function (response) {
          if (response.data.Status === 200) {
            navigate('/test-setup-edit?id='+params.id, { state: { questions_count: testPattern.questions_count, auto_select: testPattern.auto_select_yn } });
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      console.log('errors try again', validationErrors);
    }
  };

  return (
    <Row className="admin-form-row" style={{ margin: "0px" }}>
      <div className="header">
          <h4>Update Test</h4>
      </div>
      <Row>
        <Col md={4}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Test Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Test Name"
              name="test_name"
              onChange={handleChange}
              value={testPattern.test_name || ''}
            />
          </Form.Group>
          {errors.test_name && (
            <p style={{ color: 'red' }}>{errors.test_name}</p>
          )}
        </Col>
        <Col md={4}>
          <Form.Group controlId="formjob">
            <Form.Label>Job </Form.Label>
            <Form.Control
              as="select"
              name="job_id"
              className="mb-3"
              onChange={handleChange}
              value={testPattern.job_id || ''}
            >
              <option>Select Job</option>
              {job?.map((value) => (
                <option value={value.job_id}>{value.job_name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          {errors.job_id && (
            <p style={{ color: 'red' }}>{errors.job_id}</p>
          )}
        </Col>
        <Col md={4}>
          <Form.Group controlId="formjob">
            <Form.Label>Test Date </Form.Label>
            <Form.Control
              type="date"
              name="test_date"
              className="mb-3"
              onChange={handleChange}
              value={testPattern.test_date || ''}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formjob">
            <Form.Label>Result Date </Form.Label>
            <Form.Control
              type="date"
              name="result_date"
              className="mb-3"
              onChange={handleChange}
              value={testPattern.result_date || ''}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formjob">
            <Form.Label>Start Time </Form.Label>
            <Form.Control
              type="time"
              name="start_time"
              className="mb-3"
              onChange={handleChange}
              value={testPattern.start_time || ''}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formjob">
            <Form.Label>End Time </Form.Label>
            <Form.Control
              type="time"
              name="end_time"
              className="mb-3"
              onChange={handleChange}
              value={testPattern.end_time || ''}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Test type</Form.Label>
            <Form.Control
              as="select"
              className="mb-3"
              name="test_type_id"
              onChange={handleChange}
              value={testPattern.test_type_id || ''}
            >
              <option>Select Test type</option>
              {testtype?.map((value) => (
                <option value={value.assess_type_id}>
                  {value.assess_type_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {errors.test_type_id && (
            <p style={{ color: 'red' }}>{errors.test_type_id}</p>
          )}
        </Col>
        <Col md={4}>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Complexity Level</Form.Label>
            <Form.Control
              as="select"
              className="mb-3"
              name="complexity_level"
              onChange={handleChange}
              value={testPattern.complexity_level || ''}
            >
              <option>Select Complexity Level</option>
              {complexity?.map((value) => (
                <option value={value.id}>{value.complexity_name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Question Count</Form.Label>
            <Form.Control
              type="number"
              placeholder="Question Count"
              name="questions_count"
              onChange={handleChange}
              value={testPattern.questions_count || ''}
            />
          </Form.Group>
          {errors.questions_count && (
            <p style={{ color: 'red' }}>{errors.questions_count}</p>
          )}
        </Col>
        <Col md={4}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Time for each Question (in mintues)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Time for each Question"
              name="time_for_each_question"
              onChange={handleChange}
              value={testPattern.time_for_each_question || ''}
              className="mb-3 "
            />
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Test Image</Form.Label>
            <Form.Control
              type="file"
              name="test_image"
              onChange={handleChange}
              value={''}
            />
          </Form.Group>
        </Col>
        <Col md={4} className={"select-bottom-space"}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              placeholder="Status"
              name="status"
              onChange={handleChange}
              value={testPattern.status || ''}
            >
              <option>Status</option>
              <option value="Open">Open</option>
              <option value="Close">Close</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={4} className={"select-bottom-space"}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Negative Marking</Form.Label>
            <Form.Control
              as="select"
              placeholder="Negative Marking"
              name="negative_marking_yn"
              onChange={handleChange}
              value={testPattern.negative_marking_yn || ''}
            >
              <option value="N">No</option>
              <option value="Y">Yes</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Test Instruction</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Test Instruction"
              name="test_instructions"
              onChange={handleChange}
              value={testPattern.test_instructions || ''}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="exampleForm.NoOfQuestion">
            <Form.Label>Test Objective</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Test Objective"
              name="test_objectives"
              onChange={handleChange}
              value={testPattern.test_objectives || ''}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mt-3'>
          <button
            className="primary"
            onClick={handleSubmit}
          >
            Update
          </button>
        </Col>
      </Row>

    </Row>
  );
}

export default TestPatternEdit;
