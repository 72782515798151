import axios from "axios";
import React, { useEffect, useState } from "react";
import { site_ip } from "../../globalSettings";

import { Button,Col,Row,Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigation } from "react-router-dom";
import CustomTableStyle from "../customTableStyle"
import Swal from "sweetalert2";

function NewUserRequests(props){


    const userdata = JSON.parse(localStorage.getItem('userdata'));

    console.log(userdata,'User Data')



    const [requests, setRequests] = useState([]);
    const [isStatusChanged, setIsStatusChanged] = useState(false);

    useEffect(()=>{
        fetchAllNewRequests();
    },[isStatusChanged])

    const fetchAllNewRequests = async ()=>{
        try{
            const res =await  axios.get(`${site_ip}/auth/new-user-requests`);
            console.log(res,'RESS')

            if(res?.data.Status===200){
                setRequests(res.data.content)
            }
        }catch(err){
            console.log(err,'ERROR')
        }
    }

    const handleActionBtn=(item)=>{
            console.log(item)

            Swal.fire({
                title: "change company current status ?",
                icon: "success",
                confirmButtonText: "Confirmed",
                confirmButtonColor: "#45b161",
              }).then((result) => {
                if (result["isConfirmed"]) {
                //   window.location.href = "/";

                axios({
                    method:'post',
                    url:`${site_ip}/auth/update-company-status`,
                    data:{
                        item
                    }
                 }).then(function (response) {
                    console.log(response)
                    setIsStatusChanged(!isStatusChanged);
                  })
                }
              });
    }


    let tableHeader=[
        {
            name:'#',
            selector:(row,index)=>(index+1),
            width:"100px"
        },
        {
            name:"Company Name",
            selector:(row)=>row?.company_name
        },
        {
            name:"Company User",
            selector:(row)=>row?.company_user
        },
        {
            name:"Company Email",
            selector:(row)=>row?.company_email
        },
        {
            name:"Company Contact",
            selector:(row)=>row?.company_mobile
        },
        {
            name:"Status",
            selector:(row)=>{ return row?.status===0?'Inactive':'Active'}
        },
        {
            name:"Action",
            width:"150px",
            selector:(row)=>(
                <>
                <button
                className="btn btn-sm"
                role="button"
                style={{backgroundColor:'#4caf50', color: "#ffffff"}}
                onClick={()=>handleActionBtn(row)}
                ><i class="fas fa-pencil-alt"></i>
            {/* {row?.status===0?<i class="fa fa-toggle-off" aria-hidden="true"></i>
:<i class="fa fa-toggle-on" aria-hidden="true"></i>
} */}

                </button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]


    return (
        <React.Fragment>
            <Row className="align-items-center" >
                <Col md={6}>
                <p className="admin-page-title"><i class="fas fa-list-ol"></i>  New Requests</p>
                </Col>
            </Row>

            <Row>
                <Col xl={12} className="mt-3">
                    <div className="p-3 border bg-white">
                        <DataTable columns={tableHeader} data={requests} pagination customStyles={CustomTableStyle} />
                            {/* <Table striped hover size="sm" className="mt-4">
                                <thead className="lt-head">
                                    <tr>
                                    <th>Action</th>
                                    <th>Company Name</th>
                                    <th>Company User</th>
                                    <th>Company Email</th>
                                    <th>Company Contact</th>
                                    <th>Request Status</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {requests.map((request)=>(
                                        <tr key={request.id}>
                                        <td>Button</td>
                                        <td>{request.company_name}</td>
                                        <td>{request.company_user}</td>
                                        <td>{request.company_email}</td>
                                        <td>{request.company_mobile}</td>
                                        <td>{request.status===0?'InActive':'Active'}</td>
                                        </tr>
                                       
                                    ))}
                                </tbody>
                            </Table> */}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )

}


export default NewUserRequests;