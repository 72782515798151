import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap-floating-label';
import OtpInput from 'react-otp-input';
// import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { site_ip } from '../../globalSettings.js';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Exam.css';
function HomeSection(props) {
  let navigate = useNavigate();
  const { test_id } = useParams();

  console.log(test_id, 'TEST ID');

  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [showResults, setShowResults] = React.useState(false);
  const [email, setEmail] = useState('');
  const [errroMsg, setErrorMsg] = useState('');
  // useEffect(() => {
  //   if (isLoading) {
  //     simulateNetworkRequest().then(() => {
  //       setLoading(false);
  //       setShowResults(true);
  //     });
  //   }
  // }, [isLoading]);

  const handleClick = (e) => {
    setLoading(true);
    handleChangeEmail();
  };

  //state = { otp: "" };
  // var display = 'none';
  const handleChange = (otp) => {
    setOtp(otp);
    // display = 'block';
  };
  // console.log(display);
  // console.log(props.otptoken_list.data?.Content);
  const handleSubmit = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/verifyCandidateEmailOTP ',
      data: {
        candidate_email: email,
        email_otp: otp,
        test_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        localStorage.setItem(
          'TestDetails',
          JSON.stringify(response.data.TestDetails)
        );
        localStorage.setItem(
          'candidate_id',
          JSON.stringify(response.data.Candidate)
        );

        localStorage.setItem(
          'candidate_token',
          JSON.stringify(response.data.AccessToken)
        );
        navigate('/instructions');
        console.log(response);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  function hai(e) {
    setEmail(e.target.value);
    setErrorMsg('');
  }

  const handleChangeEmail = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/sendCandidateEmailOTP',

      data: {
        candidate_email: email,
        test_id,
      },
      dataType: 'json',
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.Status === 200) {
          setLoading(false);
          setShowResults(true);
        } else if (response.data.Status === 400) {
          setErrorMsg(response.data.Content);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  return (
    <Row style={{ margin: '0px' }}>
      {/* <div> */}
      {/* <div className="bg-candidate"> */}
      {/* <div class="divL"> */}
      {/* <div class="loginLZone">
            <div class="logoBody">
              <img
                src="../images/quantum-logo.png"
                alt="quantum logo"
                width="250"
              />
            </div>
            <Image
              src="../images/assessment.jpg"
              className="bannerImg img-fluid"
            />
            <h6 className="login-screen-description-text">
              {' '}
              Talent Management - Enable you to make informed decisions.
            </h6>
            <h6 className="login-screen-description-text">
              {' '}
              We empower and enable you to achieve new heights and wish you all
              the very best.
            </h6>
          </div> */}
      {/* </div> */}
      <Col
        md={6}
        className="align-items-center justify-content-center flex pos-relative"
        style={{ flexDirection: 'column' }}
      >
        <div>
          <img src="/home/logo.png" className="header-logo-img" />
        </div>
        <p
          className="text-center heading-1"
          style={{ fontWeight: 'bold', marginTop: '20px' }}
        >
          CANDIDATE LOGIN
        </p>
        <div className="login-box-div">
          <div class="align-items-center emailBox">
            <div className="inputTextBox">
              <FloatingLabel
                label="Enter Your Email "
                size="sm"
                value={email}
                onChange={hai}
              />
            </div>
            <p style={{ color: '#ff0000', fontSize: '14px' }}>{errroMsg}</p>
            <div
              style={{ display: showResults ? 'block' : 'none' }}
              className="otpBlock otpBox"
            >
              <label>Please Enter the OTP</label>
              {/* <OtpInput
                    value={otp}
                    className="otpT m-1"
                    inputStyle={{ width: '100%' }}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                  /> */}

              <OtpInput
                value={otp}
                className="otpT m-1"
                inputStyle={{ width: '100%' }}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <Row className="mt-3">
              <Col>
                {!showResults ? (
                  <button
                    disabled={isLoading}
                    className="primary-btn"
                    style={{ padding: '5px 10px', fontSize: '13px' }}
                    onClick={!isLoading ? handleClick : null}
                  >
                    {isLoading ? 'Sending…' : 'Send OTP'}
                  </button>
                ) : (
                  <button
                    disabled={isLoading}
                    className="primary-btn"
                    style={{ padding: '5px 10px', fontSize: '13px' }}
                    onClick={!isLoading ? handleClick : null}
                  >
                    {isLoading ? 'Sending…' : 'Resend OTP'}
                  </button>
                )}
              </Col>
              <Col>
                {showResults ? (
                  <button
                    className="primary-btn"
                    style={{ padding: '5px 10px', fontSize: '13px' }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col className="bg-dark-blue" style={{ height: '100vh' }}></Col>
      {/* </div> */}
      {/* </div> */}
    </Row>
  );
}

export default HomeSection;
