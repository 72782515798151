import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './Header.css';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    
    console.log('')

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`sticky ${isSticky ? 'fix-header' : ''}`}>
      <Container>
        <Row style={{ margin: "0px" }}>
          <Col md={3} style={{ padding: 0 }}>
            <img src="/home/logo.png" className="header-logo-img" />
          </Col>
          <Col md={9}>
            <div className={`navigation ${isMenuOpen ? 'open' : ''}`}>
              <div className="menu-toggle" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul>
                <li>
                  <a aria-current="page" className="active" href="/">Home</a>
                </li>
                <li>
                  <a className="" href="#solutions">Solutions</a>
                </li>
                {/* <li>
                  <a className="" target='_blank' href="https://learn.quantuminsight.in">Contents</a>
                </li> */}
                <li>
                  <a className=""  onClick={()=> navigate('/contact-us')} >Contact Us</a>
                </li>
                <li>
                  <button  onClick={() => navigate('/signup')}  className="demo-button" >Register</button>
                </li>
                <li>
                  <button  onClick={() => navigate('/login')} className="demo-button">Login</button>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
