import React from 'react';
import { Row, Col, Container, Dropdown } from 'react-bootstrap';
import './topbar.css';
function TopBar() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  return (
    <div className="topband sticky-top" >
      {/* <div className="logo-container" >
        <img className="logo-img1" style={{width:'90%'}} alt="top logo" src="../images/logo.png" class="top-logo" /> 
      </div> */}
      <div className="header-container" >
      <Container fluid>
        <Row className="align-items-center">
          <Col className="ms-auto text-end" md={4}>
            <div className="userNameblock d-flex align-items-center">
              <span className="ms-auto">
                {userdata.user_name}
              </span>
              <span userpic>
                <Dropdown>
                  <Dropdown.Toggle className="userpic" id="dropdown-basic">
                    <i className="fas fa-user" id="dropdown-basic"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/profile">User Profile</Dropdown.Item>
                    {/* <Dropdown.Item href="#/action-2">
                      Edit Profile
                    </Dropdown.Item> */}
                    <Dropdown.Item href="/update-password">
                      Change Password
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
}

export default TopBar;
