import React from 'react';
// import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar from './content/common/TopBar';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '../pages/afterlogin.css';
// import Home from "./components/pages/Home";
// import LoginPage from './components/pages/LoginPage';
import LeftSideBar from './LeftSideBar';
import Login from './content/login';
import Dashboard from './content/Dashboard';

// import ViewIndustry from './content/industry/View';
import QuestionType from './content/questiontype/View';
import TestListing from './content/manageTest/TestListing';
import TestPatternAdd from './content/manageTest/TestPatternAdd';
import TestPatternEdit from './content/manageTest/TestPatternEdit';
import TestSetupAdd from './content/manageTest/TestSetupAdd';
import TestSetupEdit from './content/manageTest/TestSetupEdit';
import TestSuccess from './content/manageTest/Success';

import CompanyList from './content/company/ListCompany';
import AddCompany from './content/company/Add';
import EditCompany from './content/company/EditCompany';

// Master
// 1. Industries
import ManageIndustries from './content/masters/ManageIndustry';
import ManageJobs from './content/masters/ManageJobs';
import ManageSkills from './content/masters/ManageSkills';
import ViewQuestionTypes from './content/masters/ViewQuestionTypes';
import ViewQuestionComplexities from './content/masters/ViewQuestionComplexities';
import ViewTaxonomy from './content/masters/ViewTaxonomy';
import ManageQuestionTags from './content/masters/ManageQuestionTags';
import ViewCategories from './content/masters/ViewCategories';
import ManageSubjects from './content/masters/ManageSubjects';
import ManageSubjectTopics from './content/masters/ManageSubjectTopics';
import IndustryJobSubjects from './content/masters/IndustryJobSubjects';

//Interview
import ManageInterviewer from './content/interview/ManageInterviewer';
import ManageInterviewerList from './content/interview/interviewer/ManageInterviewerList';
import AddInterviewerList from './content/interview/interviewer/AddInterviewerList';
import EditInterviewerList from './content/interview/interviewer/EditInterviewerList';
import FeedbackQuestions from './content/interview/feedback/FeedbackQuestions';
import AddFeedbackQuestions from './content/interview/feedback/AddFeedbackQuestions';
import ListFeedbackQuestions from './content/interview/feedback/ListFeedbackQuestions';
import ScheduleInterview from './content/interview/ScheduleInterview';
import RequestInterviewFeedback from './content/interview/RequestInterviewFeedback';
// Users
import AdminUsers from './content/users/AdminUsers';
import CompanyUsers from './content/users/CompanyUsers';
import AddCompanyUsers from './content/users/AddCompanyUsers';
import EditCompanyUsers from './content/users/EditCompanyUsers';

//Site Learner
import SiteLearner from './content/users/SiteLearner';
import AddSiteLearner from './content/users/AddSiteLearner';
import EditSiteLearner from './content/users/EditSiteLearner';

// Analytics
import LoginAnalytics from './content/analytics/MainLoginList';
import CandidateResult from './content/analytics/CandidateResult';
import TestAnalytics from './content/analytics/TestAnalytics';
import CandidateList from './content/reports/CandidateList';
import AddCandidate from '../recruiter/content/recruitment/Candidate/Add';
import ManageCandidate from './content/manageCandidate/ManageCandidate';

// import SignupPage from "./components/pages/SignupPage";
// import FreeTrail from './components/pages/FreeTrail';
// import Career from './components/pages/Career';
// import Contactus from './components/Contactus';
// import CompanyDetails from './components/pages/CompanyDetailPage';
// import Profile from './components/pages/Profile';
// import ChangePassword from './components/pages/ChangePassword';
// import ExamQuestionAdd from './components/afterLogin/exam/Questions/ExamQuestionAdd';
// import ExamQuestionEdit from './components/afterLogin/exam/Questions/ExamQuestionEdit';
// import ExamQuestionSearch from './components/afterLogin/exam/Questions/ExamQuestionSearch';
// import ExamQuestionUpload from './components/afterLogin/exam/Questions/ExamQuestionUpload';
// import TestListing from './components/afterLogin/manageTest/TestListing';
// import TestPatternAdd from './components/afterLogin/manageTest/TestPatternAdd';
// import TestPatternEdit from './components/afterLogin/manageTest/TestPatternEdit';
// import TestSetupAdd from './components/afterLogin/manageTest/TestSetupAdd';
// import TestSetupEdit from './components/afterLogin/manageTest/TestSetupEdit';
// import TestSuccess from './components/afterLogin/manageTest/Success';
// import ManageCandidate from './components/afterLogin/manageCandidate/ManageCandidate';
// import CandidateAdd from './components/afterLogin/manageCandidate/CandidateAdd';
// import CandidateEdit from './components/afterLogin/manageCandidate/CandidateEdit';
// import AssignTest from './components/afterLogin/assignTest/AssignTest';
// import Company from './components/afterLogin/company/Company';
// import Skills from './components/afterLogin/company/Skills';
// import Job from './components/afterLogin/company/Job';
// import Questiontag from './components/afterLogin/company/Questiontag';
// import Subjects from './components/afterLogin/company/Subjects';
// import Topic from './components/afterLogin/company/Topic';
// import CandidateLogin from './components/candidate/HomeSection';
// import Instructions from "./components/candidate/Pages/Instructions";
// import Exam from "./components/candidate/Pages/Exam";
// import Thankyou from './components/candidate/Pages/Thankyou';
// import EPage from './components/candidate/EPage';

// // Reports and Analytics
// import CandidateList from './components/afterLogin/reports/CandidateList';
// import BlankPage from './components/afterLogin/manageCandidate/BlankPage';
// // Admin Pages
// import Admin from './components/backend/index';
function App() {
  
  const BlankLayout = (children) => <>{children}</>;

  const LayoutOfAfterLogin = (children) => (
    <div>
      <TopBar />
      <div style={{display:'flex',overflow:'hidden'}}>
        <LeftSideBar />
      
      <div  style={{padding:'15px', width:'100%'}} className="right-block">
        <div className="content-wrapper" style={{overflowY:'auto', height: "calc(100vh - 90px)", overflowX: "hidden"}}> {children}</div>
      </div>
      </div>
     
    </div>
  );

  return (
    <>  
    <Routes>
          <Route
            exact
            path="/"
            element={BlankLayout(<Login/>)}
          />{' '}
          <Route
            exact
            path="/dashboard"
            element={LayoutOfAfterLogin(<Dashboard/>)}
          />{' '}
          <Route
            exact
            path="/questiontype/View"
            element={LayoutOfAfterLogin(<QuestionType/>)}
          />{' '}
          <Route
            exact
            path="/test-listing"
            element={LayoutOfAfterLogin(<TestListing/>)}
          />{' '}
          <Route
            exact
            path="/test-add"
            element={LayoutOfAfterLogin(<TestPatternAdd/>)}
          />{' '}
          <Route
            exact
            path="/test-edit/:id"
            element={LayoutOfAfterLogin(<TestPatternEdit/>)}
          />{' '}
          <Route
            exact
            path="/test-setup-add"
            element={LayoutOfAfterLogin(<TestSetupAdd/>)}
          />{' '}
          <Route
            exact
            path="/test-setup-edit"
            element={LayoutOfAfterLogin(<TestSetupEdit/>)}
          />{' '}
          <Route
            exact
            path="/test-success"
            element={LayoutOfAfterLogin(<TestSuccess/>)}
          />{' '}
          <Route
            exact
            path="/company-list"
            element={LayoutOfAfterLogin(<CompanyList/>)}
          />{' '}
          <Route
            exact
            path="/company-add"
            element={LayoutOfAfterLogin(<AddCompany/>)}
          />{' '}
          <Route
            exact
            path="/company-edit/:id"
            element={LayoutOfAfterLogin(<EditCompany/>)}
          />{' '}
          {/* Industry */}
          <Route
            exact
            path="/manage-industries"
            element={LayoutOfAfterLogin(<ManageIndustries/>)}
          />{' '}
          {/* Jobs */}
          <Route
            exact
            path="/manage-jobs"
            element={LayoutOfAfterLogin(<ManageJobs/>)}
          />{' '}
          {/* View Question Types */}
          <Route
            exact
            path="/view-question-types"
            element={LayoutOfAfterLogin(<ViewQuestionTypes/>)}
          />{' '}
          {/* View Question Complexity */}
          <Route
            exact
            path="/view-question-complexities"
            element={LayoutOfAfterLogin(<ViewQuestionComplexities/>)}
          />{' '}
          {/* Taxonomy */}
          <Route
            exact
            path="/view-taxonomy"
            element={LayoutOfAfterLogin(<ViewTaxonomy/>)}
          />{' '}
          {/* Manage Skills */}
          <Route
            exact
            path="/manage-skills"
            element={LayoutOfAfterLogin(<ManageSkills/>)}
          />{' '}
          {/* View Question Tag */}
          <Route
            exact
            path="/manage-questiontags"
            element={LayoutOfAfterLogin(<ManageQuestionTags/>)}
          />{' '}
          {/* View Question Tag */}
          <Route
            exact
            path="/view-categories"
            element={LayoutOfAfterLogin(<ViewCategories/>)}
          />{' '}
          {/* View Question Tag */}
          <Route
            exact
            path="/manage-subjects"
            element={LayoutOfAfterLogin(<ManageSubjects/>)}
          />{' '}
          <Route
            exact
            path="/manage-subjecttopics"
            element={LayoutOfAfterLogin(<ManageSubjectTopics/>)}
          />{' '}
          <Route
            exact
            path="/industry-job-subjects"
            element={LayoutOfAfterLogin(<IndustryJobSubjects/>)}
          />{' '}
          <Route
            exact
            path="/manage-interviewer"
            element={LayoutOfAfterLogin(<ManageInterviewer/>)}
          />{' '}
          <Route
            exact
            path="/manage-interviewer-list"
            element={LayoutOfAfterLogin(<ManageInterviewerList/>)}
          />{' '}
          <Route
            exact
            path="/add-interviewer-list"
            element={LayoutOfAfterLogin(<AddInterviewerList/>)}
          />{' '}
          <Route
            exact
            path="/edit-interviewer-list/:id"
            element={LayoutOfAfterLogin(<EditInterviewerList/>)}
          />{' '}
          <Route
            exact
            path="/feedback-questions"
            element={LayoutOfAfterLogin(<FeedbackQuestions/>)}
          />{' '}
          <Route
            exact
            path="/add-feedback-questions"
            element={LayoutOfAfterLogin(<AddFeedbackQuestions/>)}
          />{' '}
          <Route
            exact
            path="/list-feedback-questions"
            element={LayoutOfAfterLogin(<ListFeedbackQuestions/>)}
          />{' '}
          <Route
            exact
            path="/schedule-interview"
            element={LayoutOfAfterLogin(<ScheduleInterview/>)}
          />{' '}
          <Route
            exact
            path="/request-interview-feedback"
            element={LayoutOfAfterLogin(<RequestInterviewFeedback/>)}
          />{' '}
          {/* Admin Users */}
          <Route
            exact
            path="/manage/backend-users"
            element={LayoutOfAfterLogin(<AdminUsers/>)}
          />{' '}
          {/* Company Users */}
          <Route
            exact
            path="/manage-company-users"
            element={LayoutOfAfterLogin(<CompanyUsers/>)}
          />{' '}
          <Route
            exact
            path="/add-company-users"
            element={LayoutOfAfterLogin(<AddCompanyUsers/>)}
          />{' '}
          <Route
            exact
            path="/edit-company-users/:id"
            element={LayoutOfAfterLogin(<EditCompanyUsers/>)}
          />{' '}
          {/* Site Learner */}
          <Route
            exact
            path="/manage-site-learner"
            element={LayoutOfAfterLogin(<SiteLearner/>)}
          />{' '}
          <Route
            exact
            path="/add-site-learner"
            element={LayoutOfAfterLogin(<AddSiteLearner/>)}
          />{' '}
          <Route
            exact
            path="/edit-site-learner/:id"
            element={LayoutOfAfterLogin(<EditSiteLearner/>)}
          />{' '}
          {/* Main Login Analytics */}
          <Route
            exact
            path="/main-login-analytics"
            element={LayoutOfAfterLogin(<LoginAnalytics/>)}
          />{' '}
          <Route
            exact
            path="/candidate-result/:id"
            element={LayoutOfAfterLogin(<CandidateResult/>)}
          />{' '}
          <Route
            exact
            path="/candidate-list"
            element={LayoutOfAfterLogin(<CandidateList/>)}
          />{' '}
          <Route
            exact
            path="/test-analytics"
            element={LayoutOfAfterLogin(<TestAnalytics/>)}
          />{' '}

          <Route
            exact
            path='/add-candidate'
            element={LayoutOfAfterLogin(<AddCandidate/>)}
          />{' '}

          <Route
            exact
            path='/search-question'
            element={LayoutOfAfterLogin(<ManageCandidate/>)}
          />{' '}
          
        </Routes>

       
    </>
  );
}

export default App;
