import React ,{ useState, useEffect} from "react";
import { Container, Row,Col,Button, Badge, Form } from "react-bootstrap";
import axios from "axios";
import { site_ip } from "../../globalSettings";
import { useNavigate, useLocation } from "react-router-dom";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import "./Exam.css";

function PracticeTestExamPage (props){


    let navigate = useNavigate();

    let location = useLocation();

    console.log(location.state.id,'Location')
    
  const [test_type] = useState(0);
  const [quest_id, setQuest_Id] = useState(0);
  const [optionStatus, setOptionStatus] = useState([]);
  const [option, setOption] = useState(0);
  const [attempted, setAttempted] = useState(0);
  const [toggle, setToggle] = useState(false);

  const [alreadySkippedQuestions, setAlreadySkippedQuestions] = useState([])
  // create a blank arrays of option for attempted/unattempted
  const [responsestatus, setResponseStatus] = useState([]);
  const [useranswers, setUserAnswer] = useState([]);

  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  let stored_question_list = JSON.parse(localStorage.getItem('question_list'));
  let instruction_lists = JSON.parse(localStorage.getItem('instruction_lists'));
// let stored_question_list;
// let instruction_lists;

  useEffect(() => {

 let question_listing = JSON.parse(localStorage.getItem('question_list'))
    console.log(question_listing);
    let blank_array = [];
    let blank_answers = [];
    for (let i = 0; i < question_listing.Count; i++) {
      blank_array.push(false);
      if (question_listing.Questions[i].ques_type_id === 1) {
        blank_answers.push([]);
      } else if (question_listing.Questions[i].ques_type_id === 2) {
        blank_answers.push(0);
      } else if (question_listing.Questions[i].ques_type_id === 3) {
        blank_answers.push(0);
      } else if (
        question_listing.Questions[i].ques_type_id === 4 ||
        question_listing.Questions[i].ques_type_id === 5
      ) {
        blank_answers.push('');
      }
    }

    console.log(blank_answers,'BLANK ANswers')
    setUserAnswer(blank_answers);
    setResponseStatus(blank_array);    //eslint-disable-next-line
    return ()=>{
        localStorage.removeItem('question_list');
    }
  }, []);



  const updateAnswers = (e) => {
    if (!submittedAnswers.includes(quest_id)) {
      setToggle(!toggle);

      if (stored_question_list.Questions[quest_id].ques_type_id === 1) {
        let new_ans = useranswers;
        if (new_ans[quest_id].includes(parseInt(e))) {
          new_ans[quest_id] = new_ans[quest_id].filter(
            (item) => item !== parseInt(e)
          );

          if (new_ans[quest_id].length === 0) {
            let new_status = responsestatus;
            new_status[quest_id] = false;
            setResponseStatus(new_status);
          } else {
            let new_status = responsestatus;
            new_status[quest_id] = true;
            setResponseStatus(new_status);
          }
          setUserAnswer(new_ans);
        } else {
          new_ans[quest_id].push(parseInt(e));
          setUserAnswer(new_ans);
        }
      } else if (stored_question_list.Questions[quest_id].ques_type_id === 2) {
        let new_ans = useranswers;
        new_ans[quest_id] = e;
        setUserAnswer(new_ans);
      } else if (stored_question_list.Questions[quest_id].ques_type_id === 3) {
        let new_ans = useranswers;
        new_ans[quest_id] = e;
        setUserAnswer(new_ans);
      } else if (
        stored_question_list.Questions[quest_id].ques_type_id === 4 ||
        stored_question_list.Questions[quest_id].ques_type_id === 5
      ) {
        let new_ans = useranswers;
        if (new_ans[quest_id] === '' && e.target.value !== '') {
          setAttempted(parseInt(attempted) + 1);
        } else if (new_ans[quest_id] !== '' && e.target.value === '') {
          setAttempted(parseInt(attempted) - 1);
        }
        new_ans[quest_id] = e.target.value;
        setUserAnswer(new_ans);
        // Change answer status
        if (e.target.value === '') {
          let new_status = responsestatus;
          new_status[quest_id] = false;
          setResponseStatus(new_status);
        } else {
          let new_status = responsestatus;
          new_status[quest_id] = true;
          setResponseStatus(new_status);
        }
      }
    }
  };

  
  const hChange1 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(stored_question_list?.Questions[quest_id].option1);
    //console.log(stored_question_list?.Questions[quest_id].option1);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };

  const hChange2 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(stored_question_list?.Questions[quest_id].option2);
    // console.log(stored_question_list?.Questions[quest_id].option2);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }

    options[quest_id] = true;
    setResponseStatus(options);
  };

  const hChange3 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(stored_question_list?.Questions[quest_id].option3);
    // console.log(stored_question_list?.Questions[quest_id].option3);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };

  const hChange4 = (value) => {
    // setCandidateResponse({ ans: value });
    setOption(stored_question_list?.Questions[quest_id].option4);
    // console.log(stored_question_list?.Questions[quest_id].option4);

    let options = responsestatus;
    if (!options[quest_id]) {
      setAttempted(parseInt(attempted) + 1);
    }
    options[quest_id] = true;
    setResponseStatus(options);
  };

  useEffect(() => {
    setOptionStatus(optionStatus);
    //eslint-disable-next-line
  }, []);
  
  let buttonList =  responsestatus.map((item, index) => {

    if(location.state.question_count>=(index+1)){
         return (
      // <span></span>
      <div key={index} className={item ? 'attempted' : 'unattempted'}>{index + 1}</div>
    );
    }

   
  });

  const submitUserAnswer = (isSkip) => {
    // console.log(useranswers);
    let submitted_answers = submittedAnswers;

    //when option get selected
    submitted_answers.push(quest_id);
    setSubmittedAnswers(submitted_answers);

    let user_response = null;
    let desc_ans = null;
    if (
      stored_question_list?.Questions[quest_id].ques_type_id === 4 ||
      stored_question_list?.Questions[quest_id].ques_type_id === 5
    ) {
      desc_ans = useranswers[quest_id];
    } else if (stored_question_list?.Questions[quest_id].ques_type_id === 3) {
      user_response = {
        ans: useranswers[quest_id].toString(),
      };
    } else if (stored_question_list?.Questions[quest_id].ques_type_id === 2) {
      user_response = {
        ans: useranswers[quest_id].toString(),
      };
    } else if (stored_question_list?.Questions[quest_id].ques_type_id === 1) {
      user_response = {
        ans: useranswers[quest_id].sort().toString(),
      };
    }

    let dd = {
      candidate_id: JSON.parse(localStorage.getItem('candidate_id')),
      question_id: stored_question_list?.Questions[quest_id].ques_id,
      //option_id:option,
      co_question_id: stored_question_list?.Questions[quest_id].ques_id,
      attempt_status: isSkip?3:2,
      gain_marks: stored_question_list?.Questions[quest_id].marks,
      time_taken: stored_question_list?.Questions[quest_id].time_allowed,
      subject_id: stored_question_list?.Questions[quest_id].subject_id,
      job_id: instruction_lists?.Tests[test_type].job_id,
      // test_attempt_id: props.attempt_list?.data.Test,
      exam_type: instruction_lists?.Tests[test_type].test_type,
      topic_id: stored_question_list?.Questions[quest_id].topic_id,
      cand_responses: user_response,
      can_resp_desc_ans: desc_ans,
      test_id:stored_question_list?.Questions[quest_id].test_id
    }

    console.log(dd,'DATA RESPONSE')

    
        let new_question = quest_id + 1;
        if (quest_id < stored_question_list?.Count - 1) {
          setQuest_Id(new_question);
          setOption(0);
        } else {
          // alert('Quiz completed.');
        //   MailSentExam();
        }

    // axios({
    //   method: 'post',
    //   url: site_ip + '/saveCandidateTestResponses',
    //   data:dd,
    //   dataType: 'json',
    // })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log('error' + error);
    //   });

    // let new_question = quest_id + 1;
    //       if (quest_id < stored_question_list?.Count - 1) {
    //         setQuest_Id(new_question);
    //         setOption(0);
    //       } else {
    //         alert('Quiz completed.');
    //       }
  };


   const MailSentExam = () => {

    let candidate_id= JSON.parse(localStorage.getItem('candidate_id'));
    let test_id = JSON.parse(localStorage.getItem('TestDetails')).test_id;
    axios({
      method: 'post',
      url: site_ip + '/setCandidateResult',
      data: {
        candidate_id: candidate_id,
        test_id
      },
      dataType: 'json',
    }).then((response) => {
      // console.log(response);
      if (response.status === 200) navigate('/thankyou');
    });
    // navigate('/thankyou');
    // const data = { candidate_id: props.otptoken_list?.data.Candidate };
    // const apiUrl = site_ip + `/sendCandidateMail/${data.candidate_id}`;
    // console.log(apiUrl);

    // fetch(apiUrl)
    //   .then((response) => response.json())
    //   .then((data) => {

    //     console.log('This data', data);
    //   });
  };

  const Next = () => {
    if (!submittedAnswers.includes(quest_id)) submitUserAnswer();
    else MailSentExam();
  };


  const renderAnything = (text) => {  
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text.replace(/&nbsp;/g, '').replace(/<br>/g, ''),
                }}
            />
        )
  };

  const renderOptionOne = (text) => { 
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };
  const renderOptionTwo = (text) => {
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };
  const renderOptionThree = (text) => {
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };
  const renderOptionFour = (text) => {
    return (
            <div
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
  };

  const children = ({ remainingTime }) => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    return `${hours}:${minutes}:${seconds}`;
  };


  const nextQuestion =(quest_id)=>{
    console.log(alreadySkippedQuestions)

    if(location.state.question_count>=parseInt(quest_id)+1){
        
if(alreadySkippedQuestions.includes(quest_id)){
  setQuest_Id(parseInt(quest_id) + 1);
}else{
  if (!submittedAnswers.includes(quest_id)){
    submitUserAnswer(true);
  } else{
    setQuest_Id(parseInt(quest_id) + 1);
  }
}
    }
   
}



  const goBack = (quest_id) => {
    let eles = alreadySkippedQuestions;
    eles.push(quest_id);
    setAlreadySkippedQuestions(eles);
    // Find the index of quest_id in the submittedAnswers array
    const indexToRemove = submittedAnswers.findIndex(answer => answer === quest_id);
    console.log(indexToRemove,'INDEX REMOVE')
    if (indexToRemove !== -1) {
      // Remove the element at the found index
      const updatedAnswers = submittedAnswers.slice();
      updatedAnswers.splice(indexToRemove, 1);
      console.log(updateAnswers,'UPDATED')
      // Update the submittedAnswers array
      setSubmittedAnswers(updatedAnswers);
    }
    
    // Update the Quest_Id state, subtracting 1 from the current value
    setQuest_Id(prevQuestId => Math.max(0, prevQuestId - 1));
  };
  

  return (
    <>
      <Container fluid className={'exam-background'}>
        <Row className="align-items-stretch h-100">
          <div className="w-100 mx-auto bg-light headertop exam-header">
            <div class="left-box  d-flex align-items-center pt-2">
              <div class="d-inline-block align-middle mar-t12">
                <h4 class="mar-t0 mar-b8">
                  {instruction_lists?.Tests[test_type].test_name}
                </h4>
              </div>
              <div class="ms-auto text-end">
                <h6>
                  {parseInt(quest_id) + 1}/{stored_question_list?.Count} Question
                </h6>
                {/* <span><Stopwatch></Stopwatch></span> */}
              </div>
            </div>
          </div>
          <Col md={9} className="mx-auto my-3">
            {useranswers.length > 0 ? 
            <div className="border p-4 px-3 question-box">
              <div class="row">
                <div class="col-md-12">
                  <div style={{ height: '62vh', overflowY:'auto' }}>
                    <div className="p-3">
                      <p class="question" style={{ fontWeight: 'bold' }}>
                        Question No.{location.state.question_count>=(parseInt(quest_id) + 1)? parseInt(quest_id) + 1 : null}
                        <Button style={{ float:'right'}} onClick={()=>navigate(`/edit-question/${stored_question_list?.Questions[quest_id].ques_id}`)} >Edit</Button>
                      </p>
                      <p class="question">
                        <td>
                           {location.state.question_count>=(parseInt(quest_id) + 1)? renderAnything(
                            stored_question_list?.Questions[quest_id].ques_desc
                          ):null}
                        </td>
                      </p>
                      {
                        location.state.question_count>=(parseInt(quest_id)+1)?<>
                          {stored_question_list?.Questions[quest_id].ques_type_id ===
                      1 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="checkbox"
                                id={
                                  stored_question_list?.Questions[quest_id]
                                    .option1
                                }
                                value={option}
                                label={renderOptionOne(
                                  stored_question_list?.Questions[quest_id]
                                    .option1
                                )}
                                checked={useranswers[quest_id].includes(1)}
                                onChange={(e) => {
                                  hChange1('option1');
                                  updateAnswers(1);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                name={quest_id}
                                type="checkbox"
                                id={option}
                                label={renderOptionTwo(
                                  stored_question_list?.Questions[quest_id]
                                    .option2
                                )}
                                checked={useranswers[quest_id].includes(2)}
                                onChange={(e) => {
                                  hChange2('option2');
                                  updateAnswers(2);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="checkbox"
                                id={option}
                                label={renderOptionThree(
                                  stored_question_list?.Questions[quest_id]
                                    .option3
                                )}
                                checked={useranswers[quest_id].includes(3)}
                                onChange={(e) => {
                                  hChange3('option3');
                                  updateAnswers(3);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="checkbox"
                                id={option}
                                label={renderOptionFour(
                                  stored_question_list?.Questions[quest_id]
                                    .option4
                                )}
                                checked={useranswers[quest_id].includes(4)}
                                onChange={(e) => {
                                  hChange4('option4');
                                  updateAnswers(4);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {stored_question_list?.Questions[quest_id].ques_type_id ===
                      2 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={
                                  stored_question_list?.Questions[quest_id]
                                    .option1
                                }
                                value={option}
                                label={renderOptionOne(
                                  stored_question_list?.Questions[quest_id]
                                    .option1
                                )}
                                checked={useranswers[quest_id] === 1}
                                onChange={(e) => {
                                  hChange1('option1');
                                  updateAnswers(1);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                name={quest_id}
                                type="radio"
                                id={option}
                                label={renderOptionTwo(
                                  stored_question_list?.Questions[quest_id]
                                    .option2
                                )}
                                checked={useranswers[quest_id] === 2}
                                onChange={(e) => {
                                  hChange2('option2');
                                  updateAnswers(2);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="radio"
                                id={option}
                                label={renderOptionThree(
                                  stored_question_list?.Questions[quest_id]
                                    .option3
                                )}
                                checked={useranswers[quest_id] === 3}
                                onChange={(e) => {
                                  hChange3('option3');
                                  updateAnswers(3);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name={quest_id}
                                type="radio"
                                id={option}
                                label={renderOptionFour(
                                  stored_question_list?.Questions[quest_id]
                                    .option4
                                )}
                                checked={useranswers[quest_id] === 4}
                                onChange={(e) => {
                                  hChange4('option4');
                                  updateAnswers(4);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {stored_question_list?.Questions[quest_id].ques_type_id ===
                      3 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={'True'}
                                value={option}
                                label={'True'}
                                checked={useranswers[quest_id] === 1}
                                onChange={(e) => {
                                  hChange1('True');
                                  updateAnswers(1);
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="mb-4">
                            <div class="border-bottom p-2 w-100 h-100 answers ">
                              <Form.Check
                                name="radio"
                                type="radio"
                                id={'False'}
                                value={option}
                                label={'False'}
                                checked={useranswers[quest_id] === 2}
                                onChange={(e) => {
                                  hChange2('False');
                                  updateAnswers(2);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {stored_question_list?.Questions[quest_id].ques_type_id ===
                        4 ||
                      stored_question_list?.Questions[quest_id].ques_type_id ===
                        5 ? (
                        <Row>
                          <Col md={12} className="mb-4">
                            <div class="p-2 w-100 h-100 answers">
                              <Form.Control
                                as="textarea"
                                onChange={(e) => updateAnswers(e)}
                                value={useranswers[quest_id]}
                                placeholder="Answer here..."
                                style={{ height: '40vh' }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                        </>
                          :'Questions Limit Reached!'
                      }
                    
                    </div>
                  </div>
                </div>
              </div>

              <div class="btnbox d-flex align-items-center mt-3 top-line">
                <Col md={3}>
                  {quest_id > 0 ? (
                    <Button
                      onClick={() => {
                        // setQuest_Id(parseInt(quest_id) - 1);
                        goBack((parseInt(quest_id) - 1));
                      }}
                      variant="outline-danger"
                    >
                      Back
                    </Button>
                  ) : null}
                </Col>
                <Col md={6} style={{ textAlign: 'center' }}>
                  {quest_id !== parseInt(stored_question_list?.Count) - 1 ? (
                    submittedAnswers.includes(quest_id) ? null : (
                      <Button
                        disabled={
                          responsestatus[quest_id] === true ? false : true
                        }
                        className="btn ms-3 toggle-btn"
                        onClick={Next}
                      >
                        Save & Next
                      </Button>
                    )
                  ) : null}

                  {quest_id === parseInt(stored_question_list?.Count) - 1 ? (
                    <Button
                      disabled={
                        responsestatus[quest_id] === true ? false : true
                      }
                      className="btn ms-3 toggle-btn"
                      onClick={Next}
                    >
                      Finish
                    </Button>
                  ) : null}
                </Col>
                <Col md={3} style={{ textAlign: 'right' }}>
                  {quest_id !== parseInt(stored_question_list?.Count) - 1 ? (
                    <Button
                      onClick={() => nextQuestion(parseInt(quest_id))
                        // setQuest_Id(parseInt(quest_id) + 1);

                      }
                      variant="primary"
                    >
                      Next
                    </Button>
                  ) : null}
                </Col>

                <div class="mx-auto">
                  {/* <Link to="/" className="btn btn-danger ms-3">
                    Next
                       </Link>*/}
                </div>
                {/*<Link to="/thankyou" className="btn btn-danger  ms-3">
                  Finish
                      </Link>*/}
              </div>
            </div> : null }
          </Col>
          <Col md={3} className="my-3">
            <div
              class="bg-light border p-3 h-30 fix-right-nav question-box"
              style={{ textAlign: 'center' }}
            >
              <div style={{ width: '120px', display: 'inline-block' }}>
                <CountdownCircleTimer
                  isPlaying
                  size={120}
                  duration={3000}
                  colors={[
                    ['#004777', 0.33],
                    ['#F7B801', 0.33],
                    ['#A30000', 0.33],
                  ]}
                  children={children}
                >
                  {/* {({ remainingTime }) => console.log(remainingTime)} */}
                </CountdownCircleTimer>
              </div>
            </div>
            <div
              class="my-3 bg-light border p-3 fix-right-nav question-box"
              style={{ height: 'calc(100% - (3rem + 120px)' }}
            >
              <div class="d-flex align-items-center border-bottom mb-2 pb-2 px-2">
                <span class="me-4 d-flex align-items-center">
                  <Badge pill className="badge bg-success rounded-0 me-2">
                    {attempted}
                  </Badge>
                  <span style={{ fontSize: '11px' }}>Attempted</span>
                </span>
                <span class="d-flex align-items-center">
                  <Badge
                    pill
                    className="badge bg-white text-dark border rounded-0 me-2"
                  >
                    {parseInt(stored_question_list?.Count) - attempted}
                  </Badge>
                  <span style={{ fontSize: '11px' }}>Unattempted</span>
                </span>
              </div>
              <div
                class="numbering-block d-flex flex-wrap align-items-center"
                style={{ display: 'flex' }}
              >
                {buttonList}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PracticeTestExamPage;