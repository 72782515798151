import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Card,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../../../../customTableStyle';

export default function JDList(props) {
  const navigate = useNavigate();
  // const blankSearch = {
  //   jd_title:"",
  //   date_from: '',
  //   date_to: '',
  // }
  const [search, setSearch] = useState("");

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setSearch((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  
  const [candidateList, setCandidateList] = useState([]);
  const fetchCandidateList = () => {
    axios
      .get(site_ip + '/getCandidates')
      .then((res) => {
        setCandidateList(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchCandidateList();
  }, []);

  // const handleSearch = () => {
  //   axios
  //     .post(site_ip + '/searchJobDescription', search)
  //     .then((res) => {
  //       setCandidateList(res.data.Data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleReset = () => {
  //   setSearch(blankSearch)
  //   axios
  //     .post(site_ip + '/searchJobDescription', blankSearch)
  //     .then((res) => {
  //       setCandidateList(res.data.Data);
  //     })
  //     .catch((err) => console.log(err));
  // };


  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width:"100px"
    },
    {
      name: 'Name',
      selector: (row) => row?.candidate_name,
    },
    {
      name: 'Email',
      selector: (row) => row?.candidate_email,
    },
    {
      name: 'Mobile',
      selector: (row) => row?.candidate_mobile,
    },
    // {
    //   name: 'File Name',
    //   selector: (row) => attachmentName(row?.resume_file_name),
    // },
    // {
    //   name: 'Status',
    //   selector: (row) => row.resume_status === '1' ? 'Active' : 'Inactive',
    // },
    {
      name: 'Action',
      width: "150px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff" }}
            onClick={() => navigate('/recruiter/candidate/edit/' + row.candidate_id)}
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
          {/* <Link to={{ pathname: `/edit-question/${value.ques_id}` }}>
                      <i className="menu-icon fas fa-edit"></i>
                    </Link> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <>
      {/* <Row className="align-items-center">
        <Col md={2}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i>  Candidate List</p>
        </Col>
        <Col md={10} className="text-end">
        </Col>
      </Row> */}
      <Row>
        {/* <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="jd_title"
                      value={search.jd_title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_from"
                      value={search.date_from}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                      type="date"
                      name="date_to"
                      value={search.date_to}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="btn-box  border-top mt-3 pt-3">
                  <button
                    className="success"
                    onClick={handleSearch}
                  >
                    Apply
                  </button>
                  <button className="close" onClick={handleReset}>
                    Reset
                  </button>
                </div>
            </div>
          </div>
        </Col> */}
      </Row>
      <Row>
        <Col md={3}>
          <p className="admin-page-title"><i class="fas fa-filter"></i>  Filter</p>
          <div style={{border:"1px solid #ccc", marginTop:"3px", backgroundColor:"#ffffff", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"}}>
            <Form.Control
              type="text"
              placeholder='Search here...'
              name="search"
              value={search}
              style={{border:"none"}}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="filterList" style={{padding:"5px", borderTop:"1px solid #ccc"}}>
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Associated Job Openings"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Candidate Status"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Located within"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Candidate Stages"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="First Name"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Last Name"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="1st Tech Interview Date"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Final Tech Interview Date"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Status"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Source"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Email"
                className="mb-2"
              />
              <Form.Check
                type="checkbox"
                id="checkbox1"
                label="Mobile"
                className="mb-2"
              />
            </div>
          </div>
        </Col>
        <Col md={9}>
          <p className="admin-page-title"><i class="fas fa-list-ol"></i> Candidate List</p>
          <DataTable columns={tableHeader} data={candidateList} pagination customStyles={CustomTableStyle} />                
        </Col>
      </Row>
    </>
    // <Container>
    //   <Card>
    //     <Card.Header as="h4">Search Job Descriptions</Card.Header>
    //     <Card.Body>
    //       <Row>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Industry</Form.Label>
    //             <Form.Control
    //               as="select"
    //               name="industry_id"
    //               value={search.industry_id}
    //               onChange={handleChange}
    //             >
    //               <option value="">Select Industry</option>
    //               {industry?.map((item) => (
    //                 <option key={item.industry_id} value={item.industry_id}>
    //                   {item.industry_name}
    //                 </option>
    //               ))}
    //             </Form.Control>
    //           </Form.Group>
    //         </Col>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Job</Form.Label>
    //             <Form.Control
    //               as="select"
    //               name="job_id"
    //               value={search.job_id}
    //               onChange={handleChange}
    //             >
    //               <option value="">Select Job</option>
    //               {job?.map((item) => (
    //                 <option key={item.job_id} value={item.job_id}>
    //                   {item.job_name}
    //                 </option>
    //               ))}
    //             </Form.Control>
    //           </Form.Group>
    //         </Col>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Date From</Form.Label>
    //             <Form.Control
    //               type="date"
    //               name="date_from"
    //               value={search.date_from}
    //               onChange={handleChange}
    //             />
    //           </Form.Group>
    //         </Col>
    //         <Col md={3}>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Date To</Form.Label>
    //             <Form.Control
    //               type="date"
    //               name="date_to"
    //               value={search.date_to}
    //               onChange={handleChange}
    //             />
    //           </Form.Group>
    //         </Col>
    //         <Col md={4}></Col>
    //         <Col md={4}>
    //           <Button className="mt-2 mb-0 m-3" onClick={handleSearch}>
    //             Search
    //           </Button>
    //           <Button
    //             variant="warning"
    //             className="mt-2"
    //             onClick={() =>
    //               setSearch({
    //                 industry_id: '',
    //                 job_id: '',
    //                 date_from: '',
    //                 date_to: '',
    //               })
    //             }
    //           >
    //             Reset
    //           </Button>
    //         </Col>
    //         <Col md={4}></Col>
    //       </Row>
    //     </Card.Body>
    //   </Card>
    //   <br />
    //   <Card>
    //     <Card.Header as="h4">Job Description List</Card.Header>
    //     <Card.Body>
    //       <Table striped hover responsive>
    //         <thead>
    //           <tr>
    //             <th>ID</th>
    //             <th>JD File Name</th>
    //             <th>Industry</th> <th>Job</th>
    //             <th>Edit JD</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {jds.map((jd) => (
    //             <tr key={jd.jd_id}>
    //               <td>{jd.jd_id}</td>
    //               <td>{jd.jd_file_name}</td>
    //               <td>{jd.industry_id}</td> <td>{jd.job_id}</td>
    //               <td>
    //                 <Link to={'edit-jd/' + jd.jd_id}>Edit </Link>
    //               </td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </Table>
    //     </Card.Body>
    //   </Card>
    // </Container>
  );
}
