import React, { useState } from 'react';
// import { NavLink, Redirect } from 'react-router-dom';
// import '../../assets/scss/style.scss';
import Aux from '../hoc/_Aux';
// import Breadcrumb from '../layout/AdminLayout/Breadcrumb';
import { site_ip } from '../globalSetting';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import './Admin.css';
import { Col, Row, Image } from 'react-bootstrap';
import Swal from 'sweetalert2';
function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  //   useEffect(()=>{
  //       if(localStorage.getItem('quantam_admin_id') !== null){
  //         window.location.href = '/admin/dashboard';
  //       }
  //   }, []);

  const handleLogin = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/adminSignin',
      data: {
        user_email: email,
        password: password,
      },
      dataType: 'json',
    })
      .then(function (response) { 
        if (response.data.accessToken) {
          localStorage.setItem(
            'userdata',
            JSON.stringify(response.data.content)
          );
          localStorage.setItem(
            'quantam_admin_id',
            response.data.content.user_id
          );
          redirectUser();
        } else {
          alert('Invalid credentials');
        }
      })
      .catch(function (error) {
        console.log('error1' + error);
        Swal.fire({
          title:"Invalid Email or Password!",
          icon:"error"
        })
      });
  };

  const redirectUser = () => {
    setTimeout(() => {
      window.location.href = '/dashboard';
    }, 1000);
  };

  return (
    <Aux>
      <div>
        <Row style={{ margin: '0px' }} className="bg">
          <Col md={6}>{/* <div className="bg"></div> */}</Col>
          {/* <Col md={6} >
          
            </Col> */}
          <Col md={6} className="login-box">
            <div className="auth-content">
              {/* <div className="auth-bg">
                    <span className="r"/>
                    <span className="r s"/>
                    <span className="r s"/>
                    <span className="r"/>
                </div> */}
              <div className="card ">
                <div className="card-header login-card">
                  <Image src="../images/logo.png" className="top-logo" />
                </div>
                <div className="card-body text-center login-card">
                  <div className="mb-4">
                    <i className="feather icon-unlock auth-icon" />
                  </div>
                  <p className="mb-4 heading-black-text">Company's Admin Login</p>
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-group mb-4">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={handleChangePassword}
                    />
                  </div>

                  <button
                    className="btn btn-primary shadow-2 mb-4"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Aux>
  );
}

export default Login;
