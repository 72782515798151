import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../Login.css';
import WebcamComponent from '../Webcam';
import { site_ip } from '../../globalSettings.js';
const axios = require('axios');

function Profile(props) {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const initialValues = {
    user_id: userdata.id,
    first_name: userdata.first_name,
    last_name: userdata.last_name,
    user_email: userdata.user_email,
    middle_name: '',
    user_mobile: '',
    state_id: '',
    country_id: '',
    zipcode: '',
    company_name: '',
  };
  const [values, setValues] = useState(initialValues);
  const [country, setCountry] = useState(null);
  const [country_id, setCountry_Id] = useState('');
  const [states, getState] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false)
  const [profileImage, setProfileImage] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const SelectCountry = (e) => {
    setCountry_Id(e.target.value);
  };

  const fetchStateData = (value) =>
    fetch(`${site_ip}/getState/${value}`)
      .then((res) => res.json())
      .then((data) => getState(data.content));

  const fetchCountryData = (e) =>
    fetch(`${site_ip}/getCountryList`)
      .then((res) => res.json())
      .then((result) => {
        setCountry(result.content);
        console.log(country);
      });
  useEffect(() => {
    fetchCountryData();
    fetchStateData();
    //eslint-disable-next-line
  }, []);

  const [errors, setErrors] = useState({});
  const validate = (values) => {
    let errors = {};
    if (!values.user_email) {
      errors.user_email = 'Email address is required';
    }
    if (!values.user_mobile) {
      errors.user_mobile = 'Mobile Number is required';
    }
    if (!values.first_name) {
      errors.first_name = 'First Name is required';
    }
    if (!values.last_name) {
      errors.last_name = 'Last Name is required';
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      axios({
        method: 'post',
        url: `${site_ip}/auth/setSiteUser`,
        data: {
          user_id: values.user_id,
          first_name: values.first_name,
          last_name: values.last_name,
          middle_name: values.middle_name,
          user_email: values.user_email,
          user_mobile: values.user_mobile,
          zipcode: values.zipcode,
          country_id: country_id,
          state_id: values.state_id,
          company_name: values.company_name,
        },
        dataType: 'json',
      })
        .then(function (response) {
          setSubmitted(true);
          console.log(submitted);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log('errors try again', validationErrors);
    }
  };

  const profilePicChanged = (file) => {
    setWebcamVisible(false)

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      setProfileImage(reader.result)
    });
  }
  return (
    <Row style={{ margin: "0px" }}>
      <Col md={2} className="profile-sidebar bg-dark-blue" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <div className="profile-dp-div">
          <img src={profileImage === "" ? "/home/dp.png" : profileImage} />
          <button className="dp-edit-btn" onClick={() => setWebcamVisible(true)}>
            <i class="fas fa-pencil-alt"></i>
          </button>
        </div>
        <div>
          <p className="profile-name">{values.first_name + " " + values.middle_name + " " + values.last_name}</p>
          <p className="profile-email">{values.user_email}</p>
          <p className="profile-email">{values.user_mobile}</p>
        </div>
      </Col>
      <Col md={10}>
        <div className="profile-content-section">
          <p className="heading-1" style={{ color: "#000000", fontWeight: "bold" }}>
            Profile
          </p>
          <div>
            <Row>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">First Name</p>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  {errors.first_name && (
                    <p style={{ color: 'red' }}>{errors.first_name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Middle Name</p>
                  <Form.Control
                    type="text"
                    placeholder="Middle Name"
                    name="middle_name"
                    value={values.middle_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label" >Last Name</p>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  {errors.last_name && (
                    <p style={{ color: 'red' }}>{errors.last_name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicEmail"
                >
                  <p className="profile-form-label">Email address</p>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="user_email"
                    value={values.user_email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p style={{ color: 'red' }}>{errors.email}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Your Phone</p>
                  <Form.Control
                    type="text"
                    placeholder="User Mobile"
                    name="user_mobile"
                    value={values.user_mobile}
                    onChange={handleChange}
                  />
                  {errors.user_mobile && (
                    <p style={{ color: 'red' }}>{errors.user_mobile}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Your Zipcode</p>
                  <Form.Control
                    typeof="number"
                    name="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Your Country</p>
                  <Form.Control
                    as="select"
                    name="country_id"
                    value={country_id}
                    onChange={SelectCountry}
                  >
                    <option>Select Country</option>

                    {country?.map((value) => (
                      <option value={value.id}>
                        {value.country_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Your State</p>
                  <Form.Control
                    as="select"
                    name="state_id"
                    value={values.state_id}
                    onChange={handleChange}
                  >
                    <option>Select State</option>
                    {states?.map((value) => (
                      <option value={value.id}>{value.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6} style={{ marginBottom: "15px" }}>
                <Form.Group
                  controlId="formBasicPassword"
                >
                  <p className="profile-form-label">Your Company</p>
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    name="company_name"
                    value={values.company_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="align-items-center justify-content-center flex">
            <button className='primary-btn' onClick={()=>handleSubmit()}>SUBMIT</button>
          </div>
        </div>
      </Col>
      {webcamVisible ?
        <div className="webcam-div-modal">
          <div className="webcam-div">
            <WebcamComponent handleClose={() => setWebcamVisible(false)} selectedFile={(file) => profilePicChanged(file)} />
          </div>
        </div> : null}
    </Row>
  );
}

export default Profile;
