import React from "react"
import { Modal, Button } from "react-bootstrap";

const SuccessModal = (props) =>{
    return(
        <Modal show={props.successMessage} backdrop="static">
            <Modal.Body>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column' }}>
                    <i class="fas fa-check-circle" style={{ fontSize: "60px", color: "#4caf50" }}></i>
                    <p style={{ fontSize: "20px", color: "#4caf50", fontWeight: "bold", marginTop: "10px", marginBottom: "10px" }}>SUCCESS</p>
                    <p style={{ textAlign: 'center', fontSize: "15px", marginTop: "40px" }}>{props.successMessage}</p>
                    <Button className={"theme-primary-btn mb-15 mr-15"} onClick={() => props.setSuccessMessage(null)} >Okay</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SuccessModal