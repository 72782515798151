import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactStars from 'react-stars';
import { useNavigate, useLocation } from 'react-router-dom';

const QuestionFeedback = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [interviewer, setInterviewer] = useState({});
  const [questionlist, setQuestionList] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [additional, setAdditional] = useState('');
  const [selected, setSelected] = useState('No');
  const fetchQuestions = async (company, job) => {
    axios({
      method: 'post',
      url: site_ip + '/getIVFeedbackQuestionsByJob',
      data: {
        job_id: job,
        company_id: company,
      },
      dataType: 'json',
    })
      .then(function (response) {
        let initial_answers = [];
        for (let i = 0; i < response.data.Content.length; i++) {
          let answer = {
            question_id: response.data.Content[i].question_id,
            question_desc: response.data.Content[i].question_desc,
            question_type: JSON.parse(
              response.data.Content[i].question_options
            )[0],
            ans: '',
          };
          initial_answers.push(answer);
        }
        setAnswers(initial_answers);
        setQuestionList(response.data.Content);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  useEffect(() => {
    let interviewerData = localStorage.getItem('interviewer');
    if (interviewerData !== null && interviewerData !== undefined) {
      interviewerData = JSON.parse(interviewerData);
      setInterviewer(interviewerData);
      if (
        location.state.company === undefined ||
        location.state.job === undefined
      ) {
        navigate('/interviewer/login');
      } else {
        fetchQuestions(location.state.company, location.state.job);
      }
    } else {
      navigate('/interviewer/login');
    }
    //eslint-disable-next-line
  }, []);

  const renderQuestion = (text) => {
    return '';
  };

  const handleChangeAnswer = (e, index) => {
    let custans = answers;
    custans[index]['ans'] = e;
    setAnswers(custans);
    setToggle(!toggle);
  };
  // console.log(answers);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios({
      method: 'post',
      url: site_ip + '/submitIntervewFeedback',
      data: {
        id: location.state.request_id,
        // feedback_date: new Date(),
        feedback: answers,
        comments: additional,
        company_id: location.state.company,
        job_id: location.state.job,
        status: 'C',
        selected_yn: selected,
      },
    })
      .then((response) => {
        if (response.data.Sttaus === 200) {
          navigate('/interviewer/thankyou');
        } else {
          alert('Request Failed');
        }
      })
      .catch((error) => console.log(error));
    // console.log(answers);
  };

  const logOut = () => {};

  return (
    <div className="allBody">
      <div className="headerSection">
        <Container>
          <Row className="headerRow">
            <Col md={8} className="titleSection">
              <p>Feedback Questions</p>
            </Col>
            <Col md={2} className="userSection">
              <p className="userLink">
                Hi{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {interviewer?.interviewer_name}
                </span>
              </p>
            </Col>
            <Col md={2} className="userSection">
              <a
                href="/#"
                className="logout"
                onClick={(e) => {
                  e.preventDefault();
                  logOut();
                }}
              >
                Logout
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row className="listSection">
            {questionlist?.map((item, index) => {
              return (
                <div key={index} class="singleQuestion">
                  <div>
                    <p
                      style={{
                        marginBottom: '0px',
                        fontSize: '11px',
                        fontWeight: 'bold',
                        color: '#0000ff',
                        padding: '15px',
                      }}
                    >
                      Question No.{index + 1}
                    </p>
                  </div>
                  <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    {renderQuestion(item.question_desc)}
                  </div>
                  <div className="answerSection">
                    {item.question_type === 'descriptive' ? (
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="descriptive"
                          placeholder="Add your feedback here..."
                          value={answers[index].ans}
                          onChange={(e) =>
                            handleChangeAnswer(e.target.value, index)
                          }
                          style={{ height: '100px' }}
                        />
                      </Form.Group>
                    ) : null}
                    {item.question_type === 'multiple-choice' ? (
                      <Form.Group>
                        <RenderOptions
                          options={item.question_options}
                          onChange={(e) => handleChangeAnswer(e, index)}
                          value={answers[index].ans}
                        />
                      </Form.Group>
                    ) : null}

                    {item.question_type === 'rating' ? (
                      <Form.Group>
                        <ReactStars
                          count={parseInt(JSON.parse(item.question_options)[1])}
                          onChange={(e) => handleChangeAnswer(e, index)}
                          size={30}
                          value={answers[index].ans}
                          color2={'#ffd700'}
                        />
                      </Form.Group>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </Row>
          <Row className="listSection">
            <Col
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              Candidate Selected ?
            </Col>
            <Col md={6}>
              <Row>
                <Col md={3}>
                  <div class="p-2 w-100 h-100 answers">
                    <Form.Check
                      type="radio"
                      id={`radio-Yes-1`}
                      value={'Yes'}
                      label={'Yes'}
                      checked={selected === 'Yes'}
                      onChange={(e) => {
                        setSelected('Yes');
                      }}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div class="p-2 w-100 h-100 answers">
                    <Form.Check
                      type="radio"
                      id={`radio-No-2`}
                      value={'No'}
                      label={'No'}
                      checked={selected === 'No'}
                      onChange={(e) => {
                        setSelected('No');
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="listSection">
            <Col
              md={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              Comments
            </Col>
            <Col md={6} style={{ marginBottom: '20px', marginTop: '15px' }}>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  name="descriptive"
                  placeholder="Add your comments..."
                  value={additional}
                  onChange={(e) => setAdditional(e.target.value)}
                  style={{ height: '100px' }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="listSection">
            <Col md={3}></Col>
            <Col
              md={6}
              style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px',
              }}
            >
              <Button onClick={handleSubmit}>Submit</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    // <div className="feedbackPage">
    //   <Container>
    //     <br />
    //     <Row className="align-items-center">
    //       <Col md={3}></Col>
    //       <Col md={6}>
    //         <h6>Please answer these questions</h6>
    //       </Col>
    //       <Col md={3}></Col>
    //     </Row>
    //     <Row>
    //       <Col md={3}></Col>
    //       <Col md={6}>
    //         <div>
    //           {questionlist?.map((item, index) => {
    //             return (
    //               <div key={index} class="singleQuestion">
    //                 <div className="questionNumber">
    //                   <p>Question No.{index + 1}</p>
    //                 </div>
    //                 <div className="questionPart">
    //                   {renderQuestion(item.question_desc)}
    //                 </div>
    //                 <div className="answerSection">
    //                   {JSON.parse(item.question_options)[0] ===
    //                   'descriptive' ? (
    //                     <Form.Group>
    //                       <Form.Control
    //                         as="textarea"
    //                         name="descriptive"
    //                         placeholder="Add your feedback here..."
    //                         value={answers[index].ans}
    //                         onChange={(e) =>
    //                           handleChangeAnswer(e.target.value, index)
    //                         }
    //                         style={{ height: '100px' }}
    //                       />
    //                     </Form.Group>
    //                   ) : null}
    //                   {JSON.parse(item.question_options)[0] ===
    //                   'multiple-choice' ? (
    //                     <Form.Group>
    //                       <RenderOptions
    //                         options={item.question_options}
    //                         onChange={(e) => handleChangeAnswer(e, index)}
    //                         value={answers[index].ans}
    //                       />
    //                     </Form.Group>
    //                   ) : null}

    //                   {JSON.parse(item.question_options)[0] === 'rating' ? (
    //                     <Form.Group>
    //                       <ReactStars
    //                         count={parseInt(
    //                           JSON.parse(item.question_options)[2]
    //                         )}
    //                         onChange={(e) => handleChangeAnswer(e, index)}
    //                         size={30}
    //                         value={answers[index].ans}
    //                         color2={'#ffd700'}
    //                       />
    //                     </Form.Group>
    //                   ) : null}
    //                 </div>
    //               </div>
    //             );
    //           })}
    //         </div>
    //       </Col>
    //     </Row>
    //     <Row>
    //       <Col md={3}></Col>
    //       <Col md={3}>
    //         <Button onClick={handleSubmit}>Submit</Button>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  );
};

export default QuestionFeedback;

const RenderOptions = (props) => {
  const renderOption = (text) => {
    return (
      <div
        
      />
    );
  };
  let options = JSON.parse(props.options);

  return (
    <div>
      <Row>
        <Col md={12} className="mb-4">
          <div class="p-2 w-100 h-100 answers">
            <Form.Check
              type="radio"
              id={1}
              value={1}
              label={renderOption(options[0])}
              checked={props.value === 1}
              onChange={(e) => {
                props.onChange(1);
              }}
            />
          </div>
        </Col>
        <Col md={12} className="mb-4">
          <div class="p-2 w-100 h-100 answers ">
            <Form.Check
              type="radio"
              id={2}
              value={2}
              label={renderOption(options[1])}
              checked={props.value === 2}
              onChange={(e) => {
                props.onChange(2);
              }}
            />
          </div>
        </Col>
        <Col md={12} className="mb-4">
          <div class="p-2 w-100 h-100 answers">
            <Form.Check
              type="radio"
              id={3}
              value={3}
              label={renderOption(options[2])}
              checked={props.value === 3}
              onChange={(e) => {
                props.onChange(3);
              }}
            />
          </div>
        </Col>
        <Col md={12} className="mb-4">
          <div class="p-2 w-100 h-100 answers">
            <Form.Check
              type="radio"
              id={4}
              value={4}
              label={renderOption(options[3])}
              checked={props.value === 4}
              onChange={(e) => {
                props.onChange(4);
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
