import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Tab,
  Tabs,
  Form,
  Row,
  Table,
  Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { site_ip } from '../../../globalSettings';
import { site_ip } from '../../../../globalSettings';
import axios from 'axios';
function ManageCandidate() {
  const intialValues = {
    candidate_email: '',
    candidate_name: '',
    candidate_mobile: '',
    job_id: '',
  };
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [search, setSearch] = useState(intialValues);
  const handleSearch = (e) => {
    axios({
      method: 'post',
      url: site_ip + '/searchCompanyJobCandidates',
      data: {
        candidate_mobile: search.candidate_mobile,
        job_id: search.job_id,
        candidate_name: search.candidate_name,
        company_id: userdata.company_id,
        candidate_email: search.candidate_email,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  const [candidatelist, getCandidateList] = useState(null);
  const fetchCandidateListData = () =>
    fetch(`${site_ip}/getJobCandidatesList/` + userdata.company_id).then(
      (res) => res.json()
    );
  useEffect(() => {
    fetchCandidateListData().then((data) => getCandidateList(data.Content));
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <div class="align-items-center row">
        <div class="col-md-6">
          <h4>Manage Candidate</h4>
        </div>
        <Col md={6} className="text-end">
          <a href="/add-candidate" className="btn btn-dark me-3">
            <i class="fas fa-plus-square"></i> Add Candidate
          </a>
        </Col>
      </div>
      <Row>
        <Col>
          <div class="border bg-white p-3">
            <Row>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Name </Form.Label>
                    <Form.Control
                      type="text"
                      name="candidate_name"
                      value={search.candidate_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="candidate_email"
                      value={search.candidate_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type="number"
                      name="candidate_mobile"
                      value={search.candidate_mobile}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      as="select"
                      value={search.job_id}
                      name="job_id"
                      onChange={handleChange}
                    >
                      <option>Select your Position</option>
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={2} className="mt-4">
                <Form>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Button variant="primary" onClick={handleSearch}>
                      Search
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
          <div class="border bg-white p-3 h-100 mt-4">
            <Tabs defaultActiveKey="upcoming" className="mb-3">
              <Tab eventKey="upcoming" title="Active">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidatelist?.map((value, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{value.candidate_name}</td>
                        <td>{value.candidate_email}</td>
                        <td>{value.candidate_mobile}</td>
                        <td>{value.status}</td>

                        <td>
                          <Link
                            to={{
                              pathname: `/edit-candidate/${value.candidate_id}`,
                            }}
                          >
                            <span className="btn btn-primary">Edit</span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="completed" title="Completed"></Tab>
            </Tabs>
            <Modal
              show={show}
              closeButton="false"
              centered
              onHide={handleClose}
              className="rounded-0"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Candidates</Modal.Title>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </Modal.Header>
              <Modal.Body className="rounded-0">
                <Form>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Name
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control type="number" placeholder="Name" />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Email
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue="email@example.com"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="2">
                      Mobile
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control type="number" placeholder="Password" />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="2">
                      Position
                    </Form.Label>
                    <Col sm="10">
                      <Form.Select>
                        <option>Default select</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Save Details
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ManageCandidate;
