import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar from './content/common/TopBar';
import AuthHeader from './content/common/AuthHeader';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '../pages/afterlogin.css';
import LeftSideBar from './LeftSideBar';
import Login from './content/login';
import Signup from './content/Signup';
import Dashboard from './content/Dashboard';
import UnderDevelopment from './content/UnderDevelopment';

// Resume List
import ResumeList from './content/recruitment/ResumeList';
import EditResume from './content/recruitment/EditResumes';
import AddResume from './content/recruitment/AddResumes';
import BulkUploadResumes from './content/recruitment/BulkUploadResumes';

// Jobs
import JobsList from './content/recruitment/JobList';
import AddJob from './content/recruitment/AddCompanyJob';
import EditJob from './content/recruitment/EditCompanyJob';

// JobDescription
import JobDescriptionList from './content/recruitment/JDList';
import AddJobDescription from './content/recruitment/AddJobDescription';
import EditJobDescription from './content/recruitment/EditJobDescription';


// Candidate
import CandidateList from './content/recruitment/Candidate/List';
import CandidateAdd from './content/recruitment/Candidate/Add';
import CandidateEdit from './content/recruitment/Candidate/Edit';

import UserFeedbackQuestions from './content/userFeedback/userFeedbackQuestions';
import AddUserFeedbackQuestions from './content/userFeedback/addUserFeedbackQuestions';
import EditUserFeedbackQuestions from './content/userFeedback/editUserFeedbackQuestions';
// import ListUserFeedback from './content/userFeedback/ListUserFeedback';

//Interview
import ManageInterviewer from './content/interview/ManageInterviewer';
import ManageInterviewerList from './content/interview/interviewer/ManageInterviewerList';
import AddInterviewerList from './content/interview/interviewer/AddInterviewerList';
import EditInterviewerList from './content/interview/interviewer/EditInterviewerList';
import FeedbackQuestions from './content/interview/feedback/FeedbackQuestions';
import AddFeedbackQuestions from './content/interview/feedback/AddFeedbackQuestions';
import ListFeedbackQuestions from './content/interview/feedback/ListFeedbackQuestions';
import ScheduleInterview from './content/interview/ScheduleInterview';
import RequestInterviewFeedback from './content/interview/RequestInterviewFeedback';

// Referrals
import Referrals from './content/recruitment/Referrals/Referrals';
import ReferralAdd from './content/recruitment/Referrals/AddReferral';
import ReferralEdit from './content/recruitment/Referrals/EditReferral';

// Vendors
import Vendors from './content/recruitment/Vendor/List';
import VendorAdd from './content/recruitment/Vendor/Add';
import VendorEdit from './content/recruitment/Vendor/Edit';

// Customers 
import Customers from './content/recruitment/Customer/List';
import CustomerAdd from './content/recruitment/Customer/Add';
import CustomerEdit from './content/recruitment/Customer/Edit';
import AssignResumes from './content/recruitment/Customer/AssignResumes';

// Assigned Resumes
import AssignedResumes from './content/recruitment/AssignedResumes/List';

function App() {

  const LayoutOfLoginAndSignup = (children) => (
    <>
      <AuthHeader /> {children}
    </>
  );

  const LayoutOfAfterLogin = (children) => (
    <>
      <TopBar />
      <div style={{ display: "flex", overflow: "hidden" }}>
        <LeftSideBar />
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 80px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={LayoutOfLoginAndSignup(<Login />)}
        />{' '}
        <Route
          exact
          path="/signup"
          layout={LayoutOfLoginAndSignup}
          element={<Signup />}
        />{' '}
        <Route
          exact
          path="/dashboard"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<Dashboard />)}
        />
        <Route
          exact
          path="/candidate/list"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<CandidateList />)}
        />
        <Route
          exact
          path="/candidate/add"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<CandidateAdd />)}
        />
        <Route
          exact
          path="/candidate/edit/:id"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<CandidateEdit />)}
        />
        <Route
          exact
          path="/under-development"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<UnderDevelopment />)}
        />{' '}
        {/* Resumes */}
        <Route
          exact
          path="/resumes/list"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<ResumeList />)}
        />
        <Route
          exact
          path="/resumes/edit-resume/:id"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<EditResume />)}
        />
        <Route
          exact
          path="/resumes/add-resume"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<AddResume />)}
        />
        <Route
          exact
          path="/resumes/bulk-resumes"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<BulkUploadResumes />)}
        />

        {/* Jobs */}
        <Route
          exact
          path="/jobs/list"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<JobsList />)}
        />
        <Route
          exact
          path="/jobs/edit-job/:id"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<EditJob />)}
        />
        <Route
          exact
          path="/jobs/add-job"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<AddJob />)}
        />

        {/* Job Description */}
        <Route
          exact
          path="/job-description/list"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<JobDescriptionList />)}
        />
        <Route
          exact
          path="/job-description/edit-jd/:id"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<EditJobDescription />)}
        />
        <Route
          exact
          path="/job-description/add-jd"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<AddJobDescription />)}
        />
        {/* Interview questions */}
        <Route
          exact
          path="/manage-interviewer"
          element={LayoutOfAfterLogin(<ManageInterviewer />)}
        />{' '}
        <Route
          exact
          path="/manage-interviewer-list"
          element={LayoutOfAfterLogin(<ManageInterviewerList />)}
        />{' '}
        <Route
          exact
          path="/add-interviewer-list"
          element={LayoutOfAfterLogin(<AddInterviewerList />)}
        />{' '}
        <Route
          exact
          path="/edit-interviewer-list/:id"
          element={LayoutOfAfterLogin(<EditInterviewerList />)}
        />{' '}
        <Route
          exact
          path="/feedback-questions"
          element={LayoutOfAfterLogin(<FeedbackQuestions />)}
        />{' '}
        <Route
          exact
          path="/add-feedback-questions"
          element={LayoutOfAfterLogin(<AddFeedbackQuestions />)}
        />{' '}
        <Route
          exact
          path="/edit-feedback-questions/:id"
          element={LayoutOfAfterLogin(<AddFeedbackQuestions />)}
        />{' '}
        <Route
          exact
          path="/list-feedback-questions"
          element={LayoutOfAfterLogin(<ListFeedbackQuestions />)}
        />{' '}
        <Route
          exact
          path="/schedule-interview"
          element={LayoutOfAfterLogin(<ScheduleInterview />)}
        />{' '}
        <Route
          exact
          path="/request-interview-feedback"
          element={LayoutOfAfterLogin(<RequestInterviewFeedback />)}
        />{' '}
        <Route
          exact
          path="/user-feedback-questions"
          element={LayoutOfAfterLogin(<UserFeedbackQuestions />)}
        />{' '}
        <Route
          exact
          path="/add-user-feedback-questions"
          element={LayoutOfAfterLogin(<AddUserFeedbackQuestions />)}
        />{' '}
        <Route
          exact
          path="/edit-user-feedback-questions/:question_id"
          element={LayoutOfAfterLogin(<EditUserFeedbackQuestions />)}
        />{' '}
        <Route
          exact
          path="/referrals"
          element={LayoutOfAfterLogin(<Referrals />)}
        />{' '}
        <Route
          exact
          path="/referrals/add-new-referral"
          element={LayoutOfAfterLogin(<ReferralAdd />)}
        />{' '}
        <Route
          exact
          path="/referrals/edit/:id"
          element={LayoutOfAfterLogin(<ReferralEdit />)}
        />{' '}
        <Route
          exact
          path="/vendors"
          element={LayoutOfAfterLogin(<Vendors />)}
        />{' '}
        <Route
          exact
          path="/vendors/add-new-vendor"
          element={LayoutOfAfterLogin(<VendorAdd />)}
        />{' '}
        <Route
          exact
          path="/vendors/edit/:id"
          element={LayoutOfAfterLogin(<VendorEdit />)}
        />{' '}
        <Route
          exact
          path="/customers"
          element={LayoutOfAfterLogin(<Customers />)}
        />{' '}
        <Route
          exact
          path="/customers/add-new-customer"
          element={LayoutOfAfterLogin(<CustomerAdd />)}
        />{' '}
        <Route
          exact
          path="/customers/edit/:id"
          element={LayoutOfAfterLogin(<CustomerEdit />)}
        />{' '}
        <Route
          exact
          path="/customers/assign-resumes"
          element={LayoutOfAfterLogin(<AssignResumes />)}
        />{' '}
        <Route
          exact
          path="/resumes/assigned-resume-list"
          element={LayoutOfAfterLogin(<AssignedResumes />)}
        />{' '}
      </Routes>
    </>
  );
}

export default App;
