import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { site_ip } from '../globalSettings';
//import './sidebar.scss';
import '../homepage.css'
function LeftSideBar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  let megaMenus = [2]
  const [activeMenu, setActiveMenu] = useState(1);
  const [sidebarActive, setSidebarActive] = useState(0);
  const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('quantam_admin_id')));
  
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  let superAdmin=false;
  let editor=false;

  if(userdata?.user_email==="spgangwar@gmail.com"){
    superAdmin=true;
  }

  if(userdata?.role_id===13){
    editor=true;
  }

  const logOut = () => {
    axios({
      method: 'post',
      url: site_ip + '/setUserLogout',
      data: {
        login_id: localStorage.getItem('loginid'),
      },
      dataType: 'json',
    })
      .then(function (response) {
        localStorage.removeItem('loginid');
        localStorage.removeItem('userdata');
        localStorage.removeItem('quantam_admin_id');
        superAdmin=false;
        window.location.href = '/';
        // <Redirect to="/" />
        // window.location.href = '/admin/dashboard';
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  // #2E353C
  const openMenuMenu = (number) =>{
    setActiveMenu(number)
    if(number !== 1){
      setSidebarActive(1);
    }else{
      setSidebarActive(0);
      navigate("/Dashboard");
    }
  } 

  const newRequests =()=>{
    navigate("/new-user-requests")
  }

  return (
    <div className= {`new-sidebar-panel ${JSON.parse(localStorage.getItem('quantam_admin_id'))?'adminTheme':'bg-dark-blue'}`}>
      <div className={`main-sidebar ${JSON.parse(localStorage.getItem('quantam_admin_id'))?'adminTheme':'bg-dark-blue'}`}>
       {!editor &&
    <>
          <button className={activeMenu === 1 ? (isAdmin?'main-sidebar-btn-admin active':"main-sidebar-btn active") : "main-sidebar-btn"} onClick={() => openMenuMenu(1)}>
          <i class="menu-icon fas fa-tachometer-alt"></i>
          <p>Dashboard</p>
        </button>
        <button className={activeMenu === 2 ? (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => openMenuMenu(2)}>
          <i class="menu-icon fas fa-edit"></i>
          <p>Questions</p>
        </button>
        <button className={activeMenu === 3 ?  (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => openMenuMenu(3)}>
          <i class="menu-icon fas fa-diagnoses"></i>
          <p>Test</p>
        </button>
        <button className={activeMenu === 4 ? (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => openMenuMenu(4)}>
          <i class="menu-icon fas fa-user-check"></i>
          <p>Candidates</p>
        </button>
        <button className={activeMenu === 5 ? (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => openMenuMenu(5)}>
          <i class="menu-icon fas fa-user-check"></i>
          <p>Reports & Analytics</p>
        </button>
        <button className={activeMenu === 6 ? (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => openMenuMenu(6)}>
          <i class="menu-icon fas fa-user-check"></i>
          <p>Users</p>
        </button>
        
        <button className={activeMenu === 8 ? (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => openMenuMenu(8)}>
          <i class="menu-icon fas fa-folder-plus"></i>
          <p>Master</p>
        </button>
        <button className={activeMenu === 10 ? 'main-sidebar-btn active':'main-sidebar-btn '} onClick={()=>openMenuMenu(10)} >
          <i className='menu-icon fas fa-graduation-cap'></i>
          <p>Campus</p>
        </button>
    </>     

    
       }
       
       {editor && <button className={activeMenu === 7 ? 'main-sidebar-btn':'main-sidebar-btn active'} onClick={()=> openMenuMenu(7)}>
          <i className='menu-icon fas fa-edit'></i>
          <p>Editor</p>
        </button>
        }

        

       {superAdmin && <button className={activeMenu === 9 ? (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => newRequests() }>
        <i class="menu-icon fa fa-assistive-listening-systems"></i>
          <p>New Account Requests</p>
        </button>}
        <button className={activeMenu === 9 ? (isAdmin ?'main-sidebar-btn-admin active':'main-sidebar-btn active') : "main-sidebar-btn"} onClick={() => logOut()}>
          <i class="menu-icon fas fa-sign-out-alt"></i>
          <p>Logout</p>
        </button>
      </div>
      <div className={sidebarActive === 0 ? (isAdmin ? 'submenu-sidebar-admin' : 'submenu-sidebar') : (isAdmin ? 'submenu-sidebar-admin active' : 'submenu-sidebar active')}>
        <button className="sidebar-close-btn" onClick={() => setSidebarActive(0)}><i class="fas fa-times"></i></button>
        <div style={{height:"20px"}}></div>

        {activeMenu === 2 ?
          <>
            <Link to="/search-question">Search Question</Link>
            <Link to="/add-question">Add Question</Link>
            {/* <Link to="/question-upload">Upload Questions</Link> */}
          </> : null}
        {activeMenu === 3 ?
          <>
            <Link to="/test-listing">Search Test</Link>
            <Link to="/test-add">Add Test</Link>
            <Link to="/my-tests">My Tests</Link>
          </> : null}
        {activeMenu === 4 ?
          <>
            <Link to="/manage-candidate">Search Candidates</Link>
            <Link to="/add-candidate">Add Candidate </Link>
            <Link to="/assign-test">Assign Test </Link>
          </> : null}
        {activeMenu === 5 ?
          <>
            <Link to="/main-login-analytics">Candidate Results</Link>
            <Link to="/candidate-list">Candidate List</Link>
            <Link to="/candidates-feedback">Candidates Feedback</Link>
            <Link to="/test-analytics">Test Analytics</Link>
            <Link to="/test-summary">Test Summary</Link>
            <Link to="/test-summary-by-date">Test Summary By Date</Link>
            <Link to="/test-summary-by-datejob">
              Test Summary By Date Job
            </Link>
          </> : null}
        {activeMenu === 6 ?
          <>
            <Link to="/manage-company-users">Company Users</Link>
          </> : null}


          {activeMenu === 7 ?
          <>
            <Link to="/add-question">Add Questions</Link>
            <Link to="/search-question">Edit Questions</Link>
            <Link to="/custom-test">Verify Questions</Link>
          </> : null}

        {activeMenu === 8 ?
          <>
            <Link to="/manage-industries">Industries</Link>
            <Link to="/manage-jobs">Jobs</Link>
            <Link to="/manage-skills">Skills</Link>
            <Link to="/manage-questiontags">Question Tags</Link>
            <Link to="/manage-subjects">Subjects</Link>
            <Link to="/manage-subjecttopics">Subject Topic</Link>
            <Link to="/manage-interviewer">Interviewer</Link>
            <Link to="/view-question-types">View Question Types</Link>
            <Link to="/view-question-complexities">
              View Question Complexities
            </Link>
            <Link to="/view-taxonomy">View Taxonomy</Link>
            <Link to="/view-categories">View Categories</Link>
            <Link
              aria-controls="example-collapse-text"
              className="d-flex align-items-center"
              to="/user-feedback-questions"
            >
              User Feedback Questions
            </Link>
          </> : null}


          {activeMenu === 10 ? <>
              <Link to="/campus/add-college">Add College</Link>
              <Link to="/campus/add-students">Add Students</Link>
          </>:null}
      </div>
    </div>
  );
}

export default LeftSideBar;