import React, { useState, useEffect } from 'react';
import { site_ip } from '../../../globalSettings';

// import { Grid, Button } from '@material-ui/core';
import { Grid, Button } from '@mui/material';
import FormDialog from './designationDialog';
const initialValue = { id: '', designation: '', industry_id: '' };
function Company() {
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState(initialValue);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initialValue);
  };
  const url = site_ip + `/getDesignation`;
  const columnDefs = [
    { headerName: ' Id', field: 'id' },
    { headerName: 'Designation', field: 'designation' },
    { headerName: 'Industry', field: 'industry_id' },
    {
      headerName: 'Actions',
      field: 'id',
      cellRendererFramework: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleUpdate(params.data)}
          >
            Update
          </Button>
          {/* <Button variant="outlined" color="secondary" onClick={() => handleDelete(params.value)}>Delete</Button> */}
        </div>
      ),
    },
  ];
  // calling getUsers function for first time
  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, []);

  //fetching user data from server
  const getUsers = () => {
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => setTableData(resp.Content));
  };
  const onChange = (e, tagid) => {
    if (tagid === undefined) {
      const { value, id } = e.target;
      setFormData({ ...formData, [id]: value });
    } else {
      const { value } = e.target;
      setFormData({ ...formData, [tagid]: value });
    }
  };
  const onGridReady = (params) => {
    setGridApi(params);
    console.log(gridApi);
  };

  // setting update row data to form data and opening pop up window
  const handleUpdate = (oldData) => {
    setFormData(oldData);
    handleClickOpen();
  };
  //deleting a user
  // const handleDelete = (id) => {
  //   const confirm = window.confirm(
  //     'Are you sure, you want to delete this row',
  //     id
  //   );
  //   if (confirm) {
  //     fetch(site_ip + `"/getSkills"/${id}`, { method: 'DELETE' })
  //       .then((resp) => resp.json())
  //       .then((resp) => getUsers());
  //   }
  // };
  const handleFormSubmit = () => {
    if (formData.id !== '') {
      if (formData.designation === '' || formData.industry_id === '0') {
        alert('Please fill all field');
      } else {
        const confirm = window.confirm(
          'Are you sure, you want to update this row ?'
        );
        if (confirm) {
          fetch(site_ip + '/setDesignation', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
              'content-type': 'application/json',
            },
          })
            .then((resp) => resp.json())
            .then((resp) => {
              handleClose();
              getUsers();
            });
        }
      }
    } else {
      if (formData.designation === '' || formData.industry_id === '0') {
        alert('Please fill all field');
      } else {
        let array_designation = [
          {
            designation: formData.designation,
            industry_id: formData.industry_id,
          },
        ];
        fetch(site_ip + '/addDesignation', {
          method: 'POST',
          body: JSON.stringify(array_designation),
          headers: {
            'content-type': 'application/json',
          },
        })
          .then((resp) => resp.json())
          .then((resp) => {
            handleClose();
            getUsers();
          });
      }
    }
  };

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  return (
    <div className="App">
      <Grid align="right">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add Skills
        </Button>
      </Grid>
      <div className="ag-theme-alpine" style={{ height: '400px' }}>
      </div>
      <FormDialog
        open={open}
        handleClose={handleClose}
        data={formData}
        onChange={onChange}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
}
export default Company;
