import React, { useState, useEffect } from 'react';
//import Aux from "../../../hoc/_Aux";
import { Button, Col, Form, Row, Container } from 'react-bootstrap';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { site_ip } from '../../../../../globalSettings';
import Swal from 'sweetalert2';
import axios from 'axios';
export default function ExamQuestionAdd() {
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const [values, setValues] = useState();
  const initialInputs = {
    question_edit_version: "", 
    class_id: "",
    exam_type_id: "", 
    board_cd: "", 
    stream_code: "", 
    subject_id: "", 
    unit_id: "", 
    chapter_id: "", 
    topic_id: "", 
    exam_occurance: "", 
    template_type: "", 
    difficulty_level: "", 
    skill_id: "", 
    tags: "", 
    language_id: "", 
    marks: "", 
    time_allowed: "", 
    negative_marking: "", 
    question: "", 
    question_options: "", 
    answers: "", 
    explanation: "", 
    reference_text: "", 
    video_solution_link: "", 
    hint_formula_used: "", 
    system_driven_complexity: "", 
    answered_correctly_pct: "", 
    passage_inst_id: "", 
    passage_inst_ind: "", 
    ques_status: "", 
  };
  const [input, setInputs] = useState(initialInputs);

  const handleChange = (e) =>{
    let {name, value} = e.target;
    setInputs({...input, [name]: value})
  }

  const handleChangeWithName = (name, value) =>{
    setInputs({...input, [name]: value})
  }

  useEffect(()=>{
    fetchExams();
    fetchExamType();
    fetchSubjects();
    fetchSubjectTopics();
    fetchSkills();
  }, [])


  const [exams, setExams] = useState([]);
  const fetchExams = async () => {
    axios({
      method: 'get',
      url: site_ip + '/getExams',
    })
      .then(function (response) {
        setExams(response.data.Content);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [examType, setExamType] = useState([]);
  const fetchExamType = async () => {
    axios({
      method: 'get',
      url: site_ip + '/getExamType',
    })
      .then(function (response) {
        setExamType(response.data.Content);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [subjects, setSubjects] = useState([]);
  const fetchSubjects = async () => {
    axios({
      method: 'get',
      url: site_ip + '/getSubjects',
    })
      .then(function (response) {
        setSubjects(response.data.Content);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [subjecTopics, setSubjectTopics] = useState([]);
  const fetchSubjectTopics = async () => {
    axios({
      method: 'post',
      url: site_ip + '/getSubjectTopics',
      data:{}
    })
      .then(function (response) {
        setSubjectTopics(response.data.Content);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [skills, setSkills] = useState([]);
  const fetchSkills = async () => {
    axios({
      method: 'get',
      url: site_ip + '/getSkills',
    })
      .then(function (response) {
        setSkills(response.data.Content);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };




  const handleCorrectAnswer = (e) =>{

  }

  const handleSubmit = () =>{

  }

  const handleAddMore = () =>{
    
  }

  const handleReset = () =>{

  }

  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Add Question</h4>
          </div>
          <Form className="p-3 border bg-white">
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Exam</Form.Label>
                  <Form.Control
                    name="co_job_id"
                    value={input.exam_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">--Select--</option>
                    {exams?.map((value, index) => (
                      <option key={index} value={value.exam_id}>{value.exam_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Exam Type</Form.Label>
                  <Form.Control
                    name="exam_type_id"
                    value={input.exam_type_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">--Select--</option>
                    {examType?.map((value, index) => (
                      <option key={index} value={value.exam_id}>{value.exam_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    name="subject_id"
                    value={input.subject_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">--Select--</option>
                    {subjects?.map((value, index ) => (
                      <option key={index} value={value.subject_id}>{value.subject_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Subject Topic</Form.Label>
                  <Form.Control
                    name="topic_id"
                    value={input.topic_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">--Select--</option>
                    {subjects?.map((value, index ) => (
                      <option key={index} value={value.subject_id}>{value.subject_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Skill</Form.Label>
                  <Form.Control
                    name="skill_id"
                    value={input.skill_id}
                    onChange={handleChange}
                    as="select"
                  >
                    <option value="">--Select--</option>
                    {skills?.map((value, index ) => (
                      <option key={index} value={value.skill_id}>{value.skill_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Question</Form.Label>
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      padding: '2px',
                      minHeight: '300px',
                    }}
                  >
                    <CKEditor
                        editor={ Editor }
                        data={values.question}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            handleChangeWithName('question', data)
                        } }
                    />
                  </div>
                </Form.Group>
              </Col>
              {input.ques_type_id === '1' || input.ques_type_id === '2' ? (
                <Col md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>Option 1</Form.Label>
                    <CKEditor
                        editor={ Editor }
                        data={values.question}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            handleChangeWithName('question', data)
                        } }
                    />
                  </Form.Group>
                  <Form.Group className="mt-4">
                    <Form.Label>Option 3</Form.Label>
                    <CKEditor
                        editor={ Editor }
                        data={values.question}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            handleChangeWithName('question', data)
                        } }
                    />
                  </Form.Group>
                </Col>
              ) : null}
              {input.ques_type_id === '1' || input.ques_type_id === '2' ? (
                <Col md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>Option 2</Form.Label>
                    <CKEditor
                        editor={ Editor }
                        data={values.question}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            handleChangeWithName('question', data)
                        } }
                    />
                  </Form.Group>
                  <Form.Group className="mt-4">
                    <Form.Label>Option 4</Form.Label>
                    <CKEditor
                        editor={ Editor }
                        data={values.question}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            handleChangeWithName('question', data)
                        } }
                    />
                  </Form.Group>
                </Col>
              ) : null}
              {input.ques_type_id === '1' ||
                input.ques_type_id === '2' ||
                input.ques_type_id === '3' ? (
                <Col md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>Correct Answer</Form.Label>
                    {input.ques_type_id === '1' ? (
                      <Form.Control
                        name="ques_ans"
                        // value={input.ques_ans.ans}
                        onChange={(e) => handleCorrectAnswer(e)}
                        as="select"
                        multiple
                      >
                        {/* <option value="">Select Correct Answer</option> */}
                        <option value="1">Option 1</option>
                        <option value="2">Option 2</option>
                        <option value="3">Option 3</option>
                        <option value="4">Option 4</option>
                      </Form.Control>
                    ) : null}
                    {input.ques_type_id === '2' ? (
                      <Form.Control
                        name="ques_ans"
                        // value={input.ques_ans.ans}
                        onChange={(e) => handleCorrectAnswer(e)}
                        as="select"
                      >
                        <option value="">Select Correct Answer</option>
                        <option value="1">Option 1</option>
                        <option value="2">Option 2</option>
                        <option value="3">Option 3</option>
                        <option value="4">Option 4</option>
                      </Form.Control>
                    ) : null}
                    {input.ques_type_id === '3' ? (
                      <Form.Control
                        name="ques_ans"
                        // value={input.ques_ans.ans}
                        onChange={(e) => handleCorrectAnswer(e)}
                        as="select"
                      >
                        <option value="">Select Correct Answer</option>
                        <option value="1">True</option>
                        <option value="2">False</option>
                      </Form.Control>
                    ) : null}
                  </Form.Group>
                </Col>
              ) : null}
              <Col md={6} className="mb-4"></Col>
              <Col md={12}>
                {input.ques_type_id === '4' || input.ques_type_id === '5' ? (
                  <Form.Group>
                    <Form.Label>Answer</Form.Label>
                    <CKEditor
                        editor={ Editor }
                        data={values.question}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            handleChangeWithName('answers', data)
                        } }
                    />
                  </Form.Group>
                ) : null}
              </Col>

              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Explanation</Form.Label>
                  <CKEditor
                        editor={ Editor }
                        data={values.question}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            handleChangeWithName('explanation', data)
                        } }
                    />
                  </Form.Group>
              </Col>
              <br />
              <Col md={12} style={{marginBottom:"15px"}}>
                <button
                  className="primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  className="ternary"
                  onClick={handleAddMore}
                >
                  Save and Add More
                </button>
                <button
                  className="secondary"
                  onClick={handleReset}>
                  Reset
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
