import React, { useState, useEffect, useContext } from 'react';
import { site_ip } from '../../globalSettings';
import './common.css';
import {Row, Col, Container} from 'react-bootstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import '../../homepage.css'
function Dashboard() {
  const [comapanydata, getCompanyData] = useState([]);
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const fetchCompanyData = () =>
    fetch(`${site_ip}/companyDashboard/${userdata.company_id}`).then((res) =>
      res.json()
    );
  useEffect(() => {
    fetchCompanyData().then((data) => {
      // console.log(data);
      getCompanyData(data);
    });
    //eslint-disable-next-line
  }, []);
  

  return (
    <>
      <div>
        <Container>
          <Row>
            <Col>
              <div className="dashboard-card-box">
                <div className='card-icon-div'>
                  <div className='icon-bg'>
                    <i class="fas fa-question card-icon"></i>
                  </div>
                </div>
                <p className="dashboard-card-number">{comapanydata.QuestionCount}</p>
                <div className="dashboard-card-fix-bottom green-gradiant">
                  Question Count
                </div>
              </div>
            </Col>
            <Col>
              <div className="dashboard-card-box">
                <div className='card-icon-div'>
                  <div className='icon-bg'>
                    <i class="fas fa-paste card-icon"></i>
                  </div>
                </div>
                <p className="dashboard-card-number">{comapanydata.TestAssignedCount}</p>
                <div className="dashboard-card-fix-bottom green-gradiant">
                  Test Assigned Count
                </div>
              </div>
            </Col>
            <Col>
              <div className="dashboard-card-box">
                <div className='card-icon-div'>
                  <div className='icon-bg'>
                    <i class="fas fa-list-ol card-icon"></i>
                  </div>
                </div>
                <p className="dashboard-card-number">{comapanydata.TestsCount}</p>
                <div className="dashboard-card-fix-bottom green-gradiant">
                  Test Count
                </div>
              </div>
            </Col>
            <Col>
              <div className="dashboard-card-box">
                <div className='card-icon-div'>
                  <div className='icon-bg'>
                    <i class="fas fa-user-graduate card-icon"></i>
                  </div>
                </div>
                <p className="dashboard-card-number">{comapanydata.candidatesCount}</p>
                <div className="dashboard-card-fix-bottom green-gradiant">
                  Candidate Count
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{marginTop:"20px", padding:"15px"}}>
            <Col className="dashboard-card-only">
              <ChartOne />
            </Col>
          </Row>
        </Container>
      </div>
      <br />
      <br />
    </>
  );
}
export default Dashboard;


const ChartOne = () =>{

  var elevationData = [
    ['01-09-2022', 225],
    ['02-09-2022', 226],
    ['03-09-2022', 228],
    ['04-09-2022', 228],
    ['05-09-2022', 229],
    ['06-09-2022', 229],
    ['07-09-2022', 230],
    ['08-09-2022', 234],
    ['09-09-2022', 235],
    ['10-09-2022', 236],
    ['11-09-2022', 235],
    ['12-09-2022', 228],
    ['13-09-2022', 223],
    ['14-09-2022', 218],
    ['15-09-2022', 214],
    ['16-09-2022', 207],
    ['17-09-2022', 202],
    ['18-09-2022', 198],
    ['19-09-2022', 196],
    ['20-09-2022', 197],
    ['21-09-2022', 200],
    ['22-09-2022', 205],
    ['23-09-2022', 206],
    ['24-09-2022', 210],
    ['25-09-2022', 210],
    ['26-09-2022', 210],
    ['27-09-2022', 209],
    ['28-09-2022', 208],
    ['29-09-2022', 207],
    ['30-09-2022', 209]
  ];

  const options = {
    chart: {
      type: 'area',
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      scrollablePlotArea: {
        minWidth: 600
      }
    },
  
    caption: {
      text: 'This chart shows day wise candidate counts.'
    },
  
    title: {
      text: 'Last 30 days candidates'
    },
  
    lang: {
      accessibility: {
        screenReaderSection: {
          annotations: {
            descriptionNoPoints: '{annotationText}, at distance {annotation.options.point.x}, elevation {annotation.options.point.y} Nos.'
          }
        }
      }
    },
  
    credits: {
      enabled: false
    },
  
    xAxis: {
      labels: {
        format: '{value}'
      },
      minRange: 5,
      title: {
        text: 'Date (Last 30 days)'
      },
      accessibility: {
        rangeDescription: 'Range: 0 to 187.8km.'
      }
    },
  
    yAxis: {
      startOnTick: true,
      endOnTick: false,
      maxPadding: 0.35,
      title: {
        text: null
      },
      labels: {
        format: '{value} Nos.'
      },
      accessibility: {
        description: 'Elevation',
        rangeDescription: 'Range: 0 to 1,553 meters'
      }
    },
  
    tooltip: {
      headerFormat: 'Date: {point.x}<br>',
      // pointFormat: '{point.y} m a. s. l.',
      shared: true
    },
  
    legend: {
      enabled: false
    },
  
    series: [{
      data: elevationData,
      lineColor: "#2E353C",  //Highcharts.getOptions().colors[1]
      color: "#4caf50",  //Highcharts.getOptions().colors[2],
      fillOpacity: 0.5,
      name: 'Candiates',
      marker: {
        enabled: false
      },
      threshold: null
    }]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}