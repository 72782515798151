import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Container, Card } from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
function CandidateEdit() {
  const params = useParams();

  console.log(params)
  let navigate = useNavigate();
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const intialValues = {
    candidate_id: '',
    company_id: userdata.company_id,
    candidate_email: '',
    candidate_name: '',
    candidate_mobile: '',
    age: '',
    exp_in_years: '',
    highest_qualification: '',
    current_designation: '',
    current_company: '',
    current_location: '',
    job_id: '',
    co_test_id: '',
    profile_summary: '',
  };

  const [job, getJob] = useState(null);
  const [tableRows, setTableRows] = useState([intialValues]);

  const fetchJobData = () =>
    fetch(`${site_ip}/getJobMaster`).then((res) => res.json());
  useEffect(() => {
    fetchJobData().then((data) => getJob(data.Content));
  }, []);
  const [test, getTest] = useState(null);
  const fetchTestData = () =>
    fetch(`${site_ip}/getTestsList`).then((res) => res.json());
  useEffect(() => {
    fetchTestData().then((data) => getTest(data.Tests));
  }, []);
  const fetchTestDataById =async () =>
  {
        // fetch(`${site_ip}/getCandidateById/${params.id}`).then((res) => res.json());
    let url =`${site_ip}/getCandidateById/${params.id}`;

      let result = await  axios.get(url);

      console.log(result.data.Content[0],'result')

      setTableRows(result.data.Content[0])

  }
    useEffect(() => {

      fetchTestDataById();

    // fetchTestDataById().then((data) => {
    //   console.log(data);
    //   setTableRows(data.Content);
    // });
    //eslint-disable-next-line
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTableRows((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (mobile.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  // const validateCandidateRow = (data) => {
  //   let status = true;

  //   if (data.candidate_name.trim() === '') {
  //     status = false;
  //   } else if (
  //     data.candidate_email.trim() === '' ||
  //     !validateEmail(data.candidate_email.trim())
  //   ) {
  //     status = false;
  //   } else if (
  //     data.candidate_mobile.trim() === '' ||
  //     !phonenumber(data.candidate_mobile.trim())
  //   ) {
  //     status = false;
  //   } else if (data.job_id === '') {
  //     status = false;
  //   } else if (data.co_test_id === '') {
  //     status = false;
  //   }

  //   return status;
  // };

  const handleSubmit = async () => {
    console.log(tableRows);
    if (!validateEmail(tableRows.candidate_email)) {
      alert('Please fill email as per format');
    } else if (!phonenumber(tableRows.candidate_mobile)) {
      alert('Please fill phone number as per format');
    } else if (tableRows.candidate_name.trim() === '') {
      alert('Please fill the candidate name');
    } else if (tableRows.job_id === '') {
      alert('Please select the job');
    } else if (tableRows.co_test_id === '') {
      alert('Please select the test');
    } else {
      await axios({
        method: 'post',
        url: site_ip + '/setCompanyJobCandidate',
        data: {
          candidate_id: tableRows.candidate_id,
          company_id: userdata.company_id,
          candidate_email: tableRows.candidate_email,
          candidate_name: tableRows.candidate_name,
          candidate_mobile: tableRows.candidate_mobile,
          age: tableRows.age,
          exp_in_years: tableRows.exp_in_years,
          highest_qualification: tableRows.highest_qualification,
          current_designation: tableRows.current_designation,
          current_company: tableRows.current_company,
          current_location: tableRows.current_location,
          job_id: tableRows.job_id,
          co_test_id: tableRows.co_test_id,
          profile_summary: tableRows.profile_summary,
        },
        dataType: 'json',
      })
        .then(function (response) {
          console.log(response);
          if (response.data.status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Candidate updated successfully.',
              icon: 'success',
              showCancelButton: false,
              allowOutsideClick: false,
            }).then((result) => {
              // setRows(initialValues);
              navigate('/manage-candidate');
            });
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };
  return (
      <Row className="admin-form-row" style={{margin:"0px"}}>
          <div className="header">
            <h4>Update Candidate</h4>
          </div>
          <div className="p-3 border bg-white">
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Candidate Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Candidate Name"
                  className="mb-3"
                  name="candidate_name"
                  value={tableRows.candidate_name}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Candidate Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  className="mb-3"
                  name="candidate_email"
                  value={tableRows.candidate_email}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Candidate Mobile</Form.Label>
                <Form.Control
                  type="number"
                  className="mb-3"
                  placeholder="Mobile"
                  name="candidate_mobile"
                  value={tableRows.candidate_mobile}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label> Age </Form.Label>
                <Form.Control
                  type="number"
                  className="mb-3"
                  placeholder="Age"
                  name="age"
                  value={tableRows.age}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label> Experience (in years) </Form.Label>
                <Form.Control
                  type="number"
                  className="mb-3"
                  placeholder="Experience (in years)"
                  name="exp_in_years"
                  value={tableRows.exp_in_years}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label> Highest Qualification </Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  placeholder="Highest Qualification"
                  name="highest_qualification"
                  value={tableRows.highest_qualification}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label> Current Designation </Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  placeholder="Current Designation"
                  name="current_designation"
                  value={tableRows.current_designation}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label> Current Company </Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  placeholder="Current Company"
                  name="current_company"
                  value={tableRows.current_company}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label> Current Location </Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  placeholder="Current Location"
                  name="current_location"
                  value={tableRows.current_location}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Job</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  value={tableRows.job_id}
                  name="job_id"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select your Position</option>
                  {job?.map((value) => (
                    <option value={value.job_id}>{value.job_name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Test</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  value={tableRows.co_test_id}
                  name="co_test_id"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Test</option>
                  {test?.map((value) => (
                    <option value={value.test_id}>{value.test_name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label> Profile Summary </Form.Label>
                <Form.Control
                  as="textarea"
                  className="mb-3"
                  placeholder="Profile Summary"
                  name="profile_summary"
                  value={tableRows.profile_summary}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col md={5}>
              <button
                className="primary"
                onClick={handleSubmit}
              >
                Update
              </button>
            </Col>
          </Row>
        </div>
      </Row>
  );
}

export default CandidateEdit;
