import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Form,
  Dropdown,
} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import axios from 'axios';
import { site_ip } from '../../globalSettings.js';
import Stopwatch from './Stopwatch';

function EPage(props) {
  const [test_type] = useState(0);
  const [test_id] = useState(1);
  const [quest_id, setQuest_Id] = useState(0);
  const ArrayValues = props.question_list?.Questions[quest_id].ques_options;
  const [optionId, setOptionId] = useState(JSON.parse(ArrayValues));
  const [buttonStatus, setButtonStatus] = useState([]);
  const [optionStatus, setOptionStatus] = useState([]);
  console.log(optionStatus);
  const [option, setOption] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [unattempted, setUnattempted] = useState(0);
  const [other, setOther] = useState([]);
  useEffect(() => {
    const apiUrl = site_ip + `/getTestQuestionsSet/${test_id}`;
    console.log(apiUrl);

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        props.GETQUESTIONLIST(data);
        console.log('This is your data', data);
        console.log(data);
      });
    //eslint-disable-next-line
  }, []);
  const hChange1 = (e) => {
    setOption(optionId[1]);
    console.log(optionId[1]);
  };
  const hChange2 = (e) => {
    setOption(optionId[2]);
    console.log(optionId[2]);
  };
  const hChange3 = (e) => {
    setOption(optionId[3]);
    console.log(optionId[3]);
  };
  const hChange4 = (e) => {
    setOption(optionId[4]);
    console.log(optionId[4]);
  };
  useEffect(() => {
    let buttonStatus = [];
    for (let i = 0; i < props.question_list?.QuestionCount; i++) {
      buttonStatus.push(0);
    }
    setButtonStatus(buttonStatus);
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    let optionStatus = [];
    for (let i = 0; i <= ArrayValues; i++) {
      optionStatus.push(0);
    }
    console.log(optionStatus);
    setOptionStatus(optionStatus);
    //eslint-disable-next-line
  }, []);

  let buttonList = buttonStatus.map((item, index) => {
    if (item === 0) {
      return <span>{index + 1}</span>;
    }
    return '';
  });
  useEffect(() => {
    let other = [];
    for (let i = 0; i <= props.question_list?.QuestionCount - 1; i++) {
      other.push(0);
    }
    setOther(other);
    console.log(other);
    //eslint-disable-next-line
  }, []);
  const SaveResponse = () => {
    axios({
      method: 'post',
      url: site_ip + '/saveCandidateRespQues',
      data: {
        user_id: props.otptoken_list?.data.Candidate,
        question_id: quest_id,
        option_id: option,
        co_question_id: props.question_list?.Questions[quest_id].co_question_id,
        time_taken: props.question_list?.Questions.time_allowed,
        test_attempt_id: props.question_list?.Questions[test_id].test_id,
        subject_id: props.question_list?.Questions[quest_id].subject_id,

        job_id: 1,
      },
      dataType: 'json',
    })
      .then(function (response) {
        let currentAnswers = other;
        currentAnswers[quest_id] = response;
        setOther(currentAnswers);
        console.log(other);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const Next = () => {
    SaveResponse();
    let currentAnswers = other;
    currentAnswers[quest_id] = 1;
    let new_question = quest_id + 1;
    if (quest_id < props.question_list?.QuestionCount - 1) {
      let ArrayValues1 =
        props.question_list?.Questions[quest_id + 1].ques_options;
      setOptionId(JSON.parse(ArrayValues1));
      setQuest_Id(new_question);
      setOption(0);
      if (!option) {
        if (option !== optionId[1]) {
          setUnattempted(unattempted + 1);
        } else if (option !== optionId[2]) {
          setUnattempted(unattempted + 1);
        } else if (option !== optionId[3]) {
          setUnattempted(unattempted + 1);
        } else if (option !== optionId[4]) {
          setUnattempted(unattempted + 1);
        }
      } else {
        if (option === optionId[1]) {
          setCorrect(correct + 1);
        } else if (option === optionId[2]) {
          setCorrect(correct + 1);
        } else if (option === optionId[3]) {
          setCorrect(correct + 1);
        } else if (option === optionId[4]) {
          setCorrect(correct + 1);
        }
      }
    } else {
      alert('Quiz completed.');
    }
  };
  return (
    <>
      <Container fluid>
        <Row className="align-items-stretch h-100">
          <div className="w-100 mx-auto bg-light headertop">
            <div class="left-box  d-flex align-items-center pt-2">
              <div class="d-inline-block align-middle mar-t12">
                <h4 class="mar-t0 mar-b8">
                  {props.instruct_list?.Tests[test_type].test_name}
                </h4>
                <h6 class="mar-v0 text-ellipsis ng-binding">
                  Data Interpretation - Tabulation
                </h6>
              </div>
              <div class="ms-auto text-end">
                <h6>
                  {props.question_list?.Questions[quest_id].co_question_id}/
                  {props.question_list?.QuestionCount} Question
                </h6>
                <span>
                  <Stopwatch></Stopwatch>
                </span>
              </div>
            </div>
          </div>
          <Col md={9} className="mx-auto my-5">
            <div className="border p-4 px-5">
              <div class="row">
                <div class="col-md-12">
                  <div style={{ height: '62vh', overflowY:'auto' }}>
                    <div className="p-3">
                      <p class="mb-4 question">
                        <td>
                          Q
                          {
                            props.question_list?.Questions[quest_id]
                              .co_question_id
                          }
                        </td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html:
                              props.question_list?.Questions[quest_id]
                                .ques_desc,
                          }}
                        />
                      </p>

                      <Row>
                        <Col md={12} className="mb-4">
                          <div class="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name="radio"
                              type="radio"
                              id={optionId[1]}
                              value={option}
                              label={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: optionId[1],
                                  }}
                                />
                              }
                              checked={option === optionId[1]}
                              onChange={hChange1}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div class="border-bottom p-2 w-100 h-100 answers ">
                            <Form.Check
                              name={quest_id}
                              type="radio"
                              id={optionId[2]}
                              label={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: optionId[2],
                                  }}
                                />
                              }
                              checked={option === optionId[2]}
                              onChange={hChange2}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div class="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name={quest_id}
                              type="radio"
                              id={optionId[3]}
                              label={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: optionId[3],
                                  }}
                                />
                              }
                              checked={option === optionId[3]}
                              onChange={hChange3}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div class="border-bottom p-2 w-100 h-100 answers">
                            <Form.Check
                              name={quest_id}
                              type="radio"
                              id={optionId[4]}
                              label={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: optionId[4],
                                  }}
                                />
                              }
                              checked={option === optionId[4]}
                              onChange={hChange4}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mb-4">
                          <div class="p-2 w-100 h-100 answers">
                            <Form.Check
                              name="radio"
                              type="radio"
                              id="radio5"
                              label={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: optionId[5],
                                  }}
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              <div class="btnbox shadow d-flex align-items-center mt-3">
                <Button variant="outline-danger">Cancel</Button>
                <div class="mx-auto">
                  <Link to="/" className="btn btn-danger ms-auto">
                    Previous
                  </Link>
                  <Button className="btn btn-danger ms-3" onClick={Next}>
                    Next
                  </Button>
                  {/* <Link to="/" className="btn btn-danger ms-3">
                    Next
                       </Link>*/}
                </div>
                <Link to="/thankyou" className="btn btn-danger  ms-3">
                  Finish
                </Link>
              </div>
            </div>
          </Col>
          <Col md={3} className=" my-5 ">
            <div class="bg-light border p-3 h-100 fix-right-nav">
              <div class="student-details border-bottom pb-3 mb-3 d-flex align-items-center">
                <span class="img me-3">A</span>
                <span>Ashwani Kumar</span>
                <span class="ms-auto">
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle
                      size="sm"
                      variant="danger"
                      id="dropdown-autoclose-true"
                    >
                      Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">Correct</Dropdown.Item>
                      <Dropdown.Item href="#">Unattempted</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
              <div class="d-flex align-items-center border-bottom mb-2 pb-2 px-2">
                <span class="me-4 d-flex align-items-center">
                  <Badge pill className="badge bg-success rounded-0 me-2">
                    {correct >= props.question_list?.QuestionCount
                      ? props.question_list?.QuestionCount
                      : correct}
                  </Badge>
                  Correct
                </span>
                <span class="d-flex align-items-center">
                  <Badge
                    pill
                    className="badge bg-white text-dark border rounded-0 me-2"
                  >
                    {unattempted >= props.question_list?.QuestionCount
                      ? props.question_list?.QuestionCount
                      : unattempted}
                  </Badge>
                  Unattempted
                </span>
              </div>
              <div class="numbering-block d-flex flex-wrap align-items-center">
                {buttonList}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EPage;
