import React, { useState } from 'react';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { useNavigate } from 'react-router-dom';
export default function PracticeTest() {
  const learner_id = JSON.parse(localStorage.getItem('quantam_learner_id'));

  const [QCount, setQCount] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios({
      method: 'POST',
      url: site_ip + '/getPracticeTest',
      data: {
        learner_id: learner_id,
        question_count: QCount,
      },
    })
      .then((res) => {
        // console.log(res);
        redirectToTest(res.data.Admin);
      })
      .catch((err) => console.log(err));
  };

  const redirectToTest = (data) => {
    if (data.length > 0)
      navigate({
        pathname: '/learner/exam-page',
        state: { data: data, test_type: 'Practice' },
      });
    else alert('No Data Found ');
  };
  return (
    <Container>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Add Custom Test</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col sm>
                <Form.Group>
                  <Form.Label>Question Count</Form.Label>
                  <Form.Control
                    name="question_count"
                    value={QCount}
                    type="number"
                    onChange={(e) => setQCount(parseInt(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col sm>
                <Button style={{ marginTop: '30px' }} onClick={handleSubmit}>
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
