import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Container } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';

function CandidateResult(props) {
  const [result, setResult] = useState([]);
  const [questionsInfo, setQuestionsInfo] = useState([]);

  useEffect(() => {
    fetchResult();
    //eslint-disable-next-line
  }, []);

  const fetchResult = async () => {
    await axios
      .get(site_ip + '/viewCandidateResult/' + props.match.params.id)
      .then((response) => {
        console.log(response);
        setResult(response.data.Summary);
        setQuestionsInfo(response.data.SummaryInfo);
        // setValues(response.data.Content);
      });
  };

  const renderText = (text) => {
    return '';
  };

  const checkResult = (item) => {
    if (item.ques_type_id !== 4 || item.ques_type_id !== 5) {
      if (
        item.cand_responses !== null &&
        item.ques_ans !== null &&
        item.cand_responses.ans === item.ques_ans.ans
      ) {
        return '#a6d8a8';
      } else {
        return '#ff9999';
      }
    } else {
      return '#ffffff';
    }
  };

  return (
    <>
      <div class="align-items-center row">
        <div class="col-md-6">
          <h4>Result</h4>
        </div>
      </div>
      <Card>
        <Container>
          <Row md={6}>
            <Col>Total Questions</Col>
            <Col>
              {questionsInfo[0]?.correct_ans +
                questionsInfo[0]?.incorrect_ans +
                questionsInfo[0]?.unattempted_ques_cnt}
            </Col>
          </Row>
          <Row md={6}>
            <Col>Correct</Col>
            <Col>{questionsInfo[0]?.correct_ans}</Col>
          </Row>
          <Row md={6}>
            <Col>Incorrect</Col>
            <Col>{questionsInfo[0]?.incorrect_ans}</Col>
          </Row>
          <Row md={6}>
            <Col>Not Attempted</Col>
            <Col>{questionsInfo[0]?.unattempted_ques_cnt}</Col>
          </Row>
          <Row md={6}>
            <Col>Descriptive</Col>
            <Col>{0}</Col>
          </Row>
        </Container>
      </Card>
      <Row>
        <Col>
          {result.map((item, index) => {
            return (
              <div
                key={index}
                class="border p-3 mt-4"
                style={{ backgroundColor: checkResult(item) }}
              >
                <div>
                  <b>Question #{index + 1}</b>
                  {renderText(item.ques_desc)}
                </div>
                <div
                  style={{ borderTop: '1px solid #ccc', paddingTop: '10px' }}
                >
                  {item.ques_type_id === 4 || item.ques_type_id === 5 ? (
                    <div>
                      <p style={{ fontWeight: 'bold' }}>Answer</p>
                      {item.ques_ans_desc !== null
                        ? renderText(item.ques_ans_desc)
                        : 'NO ANSWER'}
                    </div>
                  ) : null}

                  {item.ques_type_id === 1 || item.ques_type_id === 2 ? (
                    <div>
                      <Row>
                        <Col md={1}>1</Col>
                        <Col md={11}>{renderText(item.option1)}</Col>
                      </Row>
                      <Row>
                        <Col md={1}>2</Col>
                        <Col md={11}>{renderText(item.option2)}</Col>
                      </Row>
                      <Row>
                        <Col md={1}>3</Col>
                        <Col md={11}>{renderText(item.option3)}</Col>
                      </Row>
                      <Row>
                        <Col md={1}>4</Col>
                        <Col md={11}>{renderText(item.option4)}</Col>
                      </Row>
                    </div>
                  ) : null}
                  {item.ques_type_id === 3 ? (
                    <div>
                      <Row>
                        <Col md={1}>1</Col>
                        <Col md={11}>True</Col>
                      </Row>
                      <Row>
                        <Col md={1}>2</Col>
                        <Col md={11}>False</Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
                {item.ques_type_id === 4 || item.ques_type_id === 5 ? (
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                      marginTop: '10px',
                      paddingTop: '5px',
                    }}
                  >
                    <Row>
                      <Col md={8}>
                        <p style={{ fontWeight: 'bold' }}>
                          Candidate Response(s)
                        </p>
                        <p>{item.can_resp_desc_ans}</p>
                      </Col>
                      {/* <Col md={4}>
                        {item.cand_responses.ans === item.ques_ans.ans ? (
                          <p style={{ fontWeight: 'bold', color: '#4CAF50' }}>
                            CORRECT
                          </p>
                        ) : (
                          <p style={{ fontWeight: 'bold', color: '#FF0000' }}>
                            INCORRECT
                          </p>
                        )}
                      </Col> */}
                    </Row>
                  </div>
                ) : null}
              </div>
            );
          })}
        </Col>
      </Row>
    </>
  );
}

export default CandidateResult;
