import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './homepage.css'
import { site_ip } from './globalSettings';
const Home = () => {
    const navigate = useNavigate();

    const blankValues = {
        name: '',
        email: '',
        telephone: '',
        job_title: '',
        industry_id:''
    };
    const [values, setValues] = useState(blankValues)

    const reviews = [
        { _id: 1, label: 'Corporate Clients', numbers: '300+' },
        { _id: 2, label: 'Skills Assessed', numbers: '25+' },
        { _id: 3, label: 'Students Assessed Annually', numbers: '2.5 million' },
        { _id: 4, label: 'Exams Annually', numbers: '400+' },
        { _id: 5, label: 'Assessments Annually', numbers: '80+' },
        { _id: 6, label: 'Countries Reached', numbers: '20+' },
        { _id: 7, label: 'Industries Assessed', numbers: '1000+' },
        { _id: 8, label: 'Academic Clients', numbers: '15+' },
    ];

    const [formOpen, setFormOpen] = useState(false);

    const handleChange = (e) =>{
        let {name, value} = e.target;
        setValues({...values, [name]:value})
        if(name === 'job_title'){
            setValues({...values, [name]:value, industry_id:''})
            fetchIndustryList(value)
        }else{
            setValues({...values, [name]:value})
        }
    }

    useEffect(()=>{
        fetchJobList()
        setTimeout(()=>{
            setFormOpen(true);
        }, 3000)
    }, [])



    const [jobList, setJobList] = useState([])
    const fetchJobList = () => {
        axios.get(
            site_ip + '/getJobMaster'
        ).then((res) => {
            setJobList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const [industryList, setIndustryList] = useState([])
    const fetchIndustryList = (value) => {
        axios.get(
            site_ip + '/getJobListByIndustryId/'+value
        ).then((res) => {
            setIndustryList(res.data.Content)
        }).catch((err) =>
            console.log(err)
        )
    }

    const handleSubmit = () =>{
        console.log("Wait")
    }

    return (
        <div>
            <div className="fix-header sticky">
                <Container>
                    <Row style={{ margin: "0px" }}>
                        <Col md={3} style={{ padding: 0 }}>
                            <img src="/home/logo.png" className="header-logo-img" />
                        </Col>
                        <Col md={9}>
                            <div className="navigation">
                                <ul>
                                    <li>
                                        <a aria-current="page" className="active" href="/">Home</a>
                                    </li>
                                    <li>
                                        <a className="" href="/#solutions">Solutions</a>
                                    </li>
                                    <li>
                                        <a className="" href="/contents">Contents</a>
                                    </li>
                                    <li>
                                        <a className="" href="/contact-us">Contact Us</a>
                                    </li>
                                    <li>
                                        <a className="" href="/signup">Register</a>
                                    </li>
                                    <li>
                                        <button onClick={() => navigate('/login')} className="signinbtn">Login</button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Row style={{ margin: "0px", marginBottom: "40px" }} id="solutions">
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue" style={{paddingTop:"30px", paddingBottom:"15px", marginBottom:"20px"}}>
                        <Container>
                            <p className="text-center text-white heading-1">
                                Blogs
                            </p>
                        </Container>
                    </div>
                    <Container>
                        <div>
                            <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:</p>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”</p>
                            <p>The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content.</p>
                            <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                            <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:</p>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”</p>
                            <p>The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content.</p>
                            <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row style={{ margin: "0px" }}>
                <Col style={{ padding: 0 }}>
                    <div className="bg-dark-blue top-bottom-footer-space">
                        <Container>
                            {/* <p className="text-center text-white heading-1">
                                Trusted by More Than 6000 Clients Worldwide
                            </p> */}
                            <Row>
                                <Col>
                                    <p className="footer-header">COMPANY</p>
                                    <a href="/" onClick={(e) => {e.preventDefault(); navigate("/about-us")}} className="footer-link">About Us</a>
                                    <a href="#" className="footer-link">Our Team</a>
                                    {/* <a href="#" className="footer-link">News</a>
                                    <a href="#" className="footer-link">Customers</a>
                                    <a href="#" className="footer-link">Careers</a>
                                    <a href="#" className="footer-link">Information Security</a> */}
                                </Col>
                                {/* <Col>
                                    <p className="footer-header">PRODUCTS</p>
                                    <a href="#" className="footer-link">Talent Assessment Solutions</a>
                                    <a href="#" className="footer-link">Coding Assessment Solutions</a>
                                    <a href="#" className="footer-link">Hiring Solutions</a>
                                    <a href="#" className="footer-link">L&D Solutions</a>
                                    <a href="#" className="footer-link">Online Examination Solution</a>
                                    <a href="#" className="footer-link">Remote Proctoring Tools</a>
                                    <a href="#" className="footer-link">360-degree Feedback Tool</a>
                                    <a href="#" className="footer-link">Hackathon Tool</a>
                                    <a href="#" className="footer-link">Test Library</a>
                                </Col> */}
                                {/* <Col>
                                    <p className="footer-header">OTHERS</p>
                                    <a href="#" className="footer-link">Resources</a>
                                    <a href="#" className="footer-link">Blog</a>
                                    <a href="#" className="footer-link">Glossary</a>
                                    <a href="#" className="footer-link">Webinars</a>
                                    <a href="#" className="footer-link">Help & Support</a>
                                    <a href="#" className="footer-link">Accessibility</a>
                                    <a href="#" className="footer-link">COVID-19 Support</a>
                                </Col> */}
                                <Col></Col>
                                <Col>
                                    {/* <p className="footer-header">Partners</p>
                                    <a href="#" className="footer-link">Strategic Partnerships</a>
                                    <a href="#" className="footer-link">Integration Partners</a>
                                    <br /> */}
                                    <p className="footer-header">CALL US</p>
                                    <a href="#" className="footer-link"><i class="fas fa-mobile-alt"></i> +91-9988 77 66 55</a>
                                    <a href="#" className="footer-link"><i class="far fa-envelope"></i> liz@quantauminsight.in</a>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </div>

    )
}

export default Home