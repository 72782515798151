import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import { site_ip } from '../../globalSetting';
import ErrorModal from '../common/ErrorModal';
import SuccessModal from '../common/SuccessModal';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
export default function EditCompanyJob() {
  const navigate = useNavigate();
  const params = useParams();
  const [values, setValues] = useState({
    company_id: "",
    job_id: "",
    industry_id: "",
    job_open_date: "",
    planned_closing_date: "",
    actual_closing_date: "",
    open_positions_count: "",
    job_desc_id: "",
    closed_positions_count: "",
    job_closing_status: "",
    client_name: ""
  });
  const [companies, setCompanies] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [jds, setJds] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const getIndustry = () => {
    axios
      .get(site_ip + '/getIndustryList')
      .then((res) => {
        // console.log(res)
        setIndustries(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getJobs = () => {
    axios
      .get(site_ip + '/getJobMaster')
      .then((res) => {
        // console.log(res);
        setJobs(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getCompanies = () => {
    axios
      .get(site_ip + '/getCompanyList')
      .then((res) => {
        // console.log(res);
        setCompanies(res.data.Content);
      })
      .catch((err) => console.log(err));
  };
  const getAllJobDescriptions = () => {
    axios
      .get(site_ip + '/getAllJobDescriptions')
      .then((res) => {
        // console.log(res);
        setJds(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getIndustry();
    getCompanies();
    getAllJobDescriptions();
    getJobs();
    getCompanyJobById(params.id);
  }, []);

  const getCompanyJobById = (id) => {
    axios
      .get(site_ip + '/getCompanyJobById/' + id)
      .then((res) => {
        setValues(res.data.Content);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    let req = {...values};
    
    if(req.company_id === ""){
      setErrorMessage("Please select company.")
      return;
    }else if(req.job_id === ""){
      setErrorMessage("Please select job.")
      return;
    }else if(req.industry_id === ""){
      setErrorMessage("Please select industry.")
      return;
    }else if(req.job_desc_id === ""){
      setErrorMessage("Please select job description.")
      return;
    }else if(req.job_open_date.trim() === ""){
      setErrorMessage("Please enter job opening date.")
      return;
    }else if(req.planned_closing_date.trim() === ""){
      setErrorMessage("Please enter planned closing date.")
      return;
    }else if(req.open_positions_count === ""){
      setErrorMessage("Please enter planned closing date.")
      return;
    }else if(req.job_closing_status === ""){
      setErrorMessage("Please select closing status.")
      return;
    }
    axios
      .post(site_ip + '/setCompanyJobs', req)
      .then((res) => {
        setSuccessMessage('Job Position updated successfully');
      })
      .catch((err) => console.log(err));
  };
  return (
    <Container fluid>
      <Row className="admin-form-row">
        <Col>
          <div className="header">
            <h4>Update Job Position</h4>
          </div>
          <div className="p-3 border bg-white">
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Company <span className='required'>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="company_id"
                    value={values.company_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Company</option>
                    {companies?.map((company) => (
                      <option key={company.company_id} value={company.company_id}>
                        {company.company_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Job <span className='required'>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="job_id"
                    value={values.job_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Job</option>
                    {jobs?.map((job) => (
                      <option key={job.job_id} value={job.job_id}>
                        {job.job_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Industry <span className='required'>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="industry_id"
                    value={values.industry_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Industry</option>
                    {industries?.map((industry) => (
                      <option
                        key={industry.industry_id}
                        value={industry.industry_id}
                      >
                        {industry.industry_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Job Description <span className='required'>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="job_desc_id"
                    value={values.job_desc_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Job Description</option>
                    {jds?.map((jd) => (
                      <option key={jd.jd_id} value={jd.jd_id}>
                        {jd.jd_title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Job Opening Date <span className='required'>*</span></Form.Label>
                  <Form.Control
                    type="date"
                    name="job_open_date"
                    value={values.job_open_date}
                    onChange={handleChange}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Planned Closing Date <span className='required'>*</span></Form.Label>
                  <Form.Control
                    type="date"
                    name="planned_closing_date"
                    value={values.planned_closing_date}
                    onChange={handleChange}
                    min={values.job_open_date === "" ? moment(new Date()).format("YYYY-MM-DD") : values.job_open_date}
                  />
                </Form.Group>
              </Col>
              {/* <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Actual Closing Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="actual_closing_date"
                    value={values.actual_closing_date}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col> */}
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Open Positions <span className='required'>*</span></Form.Label>
                  <Form.Control
                    type="number"
                    name="open_positions_count"
                    value={values.open_positions_count}
                    onChange={handleChange}
                    min={1}
                  />
                </Form.Group>
              </Col>
              {/* <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Closed Positions</Form.Label>
                  <Form.Control
                    type="number"
                    name="closed_positions_count"
                    value={values.closed_positions_count}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col> */}
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Client Name <span className='required'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="client_name"
                    value={values.client_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Job Closing Status <span className='required'>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="job_closing_status"
                    value={values.job_closing_status}
                    onChange={handleChange}
                  >
                    <option value="">Select Status</option>
                    <option value="O">Open</option>
                    <option value="C">Closed</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} style={{ marginBottom: "15px" }}>
                <button
                  className="primary"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </Col>
            </Row>
          </div>  
        </Col>
      </Row>
      <ErrorModal errorMessage={errorMessage} setErrorMessage={() => setErrorMessage(null)} />
      <SuccessModal successMessage={successMessage} setSuccessMessage={() => navigate('/recruiter/jobs/list')} />
    </Container>
  );
}
