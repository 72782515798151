import React, { useEffect, useState } from 'react';
import { Table, Nav } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { site_ip } from '../../globalSetting';
const TestSeries = () => {
  const [data, setData] = useState([]);
  // const navigate = useNavigate();
  const fetchTestData = async () => {
    const response = await axios.get(site_ip + '/getTestSeries');
    setData(response.data.Content);
    console.log(response);
  };
  useEffect(() => {
    fetchTestData();
  }, []);
  const [activeTab, setActiveTab] = useState('Open');

  return (
    <>
      <Nav
        activeKey={activeTab}
        fill
        variant="pills"
        onSelect={(selectedKey) => {
          setActiveTab(selectedKey);
        }}
      >
        <Nav.Item>
          <Nav.Link eventKey="Open">Open</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Live">Live</Nav.Link>
        </Nav.Item>
      </Nav>
      <br />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Test Series Name</th>
            <th>Test Series Type</th>
            <th>Time Allowed</th>
            <th>Subscription Type</th>
            <th>Questions Count</th>
            <th>Start Test</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) =>
            item.series_type === activeTab ? (
              <tr>
                <td>{index + 1}</td>
                <td>{item.test_series_name}</td>
                <td>{item.series_type}</td>
                <td>{item.time_allowed + ' ' + item.time_unit} </td>
                <td>{item.subscription_type}</td>
                <td>{item.questions_count}</td>
                <td>
                  <Link
                    to={{
                      pathname: '/learner/instruction-page',
                      state: item,
                    }}
                  >
                    Start
                  </Link>
                </td>
              </tr>
            ) : null
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TestSeries;
