import React from "react";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { site_ip } from "../../../../../globalSettings";
import Swal from "sweetalert2";

const CollegeDashboard =()=>{


    

    return (<>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div className="dashboard-card-box">
                                <div className="card-icon-div">
                                <div className="icon-bg">
                                    <i className="fas fa-question card-icon"></i>
                                </div>
                                </div>
                                <p className="dashboard-card-number">100</p>
                                <div className="dashboard-card-fix-bottom green-gradiant">
                                     Students
                                </div>
                            </div>
                        </Col>
                        <Col>
                        <div className="dashboard-card-box">
                            <div className="card-icon-div">
                                <div className="icon-bg">
                                    <i className="fas fa-paste card-icon"></i>
                                </div>
                            </div>
                            <p className="dashboard-card-number">52</p>
                            <div className="dashboard-card-fix-bottom green-gradiant">
                                Test Assigned Count
                            </div>
                        </div>
                        </Col>
                        <Col>
                            <div className="dashboard-card-box">
                                <div className="card-icon-div">
                                    <div className="icon-bg">
                                        <i className="fas fa-list-ol card-icon"></i>
                                    </div>
                                </div>
                                <p className="dashboard-card-number">41</p>
                                <div className="dashboard-card-fix-bottom green-gradiant">Test Count</div>
                            </div>
                        </Col>

                        
                    </Row>
                </Container>
            </div>
    </>)
}

export default CollegeDashboard;