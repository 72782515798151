import React, { useState, useEffect } from 'react';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import { TextField } from '@material-ui/core';
import axios from 'axios';
import './dialog.css';
// import MenuItem from '@material-ui/core/MenuItem';

import { Button, Dialog, DialogActions,DialogContent,DialogContentText,TextField, MenuItem , DialogTitle} from '@mui/material';


import {} from 'react-bootstrap';
import { site_ip } from '../../../globalSettings';
export default function FormDialog({
  open,
  handleClose,
  data,
  onChange,
  handleFormSubmit,
}) {
  const { id, industry_id, job_id, subject_id, topic_id, tag_name } = data;

  //const [selected_industry,setSelectedIndustry]=useState(0);

  const [industrieslist, setindustriesList] = useState([]);
  const [JobsList, setJobsList] = useState([]);
  const [SubjectList, setSubjectList] = useState([]);
  const [TopicList, setTopicList] = useState([]);

  useEffect(() => {
    const loadIndustries = async () => {
      axios({
        url: site_ip + '/getIndustries',
      }).then(function (response) {
        console.log(response.data);

        setindustriesList(response.data.content);
      });
    };
    //console.log(setindustriesList)
    loadIndustries();
  }, []);

  useEffect(() => {
    const loadjob = async () => {
      axios({
        url: site_ip + '/getJobsList',
      }).then(function (response) {
        console.log(response.data);

        setJobsList(response.data.content);
      });
    };
    //console.log(setindustriesList)
    loadjob();
  }, []);

  useEffect(() => {
    const loadSubject = async () => {
      axios({
        url: site_ip + '/getSubjectList',
      }).then(function (response) {
        console.log(response.data);

        setSubjectList(response.data.content);
      });
    };
    //console.log(setindustriesList)
    loadSubject();
  }, []);

  useEffect(() => {
    if (topic_id !== 0) {
      loadTopic(subject_id);
    }
    //eslint-disable-next-line
  }, []);

  const loadTopic = async (subject_id) => {
    axios({
      url: site_ip + '/getTopicsBySubject/' + subject_id,
    }).then(function (response) {
      setTopicList(response.data.content);
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {id ? 'Update Question Tag' : 'Create Question Tag'}
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              id="tag_name"
              value={tag_name}
              onChange={(e) => onChange(e)}
              placeholder="Enter Question Tag"
              label=""
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="industry_id"
              select
              placeholder="Industry List"
              value={industry_id}
              onChange={(e) => onChange(e, 'industry_id')}
              margin="dense"
              fullWidth
              variant="outlined"
            >
              <MenuItem key={0} value={0}>
                {'Select Industry'}
              </MenuItem>
              {industrieslist.map((option) => (
                <MenuItem key={option.industry_id} value={option.industry_id}>
                  {option.industry_name}
                </MenuItem>
              ))}
            </TextField>

            <br />

            <TextField
              id="job_id"
              select
              value={job_id}
              onChange={(e) => onChange(e, 'job_id')}
              margin="dense"
              fullWidth
              variant="outlined"
            >
              <MenuItem key={0} value={0}>
                {'Select Job'}
              </MenuItem>
              {JobsList.map((option) => (
                <MenuItem key={option.job_id} value={option.job_id}>
                  {option.job_name}
                </MenuItem>
              ))}
            </TextField>

            <br />
            <TextField
              id="subject_id"
              select
              value={subject_id}
              onChange={(e) => {
                onChange(e, 'subject_id');
                loadTopic(e.target.value);
              }}
              margin="dense"
              fullWidth
              variant="outlined"
            >
              <MenuItem key={0} value={0}>
                {'Select Subject'}
              </MenuItem>
              {SubjectList.map((option) => (
                <MenuItem key={option.subject_id} value={option.subject_id}>
                  {option.subject_name}
                </MenuItem>
              ))}
            </TextField>

            <br />
            <TextField
              id="topic_id"
              select
              value={topic_id}
              onChange={(e) => onChange(e, 'topic_id')}
              margin="dense"
              fullWidth
              variant="outlined"
            >
              <MenuItem key={0} value={0}>
                {'Select Topic'}
              </MenuItem>
              {TopicList.map((option) => (
                <MenuItem key={option.topic_id} value={option.topic_id}>
                  {option.topic_name}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => handleFormSubmit()}
            variant="contained"
          >
            {id ? 'Update' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
